import React, { useState } from 'react'
import '../../assets/css/appointments/appointmentslist.css'
import {
	Card,
	List,
	Space,
	Table,
	Button,
	Row,
	Col,
	message,
	Pagination,
	Avatar
} from 'antd'
import { commonComponents } from '../common'
import { UPDATE_APPOINTMENTS } from '../../graphql/appointments/mutation'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getName } from '../common/get-name'
import { getDateFormat } from '../../utils'
import { UserOutlined } from '@ant-design/icons'

import { sendMessage } from '../../firebase-messaging'

const { ConfirmModal, RadioModal, InputModal } = commonComponents

const PendingAppointmentsList = props => {
	const { t, i18n } = useTranslation()

	const { page, setPage, data, refetch, count, loading } = props
	const [selectModalVisible, showInputModal] = useState(false)
	const [radioModalVisible, showRadioModal] = useState(false)
	const [updateAppointment] = useMutation(UPDATE_APPOINTMENTS)
	const [[selected, patientid], setSelected] = useState(['',''])

	/* Table Columns */
	const tablecolumns = [
		{
			title: t('patients.patient'),
			className: 'appoint-table-header',
			dataIndex: 'patient',
			align: 'left',
		},
		{
			title: t('appointments.appointment_for'),
			className: 'appoint-table-header',
			dataIndex: 'purpose',
			align: 'left',
		},
		{
			title: t('appointments.schedule'),
			className: 'appoint-table-header',
			dataIndex: 'schedule',
			align: 'left',
		},
		{
			title: t('crud.contact_num'),
			className: 'appoint-table-header',
			dataIndex: 'contact',
			align: 'left',
		},
	]

	const tableDataSource = item => {
		return [
			{
				patient:
					getName(
						item.patient.user.first_name,
						item.patient.user.last_name,
						i18n.language,
					) || [],
				purpose: t(`appointments.${item.name}`),
				datetime: item.datetime,
				schedule: getSchedule(item.datetime),
				contact: item.patient.user.telephone,
				email: item.patient.user.email,
			},
		]
	}

	const dataInfo = item => {
		const newdata = tableDataSource(item)[0]

		return (
			<Row gutter = {[8,16]}
				className = "appoint-list-data" 
				justify="space-around">
				<Col xs = {12} sm = {12} md = {6}>
					<h2>{t('common.name')}</h2>
					<h1>{newdata.patient}</h1>
				</Col>
				<Col xs = {12} sm = {12} md = {6}>
					<h2>{t('appointments.appointment_for')}</h2>
					<h1>{newdata.purpose}</h1>
				</Col>
				<Col xs = {12} sm = {12} md = {6}>
					<h2>{t('appointments.schedule')}</h2>
					<h1>{newdata.schedule}</h1>
				</Col>
				<Col xs = {12} sm = {12} md = {6}>
					<h2>{t('crud.contact_num')}</h2>
					<h1>{newdata.contact}</h1>
				</Col>
				
			</Row>
		)
	}

	const options = [
		{ optionValue: '1', title: t('appointments.unavailable_hour') },
		{ optionValue: '2', title: t('appointments.attending_emergency') },
		{ optionValue: '3', title: t('appointments.emergency_leave') },
		{ optionValue: '4', title: t('appointments.other') },
	]

	const getSchedule = datetime => {
		let format = ''

		if (i18n.language === 'en') format = `${getDateFormat(i18n)} HH:mm`
		else format = `${getDateFormat(i18n)} dddd, HH:mm`

		return moment(datetime).format(format)
	}

	const sendConfirm = (userid, appointid) => {
		let data = {
			'data' : {
				'notiftype': 'appoint-approve',
				'appointmentid' : appointid 
			}
		}

		sendMessage(userid, data)
	}

	const sendDeclined = (userid, appointid) => {
		let data = {
			'data' : {
				'notiftype': 'appoint-decline',
				'appointmentid' : appointid
			}
		}

		sendMessage(userid,data)
	}

	const checkIfDatePassed = (datetime) => {
		let mom = moment(datetime)
		return mom.isSameOrBefore(moment(), 'minute')
	}

	return (
		<>
			<List
				loading={loading}
				dataSource={data}
				itemLayout='vertical'
				size='large'
				locale={{ emptyText: t('empty_messages.pending_appointments_list') }}
				renderItem={item => (
					<List.Item key={item.id} style={{ marginTop: 10, marginBottom: 10 }}>
						<Card className='appoint-card'>
							<Row gutter = {[8,16]}>
								<Col span={1}
									 style = {{minWidth: '70px'}}
								>
									<Avatar
										size={55}
										style={{ resizeMode: 'stretch' }}
										icon={<UserOutlined />}
										src={((item.patient || {}).user || {}).avatar_link}
									/>
								</Col>
								<Col 
									  md = {16}
									  sm = {15}
									  flex = "auto"
								>
									{/* <Table
										className='appoint-list'
										size='small'
										dataSource={tableDataSource(item)}
										columns={tablecolumns}
										pagination={false}
									/> */}
									{dataInfo(item)}
								</Col>
								<Col sm = {5}
									 md = {5}
									 flex = "auto"
								>
									<Row gutter = {[16,8]}>
										<Col xs = {24} md = {24} lg = {12}>
											<Button
														className='pending thin-bordered-btn'
														onClick={() => {
															ConfirmModal({
																mtitle: t('appointments.sure_cancel_appointment'),
																mcontent: t('confirm.cannot_undo'),
																monOk: () => {
																	if (!checkIfDatePassed(item.datetime)){
																		setSelected([item.id, item.patient.user.id])
																		showRadioModal(true)
																	} else{
																		message.error('appointment date has passed!')
																	}
																},
																t,
															})
														}}
														disabled = {moment(item.datetime).isBefore(moment())}
													>
														{t('appointments.decline')}
											</Button>
										</Col>
										<Col xs = {24} md = {24} lg = {12}>
											<Button
														className='pending horizontal-resizable-btn'
														onClick={() => {
															if (!checkIfDatePassed(item.datetime)){
																setSelected([item.id, item.patient.user.id])
																showInputModal(true)
															} else{
																message.error('appointment date has passed!')
															}
														}}
														disabled = {moment(item.datetime).isBefore(moment())}
													>
														{t('appointments.approve')}
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card>
					</List.Item>
				)}
			></List>
			<Pagination
				className='float-right'
				current={page}
				total={count || 0}
				hideOnSinglePage={true}
				showSizeChanger={false}
				onChange={page => {
					setPage(page)
				}}
			/>
			<InputModal
				visible={selectModalVisible}
				setVisible={showInputModal}
				submitCallback={value => {
					updateAppointment({
						variables: {
							_set: {
								status: 'active',
								location: value,
							},
							where: {
								id: {
									_eq: selected,
								},
							},
						},
					}).then(
						() => {
							refetch()
							message.success(t('appointments.success_update'))
							sendConfirm(patientid, selected)
						},
						changeError => {
							message.error(changeError.message)
						},
					)
				}}
			/>
			<RadioModal
				visible={radioModalVisible}
				setVisible={showRadioModal}
				submitCallback={value => {
					updateAppointment({
						variables: {
							_set: {
								status: 'declined', // to set cancelled as declined
								notes: options.find(item => item.optionValue === value).title,
							},
							where: {
								id: {
									_eq: selected,
								},
							},
						},
					}).then(
						() => {
							refetch()
							message.success(t('appointments.success_cancel'))
							sendDeclined(patientid, selected)
						},
						changeError => {
							message.error(changeError.message)
						},
					)
				}}
				options={options}
				defaultValue={'1'}
			/>
		</>
	)
}

export default PendingAppointmentsList
