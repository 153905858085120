import DoctorList from './doctor-list'
import PatientList from './patient-list'
import AppointmentList from './appointments/appointment-list'
import CancelledAppointments from './appointments/cancelled-appointments'
import ActiveAppointments from './appointments/active-appointments'
import PendingAppointments from './appointments/pending-appointments'
import Newsfeed from './newsfeed'

export const dashboardComponents = {
	DoctorList,
	PatientList,
	AppointmentList,
	Newsfeed,
	CancelledAppointments,
	ActiveAppointments,
	PendingAppointments,
}
