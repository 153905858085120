import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
	Form,
	message,
	DatePicker,
	Spin,
	Divider,
	Select,
	Input,
	InputNumber,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_COURSE_OF_PREGNANCIES } from '../../../../graphql/course-of-pregnancies/mutations'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'
import { getDateFormat } from '../../../../utils'

const { BlockButton } = commonComponents

const AddEditCourseRecord = props => {
	const { t, i18n } = useTranslation()

	const {
		form,
		record,
		doctor,
		refetch,
		visible,
		hidePanel,
		pregnancy,
		latestRecord = {},
	} = props

	const { pregnancy_id } = useParams()
	const [isLoading, setLoading] = useState(false)
	const [weekOfPregnancy, setWeekOfPregnancy] = useState(
		record && record.week_of_pregnancy ? record.week_of_pregnancy : null,
	)
	const [upsertCourses] = useMutation(UPSERT_COURSE_OF_PREGNANCIES)

	useEffect(() => {
		form.resetFields()
	}, [visible])

	useEffect(() => {
		form.setFieldsValue({ week_of_pregnancy: weekOfPregnancy })
	}, [weekOfPregnancy])

	// removes undefined, updates '' to null, formats datetime
	const transformValues = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else {
					if (key === 'exam_date')
						newValues[key] = moment(values[key]).format('YYYY-MM-DD')
					else if (key === 'blood_pressure') newValues[key] = values[key] + ''
					else newValues[key] = values[key]
				}
			}
		}

		return newValues
	}

	const handleUpdate = values => {
		setLoading(true)

		const doctor_id = doctor && doctor.id ? { doctor_id: doctor.id } : {}

		upsertCourses({
			variables: {
				objects: {
					id: record && record.id ? record.id : undefined,
					...doctor_id,
					pregnancy_id,
					...transformValues(values),
				},
			},
		}).then(
			() => {
				refetch()
				hidePanel()
				setLoading(false)
				form.resetFields()
				if (record && record.id) message.success(t('course.success_update'))
				else message.success(t('course.success_add'))
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const getRecordFormItems = () => {
		const formItems = [
			{
				name: 'exam_date',
				label: t('course.checkup_date'),
				type: 'datepicker',
				placeholder: t('crud.select_date'),
				onChange: date => {
					const start_date = moment(pregnancy.start_date).format('YYYY-MM-DD')
					const weeks = date
						? moment(date).diff(moment(start_date), 'weeks') + 1
						: null

					setWeekOfPregnancy(weeks <= 45 ? weeks : null)
				},
				rules: [
					{
						required: true,
						message: t('dialog.required_field'),
					},
				],
			},
			{
				name: 'week_of_pregnancy',
				label: t('course.week_pregnancy'),
				type: 'select',
				onChange: value => setWeekOfPregnancy(value),
				options: [...getWeeksOfPregnancy()],
				placeholder: t('crud.select_a_week'),
				rules: [
					{
						required: true,
						message: t('dialog.required_field'),
					},
				],
			},
			{
				name: 'height_of_uterus',
				label: `${t('course.height_uterus')} (cm)`,
				type: 'number',
				placeholder: `${t('common.eg')} 16`,
			},
			{
				name: 'circumference_of_abdomen',
				label: `${t('course.circumference')} (cm)`,
				type: 'number',
				placeholder: `${t('common.eg')} 16`,
			},
			{
				name: 'weight',
				label: `${t('health.weight')} (kg)`,
				type: 'number',
				placeholder: `${t('common.eg')} 58`,
			},
			{
				name: 'blood_pressure',
				label: `${t('course.blood_pressure')} (mmHg)`,
				type: 'number',
				placeholder: `${t('common.eg')} 120`,
			},
			{
				name: 'edema',
				label: t('course.edema'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'none', label: t('crud.none') },
					{ value: 'moderate', label: t('course.moderate') },
					{ value: 'severe', label: t('course.severe') },
				],
				placeholder: t('course.eg_select'),
			},
			{
				name: 'protein_in_urine',
				label: t('course.protein'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'none', label: t('crud.none') },
					{ value: 'moderate', label: t('course.moderate') },
					{ value: 'severe', label: t('course.severe') },
				],
				placeholder: t('course.eg_select'),
			},
			{
				name: 'sugar_in_urine',
				label: t('course.sugar'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'none', label: t('crud.none') },
					{ value: 'moderate', label: t('course.moderate') },
					{ value: 'severe', label: t('course.severe') }
				],
				placeholder: t('course.eg_select'),
			},
			{
				name: 'notes',
				label: t('crud.notes'),
				type: 'input',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'medical_institution',
				label: t('crud.medical_institution'),
				type: 'input',
				placeholder: t('baby_checkups.name_of_medical_institution'),
			},
		]

		return formItems.map(formItem => (
			<Form.Item
				name={formItem.name}
				label={formItem.label}
				className='field-input'
				rules={formItem.rules || []}
				initialValue={
					record && record[formItem.name] !== null
						? formItem.type === 'datepicker'
							? moment(record[formItem.name])
							: record[formItem.name]
						: formItem.initialValue || undefined
				}
			>
				{getFormField(formItem)}
			</Form.Item>
		))
	}

	const getFormField = formItem => {
		if (formItem.type === 'input')
			return (
				<Input
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'number')
			return (
				<InputNumber
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
					min={0}
					max={99999}
				/>
			)
		else if (formItem.type === 'select')
			return (
				<Select
					showArrow
					bordered={false}
					autoComplete='off'
					onChange={formItem.onChange}
					options={formItem.options}
					style={{ width: '100%' }}
					suffixIcon={<DownOutlined />}
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'datepicker')
			return (
				<DatePicker
					allowClear={false}
					autoComplete='off'
					format={getDateFormat(i18n)}
					style={{ width: '100%' }}
					className='input-component'
					onChange={formItem.onChange}
					placeholder={formItem.placeholder}
					disabledDate={current => {
						if (current.diff(moment(pregnancy.start_date)) > 0) return false

						return true
					}}
				/>
			)
	}

	const getWeeksOfPregnancy = () => {
		const weeks = []

		for (let i = 1; i <= 45; i++) {
			weeks.push({ value: i, label: `${capitalize(t('common.week'))} ${i}` })
		}

		return weeks
	}

	return (
		<Form
			form={form}
			layout='vertical'
			onFinish={handleUpdate}
		>
			<div className='drawer-title'>
				{!record && t('course.add_course_of_pregnancy')}
				{record && t('course.edit_course_of_pregnancy')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				{getRecordFormItems()}
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditCourseRecord
