import React, { useState } from 'react'
import { Avatar, Upload, message } from 'antd'
import {
	UserOutlined,
	CameraOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const ProfilePicture = props => {
	const { t } = useTranslation()

	const { saveImage = () => {} } = props
	const profileSrc = props.profileSrc

	/*Displays profile picture*/
	const NormalPhoto = subprops => {
		if (subprops.profileSrc && subprops.profileSrc !== '') {
			return (
				<img
					className='circle-image'
					style={{ width: '109px', height: '109px' }}
					src={subprops.profileSrc}
				/>
			)
		} else {
			return <Avatar size={109} icon={<UserOutlined />} shape='circle' />
		}
	}

	/*Overlay during edit mode*/
	const EditPhoto = () => {
		const [uploadState, setUploading] = useState({
			isUploading: false,
			uploadSrc: null,
		})

		const getBase64 = (img, callback) => {
			const reader = new FileReader()
			reader.addEventListener('load', () => callback(reader.result))
			reader.readAsDataURL(img)
		}

		const uploadprops = {
			name: 'file',
			customRequest: ({ onSuccess }) => {
				setTimeout(() => {
					onSuccess()
				}, 0)
			},
			accept: '.png,.jpg,.jpeg',
			showUploadList: false,
			listType: 'picture',
			onChange(info) {
				if (info.file.status === 'uploading') {
					setUploading({ isUploading: true })
				} else {
					if (info.file.status === 'done') {
						getBase64(info.file.originFileObj, imageUrl => {
							saveImage(info.file, imageUrl)
							setUploading({
								uploadSrc: imageUrl,
								isUploading: false,
							})
						})
					} else if (info.file.status === 'error') {
						setUploading({ isUploading: false })
						message.error(`${info.file.name} ${t('crud.file_upload_failed')}`)
					}
				}
			},
		}

		const EditPhotoState = () => {
			if (uploadState.isUploading === true) {
				return <LoadingOutlined />
			} else {
				return (
					<>
						<CameraOutlined
							style={{ fontSize: '40px', margin: '0px', padding: '0px' }}
						/>
						<span className='upload-text'>{t('crud.upload')}</span>
					</>
				)
			}
		}

		return (
			<Upload {...uploadprops}>
				<div className='overlay-container'>
					<div className='image-overlay'>
						<EditPhotoState />
					</div>
					<NormalPhoto profileSrc={profileSrc} />
				</div>
			</Upload>
		)
	}

	if (!props.isEdit) {
		return <NormalPhoto profileSrc={profileSrc} />
	} else {
		return <EditPhoto />
	}
}

export default ProfilePicture
