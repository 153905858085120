import BlockButton from './block-button'
import Sidepanel from './side-panel'
import { ConfirmModal, RadioModal, InputModal } from './modals'

export const commonComponents = {
	BlockButton,
	Sidepanel,
	ConfirmModal,
	RadioModal,
	InputModal,
}
