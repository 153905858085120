//change the endpoint in the future if the s3 is going to be moved to another account

const endpoint = process.env.REACT_APP_API_CLIENT

//add the location of the images here
//remember to append the directory name if it is inside a folder
const images = {
  loginbanner: 'backdrop.png',
  doctorbanner: 'resetbackdrop.png',
}


const getImage = (key) => {
  return `${endpoint}/${images[key]}`
}

export default getImage