import gql from 'graphql-tag'

export const GET_BABY_IMMUNIZATIONS = gql`
	query GET_BABY_IMMUNIZATIONS($where: baby_immunizations_bool_exp) {
		baby_immunizations(where: $where, order_by: { date: desc }) {
			id
			date
			vaccine
			manufacturer
			notes
			doctor {
				id
				user {
					id
					first_name
					last_name
				}
			}
		}
	}
`
