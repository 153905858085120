import React from 'react'
import '../../assets/css/common/blockbutton.css'
import { Button } from 'antd'

const BlockButton = props => {
	return (
		<Button
			block
			className={`block-button ${props.className || ''}`}
			onClick={props.onClick}
			disabled={props.disabled}
			htmlType={props.htmlType || 'button'}
			style={props.style || {}}
		>
			{props.title}
		</Button>
	)
}

export default BlockButton
