import React, { useEffect, useState, useRef } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
	Typography,
	Col,
	Row,
	Collapse,
	Button,
	Divider,
	Dropdown,
	Menu,
	Form,
	message,
} from 'antd'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { GET_COURSE_OF_PREGNACIES } from '../../../../graphql/course-of-pregnancies/queries'
import { UPDATE_COURSE_OF_PREGNANCIES } from '../../../../graphql/course-of-pregnancies/mutations'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { commonComponents } from '../../../common'
import AddEditCourseRecord from './add-edit-drawer'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'
import _ from 'lodash'

const { Panel } = Collapse
const { Sidepanel, ConfirmModal } = commonComponents

const CourseRecords = props => {
	const { t, i18n } = useTranslation()

	const { doctor, pregnancy } = props
	const { pregnancy_id } = useParams()

	const panel = useRef()
	const [form] = Form.useForm()
	const [courses, setCourses] = useState([])
	const [activePanel, setActivePanel] = useState(undefined)

	const [updateCourse] = useMutation(UPDATE_COURSE_OF_PREGNANCIES)
	const { data, refetch } = useQuery(GET_COURSE_OF_PREGNACIES, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				pregnancy_id: {
					_eq: pregnancy_id,
				},
			},
		},
	})

	useEffect(() => {
		if (!data) return

		setActivePanel(undefined)
		setCourses(data.course_of_pregnancies)
	}, [data])

	const courseItemHeader = header => {
		return (
			<>
				<Typography
					style={{
						color: '#969999',
						fontWeight: 500,
						fontSize: 12,
						lineHeight: '20px',
					}}
				>
					{header.exam_date
						? moment(header.exam_date).format(getDateFormat(i18n))
						: t('crud.n_a')}
				</Typography>
				<Typography
					style={{ color: '#3B3F3F' }}
					className='pregnancy-record-collapse-header'
				>{`${capitalize(t('common.week'))} ${
					header.week_of_pregnancy
				}`}</Typography>
			</>
		)
	}

	const getOptionReference = key => {
		if (!key) return t('crud.n_a')

		const reference = {
			none: t('crud.none'),
			moderate: t('course.moderate'),
			severe: t('course.severe'),
		}

		return reference[key]
	}

	const courseItemContent = content => {
		let courseData = [
			{
				label: t('course.checkup_date'),
				data: `${
					content.exam_date
						? moment(content.exam_date).format(getDateFormat(i18n))
						: t('crud.n_a')
				}`,
			},
			{
				label: t('course.week_pregnancy'),
				data: `${t('common.week')} ${content.week_of_pregnancy}`,
			},
			{
				label: t('course.height_uterus'),
				data: content.height_of_uterus
					? `${content.height_of_uterus} cm`
					: t('crud.n_a'),
			},
			{
				label: t('health.weight'),
				data: content.weight 
				    ? `${content.weight} kg` 
					: t('crud.n_a')
			},
			{
				label: t('course.circumference'),
				data: content.circumference_of_abdomen
					? `${content.circumference_of_abdomen} cm`
					: t('crud.n_a'),
			},
			{
				label: t('course.blood_pressure'),
				data: content.blood_pressure
					? `${content.blood_pressure} mmHg`
					: t('crud.n_a'),
			},
			{
				label: t('course.edema'),
				data: getOptionReference(content.edema),
			},
			{
				label: t('course.protein'),
				data: getOptionReference(content.protein_in_urine),
			},
			{
				label: t('course.sugar'),
				data: getOptionReference(content.sugar_in_urine)
			}
		]

		let contentData = courseData.map((data, index) => {
			return (
				<Col span={12} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		return contentData
	}

	const courseItemDoctorRemarks = content => {
		let doctorName = `Dr. ${content.doctor.user.first_name} ${content.doctor.user.last_name}`
		let doctorData = [
			{
				label: `${t('doctors.notes_from_doctor')}:`,
				data: content.notes || t('crud.n_a'),
			},
			{
				label: `${t('doctors.doctor')}:`,
				data: doctorName,
			},
			{
				label: `${t('crud.medical_institution')}:`,
				data: content.medical_institution || t('crud.n_a'),
			},
		]

		let remarksData = doctorData.map((data, index) => {
			return (
				<Col span={24} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		return remarksData
	}

	const menu = record => (
		<Menu>
			<Menu.Item
				onClick={() => {
					panel.current.showPanel(
						<AddEditCourseRecord
							form={form}
							record={record}
							refetch={refetch}
							pregnancy={pregnancy}
							latestRecord={courses[0]}
						/>,
					)
				}}
			>
				{t('crud.edit')}
			</Menu.Item>
			<Menu.Item
				onClick={() =>
					ConfirmModal({
						mtitle: t('confirm.are_you_sure'),
						mcontent: t('confirm.cannot_undo'),
						monOk: () => {
							updateCourse({
								variables: {
									_set: {
										deleted_at: moment(),
									},
									where: {
										id: {
											_eq: record.id,
										},
									},
								},
							}).then(
								() => {
									refetch()
									message.success(t('course.success_delete'))
								},
								changeError => {
									message.error(changeError.message)
								},
							)
						},
						t,
					})
				}
			>
				{t('crud.remove')}
			</Menu.Item>
		</Menu>
	)

	const courseItems = courses.map((course, index) => {
		return (
			<Panel
				header={courseItemHeader(course)}
				key={index}
				className='site-collapse-custom-panel'
				style={{ padding: '10px 0' }}
			>
				<Dropdown overlay={menu(course)} placement='bottomRight'>
					<EllipsisOutlined className='ellipsis' />
				</Dropdown>
				<Row gutter={[16, 16]}>{courseItemContent(course)}</Row>
				<Divider />
				<Row gutter={[16, 16]}>{courseItemDoctorRemarks(course)}</Row>
			</Panel>
		)
	})

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>
						{t('course.patients_pregnancy_course')}
					</h1>
				</Col>
				<Col span={12}>
					<Button
						icon={<PlusOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditCourseRecord
									form={form}
									doctor={doctor}
									refetch={refetch}
									pregnancy={pregnancy}
									latestRecord={courses[0]}
								/>,
							)
						}
					>
						<span className='title'>{t('course.add_course')}</span>
					</Button>
				</Col>
			</Row>
			<Sidepanel ref={panel} />
			<Collapse
				accordion
				expandIconPosition='right'
				bordered={false}
				style={{ padding: 20 }}
				activeKey={activePanel}
				onChange={key => setActivePanel(key)}
				className='site-collapse-custom-collapse'
			>
				{courseItems}
			</Collapse>
		</>
	)
}
export default CourseRecords
