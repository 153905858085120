import gql from 'graphql-tag'

export const GET_HEALTH_REFERENCES = gql`
	query GET_HEALTH_REFERENCES($where: health_references_bool_exp) {
		health_references(where: $where) {
			id
			height
			weight
			high_blood
			chronic_neprhitis
			diabetis_mellitis
			hepatitis
			heart_desease
			tyroid_desease
			drinker
			smoker
			blood_type
			pregnancy {
				id
				patient {
					id
					date_of_birth
				}
			}
		}
	}
`
