import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, message, Spin, Divider, Select, Input, InputNumber } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_POST_PARTUMS } from '../../../../graphql/post-partums/mutations'
import { useTranslation } from 'react-i18next'

const { BlockButton } = commonComponents

const AddEditPostPartumRecord = props => {
	const { t } = useTranslation()

	const { hidePanel, visible, record, form, refetch } = props
	const { pregnancy_id } = useParams()
	const [isLoading, setLoading] = useState(false)
	const [upsertPostPartums] = useMutation(UPSERT_POST_PARTUMS)

	useEffect(() => {
		form.resetFields()
	}, [visible])

	const removeUndefinedValuesAndEmptyStringToNull = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else newValues[key] = values[key]
			}
		}

		return newValues
	}

	const handleUpdate = values => {
		setLoading(true)

		upsertPostPartums({
			variables: {
				objects: {
					id: record && record.id ? record.id : undefined,
					pregnancy_id,
					...removeUndefinedValuesAndEmptyStringToNull(values),
				},
			},
		}).then(
			() => {
				refetch()
				hidePanel()
				setLoading(false)
				form.resetFields()
				if (record && record.id)
					message.success(t('post_partum.success_update'))
				else message.success(t('post_partum.success_add'))
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const getRecordFormItems = () => {
		const formItems = [
			{
				name: 'days_post_delivery',
				label: t('post_partum.post_deliver_past'),
				type: 'input',
				placeholder: t('post_partum.post_deliver_past_placeholder'),
			},
			{
				name: 'breast_condition',
				label: t('post_partum.condition_breasts'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{
						value: 'benign_breast',
						label: t('post_partum.benign_breast'),
					},
					{ value: 'mastitis', label: t('post_partum.mastitis') },
					{ value: 'other', label: t('post_partum.other') },
				],
				placeholder: t('post_partum.select_condition'),
			},
			{
				name: 'weight',
				// label: t('post_partum.weight'),
				label: `${t('post_partum.weight')} (kg)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'sugar_in_urine',
				label: t('post_partum.sugar_in_urine'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'none', label: t('crud.none') },
					{ value: 'moderate', label: t('course.moderate') },
					{ value: 'severe', label: t('course.severe') },
				],
				placeholder: t('course.eg_select'),
			},
			{
				name: 'protien_in_urine',
				label: t('post_partum.protein_in_urine'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'none', label: t('crud.none') },
					{ value: 'moderate', label: t('course.moderate') },
					{ value: 'severe', label: t('course.severe') },
				],
				placeholder: t('course.eg_select'),
			},
			{
				name: 'vaginal_bleeding',
				label: t('post_partum.vaginal_bleeding'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'normal', label: t('post_partum.normal') },
					{ value: 'abnormal', label: t('post_partum.abnormal') },
				],
				placeholder: t('post_partum.eg_vaginal_bleeding'),
			},
		]

		return formItems.map(formItem => (
			<Form.Item
				name={formItem.name}
				label={formItem.label}
				className='field-input'
				initialValue={
					record && record[formItem.name] !== null
						? record[formItem.name]
						: undefined
				}
			>
				{getFormField(formItem)}
			</Form.Item>
		))
	}

	const getFormField = formItem => {
		if (formItem.type === 'input')
			return (
				<Input
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'number')
			return (
				<InputNumber
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
					min={0}
				/>
			)
		else if (formItem.type === 'select')
			return (
				<Select
					showArrow
					bordered={false}
					autoComplete='off'
					options={formItem.options}
					style={{ width: '100%' }}
					suffixIcon={<DownOutlined />}
					placeholder={formItem.placeholder}
				/>
			)
	}

	return (
		<Form
			form={form}
			layout='vertical'
			onFinish={handleUpdate}
		>
			<div className='drawer-title'>
				{!record && t('post_partum.add')}
				{record && t('post_partum.update_post')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				{getRecordFormItems()}
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditPostPartumRecord
