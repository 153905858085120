import React, { useRef, useState,  } from 'react'
import '../../assets/css/appointments/appointmentslist.css'
import ViewEditAppointment from './view-edit-appointment'
import { Card, List, Space, Table, Button, Row, Col, Pagination, Form, Avatar } from 'antd'
import { commonComponents } from '../common'
import { UPDATE_APPOINTMENTS } from '../../graphql/appointments/mutation'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getName } from '../common/get-name'
import { getDateFormat } from '../../utils'
import { sendMessage } from '../../firebase-messaging'
import { useForm } from 'antd/lib/form/Form'
import { UserOutlined } from '@ant-design/icons'

const { ConfirmModal, RadioModal, Sidepanel } = commonComponents

// Complete cancel appointment on this file

const AppointmentsList = props => {
	const { t, i18n } = useTranslation()

	const { page, setPage, data, refetch, count, mode, loading } = props
	const [radioModalVisible, showRadioModal] = useState(false)
	const [updateAppointment] = useMutation(UPDATE_APPOINTMENTS)
	const [selected, setSelected] = useState('')
	const panel = useRef()
	const [form] = Form.useForm()

	const statusColumn =
		mode === 'active'
			? [
					{
						title: t('appointments.status'),
						className: 'appoint-table-header',
						dataIndex: 'status',
						align: 'left',
					},
			  ]
			: []

	const tablecolumns = [
		{
			title: t('common.name'),
			className: 'appoint-table-header',
			dataIndex: 'patient',
			align: 'left',
		},
		...statusColumn,
		{
			title: t('appointments.appointment_for'),
			className: 'appoint-table-header',
			dataIndex: 'purpose',
			align: 'left',
		},
		{
			title: t('appointments.schedule'),
			className: 'appoint-table-header',
			dataIndex: 'schedule',
			align: 'left',
		},
		{
			title: t('crud.contact_num'),
			className: 'appoint-table-header',
			dataIndex: 'contact',
			align: 'left',
		},
		{
			title: t('crud.email'),
			className: 'appoint-table-header',
			dataIndex: 'email',
			align: 'left',
		},
	]

	const getStatus = datetime => {
		if (!mode || mode !== 'active') return ''

		let status = ''

		if (moment(datetime).isAfter(moment())) status = t('appointments.upcoming')
		else if (
			moment(datetime).isBefore(
				moment(moment().subtract(30, 'minutes').format()),
			)
		)
			status = t('appointments.done')
		else status = t('appointments.ongoing')

		return status
	}

	const sendDoctorCancelled = (userid,appointid) => {
		let data = {
			'data' : {
				'notiftype': 'appoint-cancelled-doctor',
				'appointmentid' : appointid
			}
		}

		sendMessage(userid,data)
	}

	const tableDataSource = item => {
		const first = ((item.patient || {}).user || {}).first_name || ''
		const last = ((item.patient || {}).user || {}).last_name || ''

		return [
			{
				patient: getName(first, last, i18n.language),
				status: getStatus(item.datetime),
				purpose: t(`appointments.${item.name}`),
				schedule: getSchedule(item.datetime),
				contact: ((item.patient || {}).user || {}).telephone,
				email: ((item.patient || {}).user || {}).email,
			},
		]
	}

	const dataInfo = item => {
		const newdata = tableDataSource(item)[0]

		const span = 4
		console.log(`span: ${span}`)
		const xsSpan = 12

		const displayStatus = () => {
			return mode === 'active' ? (
				<Col xs = {xsSpan} md = {8} sm = {xsSpan} lg = {span}>
					<h2>{t('appointments.status')}</h2>
					<h1>{newdata.status}</h1>
				</Col>
			) : (<></>)
		}
		
		return (
			<Row gutter = {[8,16]}
				className = "appoint-list-data" 
				justify="start">
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = { span + (mode === 'active' ? 0 : 1) }>
					<h2>{t('common.name')}</h2>
					<h1>{newdata.patient}</h1>
				</Col>
				{displayStatus()}
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('appointments.appointment_for')}</h2>
					<h1>{newdata.purpose}</h1>
				</Col>
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('appointments.schedule')}</h2>
					<h1>{newdata.schedule}</h1>
				</Col>
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('crud.contact_num')}</h2>
					<h1>{newdata.contact}</h1>
				</Col>
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('crud.email')}</h2>
					<h1>{newdata.email}</h1>
				</Col>
			</Row>
		)
	}

	const getSchedule = datetime => {
		let format = ''

		if (i18n.language === 'en') format = `${getDateFormat(i18n)} HH:mm`
		else format = `${getDateFormat(i18n)} dddd, HH:mm`

		return moment(datetime).format(format)
	}

	const getEmptyText = () => {
		if (mode === 'active') return t('empty_messages.active_appointments_list')
		else if (mode === 'pending')
			return t('empty_messages.pending_appointments_list')
		else if (mode === 'cancelled')
			return t('empty_messages.cancelled_appointments_list')

		return t('empty_messages.appointment_list')
	}

	const options = [
		{ optionValue: '1', title: t('appointments.unavailable_hour') },
		{ optionValue: '2', title: t('appointments.attending_emergency') },
		{ optionValue: '3', title: t('appointments.emergency_leave') },
		{ optionValue: '4', title: t('appointments.other') },
	]

	return (
		<>
			<List
				loading={loading}
				dataSource={data}
				itemLayout='vertical'
				size='large'
				locale={{ emptyText: getEmptyText() }}
				renderItem={item => (
					<List.Item key={item.id} style={{ marginTop: 10, marginBottom: 10 }}>
						<Card className='appoint-card'>
							<Row gutter = {[0,16]}>
								<Col
									span={1}
									style = {{minWidth: '70px'}}>
									
									<Avatar
										size={55}
										style={{ resizeMode: 'stretch' }}
										icon={<UserOutlined />}
										src={((item.patient || {}).user || {}).avatar_link}
									/>	
								</Col>
								<Col 
									lg = {22}
									md = {15}
									sm = {12}
									flex = "auto"
								>
									{/* <Table
										scroll = {{x: '1000px'}}
										className='appoint-list'
										size='small'
										dataSource={tableDataSource(item)}
										columns={tablecolumns}
										pagination={false}
									/> */}
									{dataInfo(item)}
								</Col>
								<Col 
									lg = {2}
									md = {5}
									sm = {{span: 5, offset: 2}}
								    flex = "auto"
									> 
										<Button
											className='appoint horizontal-resizable-btn'
											onClick={() => {
												panel.current.showPanel(
													<ViewEditAppointment
														onCancelAppointment={() => {
															ConfirmModal({
																mtitle: t(
																	'appointments.sure_cancel_appointment',
																),
																mcontent: t('confirm.cannot_undo'),
																monOk: () => {
																	setSelected(item.id)
																	showRadioModal(true)
																},
																t,
															})
														}}
														itemId={item.id}
														refetch={refetch}
														showStatus
														form = {form}
														mode={mode}
													/>,
												)
											}}
										>
											{t('crud.view')}
										</Button>
								</Col>
							</Row>
						</Card>
					</List.Item>
				)}
			/>

			<Pagination
				className='float-right'
				current={page}
				total={count}
				hideOnSinglePage={true}
				showSizeChanger={false}
				onChange={page => {
					setPage(page)
				}}
			/>
			<Sidepanel ref={panel} />
			<RadioModal
				visible={radioModalVisible}
				setVisible={showRadioModal}
				submitCallback={value => {
					updateAppointment({
						variables: {
							_set: {
								status: 'declined', // switch to declined when filter is enabled
								notes: options.find(item => item.optionValue === value).title,
							},
							where: {
								id: {
									_eq: selected,
								},
							},
						},
					}).then(data => {
						refetch()
						const userid = data.data.update_appointments.returning[0].patient.user_id
						sendDoctorCancelled(userid,selected)
					})
				}}
				options={options}
				defaultValue={'1'}
			/>
		</>
	)
}

export default AppointmentsList
