import gql from 'graphql-tag'

export const GET_USER_WITH_LATEST_MESSAGE_VIEW = gql`
  query GET_USER_WITH_LATEST_MESSAGE_VIEW($userId: uuid, $where: user_conversations_bool_exp, $limit: Int, $offset: Int){
    search_user_conversations(
      args: { user_id: $userId},
      where: $where,
      limit: $limit,
      offset: $offset
    ){
      id
      created_at
      text
      first_name
      last_name
      role
      is_seen
      avatar_link
      is_available
      sender_id
    }
  }
`

export const GET_USERS_WITHOUT_MESSAGES = gql`
  query GET_USERS_WITHOUT_MESSAGES($where: users_bool_exp, $limit: Int, $offset: Int){
    users(where: $where, limit: $limit, offset: $offset) {
     id
     first_name
     last_name
     avatar_link
     role {
      id
      description
     }
     doctors{
      id
      is_available
     }
    }
  }
`

/**
 * query GET_USERS_WITHOUT_MESSAGES($id: uuid!){
    users_by_pk(id: $id) {
     id
     first_name
     last_name
     avatar_link
     role {
      id
      description
     }
     doctors{
      id
      is_available
     }
     messages(limit: 1, order_by: {created_at: desc}){
      id
      text
      sender_id
     }
     messagesByReceiverId(limit: 1, order_by: {created_at: desc}){
      id
      text
      receiver_id
     }
    }
  }
 * @type {DocumentNode}
 */
export const SEARCH_USERS_BY_PK = gql`
  query GET_USERS_WITHOUT_MESSAGES($id: uuid!){
    users_by_pk(id: $id) {
     id
     first_name
     last_name
    }
  }
`

export const GET_USER_WITH_LATEST_MESSAGE_VIEW_FIRST = gql`
  subscription GET_USER_WITH_LATEST_MESSAGE_VIEW($userId: uuid, $where: user_conversations_bool_exp, $limit: Int, $offset: Int){
    search_user_conversations(
      args: { user_id: $userId },
      where: $where,
      limit: 1,
      offset: 0
    ){
      id
      created_at
      text
      first_name
      last_name
      role
      is_seen
      avatar_link
      is_available
      sender_id
    }
  }
`

export const GET_MESSAGES = gql`
	subscription GET_MESSAGES($where: messages_bool_exp!) {
		messages(
			where: $where, 
			order_by: { created_at: desc }, 
			limit: 1) {
				id
				text
				is_seen
				created_at
				user {
					id
					avatar_link
					first_name
					last_name
				}
				userBySenderId {
					id
					avatar_link
					first_name
					last_name
				}
		}
	}
`

export const GET_UPDATED_MESSAGES = gql`
	subscription GET_UPDATED_MESSAGE($where: messages_bool_exp!) {
		messages(
			where: $where, 
			order_by: { updated_at: desc }, 
			limit: 1) {
				id
				text
				is_seen
				created_at
				user {
					id
					avatar_link
					first_name
					last_name
				}
				userBySenderId {
					id
					avatar_link
					first_name
					last_name
				}
		}
	}
`

export const GET_DELETED_MESSAGES = gql`
	subscription GET_DELETED_MESSAGES($where: messages_bool_exp!){
		messages(
			where: $where,
			order_by: { updated_at: desc },
			limit: 1) {
				id
				text
		}
	}
`

export const GET_MESSAGES_QUERY = gql`
	query GET_MESSAGES_Q($where: messages_bool_exp!, $limit: Int, $offset: Int){
		messages(
			where: {
				_and: [
					$where
				]
			},
			order_by: { created_at: desc },
			limit: $limit,
			offset: $offset
		) {
			id
			text
			is_seen
			created_at
			user {
				id
				avatar_link
				first_name
				last_name
			}
			userBySenderId {
				id
				avatar_link
				first_name
				last_name
			}
		}
	}
`

export const GET_MESSAGES_COUNT = gql`
	subscription GET_MESSAGES($where: messages_bool_exp!) {
		messages_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`
