import React, { useState, useEffect } from 'react'
import s3Uploader from '../../../utils/s3-uploader';
import ProfilePicture from '../../common/profile-picture'
import { Row, Col, Divider, Spin, message, Form } from 'antd'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_USER, UPSERT_USER } from '../../../graphql/users/mutation'
import { UPSERT_DOCTOR } from '../../../graphql/doctors/mutation'
import { GET_USER_DUPLICATE } from '../../../graphql/users/queries'
import '../../../assets/css/dashboard/addpatientdoctor.css'
import { useTranslation } from 'react-i18next'
import FieldGenerator from './field-generator.js'
import { ROLES } from '../../../graphql/login/queries'

const bcrypt = require('bcryptjs')

const AddDoctor = props => {
  const { t } = useTranslation()

  const { refetch, hidePanel } = props

  const { data } = useQuery(ROLES, {
    fetchPolicy: 'network-only',
  })

  const [form] = Form.useForm()
  const [roleId, setRoleId] = useState(null)
  const [image, setImage] = useState(null)
  const [tempImage, setTempImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState({})
  const [upsertUser] = useMutation(UPSERT_USER)
  const [upsertDoctor] = useMutation(UPSERT_DOCTOR)
  const [updateUser] = useMutation(UPDATE_USER)
  const [currentUser, setCurrentUser] = useState(null)
  const [getUserDuplicate] = useLazyQuery(GET_USER_DUPLICATE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data.users.length && data.users[0].deleted_at === null) {
        message.error(t('dialog.duplicate_email'))
        setLoading(false)
        return
      }

      let specialties = JSON.stringify(inputs.specialties)

      const user = data.users[0] || {
        doctors: null,
      }

      bcrypt.hash(inputs.password, 10).then(result => {
        upsertUser({
          variables: {
            objects: [
              {
                ...(user.id
                  ? { id: user.id, deleted_at: null }
                  : { email: inputs.email }),
                first_name: inputs.first_name,
                last_name: inputs.family_name,
                address: inputs.address,
                telephone: inputs.telephone,
                password: result,
                password_is_temp: true,
                role_id: roleId,
                avatar_link: null,
              },
            ],
          },
        }).then(
          userResult => {
            if (image) {
              s3Uploader(image,
                `${userResult.data.insert_users.returning[0].id}/profile`,
                (imageUrl) => {
                  updateUser({
                    variables: {
                      _set: { avatar_link: imageUrl },
                      where: { id: { _eq: userResult.data.insert_users.returning[0].id } }
                    }
                  }).then(() => {
                    updateDoctor(user, userResult, specialties)
                  })
                })
            } else {
              updateDoctor(user, userResult, specialties)
            }
          },
          userError => {
            setLoading(false)
            message.error(userError.message)
          },
        )
      })
    },
  })

  useEffect(() => {
    if (!data || !data.roles) return

    const role = data.roles.find(element => element.description === 'doctor')

    setRoleId((role || {}).id || null)
  }, [data])

  const updateDoctor = (user, userResult, specialties) => {
    upsertDoctor({
      variables: {
        objects: [
          {
            ...(((user.doctors || {})[0] || {}).id || null
              ? { id: user.doctors[0].id, deleted_at: null }
              : {}),
            specialties: specialties,
            user_id: userResult.data.insert_users.returning[0].id,
            is_available: true,
            location: inputs.address,
          },
        ],
      },
    }).then(
      () => {
        setLoading(false)
        hidePanel()
        message.success(t('doctors.success_add'))
        form.resetFields()
        refetch()
      },
      doctorError => {
        setLoading(false)
        message.error(doctorError.message)
      },
    )
  }

  const onFinish = inputs => {
    if (!roleId) {
      message.error('Internal Server Error')
      return
    }

    setInputs(inputs)
    setLoading(true)

    getUserDuplicate({
      variables: {
        where: { email: { _eq: inputs.email } },
      },
    })
  }

  const fields = [
    {
      fieldName: 'first_name',
      label: t('crud.first_name'),
      rules: [
        {
          required: true,
          whitespace: true,
          message: t('dialog.required_field'),
        },
      ],
    },
    {
      fieldName: 'family_name',
      label: t('crud.family_name'),
      rules: [
        {
          required: true,
          whitespace: true,
          message: t('dialog.required_field'),
        },
      ],
    },
    {
      fieldName: 'address',
      label: t('crud.address'),
    },
    {
      fieldName: 'telephone',
      label: t('crud.mobile_num'),
      isMobile: true,
    },
    {
      fieldName: 'specialties',
      label: t('doctors.specialties'),
      options: [
        { value: t('doctors.general_doctor') },
        { value: t('doctors.obstetrician') },
        { value: t('doctors.gynecologist') },
        { value: t('doctors.pediatrician') },
      ],
    },
    {
      fieldName: 'email',
      label: t('crud.email_add'),
      rules: [
        {
          type: 'email',
          message: t('dialog.not_valid_email'),
        },
        {
          required: true,
          message: t('dialog.required_field'),
        },
      ],
    },
    {
      fieldName: 'password',
      label: t('crud.temp_pass'),
      isPassword: true,
      rules: [
        {
          required: true,
          message: t('dialog.required_field'),
        },
      ],
    },
  ]

  return (
    <Spin spinning={loading}>
      <div className="flex justify-center">
        <ProfilePicture
          isEdit={true}
          profileSrc={tempImage}
          saveImage={(image, tempImage) => {
            setTempImage(tempImage)
            setImage(image)
          }}
        />
      </div>
      <Row>
        <Col span={24}>
          <Divider
            style={{
              backgroundColor: 'rgba(151, 151, 151, 0.5)',
              marginTop: '30px',
              marginBottom: '20px',
            }}
            dashed
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: 20 }}>
          <FieldGenerator fields={fields} form={form} onFinish={onFinish}/>
        </Col>
      </Row>
    </Spin>
  )
}

export default AddDoctor
