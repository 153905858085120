import React, { useState, useContext, useEffect } from 'react'
import { HeaderContext } from '../../layouts'
import { Form, Input, Button, Row, Spin, Col, message } from 'antd'
import getImage from '../../utils/get-image'
import { GET_USER_INFO } from '../../graphql/change-password/queries'
import { UPDATE_USER_PASSWORD } from '../../graphql/change-password/mutation'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'

const bcrypt = require('bcryptjs')

const DoctorChangePassword = props => {
	const { t } = useTranslation()

	const {
		stateSetters: { setNavCont },
		states: {
			headerNavCont: { userId },
		},
	} = useContext(HeaderContext)

	const history = useHistory()
	const [newPasswordType, setNewPasswordType] = useState('password')
	const [verifyPasswordType, setVerifyPasswordType] = useState('password')
	const [isLoading, setLoading] = useState(false)
	const [form] = Form.useForm()
	const [user, setUser] = useState({})

	const [updatePassword] = useMutation(UPDATE_USER_PASSWORD)
	const { data, refetch } = useQuery(GET_USER_INFO, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				id: {
					_eq: userId,
				},
			},
		},
	})

	useEffect(() => {
		setNavCont({ defaultKey: ['1'], hideSideBar: true, hideHeader: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (data) setUser(data.users[0] || {})
	}, [data])

	const handleSubmit = async values => {
		if (values.password !== values.confirm)
			message.error(t('reset_password.not_match'))
		else {
			setLoading(true)

			if (await isUserPasswordAndNewPasswordMatch(values.password)) {
				setLoading(false)
				message.error(t('reset_password.different_pass'))
			} else {
				bcrypt
					.hash(values.password, 10)
					.then(result => {
						updatePassword({
							variables: {
								_set: {
									password: result,
									password_is_temp: false,
								},
								where: {
									id: {
										_eq: userId,
									},
								},
							},
						})
					})
					.then(
						changeResult => {
							setLoading(false)
							message.success(t('dialog.update_success'))
							form.resetFields()
							setNavCont({ is_temp_pass: false })
							localStorage.setItem('is_temp_pass', false)
							history.push('dashboard')
						},
						changeError => {
							setLoading(false)
							message.error(changeError.message)
						},
					)
			}
		}
	}

	const isUserPasswordAndNewPasswordMatch = async newPassword => {
		const currentPasswordHash = user.password || ''

		return await bcrypt.compare(newPassword, currentPasswordHash)
	}

	const handleShowNewPassword = () => {
		setNewPasswordType('password' === newPasswordType ? 'text' : 'password')
	}

	const handleShowVerifyPassword = () => {
		setVerifyPasswordType(
			'password' === verifyPasswordType ? 'text' : 'password',
		)
	}

	return (
		<div className='login-container login-wrap'>
			<Spin
				spinning={isLoading}
				size='large'
				tip={`${t('common.logging_in')}...`}
			>
				<Row>
					<img
						src={getImage('doctorbanner')}
						alt='Doctor create password banner'
						style={{ width: 700, height: 'auto', marginBottom: 40 }}
					/>
				</Row>
				<Row className='login-wrap'>
					<Form form={form} name='login-form' onFinish={handleSubmit}>
						<Form.Item className='headerContainer'>
							<p className='welcome-message'>
								{t('common.welcome')}
								{` Dr. ${capitalize(user.first_name)} ${capitalize(
									user.last_name,
								)}!`}
							</p>
							<p className='instructions'>{t('reset_password.use_new_pass')}</p>
						</Form.Item>
						<Form.Item
							className='login-field-input'
							name='password'
							rules={[
								{ required: true, message: t('reset_password.input_pass') },
							]}
						>
							<Input.Password
								className='input-component'
								type={newPasswordType}
								placeholder='Enter new password'
								disabled={!user.id}
							/>
						</Form.Item>
						<Form.Item
							className='login-field-input'
							name='confirm'
							rules={[
								{ required: true, message: t('reset_password.input_pass') },
							]}
						>
							<Input.Password
								className='input-component'
								type={verifyPasswordType}
								placeholder='Re-enter new password'
								disabled={!user.id}
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type='primary'
								htmlType='submit'
								className='login-form-button'
								disabled={!user.id}
							>
								{t('reset_password.get_started')}
							</Button>
						</Form.Item>
					</Form>
				</Row>
			</Spin>
		</div>
	)
}

export default DoctorChangePassword
