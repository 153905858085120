import gql from 'graphql-tag'

export const GET_BABY_CHECKUPS = gql`
	query GET_BABY_CHECKUPS($where: baby_checkups_bool_exp) {
		baby_checkups(where: $where, order_by: { date: desc }) {
			id
			date
			height
			weight
			head_circumference
			chest_circumference
			diet
			breastfeeding
			feeding_method
			general_health
			nursing_ability
			jaundice
			irregularities_at_birth
			irregularities_after_birth
			preparation_for_weaning
			condition_of_mouth
			meals
			number_of_teeth
			condition_of_eyes
			medical_institution
			notes
			doctor {
				id
				user {
					id
					first_name
					last_name
				}
			}
		}
	}
`

export const GET_CONFLICT_BABY_CHECKUPS = gql`
	query COUNT_CONFLICT_BABY_CHECKUPS($where: baby_checkups_bool_exp) {
		baby_checkups(where: $where) {
			id
		}
	}
`
