import gql from 'graphql-tag'

export const UPSERT_POST_PARTUMS = gql`
	mutation UPSERT_POST_PARTUMS($objects: [post_partums_insert_input!]!) {
		insert_post_partums(
			objects: $objects
			on_conflict: {
				constraint: post_partums_pkey
				update_columns: [
					days_post_delivery
					breast_condition
					weight
					sugar_in_urine
					vaginal_bleeding
					protien_in_urine
				]
			}
		) {
			affected_rows
		}
	}
`
