import gql from 'graphql-tag'

export const ADD_DOCTOR = gql`
	mutation ADD_DOCTOR($objects: [doctors_insert_input!]!) {
		insert_doctors(objects: $objects) {
			affected_rows
			returning {
				id
			}
		}
	}
`

export const UPSERT_DOCTOR = gql`
	mutation UPSERT_USER($objects: [doctors_insert_input!]!) {
		insert_doctors(
			objects: $objects
			on_conflict: {
				constraint: doctors_pkey
				update_columns: [
					specialties
					is_available
					deleted_at
				]
			}
		) {
			affected_rows
		}
	}
`

export const UPDATE_DOCTOR = gql`
	mutation UPDATE_DOCTOR($_set: doctors_set_input!, $where: doctors_bool_exp!) {
		update_doctors(where: $where, _set: $_set) {
			affected_rows
		}
	}
`
