import React, { useState, useEffect, useContext } from 'react'
import { Form, Spin, message, Row, Col } from 'antd'
import '../../assets/css/dashboard/add-appointment.css'
import { ADD_APPOINTMENT } from '../../graphql/appointments/mutation'
import { GET_ALL_DOCTORS } from '../../graphql/doctors/queries'
import { GET_PATIENTS } from '../../graphql/patients/queries'
import { GET_APPOINTMENTS_TODAY_AND_DUPLICATES } from '../../graphql/appointments/queries'
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks'
import FieldGenerator from '../common/field-generator'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../utils'
import { sendMessage } from '../../firebase-messaging'
import { HeaderContext } from '../../layouts'

import _ from 'lodash'

const AddAppointment = props => {
	const { t, i18n } = useTranslation()

	const [form] = Form.useForm()

	const [addAppointment] = useMutation(ADD_APPOINTMENT)

	const [isLoading, setLoading] = useState(false)
	const [fields, setFields] = useState([])
	const [inputs, setInputs] = useState({})

	const { selectedId } = props

	const { states } = useContext(HeaderContext)
	const { userType } = states.headerNavCont

	const { data: doctorsData } = useQuery(GET_ALL_DOCTORS, {
		fetchPolicy: 'network-only',
	})

	const { data: patientsData } = useQuery(GET_PATIENTS, {
		fetchPolicy: 'network-only',
	})

	const [getAppointmentsToday] = useLazyQuery(
		GET_APPOINTMENTS_TODAY_AND_DUPLICATES,
		{
			fetchPolicy: 'network-only',
			onCompleted: data => {
				const appointmentDate = `${inputs.date.format('YYYY-MM-DD')}`
				const appointmentTime = `${inputs.time.format('HH:mm')}`

				if (data.appointments.length) {
					if (
						data.appointments[0].name === inputs.name &&
						data.appointments[0].patient_id === inputs.patient
					)
						message.error(t('appointments.done_appointment'))
					else if (
						appointmentDate ===
							moment(data.appointments[0].datetime).format('YYYY-MM-DD') &&
						data.appointments[0].patient_id === inputs.patient
					) {
						message.error(t('appointments.appointment_once_a_day'))
					} else message.error(t('appointments.conflict_appointment'))
					return
				}

				setLoading(true)
				addAppointment({
					variables: {
						objects: [
							{
								name: inputs.name,
								patient_id: inputs.patient,
								doctor_id: inputs.doctor,
								status: 'active',
								datetime: moment(`${appointmentDate} ${appointmentTime}`),
							},
						],
					},
				}).then(
					(data) => {
						setLoading(false)
						message.success(t('appointments.success_add'))
						form.resetFields()
						props.hidePanel()

						props.refetch()

						const docId = getUserData(inputs.doctor, null)
						const patId = getUserData(null, inputs.patient)

						const appointid = data.data.insert_appointments.returning[0].id
						if (userType === 'admin'){
							sendAdminNewAppointMsg(docId, patId, appointid)
						} else {
							sendDoctorNewAppointMsg(patId, appointid)
						}
					},
					addError => {
						setLoading(false)
						message.error(addError.message)
					},
				)
			},
		},
	)

	const sendAdminNewAppointMsg = (docReceiverId, patReceiverId, appointmentId) => {
		const data = {
			'data': {
				'notiftype': 'appoint-new-admin',
				'docid': docReceiverId,
				'patid': patReceiverId,
				'appointmentid': appointmentId
			}
		}

		sendMessage(docReceiverId, data)
		sendMessage(patReceiverId, data)
	}

	const sendDoctorNewAppointMsg = (receiverid, appointmentId) => {
		const data = {
			'data': {
				'notiftype': 'appoint-new-doctor',
				'appointmentid': appointmentId
			}
		}

		sendMessage(receiverid, data)
	}

	const descriptionOptions = [
		'checkup_1',
		'checkup_8',
		'checkup_12',
		'checkup_16',
		'checkup_20',
		'checkup_24',
		'checkup_26',
		'checkup_28',
		'checkup_30',
		'checkup_32',
		'checkup_34',
		'checkup_36',
		'checkup_37',
		'checkup_38',
		'checkup_40',
		'newborn',
		'baby_checkup_1',
		'baby_checkup_3_4',
		'baby_checkup_6_7',
		'baby_checkup_9_10',
		'baby_checkup_1_yr',
		'baby_checkup_18',
		'baby_checkup_2_yr',
		'other',
		'post_partum'
	]

	const isAppointmentDateValid = date => {
		return date >= moment().format('YYYY-MM-DD')
	}

	const isAppointmentTimeValid = time => {
		return time >= '09:00' && time <= '18:00'
	}

	const onFinish = inputs => {
		const appointmentDate = `${inputs.date.format('YYYY-MM-DD')}`
		const appointmentTime = `${inputs.time.format('HH:mm')}`

		if (!isAppointmentDateValid(appointmentDate)) {
			message.error(t('appointments.appointment_less_current_date'))
			return
		}

		if (!isAppointmentTimeValid(appointmentTime)) {
			message.error(t('appointments.appointment_possible_times'))
			return
		}
		console.log('\n\ninputs: ',inputs)
		console.log('\n doctor Id: ', getUserData(inputs.doctor, null))
		console.log('\n patient Id: ', getUserData(null, inputs.patient))

		setInputs(inputs)

		getAppointmentsToday({
			variables: {
				where: {
					_or: [
						{
							deleted_at: {
								_is_null: true,
							},
							status: {
								_eq: 'active',
							},
							doctor_id: {
								_eq: inputs.doctor,
							},
							_or: [
								{
									datetime: {
										_lte: moment(
											`${appointmentDate} ${appointmentTime}`,
										).format(),
										_gte: moment(`${appointmentDate} ${appointmentTime}`)
											.subtract(30, 'minutes')
											.format(),
									},
								},
								{
									datetime: {
										_gte: moment(
											`${appointmentDate} ${appointmentTime}`,
										).format(),
										_lte: moment(`${appointmentDate} ${appointmentTime}`)
											.add(30, 'minutes')
											.format(),
									},
								},
							],
						},
						{
							patient_id: {
								_eq: inputs.patient,
							},
							datetime: {
								_lte: moment(`${appointmentDate} ${appointmentTime}`).endOf(
									'day',
								),
								_gte: moment(`${appointmentDate} ${appointmentTime}`).startOf(
									'day',
								),
							},
							deleted_at: {
								_is_null: true,
							},
							status: {
								_eq: 'active',
							},
						},
					],
				},
			},
		})
	}

	const getUserData = (doctorId, patientId) => {
		let id
		if (doctorId){
			id = doctorsData.doctors.filter(doctor => {
				return doctor.id == doctorId
			})[0].user.id
		} 
		if (patientId){
			id = patientsData.patients.filter(patient => {
				return patient.id == patientId
			})[0].user.id
		}
		return id
	}

	useEffect(() => {
		let doctors = []
		let patients = []

		if (props.doctorLogged) {
			if (doctorsData) {
				doctors = doctorsData.doctors
					.filter(data => {
						if (data.id !== props.doctorLogged) {
							return false
						}
						return true
					})
					.map(data => {
						return {
							value: data.id,
							label: `${data.user.last_name}, ${data.user.first_name}`,
						}
					})
			}
		} else {
			if (doctorsData) {
				doctors = doctorsData.doctors.map(data => {
					return {
						value: data.id,
						label: `${data.user.last_name}, ${data.user.first_name}`,
					}
				})
			}
		}

		if (props.patientOptions) {
			patients = props.patientOptions
				.filter(data => {
					if (data.key === null) {
						return false
					}
					return true
				})
				.map(data => {
					return {
						value: data.key,
						label: data.item1,
					}
				})
		} else {
			if (patientsData) {
				patients = patientsData.patients.map(data => {
					return {
						value: data.id,
						label: `${data.user.last_name}, ${data.user.first_name}`,
					}
				})
			}
		}

		setFields([
			{
				fieldName: 'doctor',
				label: t('doctors.doctor'),
				options: doctors,
				mode: 'single',
				value: props.doctorLogged ? props.doctorLogged : '',
			},
			{
				fieldName: 'patient',
				label: t('patients.patient'),
				options: patients,
				mode: 'single',
				value: selectedId ? selectedId : '',
			},
			{
				fieldName: 'date',
				type: 'datepicker',
				format: getDateFormat(i18n),
				label: t('crud.date'),
				disableDateDirection: 'before',
				disableDateFrom: moment().format('YYYY-MM-DD'),
			},
			{
				fieldName: 'time',
				timeFormat: 'HH:mm',
				label: t('crud.time'),
				enableHrFrom: 9,
				enableHrTo: 18,
				disablePast: true,
			},
			{
				fieldName: 'name',
				placeholder: t('appointments.select_description'),
				label: t('appointments.description'),
				options: descriptionOptions.map(option => ({
					value: option,
					label: t(`appointments.${option}`),
				})),
				mode: 'single',
			},
		])
	}, [patientsData, doctorsData])

	return (
		<Spin spinning={isLoading}>
			<Row>
				<Col>
					<div className='appointment-view-title'>
						{t('appointments.appointment')}
					</div>
					<br />
				</Col>
			</Row>
			<div style={{ marginTop: 40 }}> </div>
			<FieldGenerator fields={fields} form={form} onFinish={onFinish} />
		</Spin>
	)
}

export default AddAppointment
