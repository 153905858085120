import React, { useState, useEffect, useRef } from 'react'
import { Typography, Button, Divider, Avatar, message } from 'antd'
import logo from '../../assets/img/logo.png'
import { useParams } from 'react-router-dom'
import '../../assets/css/pregnancy/pregnancydetails.css'
import moment from 'moment'

import { DELETE_PREGNANCY } from '../../graphql/pregnancies/mutation'
import { GET_PREGNANCY_DETAILS_BY_ID } from '../../graphql/pregnancies/queries'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { commonComponents } from '../common'
import { useHistory } from 'react-router-dom'
import EditPregnancy from './edit/edit-pregnancy'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../utils/get-date-format'

const PregnancyDetails = props => {
	const { t, i18n} = useTranslation()
	const { pregnancy_id } = useParams()

	const { pregnancyRefetch, pregnancy } = props

	const editPregnancyRef = useRef()

	const { ConfirmModal } = commonComponents
	const [deletePregnancy] = useMutation(DELETE_PREGNANCY)
	const history = useHistory()
	const [patientId, setPatientId] = useState()
	const [pregnancyStartDate, setPregnancyStartDate] = useState()
	const [pregnancyEndDate, setPregnancyEndDate] = useState()

	const { data: pregnancyData, refetch: refetchPregnancyData } = useQuery(
		GET_PREGNANCY_DETAILS_BY_ID,
		{
			fetchPolicy: 'network-only',
			variables: {
				where: {
					id: {
						_eq: props.pregnancy.id,
					},
				},
			},
		},
	)

	useEffect(() => {
		if (pregnancyData) {
			setPatientId((pregnancyData.pregnancies || [{}])[0].patient_id)
			setPregnancyStartDate((pregnancyData.pregnancies || [{}])[0].start_date)
			setPregnancyEndDate((pregnancyData.pregnancies || [{}])[0].due_date)
		}
	}, [pregnancyData])

	const handleDelete = () => {
		ConfirmModal({
			mtitle: t('pregnancies.delete_pregnancy'),
			mcontent: t('confirm.cannot_undo'),
			monOk: () => {
				deletePregnancy({
					variables: {
						preg_set: { deleted_at: 'now()' },
						preg_filter: {
							id: {
								_eq: props.pregnancy.id,
							},
						},
					},
				}).then(() => {
					pregnancyRefetch()
					history.push(`/patients/profile/${patientId}`)
					message.success(t('pregnancies.success_delete'))
				})
			},
			t,
		})
	}

	const getPregnancyBreadcrumb = () => {
		if (!pregnancy.id) return ''

		const pregnancies = pregnancy.patient.pregnancies
		const orderReference = {
			1: 'common.first',
			2: 'common.second',
			3: 'common.third',
		}

		for (let i = 0; i < pregnancies.length; i++) {
			const pregnancy = pregnancies[i]

			if (
				i === pregnancies.length - 1 &&
				pregnancy.id === pregnancy_id &&
				moment().isAfter(moment(pregnancy.start_date)) &&
				moment().isBefore(moment(pregnancy.due_date))
			)
				return t('pregnancies.current_pregnancy')
			else if (pregnancy.id === pregnancy_id) {
				if (i + 1 > 3)
					return `${i + 1}${t('common.th')} ${t('pregnancies.pregnancy')}`
				else return `${t(orderReference[i + 1])} ${t('pregnancies.pregnancy')}`
			}
		}
	}

	// const handleEdit = () => {
	// 	// disabled
	// 	return
	//
	// 	editPregnancyRef.current.showModal()
	// }

	return (
		<>
			<div className='pregnancy-details-container'>
				<Avatar size={105} style={{ resizeMode: 'stretch' }} src={logo} />
				<div className='pregnancy-details'>
					<Typography
						style={{
							fontSize: 18,
							lineHeight: '36px',
							fontWeight: 500,
							color: '#3B3F3F',
						}}
					>
						{getPregnancyBreadcrumb()}
					</Typography>
					<Typography
						style={{ fontSize: 12, lineHeight: '20px', color: '#969999' }}
					>
						{t('pregnancies.pregnancy_start_date')}:{' '}
						{pregnancy.start_date
							? moment(pregnancy.start_date).format(getDateFormat(i18n))
							: null}
						<Divider type='vertical' />
						{t('pregnancies.pregnancy_expected_due_date')}:{' '}
						{pregnancy.start_date
							? moment(pregnancy.due_date).format(getDateFormat(i18n))
							: null}
					</Typography>
					<div style={{ marginTop: 17 }}>
						{/* <Button onClick={handleEdit}>{t('crud.edit')}</Button>
						<Divider type='vertical' /> */}
						<Button onClick={handleDelete}>{t('crud.delete')}</Button>
					</div>
				</div>
			</div>
			<EditPregnancy
				startDate={pregnancyStartDate}
				endDate={pregnancyEndDate}
				pregnancyId={props.pregnancy.id}
				patientId={patientId}
				ref={editPregnancyRef}
				refetch={() => {
					pregnancyRefetch()
					refetchPregnancyData()
				}}
			/>
		</>
	)
}
export default PregnancyDetails
