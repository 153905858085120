import React, { useEffect, useContext } from "react"
import { HeaderContext } from "../../layouts";
import { default as ChatPage } from './chat'

export const ChatMessagePage = () => {
  const { stateSetters: { setNavCont } } = useContext(HeaderContext);

  useEffect(() => {
    setNavCont({ defaultKey: ["3"], hideSideBar: false, hideHeader: true });
  }, []);

  return <ChatPage />
}

