import React from 'react'
import {
  Button,
  Row,
  Col,
  Input,
  message,
  Form,
  DatePicker,
} from 'antd'
import s3Uploader from '../../../utils/s3-uploader';
import { commonComponents } from '../../common'
import moment from 'moment'

import AddAppointment from '../../appointments/add-appointment'
import '../../../assets/css/patients/addappointment.css'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../utils'

const bcrypt = require('bcryptjs')
const { BlockButton } = commonComponents

const FieldGenerator = props => {
  const { t, i18n } = useTranslation()

  const {
    record,
    updatePatient,
    refetch,
    refetchDetails,
    image,
    configEditModes,
    setLoading
  } = props
  const isEditing = (props.editModes || {}).isEditMode
  const hasEditedContent = (props.editModes || {}).hasEditedContent
  const setEditedState = state => {
    props.configEditModes(oldstate => ({ ...oldstate, ...state }))
  }

  const fields = [
    {
      name: 'account_code',
      label: t('patients.account_code'),
      value: record.account_code,
      disabled: true,
    },
    {
      name: 'first_name',
      label: t('crud.first_name'),
      value: record.user.first_name,
    },
    {
      name: 'last_name',
      label: t('crud.family_name'),
      value: record.user.last_name,
    },
    {
      name: 'address',
      label: t('crud.home_address'),
      value: record.user.address,
    },
    {
      name: 'telephone',
      label: t('crud.mobile_num'),
      value: record.user.telephone,
    },
    {
      name: 'date_of_birth',
      label: t('crud.date_of_birth'),
      datepicker: true,
      value: record.date_of_birth,
      disableDateFrom: moment().subtract(10, 'years'),
      disableDateDirection: 'after',
    },
    {
      name: 'email',
      label: t('crud.email_add'),
      value: record.user.email,
      rules: [
        {
          type: 'email',
          message: t('dialog.not_valid_email'),
        },
        {
          required: true,
          message: t('dialog.required_field'),
        },
      ],
    },
    {
      name: 'password',
      label: t('crud.temp_pass'),
      isPassword: true,
    },
  ]

  const items = fields.map(item => {
    const getCorrectInput = () => {
      if (item.isPassword) {
        return (
          <Input.Password key={item.name}
                          style={{ boxShadow: 'none' }}
                          className="input-component input-component-icons"
          />
        )
      } else if (item.datepicker) {
        const disablePastDt = current => {
          if (item.disableDateDirection === 'before') {
            return current.isBefore(item.disableDateFrom)
          }

          return current.isAfter(item.disableDateFrom)
        }

        return (
          <DatePicker
            key={item.name}
            className="input-component input-component-icons"
            format={getDateFormat(i18n)}
            bordered={false}
            showToday={false}
            allowClear={false}
            disabledDate={
              item.disableDateFrom !== undefined ? disablePastDt : false
            }
          />
        )
      } else {
        return <Input key={item.name} className="input-component" disabled={item.disabled}/>
      }
    }

    const printCorrectStringDisplay = () => {
      if (item.value) {
        if (item.datepicker) {
          return moment(item.value).format(getDateFormat(i18n))
        } else {
          return item.value
        }
      } else {
        return t('crud.n_a')
      }
    }

    if (!isEditing) {
      if (!item.isPassword) {
        return (
          <Row>
            <Col span={24}>
              <p className="doctor-field-name">{item.label.toUpperCase()}</p>
              <p className="doctor-field-value">
                {printCorrectStringDisplay()}
              </p>
            </Col>
          </Row>
        )
      }
    } else {
      return (
        <Form.Item
          name={item.name}
          label={item.label.toUpperCase()}
          rules={
            item.isPassword
              ? []
              : item.rules ? item.rules :
              [
                {
                 required: true,
                 message: t('dialog.required_field'),
                },
              ]
          }
          className="doctor-field-input"
        >
          {getCorrectInput()}
        </Form.Item>
      )
    }
  })

  const getFieldObject = () => {
    const fieldValues = {}

    fields.map(field => {
      if (field.datepicker)
        fieldValues[field.name] = field.value ? moment(field.value) : null
      else fieldValues[field.name] = field.value
    })

    return fieldValues
  }

  const handleFieldChange = () => {
    setEditedState({ hasEditedContent: true })
  }

  const updateFieldValues = async values => {
    let password = {}

    if (values.password !== undefined && values.password !== null)
      await bcrypt
      .hash(values.password, 10)
      .then(
        result => (password = { password: result, password_is_temp: true }),
      )

    setLoading(true)
    configEditModes({
      isEditMode: true,
      hasEditedContent: false,
    })
    if (image) {
      s3Uploader(image, `${record.user.id}/profile`, (imageUrl) => {
        handleUpdatePatient(values, password, imageUrl)
      })
    } else {
      handleUpdatePatient(values, password)
    }
  }

  const handleUpdatePatient = (values, password, image = null) => {
    updatePatient({
      variables: {
        patients_where: {
          id: { _eq: record.id },
        },
        patients_set: {
          date_of_birth: values.date_of_birth.format('YYYY-MM-DD'),
        },
        users_where: {
          patients: { id: { _eq: record.id } },
        },
        users_set: {
          first_name: values.first_name,
          last_name: values.last_name,
          address: values.address,
          telephone: values.telephone,
          email: values.email,
          avatar_link: image,
          ...password,
        },
      },
    })
    .then(
      () => {
        refetch()
        refetchDetails()
        message.success(t('patients.success_update'))
        configEditModes({
          isEditMode: props.isEditMode,
          hasEditedContent: false,
        })
        setLoading(false)
      },
      changeError => {
        message.error(changeError.message)
      },
    )
    .finally(() => {
      setEditedState({ hasEditedContent: false, isEditMode: !isEditing })
    })
  }

  const handleAddAppointmentClick = () => {
    props.panel.current.showPanel(
      <AddAppointment
        selectedId={props.patientSelected}
        patientOptions={props.patientOptions}
        refetch={() => props.refetch()}
      />,
    )
  }

  const handleOnSaveState = () => {
    refetch()

    props.onSaveChanges()
  }

  const buttonAreaHandler = () => {
    if (!props.isEditing) {
      return (
        <>
          <Col span={24}>
            <Button
              className="dash-add-button patient-viewpatient-fieldgenerator-addappointment"
              onClick={handleAddAppointmentClick}
              type="link"
            >
              + {t('appointments.add_appointment')}
            </Button>
          </Col>
          <Col span={12}>
            <BlockButton
              title={t('crud.edit_details')}
              onClick={props.onEditFields}
            />
          </Col>
          <Col span={12}>
            <Button
              block
              className="transparent-cancel-btn"
              onClick={props.onRemoveUser}
            >
              {t('patients.remove_patient')}
            </Button>
          </Col>
        </>
      )
    } else {
      return (
        <Col span={24}>
          <BlockButton
            disabled={!hasEditedContent}
            title={t('crud.save_changes')}
            onClick={handleOnSaveState}
          />
        </Col>
      )
    }
  }

  if (!props.isEditing) {
    return (
      <>
        {items}
        <Row className="btn-container">{buttonAreaHandler()}</Row>
      </>
    )
  } else {
    return (
      <>
        <Form
          form={props.form}
          layout="vertical"
          onFieldsChange={handleFieldChange}
          hideRequiredMark
          onFinish={updateFieldValues}
          initialValues={getFieldObject()}
        >
          {items}
          <Row className="btn-container">{buttonAreaHandler()}</Row>
        </Form>
      </>
    )
  }
}

export default FieldGenerator
