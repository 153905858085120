import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Typography, Col, Row, Button, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { GET_POST_PARTUMS } from '../../../../graphql/post-partums/queries'
import { commonComponents } from '../../../common'
import AddEditPostPartumRecord from './add-edit-drawer'
import { useTranslation } from 'react-i18next'

const { Sidepanel } = commonComponents

const PostPartumRecords = () => {
	const { t } = useTranslation()

	const { pregnancy_id } = useParams()

	const panel = useRef()
	const [form] = Form.useForm()
	const [postPartum, setPostPartum] = useState({})

	const { data, refetch } = useQuery(GET_POST_PARTUMS, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				pregnancy_id: {
					_eq: pregnancy_id,
				},
			},
		},
	})

	useEffect(() => {
		if (!data) return

		setPostPartum(data.post_partums[0] || {})
	}, [data])

	const PostPartumDetails = () => {
		let records = [
			{
				label: t('post_partum.days_post_delivery'),
				data: `${postPartum.days_post_delivery || t('crud.n_a')}`,
			},
			{
				label: t('post_partum.protein_in_urine'),
				data: getOptionReference(postPartum.protien_in_urine),
			},
			{
				label: t('post_partum.breast_condition'),
				data: `${getBreastCondition(postPartum.breast_condition)}`,
			},
			{
				label: t('post_partum.weight'),
				data: postPartum.weight ? `${postPartum.weight} kgs` : t('crud.n_a'),
			},
			{
				label: t('post_partum.sugar_in_urine'),
				data: getOptionReference(postPartum.sugar_in_urine),
			},
			{
				label: t('post_partum.vaginal_bleeding'),
				data: getOptionReference(postPartum.vaginal_bleeding),
			},
		]

		let postPartumRecord = records.map((record, index) => {
			return (
				<Col span={12} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{record.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{record.data}
						</Typography>
					</div>
				</Col>
			)
		})

		return postPartumRecord
	}

	const getBreastCondition = condition => {
		if (!condition) return t('crud.n_a')

		if (condition === 'benign_breast') return t('post_partum.benign_breast')
		else if (condition === 'mastitis') return t('post_partum.mastitis')
		else if (condition === 'other') return t('post_partum.other')
	}

	const getOptionReference = key => {
		if (!key) return t('crud.n_a')

		const reference = {
			none: t('crud.none'),
			moderate: t('course.moderate'),
			severe: t('course.severe'),
			normal: t('course.normal'),
			abnormal: t('course.abnormal'),
		}

		return reference[key]
	}

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>{t('post_partum.details')}</h1>
				</Col>
				<Col span={12}>
					<Button
						icon={<EditOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditPostPartumRecord
									form={form}
									refetch={refetch}
									record={postPartum}
								/>,
							)
						}
					>
						<span className='title'>{t('post_partum.update')}</span>
					</Button>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>{PostPartumDetails()}</Row>
			<Sidepanel ref={panel} />
		</>
	)
}
export default PostPartumRecords
