import React, { useContext, useState, useEffect } from 'react'
import '../../assets/css/dashboard/dashboard.css'
import { Row, Col, Statistic, PageHeader, Avatar } from 'antd'
import HeaderContext from './main-layout-context'
import moment from 'moment'
import { GET_HOSPITAL_INFO_FROM_USERS } from '../../graphql/layouts/queries'
import { useSubscription } from '@apollo/react-hooks'
import { forEach } from 'lodash'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/img/logo.png'
import { getDateFormat } from '../../utils'

const AdminHeaderPage = () => {
	const {
		states: {
			headerNavCont: { headerTitle },
		},
	} = useContext(HeaderContext)
	const { t, i18n } = useTranslation()

	const getHospital = () => {
		let name = ''
		if (i18n.language === 'en') name = `Electronic Maternal and Child Health`
		else if (i18n.language === 'fr') name = `Système de gestion du carnet de santé de la mère et de l'enfant`
		else name = `電子母子手帳システム`
		return name
	}

	const [info, setInfo] = useState({
		hospital: getHospital(),
		patients: 0,
		doctors: 0,
		available_doctors: 0,
	})

	const { data } = useSubscription(GET_HOSPITAL_INFO_FROM_USERS, {
		fetchPolicy: 'no-cache',
		shouldResubscribe: false,
	})

	useEffect(() => {
		if (data) {
			let patientsCount = 0
			let doctorsCount = 0
			let availableDoctorsCount = 0

			forEach(data.users, user => {
				if (user.role.description === 'patient') {
					if (user.patients.length > 0) {
						patientsCount++
					}
				} else if (user.role.description === 'doctor') {
					if (user.doctors.length > 0) {
						doctorsCount++
						if (user.doctors[0].is_available === true) {
							availableDoctorsCount++
						}
					}
				}
			})

			setInfo({
				...info,
				patients: patientsCount,
				doctors: doctorsCount,
				available_doctors: availableDoctorsCount,
			})
		}
	}, [data])

	const getDate = () => {
		let format = ''

		if (i18n.language === 'en') format = `ddd ${getDateFormat(i18n)}`
		else if (i18n.language === 'fr') format = `ddd ${getDateFormat(i18n)}`
		else format = `${getDateFormat(i18n)} dddd`

		return moment().format(format)
	}

	return (
		<PageHeader
			className='site-page-header-responsive'
			style={{
				marginLeft: '4px',
				background: '#fff',
			}}
			title={<b className='dash-title'>{headerTitle}</b>}
		>
			<Row>
				<Col>
					<Avatar style={{ resizeMode: 'stretch' }} size={70} src={logo} />
				</Col>
				<Col style={{ marginLeft: '10px', flexGrow: 100 }}>
					<div className='dash-header-title'>{info.hospital}</div>
					<div className='dash-header-desc' style={{ marginTop: '10px' }}>
						{getDate()}
					</div>
				</Col>
				<Col>
					<Row>
						<Col>
							<Statistic
								className='dash-header-stat'
								style={{ textAlign: 'right' }}
								title={t('doctors.available_doctors')}
								value={info.available_doctors}
							/>
						</Col>
						<Col
							style={{
								marginRight: '20px',
								marginLeft: '20px',
								background: '#E9E9E9',
								width: '1px',
							}}
						></Col>
						<Col>
							<Statistic
								className='dash-header-stat'
								style={{ textAlign: 'right' }}
								title={t('doctors.no_doctors')}
								value={info.doctors}
							/>
						</Col>
						<Col
							style={{
								marginRight: '20px',
								marginLeft: '20px',
								background: '#E9E9E9',
								width: '1px',
							}}
						></Col>
						<Col>
							<Statistic
								className='dash-header-stat'
								style={{ textAlign: 'right' }}
								title={t('patients.no_patients')}
								value={info.patients}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</PageHeader>
	)
}

export default AdminHeaderPage
