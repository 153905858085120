import React from 'react'
import HeaderButton from './HeaderButton'

const HeaderStyleControls = props => {
	const { editorState, onToggle, HEADER_STYLES } = props
	const selection = editorState.getSelection()
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType()

	return (
		<div className='RichEditor-controls'>
			{HEADER_STYLES.map(type => (
				<HeaderButton
					key={type.label}
					icon={type.icon}
					style={type.style}
					label={type.label}
					onToggle={onToggle}
					active={type.style === blockType}
				/>
			))}
		</div>
	)
}

export default HeaderStyleControls
