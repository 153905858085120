import gql from 'graphql-tag'

export const GET_NEWSFEEDS = gql`
	query GET_NEWSFEEDS($where: newsfeeds_bool_exp, $limit: Int, $offset: Int) {
		newsfeeds(where: $where, order_by: {  updated_at: desc }, limit: $limit, offset: $offset) {
			id
			category
			title
			content
			image
			week_of_pregnancy
			updated_at
			deleted_at
			subtitle
			user {
				id
				first_name
				last_name
			}
		}
	}
`

export const GET_NEWSFEEDS_SUBSCRIPTION = gql`
	subscription GET_NEWSFEEDS($where: newsfeeds_bool_exp, $limit: Int, $offset: Int) {
		newsfeeds(where: $where, order_by: { updated_at: desc }, limit: $limit, offset: $offset) {
			id
			category
			title
			content
			image
			week_of_pregnancy
			updated_at
			deleted_at
			subtitle
			user {
				id
				first_name
				last_name
			}
		}
	}
`

export const GET_DETELED_NEWSFEEDS_SUBSCRIPTION = gql`
	subscription GET_NEWSFEEDS($where: newsfeeds_bool_exp) {
		newsfeeds(where: $where, order_by: { deleted_at: desc }, limit: 1) {
			id
		}
	}
`

export const GET_NEWSFEED_INFO_BY_ID = gql`
	query GET_NEWSFEED_INFO_BY_ID($where: newsfeeds_bool_exp) {
		newsfeeds(where: $where) {
			id
			category
			content
			title
			image
			week_of_pregnancy
			updated_at
			subtitle
		}
	}
`
