import gql from 'graphql-tag'

export const GET_COURSE_OF_PREGNACIES = gql`
	query GET_COURSE_OF_PREGNACIES($where: course_of_pregnancies_bool_exp) {
		course_of_pregnancies(where: $where, order_by: { exam_date: desc }) {
			id
			exam_date
			week_of_pregnancy
			height_of_uterus
			circumference_of_abdomen
			weight
			blood_pressure
			edema
			protein_in_urine
			sugar_in_urine
			notes
			medical_institution
			doctor {
				id
				user {
					id
					first_name
					last_name
				}
			}
		}
	}
`
