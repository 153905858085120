import moment from 'moment'

import _ from 'lodash'

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformDoctorPatientList = (data, t) => {
	let patients = []

	if (data) {
		patients = data.appointments.map(record => {
			let weekText = t('crud.n_a')

			if (
				record.patient &&
				record.patient.pregnancies &&
				record.patient.pregnancies instanceof Array &&
				record.patient.pregnancies[0] &&
				record.patient.pregnancies[0].id
			) {
				if (isPregnancyDone(record.patient.pregnancies[0])) {
					const pregnancyLength = moment(
						getLatestDeliveryTimestamp(record.patient.pregnancies[0]),
					).diff(moment(record.patient.pregnancies[0].start_date), 'weeks')

					if (pregnancyLength < 39) weekText = t('pregnancies.early_term')
					else if (pregnancyLength >= 39 && pregnancyLength < 41)
						weekText = t('pregnancies.full_term')
					else if (pregnancyLength >= 41 && pregnancyLength < 42)
						weekText = t('pregnancies.late_term')
					else if (pregnancyLength >= 42) weekText = t('pregnancies.post_term')
				} else {
					const week =
						moment().diff(record.patient.pregnancies[0].start_date, 'weeks') + 1

					weekText = `${week} ${
						week > 1 ? t('common.weeks') : t('common.week')
					}`
				}
			}

			return {
				key: record.patient.id,
				imageURL: record.patient.user.avatar_link || null,
				name: {
					first: record.patient.user.first_name,
					last: record.patient.user.last_name,
				},
				code: record.patient.account_code,
				cycle: weekText,
				contact: record.patient.user.telephone,
				email: record.patient.user.email,
			}
		})
	}

	patients = _.uniqBy(patients, 'key')

	return patients
}

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformAdminPatientList = (data, t) => {
	let patients = []

	if (data) {
		patients = data.patients.map(record => {
			let weekText = t('crud.n_a')

			if (
				record &&
				record.pregnancies &&
				record.pregnancies instanceof Array &&
				record.pregnancies[0] &&
				record.pregnancies[0].id
			) {
				if (isPregnancyDone(record.pregnancies[0])) {
					const pregnancyLength = moment(
						getLatestDeliveryTimestamp(record.pregnancies[0]),
					).diff(moment(record.pregnancies[0].start_date), 'weeks')

					if (pregnancyLength < 39) weekText = t('pregnancies.early_term')
					else if (pregnancyLength >= 39 && pregnancyLength < 41)
						weekText = t('pregnancies.full_term')
					else if (pregnancyLength >= 41 && pregnancyLength < 42)
						weekText = t('pregnancies.late_term')
					else if (pregnancyLength >= 42) weekText = t('pregnancies.post_term')
				} else {
					const week =
						moment().diff(record.pregnancies[0].start_date, 'weeks') + 1

					weekText = `${week} ${
						week > 1 ? t('common.weeks') : t('common.week')
					}`
				}
			}

			return {
				key: record.id,
				imageURL: record.user.avatar_link || null,
				name: {
					first: record.user.first_name,
					last: record.user.last_name,
				},
				code: record.account_code,
				cycle: weekText,
				contact: record.user.telephone,
				address: record.user.address,
				date_of_birth: record.date_of_birth,
				email: record.user.email,
			}
		})
	}

	return patients
}

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformPreviousAppointmentList = (data, t, format) => {
	let previous = []
	if (data) {
		previous = data.map(record => {
			return {
				key: record.prevId,
				item1: t(`appointments.${record.name}`),
				item2: moment(record.datetime).format(format),
				status: (record.status !== 'cancelled' || record.status !== 'declined' ) ? 'done' : 'cancelled',
			}
		})
	}

	return previous
}

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformPregnancyList = (data, t) => {
	let pregnancies = []

	if (data) {
		pregnancies = data.map((record, i) => {
			if (isPregnancyDone(record, i)) {
				const dob = getLatestDeliveryTimestamp(record)
				const orderReference = {
					1: 'common.first',
					2: 'common.second',
					3: 'common.third',
				}

				let order = ''
				if (data.length - i > 3) order = `${data.length - i}${t('common.th')}`
				else order = t(orderReference[data.length - i])

				return {
					key: record.pregId,
					isCurrent: false,
					imageURL: null,
					name: {
						first: order,
						last: t('pregnancies.pregnancy'),
					},
					date: dob || null,
				}
			} else {
				return {
					key: record.pregId,
					isCurrent: true,
					imageURL: null,
					name: {
						first: t('pregnancies.current_pregnancy'),
						last: '',
					},
					date: record.due_date || null,
				}
			}
		})
	}

	return pregnancies
}

const isPregnancyDone = (record, i = 0) => {
	if (i !== 0 || record.record_of_deliveries.length > 0) return true

	return moment(record.due_date).diff(moment(), 'days') <= 0
}

const getLatestDeliveryTimestamp = record => {
	if (record.record_of_deliveries.length === 0) return record.due_date

	let latestDelivery = null

	for (let i = 0; i < record.record_of_deliveries.length; i++) {
		const delivery = record.record_of_deliveries[i]

		if (latestDelivery !== null) {
			if (
				delivery.delivery_timestamp &&
				moment(delivery.delivery_timestamp).isAfter(moment(latestDelivery))
			)
				latestDelivery = delivery.delivery_timestamp
		} else latestDelivery = delivery.delivery_timestamp
	}

	return latestDelivery
}
