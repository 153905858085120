import React, { useContext, useState, useEffect } from 'react'
import '../../assets/css/dashboard/dashboard.css'
import { Row, Col } from 'antd'
import { dashboardComponents } from '../../components'
import { HeaderContext } from '../../layouts'
import { GET_DOCTOR_DASHBOARD_DATA } from '../../graphql/dashboard/queries'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'

import {
	transformAppointments,
	transformCancelledAppointments,
	transformAppointmentClients,
} from '../../utils/dashboard-data'

import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { getName } from '../../components/common/get-name'

/* Sample import of component */
const {
	CancelledAppointments,
	PatientList,
	ActiveAppointments,
	PendingAppointments,
} = dashboardComponents

const DashboardDoctorPage = () => {
	const { states } = useContext(HeaderContext)
	const { userId, userType } = states.headerNavCont
	const { t, i18n } = useTranslation()

	const [dashboardData, setDashboardData] = useState({
		active: null,
		pending: null,
		cancelled: null,
		patients: null,
	})

	const [activeDate, setActiveDate] = useState(moment())
	const [pendingDate, setPendingDate] = useState(moment())

	//we get all the appointments
	const { data, refetch } = useQuery(GET_DOCTOR_DASHBOARD_DATA, {
		fetchPolicy: 'network-only',
		variables: {
			active_where: {
				doctor: {
					user_id: {
						_eq: userId,
					},
				},
				datetime: {
					_gte: moment(activeDate).startOf('day'),
					_lte: moment(activeDate).endOf('day'),
				},
				status: {
					_eq: 'active',
				},
			},
			pending_where: {
				doctor: {
					user_id: {
						_eq: userId,
					},
				},
				datetime: {
					_gte: moment().isSame(moment(pendingDate), 'days')
						? moment().subtract(30, 'minutes').format()
						: moment(pendingDate).startOf('day'),
					_lte: moment(pendingDate).endOf('day'),
				},
				status: {
					_eq: 'pending',
				},
			},
			cancelled_upcoming_where: {
				doctor: {
					user_id: {
						_eq: userId,
					},
				},
				datetime: {
					_gte: moment().startOf('day'),
					_lte: moment().endOf('day'),
				},
				_and: [
					{
						_or: [
							{
								status: {
									_eq: 'cancelled',
								}
							},
							{
								status: {
									_eq: 'declined',
								}
							}
						],
					},
				],
			},
			patients_where: {
				doctor: {
					user_id: {
						_eq: userId,
					},
				},
				datetime: {
					_gte: moment().startOf('day'),
					_lte: moment().endOf('day'),
				},
				status: {
					_eq: 'active',
				},
			},
		},
	})

	useEffect(() => {
		if (data)
			setDashboardData({
				...dashboardData,
				active: data.active,
				pending: data.pending,
				cancelled: data.cancelled,
				patients: data.patients,
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const extendGetName = (first_name, last_name) => {
		return getName(first_name, last_name, i18n.language)
	}

	return (
		<span className="doctor-dash-page">
			<Row gutter={[16, 16]} >
				<Col span={12} xs={24} sm={12}>
					<ActiveAppointments
						data={
							transformAppointments(dashboardData.active, extendGetName) || []
						}
						refetch={refetch}
						date={activeDate}
						setDate={setActiveDate}
					/>
				</Col>
				<Col span={12} xs={24} sm={12}>
					<PendingAppointments
						data={
							transformAppointments(dashboardData.pending, extendGetName) || []
						}
						refetch={refetch}
						date={pendingDate}
						setDate={setPendingDate}
					/>
				</Col>
			</Row>
			<Row style={{height: '16px'}}></Row>
			<Row gutter={[16, 16]}>
				<Col span={12} xs={24} sm={12}>
					<CancelledAppointments
						data={
							transformCancelledAppointments(
								dashboardData.cancelled,
								t,
								extendGetName,
							) || []
						}
					/>
				</Col>
				<Col span={12} xs={24} sm={12}>
					<PatientList
						data={
							transformAppointmentClients(
								dashboardData.patients,
								t,
								extendGetName,
							) || []
						}
						userId={userId}
						userType={userType}
						refetch={refetch}
					/>
				</Col>
			</Row>
		</span>
	)
}

export default DashboardDoctorPage
