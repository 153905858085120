import React, { useEffect, useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Tabs, DatePicker, Space, Col, Row } from 'antd'
import '../../assets/css/appointments/appointments.css'
import AppointmentsList from '../../components/appointments/appointments-list'
import PendingAppointmentsList from '../../components/appointments/pending-appointments-list'
import { GET_APPOINTMENTS } from '../../graphql/appointments/queries'
import { HeaderContext } from '../../layouts'
import { useTranslation } from 'react-i18next'
import CommonSearch from '../../components/common/search'

const { TabPane } = Tabs

const AppointmentsPage = () => {
	const { t } = useTranslation()
	const {
		states: {
			headerNavCont: { userId, userType },
		},
	} = useContext(HeaderContext)

	const { view_panel = null, date = moment() } = useParams()

	const history = useHistory()

	const [startDate, setStartDate] = useState(moment(date))
	const [endDate, setEndDate] = useState(moment(date))
	const [filterValue, setFilterValue] = useState('')
	const [page1, setPage1] = useState(1)
	const [page2, setPage2] = useState(1)
	const [page3, setPage3] = useState(1)

	const doctorIdQueryVariable =
		userType === 'doctor'
			? {
					doctor: { user_id: { _eq: userId } },
			  }
			: {}
	const commonQueryVariables = {
		...doctorIdQueryVariable,
		deleted_at: {
			_is_null: true,
		},
		datetime: {
			_gte: moment(startDate).startOf('day'),
			_lte: moment(endDate).endOf('day'),
		},
	}
	const filterQueryVariables = {
		_or: [
			{
				patient: {
					user: {
						_or: [
							{
								first_name: {
									_ilike: `%${filterValue}%`,
								},
							},
							{
								last_name: {
									_ilike: `%${filterValue}%`,
								},
							},
						],
					},
				},
			},
			{
				name: { _ilike: `%${filterValue}%` },
			},
		],
	}
	const [appointments, setAppointments] = useState({
		approved: [],
		pending: [],
		cancelled: [],
		approved_count: { aggregate: { count: 0 } },
		pending_count: { aggregate: { count: 0 } },
		cancelled_count: { aggregate: { count: 0 } },
	})

	const { data, loading, refetch } = useQuery(GET_APPOINTMENTS, {
		fetchPolicy: 'network-only',
		variables: {
			active_where: {
				...commonQueryVariables,
				_and: [
					filterQueryVariables,
					{ _or: [{ status: { _eq: 'active' } }, { status: { _eq: 'done' } }] },
				],
			},
			pending_where: {
				...filterQueryVariables,
				...commonQueryVariables,
				status: { _eq: 'pending' },
			},
			cancelled_where: {
				...filterQueryVariables,
				...commonQueryVariables,
				_and: [{ _or: [{status: { _eq: 'cancelled' } }, { status: { _eq: 'declined' }}] }]
			},
			offset1: (page1 - 1) * 10,
			offset2: (page2 - 1) * 10,
			offset3: (page3 - 1) * 10,
		},
	})

	useEffect(() => {
		if (data) setAppointments(data)
	}, [data])

	const handleSearch = async value => {
		await setPage1(1)
		await setPage2(1)
		await setPage3(1)

		setFilterValue(value)
	}

	const handleTabChange = key => {
		let currentmoment = moment(date)
		let matchDate = currentmoment.format('YYYY-MM-DD')
		history.push(`/appointments/${matchDate}/${key}`)
	}

	const setDate = dates => {
		setStartDate(dates[0])
		setEndDate(dates[1])
	}
	const sideAction = (
		<Space>
			<DatePicker.RangePicker
				className='appointment-date-picker'
				value={[startDate, endDate]}
				onChange={dates => {
					setDate(dates)
				}}
				allowClear={false}
				style={{ width: '100%' }}
			/>
			<CommonSearch
				 className = 'appoint-search-field'
				 onSearch={value => handleSearch(value)} />
		</Space>
	)

	return (
		<>
			<Row 
				className= "appoint-search-row"
				justify = "end"
				>{sideAction}
			</Row>
			<Row
				className = "appoint-content"
			>
				<Col span = {24}>
					<Tabs
						className='appointments-tab'
						defaultActiveKey={view_panel || 'all'}
						// tabBarExtraContent={sideAction}
						onChange = {handleTabChange}
					>
						<TabPane tab={t('appointments.index')} key='all'>
							<AppointmentsList
								loading={loading}
								data={appointments.approved}
								count={appointments.approved_count.aggregate.count}
								refetch={refetch}
								page={page1}
								setPage={setPage1}
								mode={'active'}
							/>
						</TabPane>
						<TabPane tab={t('appointments.pending')} key='pending'>
							<PendingAppointmentsList
								loading={loading}
								data={appointments.pending}
								count={appointments.pending_count.aggregate.count}
								refetch={refetch}
								page={page2}
								setPage={setPage2}
							/>
						</TabPane>
						<TabPane tab={t('appointments.cancelled')} key='cancelled'>
							<AppointmentsList
								loading={loading}
								data={appointments.cancelled}
								count={appointments.cancelled_count.aggregate.count}
								refetch={refetch}
								page={page3}
								setPage={setPage3}
								mode={'cancelled'}
							/>
						</TabPane>
					</Tabs>
				</Col>
			</Row>
		</>
	)
}

export default AppointmentsPage
