import gql from 'graphql-tag'

export const GET_PRENATAL_TESTS = gql`
	query GET_PRENATAL_TESTS($where: prenatal_tests_bool_exp) {
		prenatal_tests(where: $where, order_by: { date: asc }) {
			id
			date
			notes
			cervical_cancer
			hbv
			rubella_virus
			chlamydia
			irregular_antibody
			syphilis
			hcv
			htlv_1
			group_b_strep
			doctor {
				id
				user {
					id
					first_name
					last_name
				}
			}
		}
	}
`
