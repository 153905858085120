export const getClassName = props => {
	let className = 'RichEditor-styleButton'
	if (props.active) {
		className += ' RichEditor-activeButton'
	}

	if (props.label === 'Underline') {
		className += ' underline-btn'
	}

	return className
}
