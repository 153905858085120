import React, { useContext, useEffect } from 'react'
import { HeaderContext } from '../../layouts'
import { useTranslation } from 'react-i18next'
import '../../assets/css/common/portal.css'

import EmailConfirmation from './email-confirmation'
import ResetPassword from './reset-password'
import { useParams } from 'react-router-dom'

export const ForgotPassword = () => {
	// const { t } = useTranslation()
	const { token } = useParams()

	const {
		stateSetters: { setNavCont },
	} = useContext(HeaderContext)

	useEffect(() => {
		setNavCont({ defaultKey: ['1'], hideSideBar: true, hideHeader: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='login-container login-wrap'>
			{token && <ResetPassword token={token} />}
			{!token && <EmailConfirmation />}
		</div>
	)
}
