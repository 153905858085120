import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
	LeftOutlined,
	RightOutlined,
	ClockCircleOutlined,
	EllipsisOutlined,
} from '@ant-design/icons'
import '../../../assets/css/dashboard/tablelist.css'
import { Card, List, Row, Col, Dropdown, Menu, Skeleton, Space } from 'antd'
import AppointmentContext from './appointment-context'
import { commonComponents } from '../../common'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toUpper } from 'lodash'

const AppointmentList = props => {
	const { t } = useTranslation()

	const history = useHistory()

	const [selectedMonth, setSelectedMonth] = useState('')

	const { title, data, mode, date, setDate } = props

	return (
		<>
			<AppointmentContext.Provider value={props}>
				<Card>
						<Row style={{ width: '100%' }} gutter={[16, 16]}>
							<Col xs={16} sm={15} lg ={12}>
								<h1 className='dash-title'>{title}</h1>
							</Col>
							<Col xs={8} sm={9} lg ={12} className="date-align">
								<b className='text-black float-right font-700'>
									{selectedMonth}
								</b>
							</Col>
						</Row>
						<Row>
							<AppointmentCalendar
								date={date}
								setDate={setDate}
								selectedMonth={selectedMonth}
								setSelectedMonth={setSelectedMonth}
							/>
							</Row>
						<AppointmentTableList appointments={data} mode={mode} />
						<BlockButton
							title={t('appointments.view_more_appointments')}
							onClick={() => {
								history.push(
									`appointments${
										mode !== 'active'
											? `/${moment(date).format('YYYY-MM-DD')}/${mode}`
											: `/${moment(date).format('YYYY-MM-DD')}`
									}`,
								)
							}}
						/>
				</Card>
			</AppointmentContext.Provider>
		</>
	)
}

const AppointmentCalendar = props => {
	const context = React.useContext(AppointmentContext)
	const { i18n } = useTranslation()
	const mode = context.mode
	const { date, setDate } = props
	const { setSelectedMonth } = props
	const [selectedWeek, setSelectedWeek] = useState(0)
	const [daysOfTheWeek, setDaysOfTheWeek] = useState([])
	const setWeek = counter => {
		let startOfWeek = moment().add(counter, 'weeks').startOf('isoWeek')
		let endOfWeek = moment().add(counter, 'weeks').endOf('isoWeek')

		let days = []
		let day = startOfWeek
		while (day <= endOfWeek) {
			days.push(day)
			day = day.clone().add(1, 'd')
		}

		setSelectedMonth(getStartOfWeekFormat(startOfWeek))
		setDaysOfTheWeek(days)
	}

	const getStartOfWeekFormat = startOfWeek => {
		let format = ''
		if (i18n.language == 'en' || i18n.language == 'fr') format = 'MMMM YYYY'
		else format = 'YYYY年MM月'
		return startOfWeek.format(format)
	}

	useEffect(() => {
		setWeek(selectedWeek)
	}, [selectedWeek])

	const hasPrevWeek = () => {
		if (mode === 'pending') {
			return selectedWeek > 0
		} else {
			return true
		}
	}

	const prev = () => {
		if (hasPrevWeek()) setSelectedWeek(selectedWeek - 1)
	}

	const next = () => {
		setSelectedWeek(selectedWeek + 1)
	}

	const onClickDay = day => {
		if (mode === 'pending') {
			if (moment().isSameOrBefore(day, 'day')) setDate(day)
		} else setDate(day)
	}

	return (
		<div style={{ width: '100%' }}>
			<div
				style={{
					borderRadius: '8.01668px',
					backgroundColor: '#FAFAFA',
					width: '100%',
					height: '90px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<LeftOutlined
					onClick={prev}
					style={{
						fontSize: '18px',
						color: '#3B3F3F',
						opacity: hasPrevWeek() ? '1' : '0.1',
					}}
				/>
				<div style={{ width: '85%' }}>
					<Row>
						{daysOfTheWeek.map(day => {
							let itemStyle = 'dash-appointment-day'
							if (
								moment(day).format('YYYY-MM-DD') ===
								moment(date).format('YYYY-MM-DD')
							) {
								itemStyle = 'dash-appointment-day-selected'
							}

							return (
								<Col
									key={day}
									style={{
										width: '14.285%',
										height: '100%',
										backgroundColor: 'transparent',
									}}
									onClick={() => onClickDay(moment(day))}
									value={moment(day)}
								>
									<div className={itemStyle}>
										<div
											style={{
												borderRadius: '5px',
												padding: '7px 12px 7px 12px',
											}}
										>
											<div>
												<label
													style={{
														fontSize: '16px',
														color: '#F8B0A5',
														fontFamily: 'Noto Sans',
														textAlign: 'center',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<b>{moment(day).format('DD')}</b>
												</label>
												<label
													style={{
														fontSize: '8px',
														color: '#636D73',
														fontFamily: 'Noto Sans',
														textAlign: 'center',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													{toUpper(moment(day).format('ddd'))}
												</label>
											</div>
										</div>
									</div>
								</Col>
							)
						})}
					</Row>
				</div>
				<RightOutlined
					onClick={next}
					style={{
						fontSize: '18px',
						color: '#3B3F3F',
					}}
				/>
			</div>
		</div>
	)
}

const { BlockButton } = commonComponents

const AppointmentTableList = props => {
	const { t } = useTranslation()
	const { mode, appointments } = props
	const context = React.useContext(AppointmentContext)

	const menu = (key, doctor_id, user_id) => {
		return (
			<Menu className='menu'>
				{(context.actionList || []).map((option, index) => (
					<Menu.Item
						className='item'
					>
						<a
							target='_blank'
							onClick={() => {
								option.function(key, doctor_id, user_id)
							}}
						>
							{option.name}
						</a>
					</Menu.Item>
				))}
			</Menu>
		)
	}

	const getTitle = item => {
		let status = ''

		if (mode === 'active') {
			if (moment(item.datetime).isAfter(moment()))
				status = t('appointments.upcoming')
			else if (
				moment(item.datetime).isBefore(
					moment(moment().subtract(30, 'minutes').format()),
				)
			)
				status = t('appointments.done')
			else status = t('appointments.ongoing')
		}

		if (item.key)
			return (
				<Row>
					<Col span={16}>
						<b className = "dash-appointlist-title">
							{item.name + " "} 
							 <ClockCircleOutlined />{' '}
							{moment(item.datetime).format('HH:mm')}
						</b>
					</Col>
					<Col span={8}>
						<b className = "dash-appointlist-title">{status}</b>
					</Col>
				</Row>
			)
		else return <p style={{ height: '10px' }}> </p>
	}

	const getDescription = item => {
		if (item.key)
			return (
				<span className='dash-tablelist-item' >
					{t(`appointments.${item.desc}`)}
				</span>
			)
		else return <p style={{ height: '10px' }}> </p>
	}

	const getActions = item => {
		if (item.key)
			return [
				<Dropdown
					className='dash-tablelist-dropdown'
					overlay={menu(item.key, item.doctor_id, item.user_id)}
					placement='bottomRight'
				>
					<a className='dropdown-link' target='_blank'>
						<EllipsisOutlined className='dash-tablelist-ellipsis' />
					</a>
				</Dropdown>,
			]
		else return <p style={{ height: '10px' }}> </p>
	}

	const getEmptyText = () => {
		if (mode === 'active') return t('empty_messages.active_appointments_list')
		else if (mode === 'pending')
			return t('empty_messages.pending_appointments_list')
		else if (mode === 'cancelled')
			return t('empty_messages.cancelled_appointments_list')

		return t('empty_messages.appointment_list')
	}

	const DisplayTable = () => {
		return (
			<>
				<List
					className='dashboard-calendar-list'
					dataSource={appointments}
					locale={{ emptyText: getEmptyText() }}
					renderItem={item => (
						<List.Item actions={getActions(item)}>
							<Skeleton title={false} loading={false} active>
								<List.Item.Meta
									className="dash-title"
									title={getTitle(item)}
									description={getDescription(item)}
								/>
							</Skeleton>
						</List.Item>
					)}
				/>
			</>
		)
	}

	return <DisplayTable />
}

export default AppointmentList
