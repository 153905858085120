import gql from 'graphql-tag'

export const GET_BABIES = gql`
	query GET_BABIES($where: babies_bool_exp) {
		babies(where: $where) {
			id
			first_name
			last_name
			sex
			pregnancy {
				id
				record_of_deliveries {
					id
					baby_first_name
					baby_last_name
					baby_sex
					delivery_timestamp
				}
			}
		}
	}
`
