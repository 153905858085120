import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../../layouts'
import { default as Patient } from './patient'
import { default as PatientProfile } from './patient-profile'
import { default as PatientPregnancy } from './patient-pregnancy'
import { useTranslation } from 'react-i18next'

export const PatientPage = () => {
	const { t } = useTranslation()

	const {
		stateSetters: { setNavCont },
		state,
	} = useContext(HeaderContext)

	useEffect(() => {
		setNavCont({
			defaultKey: ['5'],
			hideSideBar: false,
			hideHeader: false,
			headerTitle: t('patients.index'),
		})
	}, [])

	return <Patient />
}

export const PatientProfilePage = () => {
	const { t } = useTranslation()

	const {
		stateSetters: { setNavCont },
	} = useContext(HeaderContext)

	useEffect(() => {
		setNavCont({
			defaultKey: ['5'],
			hideSideBar: false,
			hideHeader: false,
			headerTitle: t('patients.patient_profile'),
		})
	}, [])

	return <PatientProfile />
}

export const PatientPregnancyPage = () => {
	const { t } = useTranslation()

	const {
		stateSetters: { setNavCont },
	} = useContext(HeaderContext)

	useEffect(() => {
		setNavCont({
			defaultKey: ['5'],
			hideSideBar: false,
			hideHeader: false,
			headerTitle: t('patients.patient_pregnancy'),
		})
	}, [])

	return <PatientPregnancy />
}
