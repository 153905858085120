import AWS from 'aws-sdk'
import { useState } from 'react';

const S3_BUCKET = 'emch';
const REGION = 'us-east-1';


AWS.config.update({
  accessKeyId: 'AKIA2JN7XIISKYIXHI6Z',
  secretAccessKey: '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'
})

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

const S3_URL = 'https://emch.s3.amazonaws.com'

const s3Uploader = (file, folder, callback) => {
  try {
    if (file) {
      const params = {
        ACL: 'public-read',
        Body: file.originFileObj,
        Bucket: S3_BUCKET,
        Key: `public/${folder}/${file.name}`
      };

      s3.putObject(params)
        .on('httpDone', () => {
          callback(`${S3_URL}/${params.Key}`)
        })
        .send((error) => {
          if (error) {
            console.log(error)
          }
        })
    }

  } catch (error) {
    console.log(error)
  }

}

export default s3Uploader