import gql from 'graphql-tag'

export const UPSERT_COURSE_OF_PREGNANCIES = gql`
	mutation UPSERT_COURSE_OF_PREGNANCIES(
		$objects: [course_of_pregnancies_insert_input!]!
	) {
		insert_course_of_pregnancies(
			objects: $objects
			on_conflict: {
				constraint: course_of_pregnancies_pkey
				update_columns: [
					exam_date
					week_of_pregnancy
					height_of_uterus
					circumference_of_abdomen
					weight
					blood_pressure
					edema
					protein_in_urine
					sugar_in_urine
					notes
					medical_institution
				]
			}
		) {
			affected_rows
		}
	}
`

export const UPDATE_COURSE_OF_PREGNANCIES = gql`
	mutation UPDATE_COURSE_OF_PREGNANCIES(
		$_set: course_of_pregnancies_set_input!
		$where: course_of_pregnancies_bool_exp!
	) {
		update_course_of_pregnancies(where: $where, _set: $_set) {
			affected_rows
		}
	}
`
