import firebase from 'firebase/app'

const firebaseConfig = {
	apiKey: 'AIzaSyBllVqBcdZRc3nAWaQu6WaydUMOH0lQx0w',
    authDomain: "emch-2021.firebaseapp.com",
    projectId: "emch-2021",
    storageBucket: "emch-2021.appspot.com",
    messagingSenderId: "217683344382",
    appId: "1:217683344382:web:7845e0653aba71fde45eaf",
    measurementId: "G-LWDG4KQQWT"
};

export const initApp = firebase.initializeApp(firebaseConfig);

export const newFirebase = firebase;