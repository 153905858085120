import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useLazyQuery, useSubscription } from '@apollo/react-hooks'
import { UPDATE_MESSAGES } from '../../graphql/messages/mutations'
import {
  GET_USER_WITH_LATEST_MESSAGE_VIEW,
  GET_USER_WITH_LATEST_MESSAGE_VIEW_FIRST,
  GET_USERS_WITHOUT_MESSAGES,
  SEARCH_USERS_BY_PK
} from '../../graphql/messages/queries'
import { Row, Col } from 'antd'
import moment from 'moment'
import '../../assets/css/messages/chat.css'
import { HeaderContext } from '../../layouts'
import { chatComponents } from '../../components'
import { uniqBy } from 'lodash'

const { List, Messages } = chatComponents

const ChatPage = () => {
  const { user_id: url_user_id } = useParams()
  const {
    states: {
      headerNavCont: { userId, userType },
    },
  } = useContext(HeaderContext)

  // list of users attached with latest_message
  const [users, setUsers] = useState(() => [])
  // refers to the current select of the user conversations list
  const [currentUser, setCurrentUser] = useState({})
  const [searchFilter, setSearchFilter] = useState(null)
  const [messageFilter, setMessageFilter] = useState(null)
  const [pagination, setPagination] = useState({ limit: 20, offset: 0, currentPage: 1 })
  const [hasMoreUsers, setHasMoreUsers] = useState(true)
  const [hasSetCurrentUser, setHasSetCurrentUser] = useState(false)
  const [updateMessages] = useMutation(UPDATE_MESSAGES)

  /**
   * Store in useMemo so that its better if performance
   */
  const queryVariables = useMemo(() => ({
    userId,
    limit: pagination.limit,
    offset: pagination.offset,
    where: {
      role: getSearchRoleFilter(),
      ...getSearchMessageFilter(),
      ...getSearchFilterVariable()
    },
  }), [pagination, searchFilter, messageFilter])

  /**
   * Check if users has new message
   */
  const { data: getLatestUser } = useSubscription(GET_USER_WITH_LATEST_MESSAGE_VIEW_FIRST, {
    fetchPolicy: 'network-only',
    variables: queryVariables,
  })

  /**
   * Get users on first loads
   */
  useEffect(() => {
    getUsersQuery({ variables: queryVariables })
  }, [])

  /**
   * Load the first user messages
   */
  useEffect(() => {
    if (!hasSetCurrentUser && users.length && !url_user_id) {
      setCurrentUser(users[0])
      setHasSetCurrentUser(true)
    }
  }, [users, hasSetCurrentUser, url_user_id])

  /**
   * Reset pagination if search filter or message filter
   */
  useEffect(() => {
    resetPagination()
    setUsers([])
    setHasMoreUsers(true)
  }, [searchFilter, messageFilter])

  /**
   * Run query if pagination, filter changes
   */
  useEffect(() => {
    getUsersQuery({ variables: queryVariables })
  }, [pagination, searchFilter, messageFilter])

  /**
   *  Update the list if there's new message and theres no filter`
   */
  useEffect(() => {
    if (!getLatestUser?.search_user_conversations?.length) return

    if (!messageFilter && !searchFilter) {
      setUsers((old) => uniqBy(transformUsersList([getLatestUser?.search_user_conversations[0], ...old]),
        'id'))
    }
  }, [getLatestUser, searchFilter, messageFilter])

  /**
   * When the user search for users
   * this creates a query of getting the users without messages
   */
  useEffect(() => {
    if (!searchFilter) return

    getUsersWithoutMessages({
      variables: {
        limit: pagination.limit,
        offset: pagination.offset,
        where: {
          id: { _neq: userId },
          _and: [
            { _not: { messages: { sender_id: { _eq: userId } } } },
            { _not: {messagesByReceiverId: {receiver_id: {_eq: userId } } } },
            { role: { description: getSearchRoleFilter() } },
            { deleted_at: { _is_null: true } }
          ],
          ...getSearchFilterVariable(),
        }
      }
    })
  }, [searchFilter, pagination])

  /**
   * Update the user conversation
   */
  useEffect(() => {
    if (currentUser.id) {
      updateMessages({
        variables: {
          _set: {
            is_seen: true,
            updated_at: moment(),
          },
          where: {
            sender_id: { _eq: currentUser.id },
            receiver_id: { _eq: userId },
            is_seen: { _eq: false }
          },
        },
      })
    }
  }, [currentUser])

  /**
   * Get user if user_id is pass in the url
   */
  useEffect(() => {
    if (url_user_id) {
      searchUsersByPK({
        variables: { id: url_user_id }
      })
    }
  }, [url_user_id])

  function getSearchRoleFilter() {
    let roles = ['admin', 'doctor']

    if (userType !== 'admin') {
      roles.push('patient')
    }

    return { _in: roles }
  }

  function getSearchFilterVariable() {
    if (!searchFilter) return {}

    return {
      _or: [
        { first_name: { _ilike: `%${searchFilter}%` } },
        { last_name: { _ilike: `%${searchFilter}%` } },
      ],
    }
  }

  function getSearchMessageFilter() {
    switch (messageFilter) {
      case null:
        return {}
      case 'active':
        return { is_available: { _eq: true } }
      case 'unread':
        return { is_seen: { _eq: false }, sender_id: { _neq: userId } }
    }
  }

  /**
   * Lazy query of users
   */
  const [getUsersQuery, { loading }] = useLazyQuery(GET_USER_WITH_LATEST_MESSAGE_VIEW, {
    fetchPolicy: 'network-only',
    onCompleted: getMessagesQueryOnComplete
  })

  /**
   * Query users without messages when using the search bar
   */
  const [getUsersWithoutMessages] = useLazyQuery(GET_USERS_WITHOUT_MESSAGES, {
    fetchPolicy: 'network-only',
    onCompleted: getUsersWithoutMessagesOnComplete
  })

  const [searchUsersByPK] = useLazyQuery(SEARCH_USERS_BY_PK, {
    fetchPolicy: 'network-only',
    onCompleted: searchUsersByPKOnComplete
  })

  /**
   * Set users list
   * @param data
   */
  function getMessagesQueryOnComplete(data) {
    setHasMoreUsers(data.search_user_conversations.length + 1 > pagination.limit)
    setUsers((old) => uniqBy(transformUsersList([...old, ...data.search_user_conversations]), 'id'))
  }

  /**
   *
   * @param data
   */
  function getUsersWithoutMessagesOnComplete(data) {
    setHasMoreUsers(data?.users.length + 1 > pagination.limit)
    const users = data?.users.map(({ role, doctors, ...user }) => {
      return {
        ...user,
        created_at: null,
        text: null,
        role: role.description,
        is_seen: false,
        is_available: doctors[0]?.is_available,
        sender_id: null,
      }
    })

    setUsers(old => transformUsersList([...old, ...users]))
  }

  /**
   *
   * @param data
   */
  function searchUsersByPKOnComplete(data) {
    if (data?.users_by_pk) {
      setCurrentUser(data?.users_by_pk)
      setHasSetCurrentUser(true)
    }
  }

  /**
   * Check if user is sending the last message
   * then update the is_seen to true
   * @param users[]
   * @returns users[]
   */
  const transformUsersList = (users) => {
    return users.map(user => {
      return {
        ...user,
        is_seen: userId === user?.sender_id ? true : user?.is_seen
      }
    })
  }

  const resetPagination = () => {
    setPagination({ limit: pagination.limit, offset: 0, currentPage: 1 })
  }

  /**
   * Load more messages when scroll to top
   *
   */
  const loadMoreMessages = () => {
    const offset = pagination.currentPage * pagination.limit
    setPagination((oldState) => ({ ...oldState, currentPage: oldState.currentPage + 1, offset }))
  }

  /**
   * Check if list scroll is at top,
   * then call loadMoreMessages
   * @param event
   */
  const handleConversationScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

    if (bottom && hasMoreUsers) {
      loadMoreMessages()
    }
  }

  return (
    <div className="chat-container" style={{ height: '100%', minHeight: '700px' }}>
      <Row style={{ height: '100%' }}
           gutter={[0,16]}
      >
        <Col xs={24} sm={24} md={10} lg={8} className="chat-side-list">
          <List
            users={users}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            setSearchFilter={setSearchFilter}
            messageFilter={messageFilter}
            setMessageFilter={setMessageFilter}
            loading={loading}
            onScroll={handleConversationScroll}
          />
        </Col>
        <Col xs={24} sm={24} md={14} lg={16}>
          <Messages currentUser={currentUser}/>
        </Col>
      </Row>
    </div>
  )
}

export default ChatPage
