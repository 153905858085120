import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
	Form,
	message,
	DatePicker,
	Spin,
	Divider,
	Select,
	Input,
	InputNumber,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { UPSERT_BABY_CHECKUPS } from '../../../../graphql/baby-checkups/mutations'
import { GET_CONFLICT_BABY_CHECKUPS } from '../../../../graphql/baby-checkups/queries'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'

const { BlockButton } = commonComponents

const AddEditBabyCheckupRecord = props => {
	const { t, i18n } = useTranslation()

	const {
		hidePanel,
		visible,
		record,
		form,
		refetch,
		doctor,
		baby,
		latestRecord = {},
	} = props
	const [date, setDate] = useState(null)
	const [inputs, setInputs] = useState({})
	const [isLoading, setLoading] = useState(false)
	const [upsertBabyCheckups] = useMutation(UPSERT_BABY_CHECKUPS)
	const [
		getConflictBabyCheckups,
		{ data: conflictValidationData },
	] = useLazyQuery(GET_CONFLICT_BABY_CHECKUPS, { fetchPolicy: 'network-only' })

	useEffect(() => {
		form.resetFields()
	}, [visible])

	useEffect(() => {
		if (!conflictValidationData) return

		if (conflictValidationData.baby_checkups.length) {
			message.error(t('baby_checkups.duplicate_baby_checkup'))
			setLoading(false)
			return
		}

		saveBabyCheckup()
	}, [conflictValidationData])

	const removeUndefinedValuesAndEmptyStringToNull = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else newValues[key] = values[key]
			}
		}

		return newValues
	}

	const saveBabyCheckup = () => {
		const doctor_id = doctor && doctor.id ? { doctor_id: doctor.id } : {}

		upsertBabyCheckups({
			variables: {
				objects: {
					baby_id: baby.id,
					id: record && record.id ? record.id : undefined,
					...doctor_id,
					...removeUndefinedValuesAndEmptyStringToNull(inputs),
				},
			},
		}).then(
			() => {
				refetch()
				hidePanel()
				setLoading(false)
				form.resetFields()
				if (record && record.id)
					message.success(t('baby_checkups.success_update'))
				else message.success(t('baby_checkups.success_add'))
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const handleUpdate = values => {
		if (!baby.id) {
			message.error(t('babies.baby_err_cannot_save'))
			return
		}
		setLoading(true)

		setInputs(values)

		getConflictBabyCheckups({
			variables: getConflictBabyCheckupVariables(values.date),
		})
	}

	const getRecordOfDeliveryTimetamp = () => {
		if (!baby) return moment()

		const deliveries = baby.pregnancy.record_of_deliveries

		for (let i = 0; i < deliveries.length; i++) {
			const delivery = deliveries[i]

			if (
				baby.first_name === delivery.baby_first_name &&
				baby.last_name === delivery.baby_last_name &&
				baby.sex === delivery.baby_sex
			)
				return moment(delivery.delivery_timestamp)
		}

		return moment()
	}

	const getStartAndEndDateVariables = (_gte, _lte) => ({
		where: {
			id:
				record && record.id
					? {
							_neq: record.id,
					  }
					: undefined,
			baby_id: { _eq: baby.id },
			deleted_at: {
				_is_null: true,
			},
			date: {
				_gte: _gte.startOf('day'),
				_lte: _lte.subtract(1, 'day').endOf('day'),
			},
		},
	})

	const getConflictBabyCheckupVariables = rawDate => {
		const date = moment(rawDate)
		const delivery_timestamp = getRecordOfDeliveryTimetamp()

		const months = date.diff(delivery_timestamp, 'months')
		const weeks = date.diff(delivery_timestamp, 'weeks') || 1

		if (months) {
			if (months <= 2)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(1, 'months'),
					moment(delivery_timestamp).add(3, 'months'),
				)
			else if (months <= 5)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(3, 'months'),
					moment(delivery_timestamp).add(6, 'months'),
				)
			else if (months <= 8)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(6, 'months'),
					moment(delivery_timestamp).add(9, 'months'),
				)
			else if (months <= 11)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(9, 'months'),
					moment(delivery_timestamp).add(12, 'months'),
				)
			else if (months <= 12)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(12, 'months'),
					moment(delivery_timestamp).add(13, 'months'),
				)
			else if (months <= 18)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(13, 'months'),
					moment(delivery_timestamp).add(19, 'months'),
				)
			else if (months <= 24)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(19, 'months'),
					moment(delivery_timestamp).add(25, 'months'),
				)
		} else {
			if (weeks === 1)
				return getStartAndEndDateVariables(
					delivery_timestamp,
					moment(delivery_timestamp).add(2, 'weeks'),
				)
			else if (weeks === 2)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(2, 'weeks'),
					moment(delivery_timestamp).add(3, 'weeks'),
				)
			else if (weeks === 3)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(3, 'weeks'),
					moment(delivery_timestamp).add(4, 'weeks'),
				)
			else if (weeks === 4)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(4, 'weeks'),
					moment(delivery_timestamp).add(5, 'weeks'),
				)
			else if (weeks === 5)
				return getStartAndEndDateVariables(
					moment(delivery_timestamp).add(5, 'weeks'),
					moment(delivery_timestamp).add(6, 'weeks'),
				)
		}

		// after 2 years, restriction is on a monthly basis
		return getStartAndEndDateVariables(
			moment(delivery_timestamp).add(months, 'months'),
			moment(delivery_timestamp).add(months + 1, 'months'),
		)
	}

	const getFormItemsBasedFromBirth = () => {
		if (!date && !record) return []

		const numOfMonthsSinceBirth = moment(date || record.date).diff(
			moment(getRecordOfDeliveryTimetamp()),
			'months',
		)

		const oneToThirtyTwoOptions = [...new Array(32)].map((t, i) => ({
			value: i + 1 + '',
			label: i + 1,
		}))

		const condition_of_mouth = {
			name: 'condition_of_mouth',
			label: t('baby_checkups.condition_of_mouth'),
			type: 'select',
			options: [
				{ value: null, label: t('crud.n_a') },
				{ value: 'normal', label: t('baby_checkups.normal') },
				{ value: 'abnormal', label: t('baby_checkups.abnormal') },
			],
			placeholder: t('baby_checkups.eg_normal_abnormal'),
		}

		const meals = {
			name: 'meals',
			label: t('baby_checkups.meals'),
			type: 'select',
			options: [
				{ value: null, label: t('crud.n_a') },
				{ value: '1', label: `1 ${t('common.time_per_day')}` },
				{ value: '2', label: `2 ${t('common.times_per_day')}` },
				{ value: '3', label: `3 ${t('common.times_per_day')}` },
				{ value: '4', label: `4 ${t('common.times_per_day')}` },
				{ value: '5', label: `5 ${t('common.times_per_day')}` },
				{ value: 'other', label: t('post_partum.other') },
			],
			placeholder: t('crud.select'),
		}

		const lessThanOneMonth = [
			{
				name: 'nursing_ability',
				label: t('baby_checkups.nursing_ability'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'normal', label: t('baby_checkups.normal') },
					{ value: 'abnormal', label: t('baby_checkups.abnormal') },
				],
				placeholder: t('baby_checkups.eg_normal_abnormal'),
			},
			{
				name: 'jaundice',
				label: t('baby_checkups.jaundice'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'present', label: t('baby_checkups.present') },
					{ value: 'absent', label: t('baby_checkups.absent') },
				],
				placeholder: t('baby_checkups.eg_present_absent'),
			},
			{
				name: 'irregularities_at_birth',
				label: t('baby_checkups.irregularities_at_birth'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'yes', label: t('common.yes') },
					{ value: 'no', label: t('common.no') },
				],
				placeholder: t('baby_checkups.eg_yes_no'),
			},
			{
				name: 'irregularities_after_birth',
				label: t('baby_checkups.irregularities_after_birth'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'yes', label: t('common.yes') },
					{ value: 'no', label: t('common.no') },
				],
				placeholder: t('baby_checkups.eg_yes_no'),
			},
		]

		const fiveMonths = [
			{
				name: 'feeding_method',
				label: t('baby_checkups.feeding_method'),
				type: 'select',
				options: [
					{ value: 'breastfeeding', label: t('baby_checkups.breastfeeding') },
					{ value: 'mixed', label: t('baby_checkups.mixed') },
					{ value: 'formula', label: t('baby_checkups.formula') },
				],
				placeholder: t('baby_checkups.eg_feeding_method'),
			},
		]

		const eightMonths = [
			{
				name: 'feeding_method',
				label: t('baby_checkups.feeding_method'),
				type: 'select',
				options: [
					{ value: 'breastfeeding', label: t('baby_checkups.breastfeeding') },
					{ value: 'mixed', label: t('baby_checkups.mixed') },
					{ value: 'formula', label: t('baby_checkups.formula') },
				],
				placeholder: t('baby_checkups.eg_feeding_method'),
			},
			{
				name: 'preparation_for_weaning',
				label: t('baby_checkups.preparation_for_weaning'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'started', label: t('baby_checkups.started') },
					{ value: 'not_started', label: t('baby_checkups.not_started') },
				],
				placeholder: t('baby_checkups.eg_started_not_started'),
			},
			condition_of_mouth,
		]

		const elevenMonths = [
			meals,
			{
				name: 'number_of_teeth',
				label: t('baby_checkups.number_of_teeth'),
				type: 'select',
				options: [...oneToThirtyTwoOptions],
				placeholder: t('crud.select'),
			},
			condition_of_mouth,
		]

		const oneYear = [
			{
				name: 'breastfeeding',
				label: t('baby_checkups.breastfeeding'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{
						value: 'still_breastfeeding',
						label: t('baby_checkups.still_breastfeeding'),
					},
					{
						value: 'stopped_breastfeeding',
						label: t('baby_checkups.stopped_breastfeeding'),
					},
				],
				placeholder: t('crud.select'),
			},
			meals,
			{
				name: 'condition_of_eyes',
				label: t('baby_checkups.condition_of_eyes'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 'normal', label: t('baby_checkups.normal') },
					{ value: 'abnormal', label: t('baby_checkups.abnormal') },
				],
				placeholder: t('baby_checkups.eg_normal_abnormal'),
			},
		]

		if (numOfMonthsSinceBirth === 0) return lessThanOneMonth
		if (numOfMonthsSinceBirth <= 5) return fiveMonths
		if (numOfMonthsSinceBirth <= 8) return eightMonths
		if (numOfMonthsSinceBirth <= 11) return elevenMonths

		return oneYear
	}

	const getRecordFormItems = () => {
		const formItems = [
			{
				name: 'date',
				label: t('baby_checkups.checkup_date'),
				type: 'datepicker',
				placeholder: t('crud.select_date'),
				required: true,
			},
			{
				name: 'height',
				label: `${t('health.height')} (cm)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'weight',
				label: `${t('health.weight')} (kg)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'head_circumference',
				label: `${t('baby_checkups.head_circumference')} (cm)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'chest_circumference',
				label: `${t('baby_checkups.chest_circumference')} (cm)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'diet',
				label: t('baby_checkups.diet'),
				type: 'select',
				options: [
					{ value: 'good', label: t('baby_checkups.good') },
					{ value: 'need_advice', label: t('baby_checkups.need_advice') },
				],
				placeholder: t('baby_checkups.eg_diet'),
			},
			...getFormItemsBasedFromBirth(),
			{
				name: 'general_health',
				label: t('baby_checkups.general_health'),
				type: 'select',
				options: [
					{ value: 'healthy', label: t('baby_checkups.healthy') },
					{
						value: 'requires_monitoring',
						label: t('baby_checkups.requires_monitoring'),
					},
				],
				placeholder: t('baby_checkups.eg_general_health'),
			},
			{
				name: 'medical_institution',
				label: t('crud.medical_institution'),
				type: 'input',
				placeholder: t('baby_checkups.name_of_medical_institution'),
			},
			{
				name: 'notes',
				label: t('crud.notes'),
				type: 'input',
				placeholder: t('crud.type_here'),
			},
		]

		return formItems.map(formItem => (
			<Form.Item
				name={formItem.name}
				label={formItem.label}
				className='field-input'
				rules={[
					{
						required: formItem.required ? true : false,
						message: t('dialog.required_field'),
					},
				]}
				initialValue={
					record && record[formItem.name] !== null
						? formItem.name === 'date'
							? moment(record[formItem.name])
							: record[formItem.name]
						: undefined
				}
			>
				{getFormField(formItem)}
			</Form.Item>
		))
	}

	const getFormField = formItem => {
		if (formItem.type === 'input')
			return (
				<Input
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'number')
			return (
				<InputNumber
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
					min = {1}
					max={99999}
				/>
			)
		else if (formItem.type === 'select')
			return (
				<Select
					showArrow
					bordered={false}
					autoComplete='off'
					options={formItem.options}
					style={{ width: '100%' }}
					suffixIcon={<DownOutlined />}
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'datepicker')
			return (
				<DatePicker
					allowClear={false}
					autoComplete='off'
					format={getDateFormat(i18n)}
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
					onChange={value => setDate(value)}
					disabledDate={current => {
						if (current.diff(getRecordOfDeliveryTimetamp()) > 0) return false

						return true
					}}
				/>
			)
	}

	return (
		<Form
			form={form}
			layout='vertical'
			onFinish={handleUpdate}
		>
			<div className='drawer-title'>
				{!record && t('baby_checkups.add_baby_checkup')}
				{record && t('baby_checkups.edit_baby_checkup')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				{getRecordFormItems()}
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditBabyCheckupRecord
