import React, { useState } from 'react'
import { Row, Form, Input, message, Button, Spin, Col } from 'antd'
import emchLogo from '../../emchLogo.svg'
import getImage from '../../utils/get-image'
import { useTranslation } from 'react-i18next'
import { commonComponents } from '../../components/common'
import { useHistory } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import moment from 'moment'

const { BlockButton } = commonComponents

const EmailConfirmation = () => {
	const { t } = useTranslation()
	const [form] = Form.useForm()

	const history = useHistory()
	const [emailState, setEmailState] = useState(null)
	const [loading, setLoading] = useState(false)
	const [doesUserExists, setDoesUserExists] = useState(false)

	const handleFinish = value => {
		let xhr = new XMLHttpRequest()

		xhr.addEventListener('load', () => {
			setLoading(false)

			if (xhr.status === 200) {
				const response = xhr.response
				if (response) {
					setDoesUserExists(true)
					sendEmail(value.email)
					setEmailState(value.email)
				} else {
					setDoesUserExists(false)
					message.error(t('reset_password.email_verification_err'))
				}
			} else {
				if (xhr.status == 404) {
					message.error('Page not found')
				} else {
					message.error('Internal Server Error')
				}
			}
		})

		xhr.addEventListener('error', () => {
			message.error('Reset Server is not working. Please try again')
		})

		xhr.open('POST', `${process.env.REACT_APP_API_AUTH}/email-check`)

		xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

		xhr.send(JSON.stringify(value))

		setLoading(true)
	}

	const generateResetLink = email => {
		const token = {
			email,
			iat: moment().unix(),
			exp: moment().add(30, 'minutes').unix(),
		}

		const signedToken = jwt.sign(token, process.env.REACT_APP_API_JWT_SECRET)

		return `${process.env.REACT_APP_API_CLIENT}/reset-password/${signedToken}`
	}

	const sendEmail = email => {
		const link = generateResetLink(email)

		let xhr = new XMLHttpRequest()

		xhr.addEventListener('load', () => {
			if (xhr.status !== 200) {
				if (xhr.status == 404) {
					message.error('Page not found')
				} else {
					message.error('Internal Server Error')
				}
			}
		})

		xhr.addEventListener('error', () => {
			message.error('Reset Server is not working. Please try again')
		})

		xhr.open('POST', `${process.env.REACT_APP_API_AUTH}/send-email`)

		xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

		xhr.send(
			JSON.stringify({
				email,
				subject: t('reset_password.emch_index'),
				body_title: t('reset_password.index'),
				body_text: `
					${t('reset_password.reset_email_1')}
					<br>
					${t('reset_password.reset_email_2')}
					<br><br>
					<a href="${link}">${t('reset_password.reset_email_3')}</a>
					<br><br>
					${t('reset_password.reset_email_4')}
					<br>
					${t('reset_password.reset_email_5')}
					<br><br>
					${t('reset_password.reset_email_6')}
				`,
			}),
		)
	}

	return (
		<div className='relative'>
			<Row className='login-wrap'>
				<img
					src={getImage('loginbanner')}
					alt='Login Image'
					style={{ width: 700, height: 'auto', marginBottom: 40 }}
				/>
			</Row>
			<Row className='login-wrap'>
				<img
					src={emchLogo}
					alt='EMCH Logo'
					style={{ width: 150, height: 'auto', marginBottom: 40 }}
				/>
			</Row>
			<Row>
				<Col span={4}></Col>
				<Col span={16}>
					<div className='content-title noto'>
						{doesUserExists && <>{t('reset_password.check_email')}</>}
						{!doesUserExists && (
							<>
								<div>{t('reset_password.enter_email_with_acc')}</div>
								<div>{t('reset_password.email_link_reset_pass')}</div>
							</>
						)}
					</div>
					{doesUserExists && (
						<>
							<Button
								type='link'
								className='block content-button'
								onClick={() => {
									sendEmail(emailState)
								}}
							>
								{t('reset_password.reset_email')}
							</Button>
							<Button
								type='link'
								className='block content-button'
								onClick={() => history.push('/')}
							>
								{t('reset_password.try_log_again')}
							</Button>
						</>
					)}
					{!doesUserExists && (
						<Spin spinning={loading}>
							<Form
								form={form}
								layout='vertical'
								onFinish={handleFinish}
								className='content-form'
								onFinishFailed={() => {
									message.error(t('reset_password.email_verification_err'))
								}}
							>
								<Form.Item
									name='email'
									className='field-input'
									rules={[
										{
											type: 'email',
											message: t('dialog.not_valid_email'),
										},
										{
											required: true,
											message: t('dialog.required_field'),
										},
									]}
								>
									<Input
										autoComplete='off'
										placeholder={t('reset_password.enter_email')}
										style={{ width: '100%' }}
										className='input-component'
									/>
								</Form.Item>
								<BlockButton
									style={{ marginTop: '10px' }}
									htmlType='submit'
									title={t('common.reset')}
								/>
							</Form>
						</Spin>
					)}
				</Col>
				<Col span={4}></Col>
			</Row>
		</div>
	)
}

export default EmailConfirmation
