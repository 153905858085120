import React, { useEffect, useState, useRef } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import {
	Typography,
	Col,
	Row,
	Button,
	Form,
	Collapse,
	Dropdown,
	Menu,
	message,
} from 'antd'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { GET_BABY_IMMUNIZATIONS } from '../../../../graphql/baby-immunizations/queries'
import { UPDATE_BABY_IMMUNIZATIONS } from '../../../../graphql/baby-immunizations/mutations'
import { commonComponents } from '../../../common'
import AddEditBabyImmunizationRecord from './add-edit-drawer'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'
import { getName } from '../../../common/get-name'

const { Panel } = Collapse
const { Sidepanel, ConfirmModal } = commonComponents

const BabyImmunization = props => {
	const { t, i18n } = useTranslation()

	const { doctor, baby } = props

	const panel = useRef()
	const [form] = Form.useForm()
	const [immunizations, setImmunizations] = useState([])
	const [activePanel, setActivePanel] = useState(undefined)
	const [updateBabyImmunization] = useMutation(UPDATE_BABY_IMMUNIZATIONS)

	const { data, refetch } = useQuery(GET_BABY_IMMUNIZATIONS, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				baby_id: {
					_eq: baby.id,
				},
			},
		},
	})

	useEffect(() => {
		if (!data) return

		setActivePanel(undefined)
		setImmunizations(data.baby_immunizations)
	}, [data])

	const getVaccine = key => {
		const reference = {
			bcg: t('baby_immunizations.bcg'),
			pcv: t('baby_immunizations.pcv'),
			dtp_ipv: t('baby_immunizations.dtp_ipv'),
			mr: t('baby_immunizations.mr'),
			chickenpox: t('baby_immunizations.chicken_pox'),
			hib: t('baby_immunizations.hib'),
			hpv: t('baby_immunizations.hpv'),
			hepatitis_b: t('baby_immunizations.hepatitis_b'),
		}

		return reference[key]
	}

	const menu = record => (
		<Menu>
			<Menu.Item
				onClick={() => {
					panel.current.showPanel(
						<AddEditBabyImmunizationRecord
							form={form}
							baby={baby}
							record={record}
							refetch={refetch}
						/>,
					)
				}}
			>
				{t('crud.edit')}
			</Menu.Item>
			<Menu.Item
				onClick={() =>
					ConfirmModal({
						mtitle: t('confirm.are_you_sure'),
						mcontent: t('confirm.cannot_undo'),
						monOk: () => {
							updateBabyImmunization({
								variables: {
									_set: {
										deleted_at: moment(),
									},
									where: {
										id: {
											_eq: record.id,
										},
									},
								},
							}).then(
								() => {
									refetch()
									message.success(t('baby_immunizations.success_delete'))
								},
								changeError => {
									message.error(changeError.message)
								},
							)
						},
						t,
					})
				}
			>
				{t('crud.remove')}
			</Menu.Item>
		</Menu>
	)

	const immunizationItemContent = content => {
		let doctorName = t('crud.n_a')

		if (content.doctor && content.doctor.id && content.doctor.user.id)
			doctorName = getName(
				content.doctor.user.first_name,
				content.doctor.user.last_name,
				i18n.language,
			)

		let immunizationData = [
			{
				label: t('baby_immunizations.vaccine'),
				data: getVaccine(content.vaccine),
			},
			{
				label: t('baby_immunizations.immunization_date'),
				data: moment(content.date).format(getDateFormat(i18n)),
			},
			{
				label: t('baby_immunizations.manufacturer_of_vaccine'),
				data: content.manufacturer || t('crud.n_a'),
			},
			{
				label: t('baby_immunizations.remarks'),
				data: content.notes || t('crud.n_a'),
			},
			{
				label: t('doctors.doctor'),
				data: doctorName,
			},
		]

		let contentData = immunizationData.map((data, index) => {
			return (
				<Col span={data.label === 'Notes' ? 24 : 12} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		return contentData
	}

	const immunizationItems = immunizations.map((immunization, index) => {
		return (
			<Panel
				header={
					<>
						<div style={{ color: '#969999' }}>
							{moment(immunization.date).format(getDateFormat(i18n))}
						</div>
						<div>{getVaccine(immunization.vaccine)}</div>
					</>
				}
				key={index}
				className='site-collapse-custom-panel'
				style={{ padding: '10px 0' }}
			>
				<Dropdown overlay={menu(immunization)} placement='bottomRight'>
					<EllipsisOutlined className='ellipsis' />
				</Dropdown>
				<Row gutter={[16, 16]}>{immunizationItemContent(immunization)}</Row>
			</Panel>
		)
	})

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>
						{getName(baby.first_name, baby.last_name, i18n.language)}
						{`${t('common.s')}${t('baby_immunizations.immunization')}`}
					</h1>
				</Col>
				<Col span={12}>
					<Button
						icon={<PlusOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditBabyImmunizationRecord
									form={form}
									doctor={doctor}
									baby={baby}
									refetch={refetch}
								/>,
							)
						}
					>
						<span className='title'>
							{t('baby_immunizations.add_immunization')}
						</span>
					</Button>
				</Col>
			</Row>
			<Sidepanel ref={panel} />
			<Collapse
				accordion
				expandIconPosition='right'
				bordered={false}
				style={{ padding: 20 }}
				className='site-collapse-custom-collapse'
				activeKey={activePanel}
				onChange={key => setActivePanel(key)}
			>
				{immunizationItems}
			</Collapse>
		</>
	)
}
export default BabyImmunization
