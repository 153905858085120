import gql from 'graphql-tag'

export const GET_POST_PARTUMS = gql`
	query GET_POST_PARTUMS($where: post_partums_bool_exp) {
		post_partums(where: $where) {
			id
			days_post_delivery
			breast_condition
			weight
			sugar_in_urine
			vaginal_bleeding
			protien_in_urine
		}
	}
`
