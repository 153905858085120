import React, { useContext, useEffect, useRef, useState } from 'react'
import { HeaderContext } from '../../../layouts';
import AppointmentList from './appointment-list'
import ViewEditAppointment from '../../appointments/view-edit-appointment'
import { commonComponents } from '../../common'
import { useHistory } from 'react-router-dom'
import { UPDATE_APPOINTMENTS } from '../../../graphql/dashboard/mutation'
import { useMutation } from '@apollo/react-hooks'
import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { sendMessage } from '../../../firebase-messaging'

const { ConfirmModal, RadioModal, Sidepanel } = commonComponents

const ActiveAppointments = props => {
	const { t } = useTranslation()

	const [radioModalVisible, showRadioModal] = useState(false)
	const [updateAppointment] = useMutation(UPDATE_APPOINTMENTS)
	const [[selected,patientid], setSelected] = useState(['',''])
	const [form] = Form.useForm()
	const { data, refetch, date, setDate } = props
	const panel = useRef()
	const history = useHistory()
  const { states } = useContext(HeaderContext)
  const {userType } = states.headerNavCont

	const actionList = [
		{
			name: t('crud.view'),
			function: (key, _, patient_id) => {
				panel.current.showPanel(
					<ViewEditAppointment
						onCancelAppointment={() => {
							ConfirmModal({
								mtitle: t('appointments.sure_cancel_appointment'),
								mcontent: t('confirm.cannot_undo'),
								monOk: () => {
									setSelected([key,patient_id])
									showRadioModal(true)
								},
								t,
							})
						}}
						itemId={key}
						form={form}
						refetch={refetch}
						showStatus={true}
					/>,
				)
			},
		},
	]
	const options = [
		{ optionValue: '1', title: t('appointments.unavailable_hour') },
		{ optionValue: '2', title: t('appointments.attending_emergency') },
		{ optionValue: '3', title: t('appointments.emergency_leave') },
		{ optionValue: '4', title: t('appointments.other') },
	]

	useEffect(()=>{
		if(['doctor'].includes(userType)){
			actionList.splice(0, 0, {
				name: t('crud.contact'),
				function: (key, doctor_id, patient_id) => {
					history.push(patient_id ? `messages/${patient_id}` : 'messages')
				},
			},)
		}
	}, [userType, actionList])

	const sendDoctorCancelled = (userid, appointid) => {
		let data = {
			'data' : {
				'notiftype': 'appoint-cancelled-doctor',
				'appointmentid' : appointid
			}
		}

		sendMessage(userid,data)
	}

	return (
		<div>
			<AppointmentList
				title={t('appointments.index')}
				actionList={actionList}
				mode='active'
				data={data}
				date={date}
				setDate={setDate}
			/>
			<Sidepanel ref={panel} />
			<RadioModal
				visible={radioModalVisible}
				setVisible={showRadioModal}
				submitCallback={value => {
					updateAppointment({
						variables: {
							_set: {
								status: 'declined',
								notes: options.find(item => item.optionValue === value).title,
							},
							where: {
								id: {
									_eq: selected,
								},
							},
						},
					}).then(
						() => {
							refetch()
							message.success(t('appointments.success_cancel'))
							sendDoctorCancelled(patientid,selected)
						},
						changeError => {
							message.error(changeError.message)
						},
					)
				}}
				options={options}
				defaultValue={'1'}
			/>
		</div>
	)
}

export default ActiveAppointments
