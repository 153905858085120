import React, { useState, useContext, useEffect } from 'react'
import { HeaderContext } from '../../layouts'
import emchLogo from '../../emchLogo.svg'
import {
	Form,
	Input,
	Button,
	Row,
	Spin,
	message,
	Checkbox,
	Col,
	Typography,
	Space,
} from 'antd'
import { decodeToken } from '../../utils'
import '../../assets/css/common/portal.css'
import getImage from '../../utils/get-image'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { setMessagingTokenToServer } from '../../firebase-messaging'

const { Text } = Typography

const LogIn = () => {
	const [form] = Form.useForm()
	const { t, i18n } = useTranslation()
	const history = useHistory()

	const [passwordType, setPasswordType] = useState('password')
	const [isInvalidCredentials, setIsInvalidCredentials] = useState(false)
	const [{ loading, data, error }, setLoginState] = useState({
		loading: false,
		data: null,
		error: null,
	})
	// Navigation Initializer
	const {
		stateSetters: { setNavCont },
	} = useContext(HeaderContext)
	useEffect(() => {
		setNavCont({ defaultKey: ['1'], hideSideBar: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [saveCreds, setSaveCreds] = useState({
		rememberMe: false,
	})

	const userAuth = loginCreds => {
		// create a new XMLHttpRequest
		let xhr = new XMLHttpRequest()

		// get a callback when the server responds
		xhr.addEventListener('load', () => {
			if (xhr.status == 200) {
				let response = JSON.parse(xhr.response)

				if (response.status != null && response.status != 200) {
					setLoginState(oldState => ({
						loading: false,
						data: null,
						error: response,
					}))
				} else {
					setLoginState(oldState => ({
						loading: false,
						error: null,
						data: response,
					}))
				}

				localStorage.setItem(
					'remember_user',
					saveCreds.rememberMe === true ? loginCreds.username : '',
				)
				localStorage.setItem(
					'remember_pass',
					saveCreds.rememberMe === true ? loginCreds.password : '',
				)
				localStorage.setItem('remember_me', saveCreds.rememberMe)
			} else {
				if (xhr.status == 404) {
					setLoginState(oldState => ({
						loading: false,
						data: null,
						error: { message: 'Page not found', status: 404 },
					}))
				} else {
					setLoginState(oldState => ({
						loading: false,
						data: null,
						error: { message: 'Internal Server Error', status: 500 },
					}))
				}
			}
		})

		// get a callback when there is an error connecting to the server (server down, unreachable)
		xhr.addEventListener('error', () => {
			setLoginState(oldState => ({
				...oldState,
				loading: false,
				error: {
					message: 'Login Server is not working. Please try again',
					status: 500,
				},
			}))
		})

		// open the request with the verb and the url
		xhr.open('POST', `${process.env.REACT_APP_API_AUTH}/login-auth`)

		/*Setting body content type*/
		xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

		// send the request
		xhr.send(JSON.stringify(loginCreds))
		setLoginState(oldState => ({ ...oldState, loading: true }))
	}

	const handleSubmit = values => {
		userAuth({
			username: values.username,
			password: values.password,
			platform: 'web',
		})
	}

	useEffect(() => {
		if (data) {
			localStorage.setItem('token', data.token)

			let userIdFromToken = decodeToken(data.token).user_id
			let exp = decodeToken(data.token).exp
			let type = decodeToken(data.token).type
			let temp = decodeToken(data.token).is_temp_pass

			localStorage.setItem('is_temp_pass', temp)

			if (type === 'admin') {
				temp = false
			}

			setMessagingTokenToServer(userIdFromToken, i18n.language)

			setNavCont({
				userId: userIdFromToken,
				userExp: exp,
				userType: type,
				is_temp_pass: temp,
			})
		}
	}, [data])

	useEffect(() => {
		if (error) {
			if (error.status === 1002) setIsInvalidCredentials(true)
			else message.error(error.message)
		}
	}, [error])

	const handleShowPassword = values => {
		setPasswordType('password' === passwordType ? 'text' : 'password')
	}

	useEffect(() => {
		const rememberMeVal = localStorage.getItem('remember_me')

		form.setFieldsValue({
			username: localStorage.getItem('remember_user'),
			password: localStorage.getItem('remember_pass'),
		})

		setSaveCreds({
			rememberMe: rememberMeVal === 'true' ? true : false,
		})
	}, [])

	return (
		<div className='login-container login-wrap'>
			<Spin
				spinning={loading}
				size='large'
				tip={`${t('common.logging_in')}...`}
			>
				<Row className='login-wrap'>
					<img
						src={getImage('loginbanner')}
						alt='Login Image'
						style={{ width: 700, height: 'auto', marginBottom: 40 }}
					/>
				</Row>
				<Row className='login-wrap'>
					<img
						src={emchLogo}
						alt='EMCH Logo'
						style={{ width: 150, height: 'auto', marginBottom: 18 }}
					/>
				</Row>
				<Row className='login-wrap'>
					<Text
						type='danger'
						className={`invalid-error ${isInvalidCredentials ? 'load' : ''}`}
					>
						{t('dialog.invalid_user_pass')}
					</Text>
				</Row>
				<Row className='login-wrap'>
					<Form
						name='login-form'
						onFinish={handleSubmit}
						form={form}
						onValuesChange={() => setIsInvalidCredentials(false)}
					>
						<Form.Item
							className='login-field-input'
							name='username'
							rules={[{ required: true, message: t('sign_in.input_user') }]}
						>
							<Input
								className='input-component'
								placeholder={t('sign_in.enter_user_email')}
							/>
						</Form.Item>
						<Form.Item
							className='login-field-input'
							name='password'
							rules={[{ required: true, message: t('sign_in.input_pass') }]}
						>
							<Input.Password
								className='input-component'
								type={passwordType}
								placeholder={t('sign_in.enter_pass')}
							/>
						</Form.Item>
						<Form.Item className='login-wrap'>
							<Row>
								<Col span={15}>
									<Checkbox
										checked={saveCreds.rememberMe}
										onChange={value => {
											setSaveCreds({
												rememberMe: value.target.checked,
											})
										}}
									>
										{t('sign_in.remember_pass')}
									</Checkbox>
								</Col>
								<Col span={9} style={{ textAlign: 'right' }}>
									<Button
										className='secondary-button'
										onClick={() => history.push('forgot-password')}
									>
										{t('sign_in.forgot_pass')}
									</Button>
								</Col>
							</Row>
						</Form.Item>
						<Form.Item>
							<Button
								type='primary'
								htmlType='submit'
								className='login-form-button'
							>
								{t('sign_in.index')}
							</Button>
						</Form.Item>
					</Form>
				</Row>
			</Spin>
		</div>
	)
}

export default LogIn
