import gql from 'graphql-tag'

export const INSERT_MESSAGES = gql`
	mutation INSERT_MESSAGES($objects: [messages_insert_input!]!) {
		insert_messages(objects: $objects) {
			affected_rows
			returning {
			  id
				text
				is_seen
				created_at
				user {
					id
					avatar_link
					first_name
					last_name
				}
				userBySenderId {
					id
					avatar_link
					first_name
					last_name
				}
			}
		}
	}
`

export const UPDATE_MESSAGES = gql`
	mutation UPDATE_MESSAGES(
		$_set: messages_set_input!
		$where: messages_bool_exp!
	) {
		update_messages(where: $where, _set: $_set) {
			affected_rows
		}
	}
`
