import gql from 'graphql-tag'

export const GET_USER_HEADER = gql`
	subscription GET_USER_ACCOUNT($where: users_bool_exp) {
		users(where: $where) {
			id
			first_name
			last_name
			avatar_link
		}
	}
`

export const GET_USER_ACCOUNT = gql`
	query GET_USER_ACCOUNT($where: users_bool_exp) {
		users(where: $where) {
			id
			first_name
			last_name
			telephone
			email
			address
			avatar_link
			doctors {
				id
				specialties
				is_available
			}
		}
	}
`

export const GET_USER_DUPLICATE = gql`
	query GET_USER_DUPLICATE($where: users_bool_exp) {
		users(where: $where) {
			id
			deleted_at
			patients {
				id
			}
			doctors {
				id
			}
		}
	}
`
