import gql from 'graphql-tag'

export const ADD_USER = gql`
	mutation ADD_USER($objects: [users_insert_input!]!) {
		insert_users(objects: $objects) {
			affected_rows
			returning {
				id
			}
		}
	}
`

export const UPSERT_USER = gql`
	mutation UPSERT_USER($objects: [users_insert_input!]!) {
		insert_users(
			objects: $objects
			on_conflict: {
				constraint: users_pkey
				update_columns: [
					first_name
					last_name
					address
					telephone
					password
					password_is_temp
					role_id
					avatar_link
					deleted_at
				]
			}
		) {
			returning {
				id
			}
		}
	}
`
export const UPDATE_USER = gql`
	mutation UPDATE_USER(
		$_set: users_set_input!
		$where: users_bool_exp!
	) {
		update_users(where: $where, _set: $_set) {
			affected_rows
		}
	}
`