import React, { useState, useEffect } from 'react'
import { Row, Form, Input, message, Button, Spin, Col } from 'antd'
import emchLogo from '../../emchLogo.svg'
import getImage from '../../utils/get-image'
import { useTranslation } from 'react-i18next'
import { commonComponents } from '../../components/common'
import { useHistory } from 'react-router-dom'
import { decodeToken } from '../../utils'
import moment from 'moment'
import bcrypt from 'bcryptjs'

const { BlockButton } = commonComponents

const ResetPassword = props => {
	const { token } = props
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const decodedToken = decodeToken(token)

	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [hasChangedPassword, setHasChangedPassword] = useState(false)

	useEffect(() => {
		if (moment(decodedToken.diff).diff(moment(), 'minutes') < 0) {
			message.error(t('reset_password.exp_link'))
			history.push('/')
		}
	}, [])

	const handleFinish = values => {
		if (!isValid(values)) {
			message.error(t('reset_password.not_match'))
			return
		}

		setLoading(true)

		let xhr = new XMLHttpRequest()

		xhr.addEventListener('load', () => {
			setLoading(false)

			if (xhr.status === 200) {
				setHasChangedPassword(true)
			} else {
				if (xhr.status == 404) {
					message.error('Page not found')
				} else {
					message.error('Internal Server Error')
				}
			}
		})

		xhr.addEventListener('error', () => {
			message.error('Reset Server is not working. Please try again')
		})

		xhr.open('POST', `${process.env.REACT_APP_API_AUTH}/reset-password`)

		xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

		bcrypt.hash(values.password, 10).then(result => {
			xhr.send(
				JSON.stringify({
					email: decodedToken.email,
					password: result,
				}),
			)
		})
	}

	const isValid = values => {
		if (values.password === values.confirm_password) return true

		return false
	}

	return (
		<div className='relative'>
			<Row className='login-wrap'>
				<img
					src={getImage('doctorbanner')}
					alt='Login Image'
					style={{ width: 700, height: 'auto', marginBottom: 40 }}
				/>
			</Row>
			<Row className='login-wrap'>
				<img
					src={emchLogo}
					alt='EMCH Logo'
					style={{ width: 150, height: 'auto', marginBottom: 40 }}
				/>
			</Row>
			<Row>
				<Col span={4}></Col>
				<Col span={16}>
					<div className='content-title noto'>
						{hasChangedPassword && <>{t('reset_password.success_reset')}</>}
						{!hasChangedPassword && <>{t('reset_password.use_new_pass')}</>}
					</div>
					{hasChangedPassword && (
						<>
							<Button
								type='link'
								className='block content-button'
								onClick={() => history.push('/')}
							>
								{t('reset_password.try_log_again')}
							</Button>
						</>
					)}
					{!hasChangedPassword && (
						<Spin spinning={loading}>
							<Form
								form={form}
								layout='vertical'
								onFinish={handleFinish}
								className='content-form'
								onFinishFailed={() => {
									message.error(t('reset_password.not_match'))
								}}
							>
								<Form.Item
									name='password'
									className='field-input'
									rules={[
										{
											required: true,
											message: t('dialog.required_field'),
										},
									]}
								>
									<Input.Password
										autoComplete='off'
										placeholder={t('reset_password.enter_new_pass')}
										style={{ width: '100%' }}
										className='input-component'
									/>
								</Form.Item>
								<Form.Item
									name='confirm_password'
									className='field-input'
									rules={[
										{
											required: true,
											message: t('dialog.required_field'),
										},
									]}
								>
									<Input.Password
										autoComplete='off'
										placeholder={t('reset_password.re_enter_new_pass')}
										style={{ width: '100%' }}
										className='input-component'
									/>
								</Form.Item>
								<BlockButton
									style={{ marginTop: '10px' }}
									htmlType='submit'
									title={t('common.reset')}
								/>
							</Form>
						</Spin>
					)}
				</Col>
				<Col span={4}></Col>
			</Row>
		</div>
	)
}

export default ResetPassword
