import React, { useContext } from 'react'
import '../../assets/css/doctors/doctors.css'
import { doctorsComponents } from '../../components'
import { Input } from 'antd'
import { HeaderContext } from '../../layouts'

const { DoctorList } = doctorsComponents

const Doctors = () => {
	return (
		<>
			<DoctorList />
		</>
	)
}

export default Doctors
