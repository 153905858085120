import React, { useState, useEffect, useContext } from 'react'
import ProfilePicture from '../../common/profile-picture'
import FieldGenerator from './field-generator'
import { GET_PATIENT_INFO } from '../../../graphql/patients/queries'
import { GET_USER_DUPLICATE } from '../../../graphql/users/queries'
import { useHistory } from 'react-router-dom'
import {
  UPDATE_PATIENT,
  DELETE_PATIENT,
} from '../../../graphql/patients/mutation'
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks'
import { Button, Row, Col, Divider, Form, message, Spin } from 'antd'
import '../../../assets/css/dashboard/viewdoctor.css'
import { commonComponents } from '../../common'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getName } from '../../common/get-name'
import { HeaderContext } from '../../../layouts'

const { ConfirmModal } = commonComponents

const ViewEditPatient = props => {
  const { t, i18n } = useTranslation()
  const { id, refetch } = props
  const history = useHistory()
  const [patient, setPatient] = useState({ user: {} })
  const [editModes, configEditModes] = useState({
    isEditMode: props.isEditMode,
    hasEditedContent: false,
  })
  const [image, setImage] = useState(null)
  const [tempImage, setTempImage] = useState(null)
  const [loading, setLoading] = useState(false)

  const { states } = useContext(HeaderContext)
  const { userId, userType } = states.headerNavCont

  const [getUserDuplicate] = useLazyQuery(GET_USER_DUPLICATE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      console.log(`data: ${data.users}`)
      if (data) {
        if (data.users.length > 0) {
          message.error(t('dialog.duplicate_email'))
          return
        } else {
          console.log('submitting form')
          form.submit()
        }
      }
    }
  })

  const { data, refetch: refetchDetails } = useQuery(GET_PATIENT_INFO, {
    variables: {
      where: {
        id: {
          _eq: id,
        },
        deleted_at: { _is_null: true },
      },
      upcoming_appointments_where: {
        datetime: {
          _gte: moment().format('YYYY-MM-DD'),
        },
        status: {
          _neq: 'cancelled',
        },
      },
    },
  })

  const [form] = Form.useForm()
  const [updatePatient] = useMutation(UPDATE_PATIENT)
  const [deletePatient] = useMutation(DELETE_PATIENT)

  useEffect(() => {
    refetchDetails()
  }, [])

  useEffect(() => {
    if (data) {
      setPatient(data.patients[0] || { user: {} })
    }
  }, [data])

  const removeUser = () => {
    ConfirmModal({
      mtitle: t('patients.delete_patient'),
      mcontent: t('confirm.cannot_undo'),
      monOk: () => {
        deletePatient({
          variables: {
            id,
          },
        }).then(
          () => {
            refetch()
            props.hidePanel()
            message.success(t('patients.success_delete'))
          },
          changeError => {
            message.error(changeError.message)
          },
        )
      },
      t,
    })
  }

  const editDetails = () => {
    configEditModes({
      isEditMode: !editModes.isEditMode,
      hasEditedContent: false,
    })
  }

  const saveChanges = () => {
    let emailDb = form.getFieldValue('email')

    if (form.isFieldTouched('email')) {
      getUserDuplicate({
        variables: {
          where: {
            _and: [
              { email: { _eq: emailDb } },
              { id: { _neq: userId } },
              { deleted_at: { _is_null: true } }
            ]
          }
        }
      })
    } else {
      form.submit()
    }
  }

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={10}>
          <ProfilePicture
            isEdit={editModes.isEditMode}
            profileSrc={tempImage || patient.user.avatar_link}
            saveImage={(image, tempImage) => {
              setTempImage(tempImage)
              setImage(image)
              configEditModes({
                isEditMode: true,
                hasEditedContent: true,
              })
            }}
          />
        </Col>
        <Col span={14}>
          <p className="doctor-specialty-title">{t('patients.patient')}</p>
          <p className="doctor-name">
            {getName(
              patient.user.first_name,
              patient.user.last_name,
              i18n.language,
            )}
          </p>
        </Col>
      </Row>
      {['doctor'].includes(userType) && (
        <Row>
          <Col span={14} offset={10}>
            <Button
              block
              disabled={editModes.isEditMode}
              className="thin-bordered-btn"
              onClick={() => {
                const id = patient.user.id
                history.push(id ? `messages/${id}` : 'messages')
              }}
            >
              {t('crud.send_message')}
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Divider
            style={{
              backgroundColor: 'rgba(151, 151, 151, 0.5)',
              marginTop: '35px',
              marginBottom: '35px',
            }}
            dashed
          />
        </Col>
      </Row>
      <FieldGenerator
        form={form}
        refetch={refetch}
        refetchDetails={refetchDetails}
        record={patient}
        updatePatient={updatePatient}
        image={image}
        isEditing={editModes.isEditMode}
        onEditFields={editDetails}
        onRemoveUser={removeUser}
        onSaveChanges={saveChanges}
        editModes={editModes}
        configEditModes={configEditModes}
        panel={props.panel}
        patientSelected={id}
        patientOptions={props.patientOptions}
        doctorLogged={props.doctorLogged}
        setLoading={setLoading}

      />
    </Spin>
  )
}

export default ViewEditPatient
