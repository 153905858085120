import React, { useRef } from 'react'
import '../../assets/css/patients/patientspregnancylist.css'
import { Card, List, Space, Button, Row, Col, Avatar } from 'antd'
import logo from '../../assets/img/logo.png'
import { useHistory } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { transformPregnancyList } from '../../utils/patient-data'
import AddPregnancy from './add-pregnancy'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getDateFormat } from '../../utils'
import InfiniteScroll from 'react-infinite-scroller';

const PatientPregnancyList = props => {
	const { t, i18n } = useTranslation()

	const addPregnancyRef = useRef()

	const history = useHistory()

	const data = props.data

	const addBtnOnClick = e => {
		addPregnancyRef.current.showModal()
	}

	const displayPregDateLabel = item => {
		let dateLabel = ''
		if (item.isCurrent === true) {
			dateLabel = t('pregnancies.pregnancy_expected_due_date')
		} else {
			dateLabel = t('record_of_deliveries.delivery_date')
		}
		return dateLabel
	}

	return (
		<>
			<Card className='dash-card' bodyStyle={{ paddingBottom: '0' }}>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<h1 className='dash-title'>{t('pregnancies.index')}</h1>
					</Col>
					<Col span={12}>
						<Button
							icon={<PlusOutlined />}
							onClick={addBtnOnClick}
							className={'dash-add-button'}
						>
							<span className='title'>{t('pregnancies.add_pregnancy')}</span>
						</Button>
					</Col>
				</Row>
				<Row className="pregnancy-list-container h-62" 
				style={{ marginBottom: '20px', overflowY: 'auto', height: 370 }}
				>
					<InfiniteScroll
						className="pregnancy-scroller"
						initialLoad={false}
						pageStart={0}
						hasMore={true}
					>
					<List
						dataSource={transformPregnancyList(data, t)}
						itemLayout='vertical'
						size='large'
						locale={{ emptyText: t('empty_messages.pregnancies_list') }}
						renderItem={item => (
							<List.Item key={item.key} className='pregnancy-list-item'>
								<Row style={{ margin: '12px 0', alignItems: 'center' }}>
									<Col>
										<Avatar
											size={55}
											style={{ resizeMode: 'stretch' }}
											src={item.imageURL || logo}
										/>
									</Col>
									<Col
										style={{
											flex: 1,
											marginLeft: 10,
										}}
									>
										<div className='patient-table-date'>
											{`${displayPregDateLabel(item)}: ${moment(
												item.date,
											).format(getDateFormat(i18n))}`}
										</div>
										<div className='patient-table-name'>
											{item.name.first + ' ' + item.name.last}
										</div>
									</Col>
									<Col>
										<Space>
											<Button
												className='horizontal-resizable-btn'
												onClick={() => {
													let pregnancyId = item.key
													history.push(
														`/patients/profile/pregnancy/${pregnancyId}`,
													)
												}}
											>
												{t('crud.view')}
											</Button>
										</Space>
									</Col>
								</Row>
							</List.Item>
						)}
					/>
					</InfiniteScroll>
				</Row>
			</Card>
			<AddPregnancy
				ref={addPregnancyRef}
				patient={props.patient}
				doctor={props.doctor}
				pregnancies={data}
				refetch={() => props.refetch()}
			/>
		</>
	)
}

export default PatientPregnancyList
