import decode from 'jwt-decode'

export const decodeToken = token => {
	let tokenObject
	try {
		tokenObject = decode(token)
		return tokenObject
	} catch {
		// console.log('Token decoding error: ', e)
	}
}