import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Input, message, Form, Tag, Select } from 'antd'

import { commonComponents } from '../../common'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import AddAppointment from '../../appointments/add-appointment'

const { BlockButton } = commonComponents

const FieldGenerator = props => {
	const { t } = useTranslation()

	const docfields = props.fields || []
	const isEditing = (props.editModes || {}).isEditMode
	const hasEditedContent = (props.editModes || {}).hasEditedContent
	const refetch = props.refetch
	const setEditedState = state => {
		props.configEditModes(oldstate => ({ ...oldstate, ...state }))
	}

	const items = (props.fields || []).map(item => {
		const tagRender = subProps => {
			const { label, closable, onClose } = subProps

			return (
				<Tag className='specialty-tag' closable={closable} onClose={onClose}>
					{label}
				</Tag>
			)
		}

		if (item.value) {
			props.form.setFieldsValue({ [item.fieldKey]: item.value })
		}

		const getCorrectInput = () => {
			if (item.isPassword) {
				return (
					<Input.Password
						style={{ boxShadow: 'none' }}
						className='input-component'
					/>
				)
			} else if (item.options) {
				return (
					<Select
						tagRender={tagRender}
						mode='multiple'
						placeholder={t('doctors.enter_specialties')}
						block
						suffixIcon={<DownOutlined />}
						showArrow
						bordered={false}
						options={item.options}
					/>
				)
			} else {
				return <Input className='input-component' />
			}
		}
		const printCorrectStringDisplay = () => {
			if (item.value) {
				if (typeof item.value !== 'object') {
					return item.value
				} else {
					var valuestring = ''

					;(item.value || []).map((val, index) => {
						if (index < Object.keys(item.value).length - 1) {
							valuestring = valuestring.concat(val + ' / ')
						} else {
							valuestring = valuestring.concat(val)
						}
					})

					return valuestring
				}
			} else {
				return t('crud.n_a')
			}
		}

		if (!isEditing) {
			if (!item.isPassword) {
				return (
					<Row>
						<Col span={24}>
							<p className='doctor-field-name'>
								{item.fieldName.toUpperCase()}
							</p>
							<p className='doctor-field-value'>
								{printCorrectStringDisplay()}
							</p>
						</Col>
					</Row>
				)
			}
		} else {
			return (
				<Form.Item
					name={item.fieldKey}
					label={item.fieldName.toUpperCase()}
					rules={
						item.isPassword
						  ? []
						  : item.rules ? item.rules :
						  [
							{
							 required: true,
							 message: t('dialog.required_field'),
							},
						  ]
					  }
					className='doctor-field-input'
				>
					{getCorrectInput()}
				</Form.Item>
			)
		}
	})

	const getFieldObject = () => {
		var fieldValues = {}
		;(props.fields || []).map(field => {
			fieldValues[field.fieldName] = field.value
		})
		return fieldValues
	}

	const handleFieldChange = (oldFields, newFields) => {
		docfields.forEach(field => {
			if (oldFields.length && field.fieldKey == oldFields[0].name) {
				field.value = oldFields[0].value
			}
		})
		refetch()
		setEditedState({ hasEditedContent: true })
	}

	const handleOnSaveState = () => {
		refetch()

		props.fields.forEach(item => {console.log(`fields: ${item.fieldKey} ${item.value}`)})

		props.onSaveChanges()
	}

	const updateFieldValues = values => {
		/* To change function to GraphQL mutation event*/
		const updates = (props.fields || []).map(field => {
			return {
				fieldName: field.fieldKey,
				value: values[field.fieldKey],
				showInView: field.showInView,
				isPassword: field.isPassword || false,
				options: field.options,
			}
		})
		refetch()
		setEditedState({ hasEditedContent: false, isEditMode: !isEditing })
		props.updateDocDetails(values)
		props.onSubmit(updates)
	}

	const handleAddAppointmentClick = () => {
		props.panel.current.showPanel(
			<AddAppointment
				patientOptions={null}
				doctorLogged={props.doctorLogged}
				refetch={() => props.refetch()}
			/>,
		)
	}

	const buttonAreaHandler = () => {
		if (!props.isEditing) {
			return (
				<>
					<Col span={24}>
						<Button
							className='dash-add-button patient-viewpatient-fieldgenerator-addappointment'
							onClick={handleAddAppointmentClick}
							type='link'
						>
							+ {t('appointments.add_appointment')}
						</Button>
					</Col>
					<Col span={12}>
						<BlockButton title={t('crud.edit_details')} onClick={props.onEditFields} />
					</Col>
					<Col span={12}>
						<Button
							block
							className='transparent-cancel-btn'
							onClick={props.onRemoveUser}
						>
							{t('doctors.remove_doctor')}
						</Button>
					</Col>
				</>
			)
		} else {
			return (
				<Col span={24}>
					<BlockButton
						disabled={!hasEditedContent}
						title={t('crud.save_changes')}
						onClick={handleOnSaveState}
					/>
				</Col>
			)
		}
	}

	if (!props.isEditing) {
		return (
			<>
				{items}
				<Row className='btn-container'>{buttonAreaHandler()}</Row>
			</>
		)
	} else {
		return (
			<>
				<Form
					form={props.form}
					layout='vertical'
					onFieldsChange={handleFieldChange}
					hideRequiredMark
					onFinish={updateFieldValues}
					initialValues={getFieldObject}
				>
					{items}
				</Form>
				<Row className='btn-container'>{buttonAreaHandler()}</Row>
			</>
		)
	}
}

export default FieldGenerator
