import React, {
	forwardRef,
	useImperativeHandle,
	useState,
	useEffect,
} from 'react'
import '../../../assets/css/pregnancy/editpregnancy.css'
import { UPDATE_PREGNANCY } from '../../../graphql/pregnancies/mutation'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { Modal, Button, DatePicker, message, Form, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../utils/get-date-format'

const EditPregnancy = forwardRef((props, ref) => {
	const { t, i18n } = useTranslation()

	const [form] = Form.useForm()
	const [visible, setvisible] = useState(false)
	const [startDate, setStartDate] = useState(moment())
	const [endDate, setEndDate] = useState(moment())
	const [shouldUpdateEndDate, setShouldUpdateEndDate] = useState(false)

	const [editPregnancy] = useMutation(UPDATE_PREGNANCY)

	useImperativeHandle(ref, () => {
		return {
			showModal: () => show(),
		}
	})

	const show = () => {
		setvisible(true)
	}

	const handleEdit = () => {
		editPregnancy({
			variables: {
				preg_set: {
					start_date: startDate.format('YYYY-MM-DD'),
					due_date: endDate.format('YYYY-MM-DD'),
				},
				preg_filter: {
					id: {
						_eq: props.pregnancyId,
					},
				},
			},
		}).then(
			() => {
				props.refetch()
				setvisible(false)
				message.success(t('pregnancies.success_update'))
			},
			changeError => {
				message.error(changeError.message)
			},
		)
	}

	const handleCancel = () => {
		setvisible(false)
	}

	useEffect(() => {
		setShouldUpdateEndDate(false)
		setStartDate(moment(props.startDate) || moment())
		setEndDate(moment(props.endDate) || moment())
	}, [props.startDate])

	useEffect(() => {
		if (shouldUpdateEndDate) setEndDate(moment(startDate).add(40, 'weeks'))

		setShouldUpdateEndDate(true)
	}, [startDate])

	return (
		<>
			<Modal
				visible={visible}
				onCancel={handleCancel}
				footer={[
					<Button
						className='add-pregnancy-button-cancel'
						key='back'
						onClick={handleCancel}
					>
						{t('common.cancel')}
					</Button>,
					<Button
						className='add-pregnancy-button-add'
						form='update_pregnancy_form'
						key='submit'
						htmlType='submit'
					>
						{t('crud.update')}
					</Button>,
				]}
			>
				<Row>
					<Col>
						<div className='add-pregnancy-title'>
							{t('pregnancies.edit_pregnancy')}
						</div>
						<br />
					</Col>
				</Row>
				<div style={{ marginTop: 40 }}> </div>
				<Form
					form={form}
					id='update_pregnancy_form'
					layout='vertical'
					hideRequiredMark
					onFinish={handleEdit}
				>
					<Form.Item
						label={t('pregnancies.pregnancy_start_date')}
						rules={[
							{
								required: true,
								message: t('dialog.required_field'),
							},
						]}
						className='doctor-field-input'
					>
						<DatePicker
							className='input-component'
							style={{ width: '100%' }}
							format={getDateFormat(i18n)}
							allowClear={false}
							value={startDate}
							onChange={date => setStartDate(date)}
						/>
					</Form.Item>
					<Form.Item
						label={t('pregnancies.pregnancy_expected_due_date')}
						rules={[
							{
								required: true,
								message: t('dialog.required_field'),
							},
						]}
						className='doctor-field-input'
					>
						<DatePicker
							className='input-component'
							style={{ width: '100%' }}
							format={getDateFormat(i18n)}
							allowClear={false}
							value={endDate}
							onChange={date => setEndDate(date)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
})

export default EditPregnancy
