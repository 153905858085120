import React, { useRef } from 'react'
import TableList from '../common/table-list'
import ViewEditDoctor from '../doctors/view-edit-doctor'
import AddDoctor from '../doctors/add-doctor'
import { commonComponents } from '../common'
import { useHistory } from 'react-router-dom'
import AddAppointment from '../appointments/add-appointment'
import { useTranslation } from 'react-i18next'

const { Sidepanel } = commonComponents

const DoctorList = props => {
	const { t } = useTranslation()

	const panel = useRef()
	const history = useHistory()
	const data = props.data
	const refetch = props.refetch

	const actionList = [
		{
			name: t('crud.view'),
			function: doctorId => {
				panel.current.showPanel(
					<ViewEditDoctor
						doctorUserId={doctorId}
						isEditMode={false}
						refetch={refetch}
						panel={panel}
					/>,
				)
			},
		},
		{
			name: t('crud.contact'),
			function: id => {
				history.push(id ? `messages/${id}` : 'messages')
			},
		},
		{
			name: `${t('appointments.add_appointment')}`,
			function: doctorId => {
				panel.current.showPanel(
					<AddAppointment
						doctorLogged={doctorId}
						refetch={refetch}
						panel={panel}
					/>,
				)
			},
		},
	]

	return (
		<>
			<TableList
				title={t('doctors.list_of_doctors')}
				addtitle={t('doctors.add_doctor')}
				addBtnOnClick={() => {
					panel.current.showPanel(
						<AddDoctor mode='doctor' refetch={() => props.refetch()} />,
					)
				}}
				tableMode={'doctor_list'}
				columns={[t('common.name'), t('doctors.specialty')]}
				items={data}
				actions={actionList}
				refetch={refetch}
				viewbtnprops={{
					title: t('doctors.view_doctors'),
					onClick: () => {
						refetch()
						history.push(`doctors`)
					},
				}}
			/>
			<Sidepanel ref={panel} />
		</>
	)
}

export default DoctorList
