export const getPregIds = (week, then) => {
    let fcm_xhr = new XMLHttpRequest()

    fcm_xhr.addEventListener('load', () => {
      if (fcm_xhr.status == 200){
        const response = JSON.parse(fcm_xhr.response)

        if (response.code != null){
          if (response.code == 200){
            console.log("Pregnancy id retrieval Success! ", response.ids)
            then(response.ids)
          } else{
            console.error("Pregnancy id retrieval Failed! ", response.error)
          }
        } else {
          console.error("Pregnancy id retrieval Error")
        }
      }
    })

    fcm_xhr.addEventListener('error', () => {
      console.error('Pregnancy id retrieval Error! Server is not responding!. Need to retry request')
    })
    
    let urlString = `${process.env.REACT_APP_API_AUTH}/get-preg-ids/${week}`

    fcm_xhr.open('POST', urlString)
    fcm_xhr.setRequestHeader('Content-Type','application/json;charset=UTF-8')

    fcm_xhr.send()
}