import React from 'react'
import { getClassName } from './getClassName'

const StyleButton = props => {
  const onToggle = e => {
    e.preventDefault()
    props.onToggle(props.style)
  }

  let className = getClassName(props)

  return <img src={props.icon} className={className} onClick={onToggle} />
}

export default StyleButton