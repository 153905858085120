import React, { useEffect, useState, useRef } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
	Typography,
	Col,
	Row,
	Collapse,
	Button,
	Divider,
	Dropdown,
	Menu,
	Form,
	message,
} from 'antd'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { GET_PRENATAL_TESTS } from '../../../../graphql/prenatal-tests/queries'
import { UPDATE_PRENATAL_TESTS } from '../../../../graphql/prenatal-tests/mutations'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { commonComponents } from '../../../common'
import AddEditPrenatalTestRecord from './add-edit-drawer'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'
import { getName } from '../../../common/get-name'

import { capitalize } from 'lodash'

const { Panel } = Collapse
const { Sidepanel, ConfirmModal } = commonComponents

const PrenatalTestRecords = props => {
	const { t, i18n } = useTranslation()

	const { doctor, pregnancy } = props

	const { pregnancy_id } = useParams()

	const panel = useRef()
	const [form] = Form.useForm()
	const [prenatalTests, setPrenatalTests] = useState([])
	const [activePanel, setActivePanel] = useState(undefined)

	const [updatePrenatalTest] = useMutation(UPDATE_PRENATAL_TESTS)
	const { data, refetch } = useQuery(GET_PRENATAL_TESTS, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				pregnancy_id: {
					_eq: pregnancy_id,
				},
			},
		},
	})

	const fillInheritedTests = rawCheckups => {
		const testsHashset = {}
		const checkups = [...rawCheckups]

		for (const tests of checkups) {
			for (const key in tests) {
				if (key !== 'notes') {
					if (tests[key] !== null) testsHashset[key] = tests[key]
					if (testsHashset[key] !== undefined) tests[key] = testsHashset[key]
				}
			}
		}

		return checkups.reverse()
	}

	useEffect(() => {
		if (!data) return

		setActivePanel(undefined)
		setPrenatalTests(fillInheritedTests(data.prenatal_tests))
	}, [data])

	const itemDoctorRemarks = content => {
		let doctorName =
			`Dr. ` +
			getName(
				content.doctor.user.first_name,
				content.doctor.user.last_name,
				i18n.language,
			)
		let doctorData = [
			{
				label: `${t('doctors.notes_from_doctor')}:`,
				data: content.notes || t('crud.n_a'),
			},
			{
				label: `${t('doctors.doctor')}:`,
				data: doctorName,
			},
		]

		let remarksData = doctorData.map((data, index) => {
			return (
				<Col span={24} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		return remarksData
	}

	const itemContent = content => {
		const getResult = test => {
			if (test === null) return t('crud.n_a')
			else
				return test
					? t('prenatal_tests.tested')
					: t('prenatal_tests.not_tested')
		}

		let checkupData = [
			{
				label: t('prenatal_tests.irregular_antibody'),
				data: getResult(content.irregular_antibody),
			},
			{
				label: t('prenatal_tests.cervical_cancer'),
				data: getResult(content.cervical_cancer),
			},
			{
				label: t('prenatal_tests.syphilis'),
				data: getResult(content.syphilis),
			},
			{
				label: t('prenatal_tests.hbv'),
				data: getResult(content.hbv),
			},
			{
				label: t('prenatal_tests.hcv'),
				data: getResult(content.hcv),
			},
			{
				label: t('prenatal_tests.rubella_virus'),
				data: getResult(content.rubella_virus),
			},
			{
				label: t('prenatal_tests.htlv_1'),
				data: getResult(content.htlv_1),
			},
			{
				label: t('prenatal_tests.chlamydia'),
				data: getResult(content.chlamydia),
			},
			{
				label: t('prenatal_tests.group_b_strep'),
				data: getResult(content.group_b_strep),
			},
		]

		let contentData = checkupData.map((data, index) => {
			return (
				<Col span={12} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		return contentData
	}

	const checkupHeader = header => {
		return (
			<Typography style={{ color: '#3B3F3F' }}>
				{t('prenatal_tests.checkup_date')}:{' '}
				<span style={{ color: '#969999' }}>{`${moment(header.date).format(
					getDateFormat(i18n),
				)}`}</span>
			</Typography>
		)
	}

	const menu = record => (
		<Menu>
			<Menu.Item
				onClick={() => {
					panel.current.showPanel(
						<AddEditPrenatalTestRecord
							form={form}
							record={record}
							refetch={refetch}
							pregnancy={pregnancy}
							latestRecord={prenatalTests[0]}
						/>,
					)
				}}
			>
				{t('crud.edit')}
			</Menu.Item>
			<Menu.Item
				onClick={() =>
					ConfirmModal({
						mtitle: t('confirm.are_you_sure'),
						mcontent: t('confirm.cannot_undo'),
						monOk: () => {
							updatePrenatalTest({
								variables: {
									_set: {
										deleted_at: moment(),
									},
									where: {
										id: {
											_eq: record.id,
										},
									},
								},
							}).then(
								() => {
									refetch()
									message.success(t('prenatal_tests.success_delete'))
								},
								changeError => {
									message.error(changeError.message)
								},
							)
						},
						t,
					})
				}
			>
				{t('crud.remove')}
			</Menu.Item>
		</Menu>
	)

	const prenatalTestItems = (prenatalTests || []).map((prenatalTest, index) => {
		return (
			<Panel
				key={index}
				style={{ padding: '10px 0' }}
				header={checkupHeader(prenatalTest)}
				className='site-collapse-custom-panel'
			>
				<Row>
					<Col span={12}>
						<Typography
							style={{ marginBottom: 20 }}
							className='pregnancy-record-data'
						>
							{t('prenatal_tests.types_of_prenatal_tests')}
						</Typography>
					</Col>
					<Col span={12}>
						<Dropdown overlay={menu(prenatalTest)} placement='bottomRight'>
							<EllipsisOutlined className='ellipsis' />
						</Dropdown>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>{itemContent(prenatalTest)}</Row>
				<Divider />
				<Row gutter={[16, 16]}>{itemDoctorRemarks(prenatalTest)}</Row>
			</Panel>
		)
	})

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>{t('prenatal_tests.index')}</h1>
				</Col>
				<Col span={12}>
					<Button
						icon={<PlusOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditPrenatalTestRecord
									form={form}
									doctor={doctor}
									refetch={refetch}
									pregnancy={pregnancy}
									latestRecord={prenatalTests[0]}
								/>,
							)
						}
					>
						<span className='title'>{t('prenatal_tests.add_prenatal')}</span>
					</Button>
				</Col>
			</Row>
			<Sidepanel ref={panel} />
			<Collapse
				accordion
				expandIconPosition='right'
				bordered={false}
				style={{ padding: 20 }}
				className='site-collapse-custom-collapse'
				activeKey={activePanel}
				onChange={key => setActivePanel(key)}
			>
				{prenatalTestItems}
			</Collapse>
		</>
	)
}
export default PrenatalTestRecords
