import React, { useEffect, useContext } from "react"
import { HeaderContext } from "../../layouts";
import { default as Doctors } from './doctors'
import { useTranslation } from 'react-i18next'

export const DoctorsPage = () => {
  const { stateSetters: { setNavCont } } = useContext(HeaderContext);
  const { t } = useTranslation()

  useEffect(() => {
    setNavCont({ defaultKey: ["4"], hideSideBar: false, hideHeader: false, headerTitle: t('doctors.index') });
  }, []);

  return <Doctors />
}

