import gql from 'graphql-tag'

export const UPSERT_BABY_IMMUNIZATIONS = gql`
	mutation UPSERT_BABY_IMMUNIZATIONS(
		$objects: [baby_immunizations_insert_input!]!
	) {
		insert_baby_immunizations(
			objects: $objects
			on_conflict: {
				constraint: baby_immunizations_pkey
				update_columns: [
					date
					vaccine
					manufacturer
					notes
				]
			}
		) {
			affected_rows
		}
	}
`

export const UPDATE_BABY_IMMUNIZATIONS = gql`
	mutation UPDATE_BABY_IMMUNIZATIONS(
		$_set: baby_immunizations_set_input!
		$where: baby_immunizations_bool_exp!
	) {
		update_baby_immunizations(where: $where, _set: $_set) {
			affected_rows
		}
	}
`
