import React, { useState } from 'react'
import { Button, Modal, Radio, Input } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { confirm } = Modal

export const ConfirmModal = ({ mtitle, mcontent, monOk, monCancel, t }) => {

	confirm({
		title: mtitle,
		icon: <ExclamationCircleFilled />,
		content: mcontent,
		onOk() {
			if (monOk) {
				monOk()
			}
		},
		centered: true,
		okButtonProps: {
			className: 'horizontal-resizable-btn',
		},
		okText: t('confirm.yes'),
		cancelButtonProps: {
			className: 'thin-bordered-btn',
		},
		cancelText: t('confirm.no'),
		onCancel() {
			if (monCancel) {
				monCancel()
			}
		},
	})
}

export const InputModal = props => {
	const { t } = useTranslation()

	const { submitCallback, setVisible, visible } = props
	const [value, setValue] = useState('')

	const submit = () => {
		setValue('')
		submitCallback(value)
		setVisible(false)
	}

	const onChange = event => {
		setValue(event.target.value)
	}

	return (
		<Modal
			title={t('appointments.appointment_approve')}
			visible={visible}
			onCancel={() => {
				setValue('')
				setVisible(false)
			}}
			centered
			footer={
				<Button
					className='horizontal-resizable-btn'
					style={{ width: '200px' }}
					onClick={submit}
				>
					{t('crud.submit')}
				</Button>
			}
		>
			<p>{t('appointments.select_room')}</p>
			<Input
				style={{ width: '100%' }}
				onChange={onChange}
				placeholder={t('appointments.enter_room_no')}
				value={value}
			></Input>
		</Modal>
	)
}

export const RadioModal = props => {
	const { t } = useTranslation()

	const {
		defaultValue = '',
		submitCallback,
		visible,
		setVisible,
		options: rawOptions,
	} = props
	const [radioValue, setValue] = useState(defaultValue)

	const submit = () => {
		setValue(defaultValue)
		submitCallback(radioValue)
		setVisible(false)
	}

	const onChange = event => {
		setValue(event.target.value)
	}

	const options = (rawOptions || []).map(option => (
		<Radio className='radio-item' key={option.optionValue} value={option.optionValue}>
			{option.title}
		</Radio>
	))

	return (
		<Modal
			title={t('appointments.cancellation_reason')}
			visible={visible}
			onCancel={() => {
				setValue(defaultValue)
				setVisible(false)
			}}
			centered
			footer={
				<Button
					className='horizontal-resizable-btn'
					style={{ width: '200px' }}
					onClick={submit}
				>
					{t('crud.submit')}
				</Button>
			}
		>
			<Radio.Group onChange={onChange} value={radioValue}>
				{options}
			</Radio.Group>
		</Modal>
	)
}
