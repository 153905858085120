import React, { useContext, useState, useEffect, useRef, useMemo } from 'react'
import { Row, Col, Input, List, Button, Tooltip, Form } from 'antd'
import { Typography, Avatar, Collapse, message } from 'antd'
import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks'
import {
  GET_DELETED_MESSAGES,
  GET_MESSAGES,
  GET_MESSAGES_QUERY, GET_UPDATED_MESSAGES,
} from '../../graphql/messages/queries'
import {
  INSERT_MESSAGES,
  UPDATE_MESSAGES,
} from '../../graphql/messages/mutations'
import { HeaderContext } from '../../layouts'
import moment from 'moment'
import '../../assets/css/messages/chat-messages.css'
import '../../assets/css/dashboard/tablelist.css'
import { useTranslation } from 'react-i18next'
import { commonComponents } from '../common'
import { getDateFormat } from '../../utils'
import { getName } from '../common/get-name'
import { sendMessage } from '../../firebase-messaging'
import { sortBy, uniqBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const { Text } = Typography
const { Panel } = Collapse

const { ConfirmModal } = commonComponents

const extendGetName = (first_name, last_name, language) => {
  return getName(first_name, last_name, language)
}

const sendChatMessageNotif = (receiverid, message) => {
  const data = {
    data: {
      notiftype: 'chat-message',
      message: message
    }
  }

  sendMessage(receiverid, data)
}

const Messages = props => {
  const { t, i18n } = useTranslation()
  const { currentUser } = props
  const {
    states: {
      headerNavCont: { userId },
    },
  } = useContext(HeaderContext)
  const [form] = Form.useForm()

  const [messages, setMessages] = useState([])
  const messagesRef = useRef(null)
  const [insertMessage] = useMutation(INSERT_MESSAGES)
  const [pagination, setPagination] = useState({ limit: 20, offset: 0, currentPage: 1 })
  const [lastMessageOfCurrentUser, setLastMessageOfCurrentUser] = useState({})
  const [shouldUpdateLatestMessage, setShouldUpdateLatestMessage] = useState(false)
  const [hasMoreMessages, setHasMoreMessages] = useState(true)
  const [, setRerender] = useState(null)
  const queryParams = useMemo(() => ({
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      where: {
        deleted_at: { _is_null: true },
        _or: [
          {
            sender_id: { _eq: userId },
            receiver_id: { _eq: currentUser.id }
          },
          {
            sender_id: { _eq: currentUser.id },
            receiver_id: { _eq: userId }
          }
        ]
      },
    }
  }), [currentUser, userId, pagination])

  /**
   * Get the latest message of the current user
   */
  const { data: getLatestMessage } = useSubscription(GET_MESSAGES, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        sender_id: { _eq: currentUser.id },
        receiver_id: { _eq: userId },
        created_at: { _gt: messages[messages.length - 1]?.created_at },
        deleted_at: { _is_null: true }
      }
    }
  })

  /**
   *
   */
  const { data: getUpdatedMessages } = useSubscription(GET_UPDATED_MESSAGES, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        _or: [
          { receiver_id: { _eq: userId }, sender_id: { _eq: currentUser.id } },
          { receiver_id: { _eq: currentUser.id }, sender_id: { _eq: userId } }
        ]
      }
    }
  })

  /**
   *
   */
  const { data: getDeletedMessages } = useSubscription(GET_DELETED_MESSAGES, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        deleted_at: { _is_null: false },
        _or: [
          { receiver_id: { _eq: userId }, sender_id: { _eq: currentUser.id } },
          { receiver_id: { _eq: currentUser.id }, sender_id: { _eq: userId } }
        ]
      }
    }
  })

  /**
   * Removes messages when current user is changed
   */
  useEffect(() => {
    resetPagination()
    setMessages([])
    setHasMoreMessages(true)
    getMessagesQuery({
      variables: queryParams.variables
    })
  }, [currentUser.id])

  /**
   * Check latest message of the receiver
   * then append it if there's new
   */
  useEffect(() => {
    if (getLatestMessage?.messages.length) {
      setLastMessageOfCurrentUser(getLatestMessage?.messages[0])
      setShouldUpdateLatestMessage(true)
    } else {
      setLastMessageOfCurrentUser({})
    }
  }, [getLatestMessage, currentUser.id])

  /**
   * Check if the latest message is in the current data
   * if not, append the latest message
   */
  useEffect(() => {
    if (messages.length && Object.keys(lastMessageOfCurrentUser).length && shouldUpdateLatestMessage) {
      const lastMessage = messages.find(message => message.id === lastMessageOfCurrentUser.id)

      if (!lastMessage) {
        setMessages((old) => ([...old, lastMessageOfCurrentUser]))
        setShouldUpdateLatestMessage(false)
        scrollToBottom()
      } else {
        setMessages((old) => old)
      }
    }
  }, [lastMessageOfCurrentUser, messages, shouldUpdateLatestMessage])

  /**
   * Check if pagination is change,
   * then query again for past messages
   */
  useEffect(() => {
    getMessagesQuery({
      variables: queryParams.variables
    })
  }, [pagination])

  useEffect(() => {
    if (getUpdatedMessages?.messages.length && messages.length) {
      const messageIndex = messages.findIndex(value => {
        return getUpdatedMessages.messages[0].id === value.id
      })

      if (messageIndex !== -1) {
        messages.splice(messageIndex, 1, { ...getUpdatedMessages?.messages[0], key: uuidv4() })
        setRerender(uuidv4())
      }
    }
  }, [getUpdatedMessages, messages])

  useEffect(() => {
    if (getDeletedMessages?.messages.length && messages.length) {
      const messageIndex = messages.findIndex(value => {
        return getDeletedMessages.messages[0].id === value.id
      })

      if (messageIndex !== -1) {
        messages.splice(messageIndex, 1)
        setRerender(uuidv4())
      }
    }
  }, [getDeletedMessages, messages])

  /**
   * Get the messages of the current user
   */
  const [getMessagesQuery, { loading }] = useLazyQuery(GET_MESSAGES_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: getMessagesQueryOnComplete
  })

  /**
   * Set the messages of the current user
   * @param data
   */
  function getMessagesQueryOnComplete(data) {
    setHasMoreMessages(data.messages.length + 1 > pagination.limit)
    setMessages((old) => (sortBy([...old, ...data.messages], 'created_at')))
  }

  const resetPagination = () => {
    setPagination({ limit: pagination.limit, offset: 0, currentPage: 1 })
  }

  /**
   * Load more messages when scroll to top
   *
   */
  const loadMoreMessages = () => {
    const offset = pagination.currentPage * pagination.limit
    setPagination((oldState) => ({ ...oldState, currentPage: oldState.currentPage + 1, offset }))
  }

  /**
   * Check if message-list scroll is at top,
   * then call loadMoreMessages
   * @param event
   */
  const handleMessageScroll = (event) => {
    const top = event.target.scrollHeight + event.target.scrollTop === event.target.clientHeight

    if (top && hasMoreMessages && event.target.scrollTop !== 0) {
      loadMoreMessages()
    }
  }

  const scrollToBottom = () => {
    const current = messagesRef.current
    current.scrollTop = current.scrollHeight
  }

  /**
   * Send message to current user
   * @param messageInput
   * @returns {Promise<void>}
   */
  const handleInsertMessage = async ({ message: messageInput }) => {
    try {
      const { data } = await insertMessage({
        variables: {
          objects: {
            sender_id: userId,
            receiver_id: currentUser.id,
            text: messageInput,
          },
        },
      })

      if (data?.insert_messages) {
        setMessages(old => (uniqBy([...old, data.insert_messages.returning[0]], 'id')))

        scrollToBottom()
        sendChatMessageNotif(currentUser.id, messageInput)
      }

    } catch (error) {
      message.error(error.message)
    } finally {
      form.resetFields()
    }
  }

  return (
    <>
      <div className="chat-title-container truncate">
        <Text className="chat-message-name">
          {extendGetName(
            currentUser.first_name || '',
            currentUser.last_name || '',
            i18n.language
          )}
        </Text>
      </div>
      <div
        className="messages-list"
        ref={messagesRef}
        onScroll={handleMessageScroll}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          flexFlow: 'column-reverse',
          minHeight: '500px'
        }}
      >
        <div>
          <List
              itemLayout="vertical"
              size="large"
              loading={loading}
              locale={{ emptyText: t('empty_messages.messages') }}
              dataSource={messages}
              style={{ height: '100%'}}
              renderItem={message => {
                return (
                  <div key={message.id} style={{ margin: '0 20px' }}>
                    {message.user.id === userId ? (
                      <ChatMessageLeft message={message}/>
                    ) : (
                      <ChatMessageRight message={message}/>
                    )}
                  </div>
                )
              }}
            />
          </div>
      </div>
      <div className="messages-action-panel">
        <p
          style={{
            height: 1,
            width: '100%',
            backgroundColor: 'rgba(150, 153, 153, 0.2)',
          }}
        />
        <Form form={form} onFinish={handleInsertMessage}>
          <Row>
            <Col
              style={{
                flexGrow: 100,
                marginTop: -5,
                marginLeft: 10,
                color: '#C8CDDC',
              }}
            >
              <Form.Item noStyle={true} name={'message'} rules={[{ required: true }]}>
                <Input
                  autoComplete="off"
                  className="message-input"
                  style={{ height: 40, paddingLeft: '12px' }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                marginRight: 10,
                marginLeft: 10,
                marginTop: -5,
                color: '#C8CDDC',
              }}
            >
              <Button
                block
                disabled={false}
                className="send-button"
                htmlType={'submit'}
              >
                {t('messages.send')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

const UserMedia = (user, position) => {
  const { i18n } = useTranslation()

  if (user.avatar_link)
    return (
      <Avatar
        size={40}
        style={{ resizeMode: 'stretch' }}
        src={user.avatar_link}
      />
    )

  return (
    <Text className={`chat-media-name chat-media-name-${position}`}>
      {extendGetName(user.first_name[0], user.last_name[0], i18n.language)}
    </Text>
  )
}

const MessageOptions = props => {
  const { t } = useTranslation()
  const [updateMessage] = useMutation(UPDATE_MESSAGES)
  const { id, editedMessage, setEditedMessage, newMessage, position } = props

  const handleDelete = () => {
    ConfirmModal({
      mtitle: t('messages.delete_message'),
      mcontent: t('confirm.cannot_undo'),
      monOk: () => {
        updateMessage({
          variables: {
            _set: {
              deleted_at: moment(),
            },
            where: {
              id: {
                _eq: id,
              },
            },
          },
        })
      },
      t,
    })
  }

  const handleUpdate = () => {
    if (!newMessage) return

    updateMessage({
      variables: {
        _set: {
          text: newMessage,
          updated_at: moment()
        },
        where: {
          id: {
            _eq: id,
          },
        },
      },
    })

    setEditedMessage(null)
  }

  return (
    <>
      {position === 'left' && (
        <Button type="link" danger onClick={handleDelete}>
          {t('crud.remove')}
        </Button>
      )}
      {position === 'right' && (
        <>
          {editedMessage === id && (
            <>
              <Button type="link" danger onClick={() => setEditedMessage(null)}>
                {t('common.cancel')}
              </Button>
              <Button type="link" onClick={handleUpdate}>
                {t('crud.save')}
              </Button>
            </>
          )}
          {editedMessage !== id && (
            <>
              <Button type="link" danger onClick={handleDelete}>
                {t('crud.remove')}
              </Button>
              <Button type="link" onClick={() => {
                console.log(' edited message id: ', id)
                setEditedMessage(id)
              }}>
                {t('crud.edit')}
              </Button>
            </>
          )}
        </>
      )}
    </>
  )
}

const ChatMessageLeft = props => {
  const { i18n } = useTranslation()

  const { message } = props
  const user = message.userBySenderId

  return (
    <Collapse
      ghost
      key={message.id}
      destroyInactivePanel={true}
      className="datetime-message-collapse-left"
    >
      <Panel
        key="1"
        showArrow={false}
        header={
          <>
            <div className="chat-message">
              <Tooltip
                placement="right"
                color="transparent"
                overlayClassName="tooltip"
                // title={<MessageOptions id={message.id} position="left"/>}
              >
                <div className="chat-message-left noto">
                  <span style={{ display: 'block', wordWrap: 'break-word' }}>
                      {message.text}
                  </span>
                </div>
              </Tooltip>
            </div>
            <div className="chat-media">{UserMedia(user, 'left')}</div>
          </>
        }
      >
        <div className="noto text-10 text-gray">
          {moment(message.created_at).format(`${getDateFormat(i18n)} HH:mm`)}
        </div>
      </Panel>
    </Collapse>
  )
}

const ChatMessageRight = props => {
  const { i18n } = useTranslation()

  const { message } = props
  const user = message.userBySenderId

  const [updateMessage] = useMutation(UPDATE_MESSAGES)
  const [editedMessage, setEditedMessage] = useState(null)
  const [newMessage, setNewMessage] = useState(message.text)

  const handleUpdate = value => {
    if (!value) return

    updateMessage({
      variables: {
        _set: {
          text: value,
          updated_at: moment()
        },
        where: {
          id: {
            _eq: message.id,
          },
        },
      },
    })

    setEditedMessage(null)
  }

  useEffect(() => {
    setNewMessage(message.text)
  }, [message])

  return (
    <Collapse
      ghost
      key={message.id}
      destroyInactivePanel={true}
      className="datetime-message-collapse-right"
    >
      <Panel
        header={
          <>
            <div
              className="chat-message"
              style={{ justifyContent: 'flex-end' }}
            >
              <Tooltip
                placement="right"
                color="transparent"
                overlayClassName="tooltip"
                title={
                  <MessageOptions
                    id={message.id}
                    position="right"
                    newMessage={newMessage}
                    editedMessage={editedMessage}
                    setEditedMessage={setEditedMessage}
                  />
                }
              >
                <div className="chat-message-right noto">
                  <span style={{ display: 'block', wordWrap: 'break-word', }}>
                    {editedMessage === message.id ? (
                      <Input.TextArea
                        style={{
                          width: '500px',
                          fontSize: '12px',
                          paddingLeft: '12px',
                        }}
                        autoSize={true}
                        value={newMessage}
                        onPressEnter={e => {
                          e.preventDefault()
                          const value = e.currentTarget.value

                          handleUpdate(value)
                        }}
                        onChange={e => {
                          const value = e.currentTarget.value

                          setNewMessage(value)
                        }}
                      />
                    ) : message.text}
                  </span>
                </div>
              </Tooltip>
            </div>
            <div className="chat-media chat-media-right">
              {UserMedia(user, 'right')}
            </div>
          </>
        }
        key="1"
        showArrow={false}
      >
        <div className="noto text-10 text-gray">
          {moment(message.created_at).format(`${getDateFormat(i18n)} HH:mm`)}
        </div>
      </Panel>
    </Collapse>
  )
}

export default Messages
