import React, { useState, useEffect, useContext } from 'react'
import s3Uploader from '../../../utils/s3-uploader';
import ProfilePicture from '../../common/profile-picture'
import FieldGenerator from './field-generator'
import { Button, Row, Col, Divider, Form, message, Spin } from 'antd'
import '../../../assets/css/dashboard/viewdoctor.css'
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks'
import { GET_DOCTOR_INFO } from '../../../graphql/dashboard/queries'
import { GET_USER_DUPLICATE } from '../../../graphql/users/queries'
import {
  DELETE_DOCTOR,
  UPDATE_DOCTOR,
} from '../../../graphql/dashboard/mutation'
import { useHistory } from 'react-router-dom'
import { commonComponents } from '../../common'
import { useTranslation } from 'react-i18next'
import { getName } from '../../common/get-name'
import { HeaderContext } from '../../../layouts'

const bcrypt = require('bcryptjs')
const { ConfirmModal } = commonComponents

const ViewEditDoctor = props => {
  const { t, i18n } = useTranslation()

  const doctorUserId = props.doctorUserId
  const history = useHistory()
  const [form] = Form.useForm()
  const refetch = props.refetch

  const { states } = useContext(HeaderContext)
  const { userId } = states.headerNavCont

  const [updateDoctor] = useMutation(UPDATE_DOCTOR)
  const [deleteDoctor] = useMutation(DELETE_DOCTOR)
  const [image, setImage] = useState(null)
  const [tempImage, setTempImage] = useState(null)
  const [loading, setLoading] = useState(false)

  const [editModes, configEditModes] = useState({
    isEditMode: props.isEditMode,
    hasEditedContent: false,
  })

  const [header, setHeader] = useState({
    name: '',
    avatar_link: null,
    specialties: null,
  })

  const [fields, editFields] = useState([])

  const { data, refetch: refetchDetails } = useQuery(GET_DOCTOR_INFO, {
    variables: {
      where: {
        id: {
          _eq: doctorUserId,
        },
      },
    },
  })

  const [getUserDuplicate] = useLazyQuery(GET_USER_DUPLICATE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      console.log(`data: ${data.users}`)
      if (data) {
        if (data.users.length > 0) {
          message.error(t('dialog.duplicate_email'))
          return
        } else {
          console.log('submitting form')
          form.submit()
        }
      }
    }
  })

  useEffect(() => {
    if (data) {
      setHeader({
        ...header,
        name: getName(
          data.doctors[0].user.first_name,
          data.doctors[0].user.last_name,
          i18n.language
        ),
        avatar_link: data.doctors[0].user.avatar_link,
        specialties: JSON.parse(data.doctors[0].specialties),
      })

      editFields([
        {
          fieldName: t('crud.first_name'),
          fieldKey: 'first_name',
          value: data.doctors[0].user.first_name,
        },
        {
          fieldName: t('crud.family_name'),
          fieldKey: 'last_name',
          value: data.doctors[0].user.last_name,
        },
        {
          fieldName: t('crud.home_address'),
          fieldKey: 'address',
          value: data.doctors[0].user.address,
        },
        {
          fieldName: t('crud.mobile_num'),
          fieldKey: 'telephone',
          value: data.doctors[0].user.telephone,
        },
        {
          fieldName: t('doctors.specialties'),
          fieldKey: 'specialties',
          value: JSON.parse(data.doctors[0].specialties),
          options: [
            { value: t('doctors.general_doctor') },
            { value: t('doctors.obstetrician') },
            { value: t('doctors.gynecologist') },
            { value: t('doctors.pediatrician') },
          ],
        },
        {
          fieldName: t('crud.email_add'),
          fieldKey: 'email',
          value: data.doctors[0].user.email,
          rules: [
            {
              type: 'email',
              message: t('dialog.not_valid_email'),
            },
            {
              required: true,
              message: t('dialog.required_field'),
            },
          ],
        },
        {
          fieldName: t('crud.temp_pass'),
          fieldKey: 'password',
          isPassword: true,
        },
      ])
      refetch()
      refetchDetails()
    }
  }, [data])

  /*Page Sub components*/
  /*Button Actions*/
  const removeUser = () => {
    ConfirmModal({
      mtitle: t('doctors.delete_doctor'),
      mcontent: t('confirm.cannot_undo'),
      monOk: () => {
        deleteDoctor({
          variables: {
            id: doctorUserId,
          },
        }).then(
          () => {
            refetch()
            props.hidePanel()
            message.success(t('doctors.success_delete'))
          },
          changeError => {
            message.error(changeError.message)
          },
        )
      },
      t,
    })
  }

  const editDetails = () => {
    /*Force Change to reset content Edit status*/
    configEditModes({
      isEditMode: !editModes.isEditMode,
      hasEditedContent: false,
    })
  }

  const saveChanges = () => {
    /*Force Change to reset content Edit status*/
    let emailDb = form.getFieldValue('email')

    if (form.isFieldTouched('email')) {
      getUserDuplicate({
        variables: {
          where: {
            _and: [
              { email: { _eq: emailDb } },
              { id: { _neq: userId } },
              { deleted_at: { _is_null: true } }
            ]
          }
        }
      })
    } else {
      form.submit()
    }
  }

  const updateDocDetails = async values => {
    let password = {}
    let specialties = JSON.stringify(values.specialties)
    delete values.specialties

    if (values.password !== undefined && values.password !== null) {
      await bcrypt
      .hash(values.password, 10)
      .then(
        result => (password = { password: result, password_is_temp: true }),
      )
    }

    setLoading(true)
    configEditModes({
      isEditMode: true,
      hasEditedContent: false,
    })

    if (image) {
      s3Uploader(image, `${props.userId}/profile`, (imageUrl) => {
        handleUpdateDoctor(values, specialties, password, imageUrl)
      })
    } else {
      handleUpdateDoctor(values, specialties, password)
    }
  }

  const handleUpdateDoctor = (values, specialties, password, imageUrl = null) => {
    updateDoctor({
      variables: {
        doctors_where: {
          id: { _eq: doctorUserId },
        },
        doctors_set: {
          specialties: specialties,
        },
        users_where: {
          doctors: { id: { _eq: doctorUserId } },
        },
        users_set: {
          first_name: values.first_name,
          last_name: values.last_name,
          address: values.address,
          telephone: values.telephone,
          email: values.email,
          avatar_link: imageUrl || undefined,
          ...password,
        },
      },
    }).then(
      () => {
        refetch()
        refetchDetails()
        message.success(t('doctors.success_update'))
        configEditModes({
          isEditMode: props.isEditMode,
          hasEditedContent: false,
        })
        setLoading(false)
      },
      changeError => {
        message.error(changeError.message)
      },
    )
  }

  /**
   * TODO: UPDATE IMAGE
   */
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={10}>
          <ProfilePicture
            isEdit={editModes.isEditMode}
            profileSrc={tempImage || header.avatar_link}
            saveImage={(image, tempImage) => {
              setTempImage(tempImage)
              setImage(image)
              configEditModes({
                isEditMode: true,
                hasEditedContent: true,
              })
            }}
          />
        </Col>
        <Col span={14}>
          <p className="doctor-specialty-title">
            {(header.specialties || []).join(', ')}
          </p>
          <p className="doctor-name">{`Dr. ${header.name}`}</p>
        </Col>
      </Row>
      <Row>
        <Col span={14} offset={10}>
          <Button
            block
            disabled={editModes.isEditMode}
            className="thin-bordered-btn"
            onClick={() => {
              history.push(`messages/${data.doctors[0].user.id}`)
            }}
          >
            {t('crud.send_message')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider
            style={{
              backgroundColor: 'rgba(151, 151, 151, 0.5)',
              marginTop: '35px',
              marginBottom: '35px',
            }}
            dashed
          />
        </Col>
      </Row>
      <FieldGenerator
        fields={fields}
        form={form}
        refetch={refetch}
        updateDocDetails={updateDocDetails}
        isEditing={editModes.isEditMode}
        onEditFields={editDetails}
        onSaveChanges={saveChanges}
        onRemoveUser={removeUser}
        onSubmit={editFields}
        editModes={editModes}
        configEditModes={configEditModes}
        doctorLogged={doctorUserId}
        panel={props.panel}
      />
    </Spin>
  )
}

export default ViewEditDoctor
