import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../../layouts'
import { default as Appointments } from './appointments'
import { useTranslation } from 'react-i18next'

export const AppointmentsPage = () => {
	const { t } = useTranslation()

	const {
		stateSetters: { setNavCont },
	} = useContext(HeaderContext)

	useEffect(() => {
		setNavCont({
			defaultKey: ['2'],
			hideSideBar: false,
			headerTitle: t('appointments.index'),
			hideHeader: false,
		})
	}, [])

	return <Appointments />
}
