import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { Typography, Col, Row, Button, Divider, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { GET_LIVING_STATUSES } from '../../../../graphql/living-statuses/queries'
import { commonComponents } from '../../../common'
import AddEditLivingStatusRecord from './add-edit-drawer'
import { useTranslation } from 'react-i18next'

const { Sidepanel } = commonComponents

const LivingStatusRecords = () => {
	const { t } = useTranslation()

	const { pregnancy_id } = useParams()

	const panel = useRef()
	const [form] = Form.useForm()
	const [livingStatus, setLivingStatus] = useState({})

	const { data, refetch } = useQuery(GET_LIVING_STATUSES, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				pregnancy_id: {
					_eq: pregnancy_id,
				},
			},
		},
	})

	useEffect(() => {
		if (!data) return

		setLivingStatus(data.living_statuses[0] || {})
	}, [data])

	const recordCategories = [
		{
			label: t('living_status.working_conditions_first_pregnant'),
			records: [
				{ name: 'job', label: t('living_status.job_type_work_env') },
				{ name: 'work_schedule', label: t('living_status.work_hours') },
				{ name: 'transportation', label: t('living_status.get_to_work') },
				{ name: 'commute', label: t('living_status.commute_length') },
			],
		},
		{
			label: t('living_status.working_conditions_during_pregnant'),
			records: [
				{ name: 'time_off', label: t('living_status.time_off') },
				{ name: 'changed_jobs', label: t('living_status.change_job') },
				{
					name: 'permanently_left',
					label: t('living_status.permanently_left'),
				},
			],
		},
		{
			label: t('living_status.maternity_paternity_leave'),
			records: [
				{
					name: 'maternity_leave_before_birth',
					label: t('living_status.maternity_before_birth'),
				},
				{
					name: 'maternity_leave_after_birth',
					label: t('living_status.maternity_after_birth'),
				},
				{
					name: 'paternity_leave_before_birth',
					label: t('living_status.paternity_before_birth'),
				},
				{
					name: 'paternity_leave_after_birth',
					label: t('living_status.paternity_after_birth'),
				},
			],
		},
		{
			label: t('living_status.index'),
			records: [
				{
					name: 'living_situation',
					label: t('living_status.home_living_situation'),
				},
				{
					name: 'living_environment',
					label: t('living_status.living_envirionment'),
					placeholder: t('living_status.eg_select_living_envirionment'),
					type: 'select',
					options: [
						{
							value: 'quiet',
							label: t('living_status.quiet'),
						},
						{ value: 'normal', label: t('living_status.normal') },
						{ value: 'noisy', label: t('living_status.noisy') },
					],
				},
				{
					name: 'sunshine_exposure',
					label: t('living_status.sunshine_exposure'),
					placeholder: t('living_status.eg_select_sunshine_exposure'),
					type: 'select',
					options: [
						{
							value: 'good',
							label: t('living_status.good'),
						},
						{ value: 'normal', label: t('living_status.normal') },
						{ value: 'poor', label: t('living_status.poor') },
					],
				},
				{
					name: 'housemates',
					label: t('living_status.lives_with_you'),
				},
			],
		},
	]

	const selectOptionRef = {
		quiet: t('living_status.quiet'),
		normal: t('living_status.normal'),
		noisy: t('living_status.noisy'),
		good: t('living_status.good'),
		poor: t('living_status.poor'),
	}

	const LivingStatusRecordsCategories = () => {
		return recordCategories.map((recordCategory, index) => {
			return (
				<>
					{index !== 0 && <Divider dashed />}
					<Typography
						className='dash-title'
						style={{ float: 'unset', marginBottom: 20 }}
					>
						{recordCategory.label}
					</Typography>
					<Row gutter={[16, 16]}>
						{LivingStatusRecords(recordCategory.records)}
					</Row>
				</>
			)
		})
	}

	const LivingStatusRecords = records => {
		return records.map(record => {
			return (
				<Col span={12}>
					<div>
						<Typography className='pregnancy-record-header'>
							{record.label}
						</Typography>
					</div>
					<div>
						<Typography className='pregnancy-record-data'>
							{record.type === 'select' &&
								(selectOptionRef[livingStatus[record.name] || null] ||
									t('crud.n_a'))}
							{record.type !== 'select' &&
								(livingStatus[record.name] || t('crud.n_a'))}
						</Typography>
					</div>
				</Col>
			)
		})
	}
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>
						{t('living_status.patients_living_status')}
					</h1>
				</Col>
				<Col span={12}>
					<Button
						icon={<EditOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditLivingStatusRecord
									form={form}
									refetch={refetch}
									record={livingStatus}
									recordCategories={recordCategories}
								/>,
							)
						}
					>
						<span className='title'>
							{t('living_status.update_living')}
						</span>
					</Button>
				</Col>
			</Row>
			{LivingStatusRecordsCategories()}
			<Sidepanel ref={panel} />
		</>
	)
}
export default LivingStatusRecords
