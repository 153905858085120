import React from 'react'
import { Tabs, Col, Row } from 'antd'
import '../../assets/css/pregnancy/pregnancy-records.css'

import { recordComponents } from './records'
import { useTranslation } from 'react-i18next'

const {
	HealthRecord,
	LivingStatusRecord,
	CourseRecords,
	PrenatalTestRecords,
	DeliveryRecord,
	PostpartumRecord,
} = recordComponents

const { TabPane } = Tabs

const PregnancyRecords = props => {
	const { t } = useTranslation()

	const { doctor, babies, babyRefetch, pregnancy } = props

	const tabs = [
		{ key: 1, title: t('health.index'), component: <HealthRecord /> },
		{
			key: 2,
			title: t('living_status.index'),
			component: <LivingStatusRecord />,
		},
		{
			key: 3,
			title: t('course.index'),
			component: <CourseRecords doctor={doctor} pregnancy={pregnancy} />,
		},
		{
			key: 4,
			title: t('prenatal_tests.index'),
			component: <PrenatalTestRecords doctor={doctor} pregnancy={pregnancy} />,
		},
		{
			key: 5,
			title: t('record_of_deliveries.index'),
			component: (
				<DeliveryRecord
					doctor={doctor}
					babies={babies}
					babyRefetch={babyRefetch}
					pregnancy={pregnancy}
				/>
			),
		},
		{
			key: 6,
			title: t('post_partum.index'),
			component: <PostpartumRecord />,
		},
	]

	return (
		<div class='prenancy-records-container'>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<h1 className='dash-title'>{t('pregnancies.pregnancy_records')}</h1>
				</Col>
			</Row>
			<Tabs defaultActiveKey='1' className='patient-record-tabs'>
				{tabs.map(tab => {
					return (
						<TabPane tab={tab.title} key={tab.key}>
							{tab.component}
						</TabPane>
					)
				})}
			</Tabs>
		</div>
	)
}
export default PregnancyRecords
