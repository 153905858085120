import gql from 'graphql-tag'

export const GET_APPOINTMENTS = gql`
	query GET_APPOINTMENTS($where: appointments_bool_exp) {
		appointments(where: $where, order_by: { datetime: desc }, limit: 3) {
			id
			name
			datetime
			patient {
				id
				user {
					id
					last_name
					first_name
				}
			}
		}
	}
`

export const GET_ADMIN_DASHBOARD_DATA = gql`
	query GET_ADMIN_DASHBOARD_DATA(
		$active_where: appointments_bool_exp
		$patients_where: patients_bool_exp
		$doctors_where: doctors_bool_exp
	) {
		active: appointments(where: $active_where, order_by: { datetime: asc }) {
			id
			name
			datetime
			patient {
				id
				user {
					id
					last_name
					first_name
				}
			}
			doctor {
				id
				user {
					id
				}
			}
		}
		patients(
			where: $patients_where
			order_by: { user: { first_name: asc, last_name: asc } }
		) {
			id
			user {
				id
				last_name
				first_name
			}
			pregnancies(limit: 1, order_by: { start_date: desc }) {
				id
				start_date
			}
		}
		doctors(
			where: $doctors_where
			order_by: { user: { first_name: asc, last_name: asc } }
		) {
			id
			user {
				id
				first_name
				last_name
			}
			specialties
		}
	}
`

export const GET_DOCTOR_DASHBOARD_DATA = gql`
	query GET_DOCTOR_DASHBOARD_DATA(
		$active_where: appointments_bool_exp
		$pending_where: appointments_bool_exp
		$previous_where: appointments_bool_exp
		$cancelled_upcoming_where: appointments_bool_exp
		$patients_where: appointments_bool_exp
	) {
		active: appointments(where: $active_where, order_by: { datetime: asc }) {
			id
			name
			datetime
			patient {
				id
				user {
					id
					last_name
					first_name
				}
			}
			doctor {
				id
				user {
					id
				}
			}
		}
		pending: appointments(where: $pending_where, order_by: { datetime: asc }) {
			id
			name
			datetime
			patient {
				id
				user {
					id
					last_name
					first_name
				}
			}
			doctor {
				id
				user {
					id
				}
			}
		}
		cancelled: appointments(
			where: $cancelled_upcoming_where
			order_by: { datetime: asc }
		) {
			id
			name
			status
			datetime
			patient {
				id
				user {
					id
					last_name
					first_name
				}
			}
		}
		patients: appointments(
			where: $patients_where
			order_by: { datetime: asc }
		) {
			id
			datetime
			patient {
				id
				user {
					id
					last_name
					first_name
				}
				pregnancies(limit: 1, order_by: { start_date: desc }) {
					id
					start_date
				}
			}
		}
	}
`

export const GET_DOCTOR_INFO = gql`
	query GET_DOCTOR_INFO($where: doctors_bool_exp!) {
		doctors(where: $where) {
			id
			specialties
			user {
				id
				first_name
				last_name
				address
				telephone
				email
				password
				avatar_link
			}
		}
	}
`
