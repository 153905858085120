import gql from 'graphql-tag'

export const GET_DOCTORS = gql`
	query GET_DOCTORS($where: doctors_bool_exp, $offset: Int) {
		doctors(
			where: $where
			order_by: { user: { last_name: asc, first_name: asc } }
			limit: 10
			offset: $offset
		) {
			id
			user {
				id
				first_name
				last_name
				telephone
				email
				avatar_link
			}
			specialties
		}
		doctors_count: doctors_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`

export const GET_ALL_DOCTORS = gql`
	query GET_ALL_DOCTORS {
		doctors(
			order_by: { user: { last_name: asc, first_name: asc } }
			where: { deleted_at: { _is_null: true } }
		) {
			id
			user {
				id
				first_name
				last_name
				telephone
				email
				avatar_link
			}
			specialties
		}
	}
`

export const GET_DOCTOR_BY_USER_ID = gql`
	query GET_DOCTOR_BY_USER_ID($where: doctors_bool_exp) {
		doctors(where: $where) {
			id
			user {
				id
			}
		}
	}
`
