import React from 'react'
import StyleButton from './StyleButton'

const BlockStyleControls = props => {
	const { editorState, onToggle, BLOCK_TYPES } = props
	const selection = editorState.getSelection()
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType()

	return (
		<div className='RichEditor-controls' style={{ marginTop: '3px' }}>
			{BLOCK_TYPES.map(type => (
				<StyleButton
					key={type.label}
					icon={type.icon}
					style={type.style}
					label={type.label}
					onToggle={onToggle}
					active={type.style === blockType}
				/>
			))}
		</div>
	)
}

export default BlockStyleControls
