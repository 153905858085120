import gql from 'graphql-tag'

export const UPSERT_BABY_CHECKUPS = gql`
	mutation UPSERT_BABY_CHECKUPS($objects: [baby_checkups_insert_input!]!) {
		insert_baby_checkups(
			objects: $objects
			on_conflict: {
				constraint: baby_checkups_pkey
				update_columns: [
					date
					height
					weight
					head_circumference
					chest_circumference
					diet
					breastfeeding
					feeding_method
					general_health
					nursing_ability
					jaundice
					irregularities_at_birth
					irregularities_after_birth
					preparation_for_weaning
					condition_of_mouth
					meals
					number_of_teeth
					condition_of_eyes
					medical_institution
					notes
				]
			}
		) {
			affected_rows
		}
	}
`

export const UPDATE_BABY_CHECKUPS = gql`
	mutation UPDATE_BABY_CHECKUPS(
		$_set: baby_checkups_set_input!
		$where: baby_checkups_bool_exp!
	) {
		update_baby_checkups(where: $where, _set: $_set) {
			affected_rows
		}
	}
`
