import React, { useContext } from 'react'
import '../../assets/css/patients/patients.css'
import { patientsComponents } from '../../components'
import { HeaderContext } from '../../layouts'

const { PatientList } = patientsComponents

const PatientsPage = () => {
	const { states } = useContext(HeaderContext)
	const { userId, userType } = states.headerNavCont

	return (
		<>
			<PatientList userInfo={{ userId: userId, userType: userType }} />
		</>
	)
}

export default PatientsPage
