import React, { useEffect, useState, useRef } from 'react'
import '../../assets/css/doctors/doctorslist.css'
import {
	Card,
	List,
	Space,
	Table,
	Button,
	Row,
	Col,
	Divider,
	Pagination,
	Avatar
} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { GET_DOCTORS } from '../../graphql/doctors/queries'
import { useQuery } from '@apollo/react-hooks'
import { commonComponents } from '../common'
import ViewEditDoctor from './view-edit-doctor'
import { useTranslation } from 'react-i18next'
import AddDoctor from './add-doctor'
import { getName } from '../common/get-name'
import CommonSearch from '../../components/common/search'

const { Sidepanel } = commonComponents

const DoctorsList = props => {
	const { t, i18n } = useTranslation()

	const panel = useRef()
	const [dataSource, setDataSource] = useState({
		doctors: [],
		doctor_count: 0,
	})
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState('')

	const { data, loading, refetch } = useQuery(GET_DOCTORS, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				user: {
					_or: [
						{
							first_name: {
								_ilike: `%${filter}%`,
							},
						},
						{
							last_name: {
								_ilike: `%${filter}%`,
							},
						},
					],
				},
			},
			offset: (page - 1) * 10,
		},
	})

	useEffect(() => {
		if (data) {
			setDataSource({
				doctors: transformDoctorsList(data),
				doctor_count: data.doctors_count.aggregate.count,
			})
		}
	}, [data])

	const transformDoctorsList = data => {
		let doctors = []

		if (data) {
			doctors = data.doctors.map(record => {
				return {
					key: record.id,
					imageURL:
						record.user.avatar_link,
					name: {
						first: record.user.first_name,
						last: record.user.last_name,
					},
					specialties: JSON.parse(record.specialties).join(', '),
					contact: record.user.telephone,
					email: record.user.email,
					user_id: record.user.id
				}
			})
		}

		return doctors
	}

	const dataInfo = item => {
		const newdata = tableDataSource(item)[0]
		
		return (
			<Row gutter = {[8,16]}
				className = "doctor-list-data" 
				justify="start">
				<Col xs = {12} sm = {12} md= {6}>
					<h2>{t('doctors.doctor')}</h2>
					<h1>{newdata.doctor}</h1>
				</Col>
				<Col xs = {12} sm = {12} md= {6}>
					<h2>{t('doctors.specialty')}</h2>
					<h1>{newdata.specialty}</h1>
				</Col>
				<Col xs = {12} sm = {12} md= {6}>
					<h2>{t('crud.contact_num')}</h2>
					<h1>{newdata.contact}</h1>
				</Col>
				<Col xs = {12} sm = {12} md= {6}>
					<h2>{t('crud.email')}</h2>
					<h1>{newdata.email}</h1>
				</Col>
			</Row>
		)
	}
	

	/* Table Columns */
	const tablecolumns = [
		{
			title: t('doctors.doctor'),
			className: 'doctor-table-header patient ',
			dataIndex: 'doctor',
			align: 'left',
			width: '25%',
		},
		{
			title: t('doctors.specialty'),
			className: 'doctor-table-header cycle',
			dataIndex: 'specialty',
			align: 'left',
			width: '35%',
		},
		{
			title: t('crud.contact_num'),
			className: 'doctor-table-header contact',
			dataIndex: 'contact',
			align: 'left',
			width: '20%',
		},
		{
			title: t('crud.email'),
			className: 'doctor-table-header email',
			dataIndex: 'email',
			align: 'left',
			width: '20%',
		},
	]

	const tableDataSource = item => {
		return [
			{
				doctor: extendGetName(item.name.first, item.name.last),
				specialty: item.specialties,
				contact: item.contact,
				email: item.email,
			},
		]
	}

	const extendGetName = (first_name, last_name) => {
		return getName(first_name, last_name, i18n.language)
	}

	const onHandleKeyDown = async value => {
		await setPage(1)

		setFilter(value)
	}

	const handleAddDoctor = () => {
		panel.current.showPanel(<AddDoctor mode='doctor' refetch={refetch} />)
	}

	return (
		<>
			<div>
				<div className='text-right'>
					<Space>
						<Button
							className='dash-add-button'
							type='link'
							onClick={handleAddDoctor}
						>
							+ {t('doctors.add_doctor')}
						</Button>
						<CommonSearch onSearch={onHandleKeyDown} />
					</Space>
				</div>
				<div className='noto text-14 text-black' style={{ marginTop: '-12px' }}>
					{t('doctors.index')}
				</div>
				<Divider className='divider' />
				<List
					loading={loading}
					dataSource={dataSource.doctors}
					itemLayout='vertical'
					size='large'
					locale={{ emptyText: t('empty_messages.doctors_list') }}
					renderItem={item => (
						<List.Item className='doctors-list-listitem' key={item.key}>
							<Card className='doctor-card'>
								<Row gutter = {[0,16]}>
									<Col span={1}
										 style = {{minWidth: '70px'}}
									>
										<Avatar
											size={55}
											style={{ resizeMode: 'stretch' }}
											icon={<UserOutlined />}
											src={item.imageURL}
										/>		
									</Col>
									<Col 
										lg = {22}
										md = {15}
										sm = {12}
										flex = "auto"
									>
										{dataInfo(item)}
									</Col>
									<Col lg = {2}
										 md = {5}
										 sm = {{span: 5, offset: 2}}
										 flex = "auto"
									>
										<Button
											className='horizontal-resizable-btn doctors-list-button-view'
											onClick={() => {
												panel.current.showPanel(
													<ViewEditDoctor
														doctorUserId={item.key}
														isEditMode={false}
														refetch={refetch}
														panel={panel}
														userId={item.user_id}
													/>,
												)
											}}
										>
											{t('crud.view')}
										</Button>
									</Col>
								</Row>
							</Card>
						</List.Item>
					)}
				/>
			</div>
			<Pagination
				className='float-right'
				current={page}
				total={dataSource.doctor_count}
				hideOnSinglePage={true}
				showSizeChanger={false}
				onChange={page => {
					setPage(page)
				}}
			/>

			<Sidepanel ref={panel} />
		</>
	)
}

export default DoctorsList
