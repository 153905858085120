import React, { useState, useEffect } from 'react'
import { Tabs, Col, Row, Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import '../../assets/css/pregnancy/pregnancy-records.css'
import { recordComponents } from './records'
import { useTranslation } from 'react-i18next'
import { getName } from '../common/get-name'

const { BabyCheckup, Immunization } = recordComponents

const { TabPane } = Tabs

const BabyRecords = props => {
	const { t, i18n } = useTranslation()

	const { doctor, babies } = props

	const [baby, setBaby] = useState(babies[0] || {})

	const hasTwoOrMoreBabies =
		babies && babies instanceof Array && babies.length > 1

	useEffect(() => {
		setBaby(babies[0] || {})
	}, [babies])

	const tabs = [
		{
			key: 1,
			title: t('baby_checkups.index'),
			component: <BabyCheckup doctor={doctor} baby={baby} />,
		},
		{
			key: 2,
			title: t('baby_immunizations.immunization'),
			component: <Immunization doctor={doctor} baby={baby} />,
		},
	]

	const addEllipsis = (fname, lname) => {
		var ellipsisVal = ''
		var combinedName = fname + ' ' + lname
		if (combinedName.length > 15) {
			ellipsisVal = '...'
		}
		return ellipsisVal
	}

	return (
		<div class='prenancy-records-container'>
			<Row gutter={[16, 16]}>
				<Col span={14}>
					<h1 className='dash-title'>{t('babies.babys_records')}</h1>
				</Col>
				<Col span={10}>
					{hasTwoOrMoreBabies && (
						<Select
							showArrow
							autoComplete='off'
							options={babies.map(baby => ({
								value: baby.id,
								label:
									getName(baby.first_name, baby.last_name, i18n.language) +
									addEllipsis(baby.first_name, baby.last_name),
							}))}
							value={baby.id}
							style={{ minWidth: 150, maxWidth: 150 }}
							className='float-right default-ant-select'
							suffixIcon={<DownOutlined />}
							onChange={id => {
								setBaby(babies.find(baby => baby.id === id))
							}}
						/>
					)}
				</Col>
			</Row>
			<Tabs defaultActiveKey='1'>
				{tabs.map(tab => {
					return (
						<TabPane tab={tab.title} key={tab.key}>
							{tab.component}
						</TabPane>
					)
				})}
			</Tabs>
		</div>
	)
}
export default BabyRecords
