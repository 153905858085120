import React, { useState, useContext } from 'react'
import {
  Input,
  Row,
  Col,
  Form,
  Select,
  Button,
  Upload,
  Spin,
  message,
  Modal,
} from 'antd'
import '../../../assets/css/dashboard/addpost.css'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_NEWSFEEDS, UPSERT_NEWSFEEDS } from '../../../graphql/newsfeeds/mutations'
import { capitalize } from 'lodash'
import logo from '../../../assets/img/default_post.jpg'
import { HeaderContext } from '../../../layouts'
import { useTranslation } from 'react-i18next'
import s3Uploader from '../../../utils/s3-uploader';
import RichTextEditor from '../../common/rich-text-editor/index'
import { getPregIds } from '../../../utils/get-preg-ids-by-week'
import { sendMessage } from '../../../firebase-messaging'

const AddPost = props => {
  const { t, i18n } = useTranslation()
  const { Option } = Select

  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState(false)
  const [upsertNewsfeeds] = useMutation(UPSERT_NEWSFEEDS)
  const [updateNewsfeed] = useMutation(UPDATE_NEWSFEEDS)
  const { states } = useContext(HeaderContext)
  const { userId } = states.headerNavCont
  const [uploadState, setUploading] = useState({
    isUploading: false,
    uploadSrc: null,
  })
  const [fileList, setFileList] = useState([])
  const [tempImage, setTempImage] = useState(null)
  const [image, setImage] = useState(null)

  const onFinish = inputs => {
    setLoading(true)

    upsertNewsfeeds({
      variables: {
        objects: [
          {
            author_id: userId,
            title: inputs.title,
            category: inputs.category,
            content: inputs.content,
            image: null,
            week_of_pregnancy: inputs.week_of_pregnancy,
            subtitle: inputs.subtitle,
          },
        ],
      },
    }).then(
      addResult => {
        if (image) {
          s3Uploader(image,
            `newsfeeds/${addResult.data.insert_newsfeeds.returning[0].id}`,
            (imageUrl) => {
              updateNewsfeed({
                variables: {
                  where: { id: { _eq: addResult.data.insert_newsfeeds.returning[0].id } },
                  _set: {
                    image: imageUrl
                  }
                }
              }).then(() => {
                resetForms(inputs, addResult)
              })
            })
        } else {
          resetForms(inputs, addResult)
        }

      },
      addError => {
        setLoading(false)
        message.error(addError.message)
      },
    )
  }

  const resetForms = (inputs, addResult) => {
    setLoading(false)
    message.success(t('newsfeeds.success_add'))
    form.resetFields()
    resetUploadState()
    props.handleCloseModal()
    props.refetch()
    getPregIds(inputs.week_of_pregnancy, (ids) => {
      ids.forEach(id => {
        const postid = addResult.data.insert_newsfeeds.returning[0].id
        console.log(`\nPregnancy id: ${id} with news article: ${addResult.data.insert_newsfeeds.returning[0].id}`)
        sendNewArticleNotif(id, postid)
      })
    })
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const uploadprops = {
    name: 'image',
    accept: '.png,.jpg,.jpeg',
    fileList,
    onChange: async info => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map(file => {
        if (file.response) file.url = file.response.url
        return file
      })
      setFileList(fileList)

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
        getBase64(info.file.originFileObj, imageUrl => {
          setImage(info.file)
          setTempImage(imageUrl)
          setUploading({
            uploadSrc: imageUrl,
            isUploading: false,
          })
        })
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const sendNewArticleNotif = (userid, postid) => {
    let data = {
      'data': {
        'notiftype': 'post-new',
        'postid': postid
      }
    }
    sendMessage(userid, data)
  }

  const actionSuccess = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const closeModal = () => {
    props.handleCloseModal()
    form.resetFields()
    resetUploadState()
  }

  const resetUploadState = () => {
    setUploading({
      uploadSrc: null,
      isUploading: false,
    })
  }

  const getWeeksOfPregnancy = () => {
    const weeks = []

    for (let i = 1; i <= 40; i++) {
      weeks.push(<Option key={i} value={i}>{getWeek(i)}</Option>)
    }

    return weeks
  }

  const getWeek = i => {
    if (i18n.language === 'en') return `${capitalize(t('common.week'))} ${i}`
    else return `${i} ${capitalize(t('common.week'))}`
  }

  return (
    <Modal
      centered
      visible={props.isModalAddVisible}
      onCancel={closeModal}
      footer={null}
      width={900}
      keyboard={true}
    >
      <Spin spinning={isLoading}>
        <Row>
          <div className="post-title">{t('newsfeeds.add_newsfeed_post')}</div>
        </Row>
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
        >
          <Form.Item
            name="title"
            label={t('newsfeeds.title')}
            rules={[
              {
                required: true,
                message: t('dialog.required_field'),
              },
            ]}
            className="doctor-field-input"
          >
            <Input
              className="input-component"
              placeholder={t('newsfeeds.title_placeholder')}
            />
          </Form.Item>
          <Form.Item
            name="subtitle"
            label={t('newsfeeds.subtitle')}
            rules={[
              {
                required: true,
                message: t('dialog.required_field'),
              },
            ]}
            className="doctor-field-input"
          >
            <Input
              className="input-component"
              placeholder={t('newsfeeds.subtitle_placeholder')}
            />
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="category"
                label={t('newsfeeds.category')}
                rules={[
                  {
                    required: true,
                    message: t('dialog.required_field'),
                  },
                ]}
                className="doctor-field-input"
              >
                <Select
                  placeholder={t('newsfeeds.select_category')}
                  showArrow
                  bordered={false}
                >
                  <Option key={'physical_changes'} value={'physical_changes'}>
                    {t('newsfeeds.physical_changes')}
                  </Option>
                  <Option key={'things_to_expect'} value={'things_to_expect'}>
                    {t('newsfeeds.things_to_expect')}
                  </Option>
                  <Option key={'medical_tips'} value={'medical_tips'}>
                    {t('newsfeeds.medical_tips')}
                  </Option>
                  <Option key={'baby_development'} value={'baby_development'}>
                    {t('newsfeeds.baby_development')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="week_of_pregnancy"
                label={t('newsfeeds.week_pregnancy')}
                rules={[
                  {
                    required: true,
                    message: t('dialog.required_field'),
                  },
                ]}
                className="doctor-field-input"
              >
                <Select
                  placeholder={t('crud.select_a_week')}
                  showArrow
                  bordered={false}
                >
                  {getWeeksOfPregnancy()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            className="btn-container doctor-field-input-label"
            label={t('account_settings.upload_photo')}
          >
            <Upload
              {...uploadprops}
              customRequest={actionSuccess}
              onPreview={file => {
                var image = new Image()
                image.src = tempImage
                var w = window.open('')
                w.document.write(image.outerHTML)
              }}
              showUploadList={false}
            >
              <Button className="block-button">{t('account_settings.upload_photo')}</Button>
            </Upload>
            {uploadState.uploadSrc !== null && (
              <Button
                type="link"
                style={{ color: '#e5526f' }}
                onClick={() => {
                  if (uploadState.uploadSrc === null) return
                  resetUploadState()
                }}
              >
                {t('account_settings.remove_photo')}
              </Button>
            )}
          </Form.Item>
          <Form.Item
            name="content"
            label={t('newsfeeds.content')}
            rules={[
              {
                required: true,
                message: t('dialog.required_field'),
              },
            ]}
          >
            <RichTextEditor setContent={(newContent) => {
              form.setFieldsValue({ content: newContent })
            }}/>
          </Form.Item>
          <div style={{ marginTop: 70 }}></div>
          <Form.Item className="btn-container">
            <Button
              block
              className="block-button add-post-btn"
              htmlType="submit"
              style={{ width: '20%'}}
            >
              {t('newsfeeds.add_post')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default AddPost
