import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, List, Row, Col, Button } from 'antd'
import '../../assets/css/dashboard/newsfeed.css'
import AddPost from './add-post/add-post'
import ViewEditPost from './view-edit-post'
import {
  GET_NEWSFEEDS,
  GET_NEWSFEEDS_SUBSCRIPTION,
  GET_DETELED_NEWSFEEDS_SUBSCRIPTION
} from '../../graphql/newsfeeds/queries'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/img/default_post.jpg'
import { capitalize, uniqBy, remove } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller';
import { v4 as uuid } from 'uuid'


const Newsfeed = () => {
  const { t } = useTranslation()

  const [filter, setFilter] = useState(null)
  const [newsfeeds, setNewsfeeds] = useState([])
  const [dataState, setDataState] = useState({})
  const [modalType, setModalType] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [pagination, setPagination] = useState({ limit: 5, offset: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [, setRandom] = useState(uuid())
  const { data, refetch } = useQuery(GET_NEWSFEEDS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        deleted_at: { _is_null: true },
        category: filter ? { _eq: filter } : null,
      },
      limit: pagination.limit,
      offset: 0
    },
  })
  const { data: newsfeedSubscription } = useSubscription(GET_NEWSFEEDS_SUBSCRIPTION, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        deleted_at: { _is_null: true },
        category: filter ? { _eq: filter } : null,
      },
      limit: 1,
      offset: 0
    }
  })
  const { data: newsfeedDeletedSubscription } = useSubscription(GET_DETELED_NEWSFEEDS_SUBSCRIPTION,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          deleted_at: { _is_null: false },
        },
      }
    })

  const { data: updatedData, refetch: refetchUpdated } = useQuery(GET_NEWSFEEDS, {
    fetchPolicy: 'network-only',
    variables: {
      where: { id: { _eq: '' } }
    }
  })

  useEffect(() => {
    if (data) {
      setNewsfeeds((oldState) => {
        return uniqBy([...oldState, ...transformPostList(data)], 'id')
      })

      const page = currentPage * pagination.limit
      setCurrentPage(currentPage + 1)
      setPagination((oldState) => ({ ...oldState, offset: page }))
    }
  }, [data])

  useEffect(() => {
    if (newsfeedSubscription?.newsfeeds && newsfeedSubscription?.newsfeeds.length && newsfeeds.length) {
      const newsfeedIndex = newsfeeds.findIndex(value => newsfeedSubscription?.newsfeeds[0].id === value.id)
      if (newsfeedIndex === -1) {
        setNewsfeeds((old) => {
          return [...transformPostList(newsfeedSubscription), ...old]
        })
      }
    }
  }, [newsfeedSubscription, newsfeeds])

  useEffect(() => {
    if (newsfeedDeletedSubscription?.newsfeeds && newsfeedDeletedSubscription?.newsfeeds.length) {
      const index = newsfeeds.findIndex(value => value.id === newsfeedDeletedSubscription?.newsfeeds[0].id)
      if (index !== -1) {
        newsfeeds.splice(index, 1)
        setRandom(uuid())
      }
    }
  }, [newsfeedDeletedSubscription, newsfeeds])

  useEffect(() => {
    if (updatedData) {
      console.log('updating data')
      if (updatedData.length == 0) return

      const transformedD = transformPostList(updatedData)

      let newState;
      if (transformedD[0].deleted_at == null) {
        console.log('updating')
        newState = newsfeeds.map(item => {
          if (item.id == transformedD[0].id) return transformedD[0]
          return item
        })
      } else {
        console.log('deleting')
        newState = newsfeeds.filter(item => item.id != transformedD[0].id)
      }

      setNewsfeeds(newState)
    }
  }, [updatedData])

  useEffect(() => {
    setNewsfeeds([])
    setCurrentPage(1)
    setPagination((oldState) => ({ ...oldState, offset: 0 }))
    refetch({
      where: {
        deleted_at: { _is_null: true },
        category: filter ? { _eq: filter } : null,
      },
      limit: pagination.limit,
      offset: 0
    })
  }, [filter])

  const handleLoadMore = () => {
    refetch({
      where: {
        deleted_at: { _is_null: true },
        category: filter ? { _eq: filter } : null,
      },
      ...pagination
    })
  }

  const transformPostList = data => {
    let posts = []

    if (data) {
      posts = data.newsfeeds.map(record => {
        return {
          id: record.id,
          image: record.image || logo,
          title: record.title,
          category: record.category,
          content: record.content,
          week_of_pregnancy: record.week_of_pregnancy,
          updated_at: record.updated_at,
          deleted_at: record.deleted_at,
          subtitle: record.subtitle,
          name: {
            id: record.user.id,
            first: record.user.first_name,
            last: record.user.last_name,
          },
        }
      })
    }

    return posts
  }

  const handleCloseAddModal = () => {
    setModalType('add')
    setIsVisible(false)
  }

  const handleCloseViewModal = () => {
    setModalType('view')
    setIsVisible(false)
  }

  const handleAddPost = () => {
    setModalType('add')
    setIsVisible(true)
  }

  const handleViewPost = data => {
    setModalType('view')
    setIsVisible(true)
    setDataState(data)
  }

  const isModalViewVisible = modalType === 'view' && isVisible === true
  const isModalAddVisible = modalType === 'add' && isVisible === true

  const shortenText = (text, len) => {
    return text && text.length > len ? text.substring(0, len) : text
  }

  const displayEllipsis = (subtitle, postPart) => {
    let text = subtitle
    let part = postPart === 'title' ? 45 : 150

    if (text && text.length > part) {
      text = `${shortenText(subtitle, part)}...`
    }

    return text
  }

  return (
    <>
      <Card className="dash-card" bodyStyle={{ paddingBottom: '0' }}>
        <Row>
          <Col span={12}>
						<span className="newsfeed-title text-14 font-600 noto normal">
							{t('newsfeeds.index')}
						</span>
          </Col>
          <Col span={12}>
            <Button
              type="submit"
              className="newsfeed-add-button font-700 noto float-right border-none normal outline-none shadow-none bg-transparent"
              onClick={handleAddPost}
            >
              + {t('newsfeeds.add_post')}
            </Button>
          </Col>
        </Row>
        <Row gutter={[16,16]} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Button
            type="submit"
            className="filter-button outline-none text-black w-auto noto font-600 text-10 text-center h-25 all-button"
            style={{ marginLeft: '3px' }}
            onClick={() => setFilter(null)}
          >
            {t('common.all')}
          </Button>
          <Button
            type="submit"
            className="filter-button outline-none text-white noto font-600 text-10 w-auto normal text-center h-25 bg-physical-changes"
            style={{ marginLeft: '3px' }}
            onClick={() => setFilter('physical_changes')}
          >
            {t('newsfeeds.physical_changes')}
          </Button>
          <Button
            type="submit"
            className="filter-button outline-none text-white noto font-600 text-10 w-auto normal text-center h-25 bg-things-to-expect"
            style={{ marginLeft: '3px' }}
            onClick={() => setFilter('things_to_expect')}
          >
            {t('newsfeeds.things_to_expect')}
          </Button>
          <Button
            type="submit"
            className="filter-button outline-none text-white noto font-600 text-10 w-auto normal text-center h-25 bg-medical-tips"
            style={{ marginLeft: '3px' }}
            onClick={() => setFilter('medical_tips')}
          >
            {t('newsfeeds.medical_tips')}
          </Button>
          <Button
            type="submit"
            className="filter-button outline-none text-white noto font-600 text-10 w-auto normal text-center h-25 bg-baby-development"
            style={{ marginLeft: '3px' }}
            onClick={() => setFilter('baby_development')}
          >
            {t('newsfeeds.baby_development')}
          </Button>
        </Row>
        <Row
          className="newsfeed-list-container h-62"
          style={{ marginBottom: '20px', overflowY: 'auto' }}
        >

            <InfiniteScroll
              className="newsfeed-scroller"
              initialLoad={false}
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={true}
              useWindow={false}
            >
              <List
                className="newsfeed-view-list"
                style={{ width: '100%' }}
                locale={{ emptyText: t('empty_messages.dashboard_newsfeed') }}
                dataSource={newsfeeds}
                renderItem={data => (
                  <a
                    key={data.id}
                    style={{ color: 'black' }}
                    onClick={() => handleViewPost(data)}
                  >
                    <Row
                      style={{
                        marginRight: '20px',
                        borderBottom: '1px dashed #A2C0D4',
                      }}
                    >
                      <List.Item style={{ width: '100%' }}>
                        <Col flex="none">
                          <img className="thumbnail-post" src={data.image}/>
                        </Col>
                        <Col flex="auto">
                          <Row gutter = {[0,16]}>
                            <span
                              className="newsfeed-category noto font-700 text-8 normal"
                              style={{ color: '#E5526F' }}
                            >
                              {`${capitalize(t('common.week'))} ${
                                data.week_of_pregnancy
                              } // ${t(`newsfeeds.${data.category}`)}`}
                            </span>
                          </Row>
                          <Row gutter={[0,16]}>
                            <span
                              className="newsfeed-title font-600 normal text-64 line-20 text-black"
                              style={{marginTop: '5px'}}
                            >
                              {displayEllipsis(data.title, 'title')}
                            </span>
                          </Row>
                          <Row>
                            <p
                              className="noto font-400 text-10 font-normal text-left line-14 text-black"
                              style={{ marginTop: '5px' }}
                            >
                              {displayEllipsis(data.subtitle, 'subtitle')}
                            </p>
                          </Row>
                        </Col>
                      </List.Item>
                    </Row>
                  </a>
                )}
              />
            </InfiniteScroll>
        </Row>
      </Card>
      <ViewEditPost
        newsfeedFields={dataState}
        isEditMode={false}
        refetch={refetch}
        refetchUpdated={refetchUpdated}
        isModalViewVisible={isModalViewVisible}
        handleCloseModal={handleCloseViewModal}
      />
      <AddPost
        refetch={refetch}
        isModalAddVisible={isModalAddVisible}
        handleCloseModal={handleCloseAddModal}
      />
    </>
  )
}

export default Newsfeed
