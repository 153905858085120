import gql from 'graphql-tag'

export const UPSERT_BABIES = gql`
	mutation UPSERT_BABIES($objects: [babies_insert_input!]!) {
		insert_babies(
			objects: $objects
			on_conflict: {
				constraint: babies_pkey
				update_columns: [
					first_name
					last_name
					sex
				]
			}
		) {
			affected_rows
		}
	}
`