import gql from 'graphql-tag'

export const ADD_PREGNANCY = gql`
	mutation ADD_PREGNANCY($objects: [pregnancies_insert_input!]!) {
		insert_pregnancies(objects: $objects) {
			affected_rows
			returning {
				id
			}
		}
	}
`

export const DELETE_PREGNANCY = gql`
	mutation DELETE_PREGNANCY(
		$preg_filter: pregnancies_bool_exp!
		$preg_set: pregnancies_set_input
	) {
		update_pregnancies(where: $preg_filter, _set: $preg_set) {
			affected_rows
		}
	}
`

export const UPDATE_PREGNANCY = gql`
	mutation UPDATE_PREGNANCY(
		$preg_filter: pregnancies_bool_exp!
		$preg_set: pregnancies_set_input
	) {
		update_pregnancies(where: $preg_filter, _set: $preg_set) {
			affected_rows
		}
	}
`
