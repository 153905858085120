import gql from 'graphql-tag'

export const GET_DOCTOR_INFO = gql`
	subscription GET_DOCTOR_INFO(
		$userId: uuid!
		$currentDate: timestamptz
		$weekStart: timestamptz
		$weekEnd: timestamptz
	) {
		doctors(where: { user: { id: { _eq: $userId } } }) {
			id
			is_available
			user {
				id
				first_name
				last_name
				avatar_link
			}
			active: appointments_aggregate(
				where: {
					datetime: { _gt: $currentDate }
					status: { _eq: "active" }
					deleted_at: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
			pending: appointments_aggregate(
				where: {
					datetime: { _gt: "now()" }
					status: { _eq: "pending" }
					deleted_at: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
			week_total: appointments_aggregate(
				where: {
					datetime: { _gte: "now()", _lte: $weekEnd }
					status: { _eq: "active" }
					deleted_at: { _is_null: true }
				}
			) {
				aggregate {
					count
				}
			}
		}
	}
`

export const GET_HOSPITAL_INFO = gql`
	query GET_HOSPITAL_INFO {
		patients: patients_aggregate(deleted_at: { _is_null: true }) {
			aggregate {
				count
			}
		}
		doctors: doctors_aggregate(deleted_at: { _is_null: true }) {
			aggregate {
				count
			}
		}
		available_doctors: doctors_aggregate(
			where: { is_available: { _eq: true }, deleted_at: { _is_null: true } }
		) {
			aggregate {
				count
			}
		}
	}
`

export const GET_HOSPITAL_INFO_FROM_USERS = gql`
	subscription GET_HOSPITAL_INFO_FROM_USERS {
		users(where: { deleted_at: { _is_null: true } }) {
			id
			role {
				id
				description
			}
			patients(where: { deleted_at: { _is_null: true } }) {
				id
			}
			doctors(where: { deleted_at: { _is_null: true } }) {
				id
				is_available
			}
		}
	}
`
