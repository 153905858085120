import gql from 'graphql-tag'

export const ADD_PRENATAL_TESTS = gql`
	mutation ADD_PRENATAL_TESTS($objects: [prenatal_tests_insert_input!]!) {
		insert_prenatal_tests(objects: $objects) {
			affected_rows
		}
	}
`

export const UPDATE_PRENATAL_TESTS = gql`
	mutation UPDATE_PRENATAL_TESTS(
		$_set: prenatal_tests_set_input!
		$where: prenatal_tests_bool_exp!
	) {
		update_prenatal_tests(where: $where, _set: $_set) {
			affected_rows
		}
	}
`
