import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Form, message, DatePicker, Spin, Divider, Select, Input } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_BABY_IMMUNIZATIONS } from '../../../../graphql/baby-immunizations/mutations'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'

const { BlockButton } = commonComponents

const AddEditBabyImmunizationRecord = props => {
	const { t, i18n } = useTranslation()

	const { hidePanel, visible, record, form, refetch, doctor, baby } = props
	const [isLoading, setLoading] = useState(false)
	const [upsertBabyImmunizations] = useMutation(UPSERT_BABY_IMMUNIZATIONS)

	useEffect(() => {
		form.resetFields()
	}, [visible])

	const removeUndefinedValuesAndEmptyStringToNull = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else {
					if (key === 'date')
						newValues[key] = moment(values[key]).format('YYYY-MM-DD')
					else newValues[key] = values[key]
				}
			}
		}

		return newValues
	}

	const handleUpdate = values => {
		if (!baby.id) {
			message.error(t('babies.baby_err_cannot_save'))
			return
		}
		setLoading(true)

		const doctor_id = doctor && doctor.id ? { doctor_id: doctor.id } : {}

		upsertBabyImmunizations({
			variables: {
				objects: {
					baby_id: baby.id,
					id: record && record.id ? record.id : undefined,
					...doctor_id,
					...removeUndefinedValuesAndEmptyStringToNull(values),
				},
			},
		}).then(
			() => {
				refetch()
				hidePanel()
				setLoading(false)
				form.resetFields()
				if (record && record.id)
					message.success(t('baby_immunizations.success_update'))
				else message.success(t('baby_immunizations.success_add'))
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const getRecordOfDeliveryTimetamp = () => {
		if (!baby) return moment()

		const deliveries = baby.pregnancy.record_of_deliveries

		for (let i = 0; i < deliveries.length; i++) {
			const delivery = deliveries[i]

			if (
				baby.first_name === delivery.baby_first_name &&
				baby.last_name === delivery.baby_last_name &&
				baby.sex === delivery.baby_sex
			)
				return moment(delivery.delivery_timestamp)
		}

		return moment()
	}

	const getRecordFormItems = () => {
		const formItems = [
			{
				name: 'date',
				label: t('baby_immunizations.immunization_date'),
				type: 'datepicker',
				placeholder: t('crud.select_date'),
				required: true,
			},
			{
				name: 'vaccine',
				label: t('baby_immunizations.vaccine'),
				type: 'select',
				options: [
					{ value: 'bcg', label: t('baby_immunizations.bcg') },
					{ value: 'pcv', label: t('baby_immunizations.pcv') },
					{
						value: 'dtp_ipv',
						label: t('baby_immunizations.dtp_ipv'),
					},
					{ value: 'mr', label: t('baby_immunizations.mr') },
					{ value: 'chickenpox', label: t('baby_immunizations.chicken_pox') },
					{ value: 'hib', label: t('baby_immunizations.hib') },
					{ value: 'hpv', label: t('baby_immunizations.hpv') },
					{ value: 'hepatitis_b', label: t('baby_immunizations.hepatitis_b') },
				],
				placeholder: t('baby_immunizations.select_vaccine'),
				required: true,
			},
			{
				name: 'manufacturer',
				label: t('baby_immunizations.manufacturer_of_vaccine'),
				type: 'input',
				placeholder: t('crud.type_here'),
				maxLength: 20
			},
			{
				name: 'notes',
				label: t('crud.notes'),
				type: 'input',
				placeholder: t('crud.type_here'),
				maxLength: 23
			},
		]

		return formItems.map(formItem => (
			<Form.Item
				name={formItem.name}
				label={formItem.label}
				className='field-input'
				rules={[
					{
						required: formItem.required ? true : false,
						message: t('dialog.required_field'),
					},
				]}
				initialValue={
					record && record[formItem.name] !== null
						? formItem.name === 'date'
							? moment(record[formItem.name])
							: record[formItem.name]
						: undefined
				}
			>
				{getFormField(formItem)}
			</Form.Item>
		))
	}

	const getFormField = formItem => {
		if (formItem.type === 'input')
			return (
				<Input
					maxLength={formItem.maxLength}
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'select')
			return (
				<Select
					showArrow
					bordered={false}
					autoComplete='off'
					options={formItem.options}
					style={{ width: '100%' }}
					suffixIcon={<DownOutlined />}
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'datepicker')
			return (
				<DatePicker
					allowClear={false}
					autoComplete='off'
					format={getDateFormat(i18n)}
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
					disabledDate={current => {
						if (current.diff(getRecordOfDeliveryTimetamp()) > 0) return false

						return true
					}}
				/>
			)
	}

	return (
		<Form
			form={form}
			layout='vertical'
			onFinish={handleUpdate}
		>
			<div className='drawer-title'>
				{!record && t('baby_immunizations.add_baby_immunization')}
				{record && t('baby_immunizations.edit_baby_immunization')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				{getRecordFormItems()}
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditBabyImmunizationRecord
