import gql from 'graphql-tag'

export const UPDATE_APPOINTMENTS = gql`
	mutation UPDATE_APPOINTMENTS(
		$_set: appointments_set_input!
		$where: appointments_bool_exp!
	) {
		update_appointments(where: $where, _set: $_set) {
			affected_rows
			returning{
				patient{
				  user_id
				}
			}
		}
	}
`

export const ADD_APPOINTMENT = gql`
	mutation ADD_APPOINTMENT($objects: [appointments_insert_input!]!) {
		insert_appointments(objects: $objects) {
			affected_rows
			returning {
				id
			}
		}
	}
`
