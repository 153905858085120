import gql from 'graphql-tag'

export const UPSERT_NEWSFEEDS = gql`
	mutation UPSERT_NEWSFEEDS($objects: [newsfeeds_insert_input!]!) {
		insert_newsfeeds(
			objects: $objects
			on_conflict: {
				constraint: newsfeeds_pkey
				update_columns: [
					category
					title
					content
					image
					week_of_pregnancy
					subtitle
				]
			}
		) {
			affected_rows
			returning{
				id
			}
		}
	}
`

export const UPDATE_NEWSFEEDS = gql`
	mutation UPDATE_NEWSFEEDS(
		$_set: newsfeeds_set_input!
		$where: newsfeeds_bool_exp!
	) {
		update_newsfeeds(where: $where, _set: $_set) {
			affected_rows
			returning{
				id
			}
		}
	}
`
