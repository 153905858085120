import React, { useRef } from 'react'
import TableList from '../../common/table-list'
import { commonComponents } from '../../common'
import { useHistory } from 'react-router-dom'
import ViewEditAppointment from '../../appointments/view-edit-appointment'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const { Sidepanel } = commonComponents

const CancelledAppointments = props => {
	const { t } = useTranslation()

	const data = props.data
	const history = useHistory()
	const panel = useRef()

	const actionList = [
		{
			name: t('crud.view'),
			function: key => {
				panel.current.showPanel(<ViewEditAppointment itemId={key} showStatus/>)
			},
		},
	]

	return (
		<>
			<TableList
				title={t('appointments.cancelled_appointments')}
				isAddBtnHidden={true}
				columns={[t('appointments.appointment_for'), t('common.name')]}
				items={data}
				actions={actionList}
				columnProps={{
					align: 'left',
				}}
				tableMode={'cancelled_appointments'}
				viewbtnprops={{
					title: t('appointments.view_more_appointments'),
					onClick: () => {
						history.push(
							`appointments/${moment().format('YYYY-MM-DD')}/cancelled`,
						)
					},
				}}
			/>
			<Sidepanel ref={panel} />
		</>
	)
}

export default CancelledAppointments
