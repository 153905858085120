import React from 'react'
import moment from 'moment'
import { isEmpty, capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../utils'
import { getName } from '../../common/get-name'
import { UserOutlined } from '@ant-design/icons'
import logo from '../../../assets/img/default_post.jpg'
import { Row, Col, Button, Space, Card, Modal, Avatar } from 'antd'

const ViewPost = props => {
	const { t, i18n } = useTranslation()
	const {
		newsfeedFields,
		handleDelete,
		handleEdit,
		isEdit,
		isModalViewVisible,
		handleCloseModal,
		closeViewModal,
	} = props

	const closeModal = () => {
		closeViewModal()
		handleCloseModal()
	}
	// FIXME:
	const categoryClassName = category => {
		let categoryClass = null

		switch (category) {
			case 'physical_changes':
				categoryClass = 'view-post-category physical-changes-color'
				break
			case 'things_to_expect':
				categoryClass = 'view-post-category things-to-expect-color'
				break
			case 'medical_tips':
				categoryClass = 'view-post-category medical-tips-color'
				break
			case 'baby_development':
				categoryClass = 'view-post-category baby-development-color'
				break
		}
		return categoryClass
	}
	return (
		<>
			<Modal
				className='newsfeed-modal'
				centered
				visible={!isEdit && isModalViewVisible}
				onCancel={closeModal}
				footer={null}
				width={900}
				keyboard={true}
			>
				<Card style={{ border: 'none'}}>
					<p className='newsfeed-category noto font-700 text-12 normal text-black line-14 category-label'>
						{`${capitalize(t('common.week'))} ${
							newsfeedFields.week_of_pregnancy
						}`}
					</p>

					<p className='newsfeed-text-title noto font-700 text-24 normal line-20'>
						{newsfeedFields.title}
						<p className='newsfeed-text-subtitle noto font-400 normal'>
							{newsfeedFields.subtitle}
						</p>
					</p>
					<Row>
						<div class={categoryClassName(newsfeedFields.category)}>
							{newsfeedFields.category
								? t(`newsfeeds.${newsfeedFields.category}`)
								: ''}
						</div>
					</Row>
					<Row align='middle' style={{ marginTop: '10px' }}>
						<Col>
							<Avatar size={45} icon={<UserOutlined />} />
						</Col>
						<Col>
							<Row>
								<span className='newsfeed-name noto'>
									{!isEmpty(newsfeedFields) &&
										getName(
											newsfeedFields.name.first,
											newsfeedFields.name.last,
											i18n.language,
										)}
								</span>
							</Row>
							<Row>
								<span className='newsfeed-category noto text-12 normal newsfeed-date-style'>
									{moment(newsfeedFields.updated_at).format(
										getDateFormat(i18n),
									)}
								</span>
							</Row>
						</Col>
					</Row>
					<Row style={{ border: 'none', marginTop: '20px' }}>
						<div className='w-full overflow-y-auto'
							 style ={ {
								 	maxHeight: '500px',
								    overflowX:'visible',
								    msOverflowX: 'visible' }}>
							<img
								className='logo-image'
								src={newsfeedFields.image || logo}
							/>
							<p
								dangerouslySetInnerHTML={{ __html: newsfeedFields.content }}
								className='newsfeed-content noto'
							/>
						</div>
					</Row>
					<Row
						span={45}
						style={{ justifyContent: 'space-between', marginTop: '20px' }}
					>
						<Col>
							<Button
								className='block-button edit-post-button'
								style={{ marginRight: '10px' }}
								onClick={handleEdit}
								hidden={isEdit}
							>
								{t('newsfeeds.edit_post')}
							</Button>
							<Button
								className='block-button delete-post-button'
								onClick={handleDelete}
							>
								{t('newsfeeds.delete_post')}
							</Button>
						</Col>
						<Col>
							<Button
								className='block-button close-post-button'
								style={{ alignItems: 'flex-end' }}
								onClick={closeModal}
							>
								{t('newsfeeds.close_post')}
							</Button>
						</Col>
					</Row>
				</Card>
				<div style={{ marginTop: '10px' }}></div>
			</Modal>
		</>
	)
}

export default ViewPost
