import gql from 'graphql-tag'

export const GET_DELIVERIES = gql`
	query GET_DELIVERIES($where: record_of_deliveries_bool_exp) {
		record_of_deliveries(where: $where) {
			id
			length_of_pregnancy
			delivery_place
			delivery_attendants
			delivery_timestamp
			delivery_type
			delivery_method
			length_of_labor
			amount_of_bleeding
			blood_transfusion
			baby_first_name
			baby_last_name
			baby_sex
			baby_order
			baby_height
			baby_weight
			baby_head_circumference
			baby_chest_circumference
			baby_special_condition
			doctor {
				id
				user {
					id
					first_name
					last_name
				}
			}
		}
	}
`
