import gql from 'graphql-tag'

export const GET_LIVING_STATUSES = gql`
	query GET_LIVING_STATUSES($where: living_statuses_bool_exp) {
		living_statuses(where: $where) {
			id
			job
			work_schedule
			transportation
			commute
			time_off
			changed_jobs
			permanently_left
			maternity_leave_before_birth
			maternity_leave_after_birth
			paternity_leave_before_birth
			paternity_leave_after_birth
			living_situation
			living_environment
			sunshine_exposure
			housemates
		}
	}
`
