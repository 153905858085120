import React, { useRef, useState } from 'react'
import moment from 'moment'
import AppointmentList from './appointment-list'
import ViewEditAppointment from '../../appointments/view-edit-appointment'
import { commonComponents } from '../../common'
import { UPDATE_APPOINTMENTS } from '../../../graphql/dashboard/mutation'
import { useMutation } from '@apollo/react-hooks'
import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { sendMessage } from '../../../firebase-messaging'

const { ConfirmModal, RadioModal, InputModal, Sidepanel } = commonComponents

const PendingAppointments = props => {
	const { t } = useTranslation()

	const [selectModalVisible, showInputModal] = useState(false)
	const [radioModalVisible, showRadioModal] = useState(false)
	const [updateAppointment] = useMutation(UPDATE_APPOINTMENTS)
	const [[selected, patientid], setSelected] = useState(['',''])
	const { data, refetch, date, setDate } = props
	const panel = useRef()
	const [form] = Form.useForm()

	const actionList = [
		{
			name: t('crud.view'),
			function: (key, _, user_id) => {
				panel.current.showPanel(
					<ViewEditAppointment
						onCancelAppointment={() => {
							ConfirmModal({
								mtitle: t('appointments.sure_cancel_appointment'),
								mcontent: t('confirm.cannot_undo'),
								monOk: () => {
									setSelected([key,user_id])
									showRadioModal(true)
								},
								t,
							})
						}}
						itemId={key}
						form={form}
						refetch={refetch}
					/>,
				)
			},
		},
		{
			name: t('appointments.approve'),
			function: (key,item,user_id) => {
				console.log(key)
				console.log(`item: ${item}`)
				if (!checkIfDatePassed(key)){
					setSelected([key,user_id])
					showInputModal(true)
				} else{
					message.error("appointment date has passed!")
				}
			},
		},
		{
			name: t('appointments.decline'),
			function: (key,_,user_id) => {
				console.log(user_id)
				if (!checkIfDatePassed(key)){
					setSelected([key,user_id])
					showRadioModal(true)
				} else{
					message.error("appointment date has passed!")
				}
			},
		},
	]

	const checkIfDatePassed = (apptId) => {
		let apptDate = data.filter((item)=>{
			return item.key == apptId
		})[0].datetime

		let mom = moment(apptDate)
		return mom.isSameOrBefore(moment(), 'minute')
	}

	const sendConfirm = (userid,appointid) => {
		let data = {
			'data' : {
				'notiftype': 'appoint-approve',
				'appointmentid' : appointid
			}
		}

		sendMessage(userid, data)
	}

	const sendDeclined = (userid, appointid) => {
		let data = {
			'data' : {
				'notiftype': 'appoint-decline',
				'appointmentid' : appointid
			}
		}

		sendMessage(userid,data)
	}

	const radioOptions = [
		{ optionValue: '1', title: t('appointments.unavailable_hour') },
		{ optionValue: '2', title: t('appointments.attending_emergency') },
		{ optionValue: '3', title: t('appointments.emergency_leave') },
		{ optionValue: '4', title: t('appointments.other') },
	]

	const filteredData = data.filter((item)=>{return moment(item.datetime).isAfter(moment())})

	return (
		<div>
			<AppointmentList
				title={t('appointments.appointments_for_approval')}
				actionList={actionList}
				mode='pending'
				data={filteredData}
				date={date}
				setDate={setDate}
			/>
			<Sidepanel ref={panel} />
			<InputModal
				visible={selectModalVisible}
				setVisible={showInputModal}
				submitCallback={value => {
					updateAppointment({
						variables: {
							_set: {
								status: 'active',
								location: value,
							},
							where: {
								id: {
									_eq: selected,
								},
							},
						},
					}).then(
						() => {
							refetch()
							message.success(t('appointments.success_update'))
							sendConfirm(patientid, selected)
						},
						changeError => {
							message.error(changeError.message)
						},
					)
				}}
			/>
			<RadioModal
				visible={radioModalVisible}
				setVisible={showRadioModal}
				submitCallback={value => {
					updateAppointment({
						variables: {
							_set: {
								status: 'declined',
								notes: radioOptions.find(item => item.optionValue === value)
									.title,
							},
							where: {
								id: {
									_eq: selected,
								},
							},
						},
					}).then(
						() => {
							refetch()
							message.success(t('appointments.success_cancel'))
							console.log('patient id: ', patientid)
							sendDeclined(patientid,selected)
						},
						changeError => {
							message.error(changeError.message)
						},
					)
				}}
				options={radioOptions}
				defaultValue={'1'}
			/>
		</div>
	)
}

export default PendingAppointments
