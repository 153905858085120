import gql from 'graphql-tag'

export const ADD_PATIENT = gql`
	mutation ADD_PATIENT($objects: [patients_insert_input!]!) {
		insert_patients(objects: $objects) {
			affected_rows
		}
	}
`

export const UPSERT_PATIENT = gql`
	mutation UPSERT_PATIENT($objects: [patients_insert_input!]!) {
		insert_patients(
			objects: $objects
			on_conflict: {
				constraint: patients_pkey
				update_columns: [
					account_code
					date_of_birth
					deleted_at
				]
			}
		) {
			affected_rows
		}
	}
`

export const UPDATE_PATIENT = gql`
	mutation UPDATE_PATIENT(
		$patients_where: patients_bool_exp!
		$patients_set: patients_set_input!
		$users_where: users_bool_exp!
		$users_set: users_set_input!
	) {
		update_patients(where: $patients_where, _set: $patients_set) {
			returning {
				date_of_birth
			}
			affected_rows
		}
		update_users(where: $users_where, _set: $users_set) {
			returning {
				address
				email
				first_name
				last_name
				telephone
			}
			affected_rows
		}
	}
`

export const DELETE_PATIENT = gql`
	mutation DELETE_PATIENT($id: uuid!) {
		update_users(
			where: { patients: { id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_appointments(
			where: { patient_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_pregnancies(
			where: { patient_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_prenatal_tests(
			where: { pregnancy: { patient_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_course_of_pregnancies(
			where: { pregnancy: { patient_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_health_references(
			where: { pregnancy: { patient_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_post_partums(
			where: { pregnancy: { patient_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_record_of_deliveries(
			where: { pregnancy: { patient_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_babies(
			where: { pregnancy: { patient_id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_baby_checkups(
			where: { baby: { pregnancy: { patient_id: { _eq: $id } } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_baby_immunizations(
			where: { baby: { pregnancy: { patient_id: { _eq: $id } } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_patients(
			where: { id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
	}
`
