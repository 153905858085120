import gql from 'graphql-tag'

export const GET_PREGNANCIES = gql`
	query GET_PREGNANCIES($where: pregnancies_bool_exp!) {
		pregnancies(where: $where) {
			id
			due_date
			start_date
			patient {
				id
				user {
					id
					first_name
					last_name
				}
				pregnancies(order_by: { start_date: asc }) {
					id
					due_date
					start_date
				}
			}
		}
	}
`
export const GET_PREGNANCY_DETAILS_BY_ID = gql`
	query GET_PREGNANCY_DETAILS_BY_ID($where: pregnancies_bool_exp) {
		pregnancies(where: $where) {
			id
			patient_id
			due_date
			start_date
		}
	}
`
