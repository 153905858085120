import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './fonts/NotoSans-Bold.ttf'
import './fonts/NotoSans-BoldItalic.ttf'
import './fonts/NotoSans-Italic.ttf'
import './fonts/NotoSans-Regular.ttf'
import './i18next'
serviceWorker.register()
  
ReactDOM.render(
	<Suspense fallback={<></>}>
		<App />
	</Suspense>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

