import React, { useRef } from 'react'
import { Card, Col, Row, Typography, Divider, Button, List } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import AddAppointment from '../appointments/add-appointment'
import { commonComponents } from '../common'
import '../../assets/css/patients/patientupcominglist.css'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../utils'

const { Sidepanel } = commonComponents

const PatientUpcomingAppointment = props => {
	const { t, i18n } = useTranslation()

	const panel = useRef()
	const dataSource = props.data

	const addBtnOnClick = e => {
		panel.current.showPanel(
			<AddAppointment
				doctorLogged={props.doctor}
				selectedId={props.patient}
				refetch={() => props.refetch()}
			/>,
		)
	}

	const getDateTime = datetime => {
		let format = ''

		if (i18n.language == 'en') format = `dddd, ${getDateFormat(i18n)} @ HH:mm`
		else format = `${getDateFormat(i18n)} dddd, @ HH:mm`

		return moment(datetime).format(format)
	}

	return (
		<>
			<Card className='patient-profile-card'>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<h1 className='dash-title'>
							{t('appointments.upcoming_appointments')}
						</h1>
					</Col>
					<Col span={12}>
						<Button
							icon={<PlusOutlined />}
							onClick={addBtnOnClick}
							className={'dash-add-button'}
						>
							<span className='title'>{t('appointments.add_appointment')}</span>
						</Button>
					</Col>
				</Row>
				<Row
					className='patient-upcoming-appointment-container'
				>
					<List
						dataSource={dataSource}
						itemLayout="vertical"
						size="large"
						renderItem ={appointment => (
							<Row 
								className="upcoming-row"
								gutter={[16,16]}>
								<Col span={24}>
									<Card className='upcoming-appointment-card'>
										<div
											className='appointment-date'
											style={{ width: '10%', textAlign: 'center' }}
										>
											<Typography
												className='fs-24 color-black'
											>
												{moment(appointment.datetime).format('DD')}
											</Typography>
											<Typography className='fs-12 color-black'>
												{moment(appointment.datetime).format('ddd')}
											</Typography>
										</div>
										<Divider
											type='vertical'
											style={{ background: '#969999', fontSize: 54, top: 0 }}
										/>
										<div
											className='appointment-details'
											style={{ width: '90%', marginLeft: 10 }}
										>
											<Typography className='fs-12 color-black'>
												{t(`appointments.${appointment.name}`)}
											</Typography>
											<Typography className='fs-10 color-gray'>
												{getDateTime(appointment.datetime)}
											</Typography>
											<Typography className='fs-10 color-gray'>
												{appointment.location}
											</Typography>
										</div>
									</Card>
								</Col>
							</Row>
						)}>
					</List>
				</Row>
			</Card>
			<Sidepanel ref={panel} />
		</>
	)
}
export default PatientUpcomingAppointment
