import gql from 'graphql-tag'

export const UPDATE_USER_PASSWORD = gql `
  mutation UPDATE_USER_PASSWORD(
		$_set: users_set_input!
		$where: users_bool_exp!
	) {
		update_users(where: $where, _set: $_set) {
			affected_rows
		}
	}
`