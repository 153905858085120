import React, { useEffect, useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import '../../assets/css/account-settings/index.css'
import { commonComponents } from '../../components/common'
import { HeaderContext } from '../../layouts'

import { Row, Col, Input, Switch, Avatar, Select } from 'antd'
import { Tag, Upload, message, Button, Spin, Form } from 'antd'
import { UserOutlined, DownOutlined } from '@ant-design/icons'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'

import { UPDATE_USER } from '../../graphql/users/mutation'
import { UPDATE_DOCTOR } from '../../graphql/doctors/mutation'
import { GET_USER_ACCOUNT } from '../../graphql/users/queries'

import { startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import s3Uploader from '../../utils/s3-uploader';

const bcrypt = require('bcryptjs')
const { BlockButton, ConfirmModal } = commonComponents

export const AccountPage = () => {
  const {
    states: {
      headerNavCont: { userId },
    },
    stateSetters: { setNavCont },
  } = useContext(HeaderContext)

  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [isValuesChanged, setIsValuesChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [fileList, setFileList] = useState([])
  const [account, setAccount] = useState({
    doctors: [{}],
  })

  const [updateUser] = useMutation(UPDATE_USER)
  const [updateDoctor] = useMutation(UPDATE_DOCTOR)
  const { data, refetch } = useQuery(GET_USER_ACCOUNT, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: {
          _eq: userId,
        },
      },
    },
  })

  const uploadProps = {
    name: 'avatar_link',
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        onSuccess()
      }, 0)
    },
    accept: '.png,.jpg,.jpeg',
    fileList,
    showUploadList: false,
    onChange: async info => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map(file => {
        if (file.response) file.url = file.response.url
        return file
      })
      setFileList(fileList)

      if (info.file.status === 'done') {
        s3Uploader(info.file, `${userId}/profile`, (imageUrl) => {
          saveAccountField('avatar_link', imageUrl)
        })
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  useEffect(() => {
    setNavCont({
      defaultKey: ['0'],
      hideSideBar: false,
      headerTitle: t('account_settings.account'),
      hideHeader: false,
    })
  }, [])

  useEffect(() => {
    if (data)
      setAccount(
        data.users[0] || {
          doctors: [{}],
        },
      )
  }, [data])

  const Settings = () => {
    const settingItems = [
      {
        label: t('crud.family_name'),
        name: 'last_name',
        value: account.last_name,
        type: 'input',
        rules: [
          {
            required: true,
            message: t('dialog.required_field'),
          },
        ],
      },
      {
        label: t('crud.first_name'),
        name: 'first_name',
        value: account.first_name,
        type: 'input',
        rules: [
          {
            required: true,
            message: t('dialog.required_field'),
          },
        ],
      },
      {
        label: t('doctors.specialty'),
        name: 'specialties',
        value: account.doctors[0].specialties
          ? JSON.parse(account.doctors[0].specialties)
          : undefined,
        type: 'select',
        options: [
          { value: t('doctors.general_doctor') },
          { value: t('doctors.obstetrician') },
          { value: t('doctors.gynecologist') },
          { value: t('doctors.pediatrician') },
        ],
      },
      {
        label: t('crud.address'),
        name: 'address',
        value: account.address,
        type: 'input',
      },
      {
        label: t('crud.tel'),
        name: 'telephone',
        value: account.telephone,
        type: 'input',
      },
      {
        label: t('crud.pass'),
        name: 'password',
        value: undefined,
        type: 'password',
      },
      {
        label: t('crud.availability'),
        name: 'is_available',
        value: account.doctors[0].is_available,
        type: 'switch',
      },
    ]

    return settingItems.map(item => {
      return (
        <Row className="mb-31 h-36">
          <Col span={8}>{item.label}</Col>
          <Col span={14}>
            {item.type !== 'switch' && (
              <>
                {!isEdit && getValue(item)}
                {isEdit && getFormItem(item)}
              </>
            )}
            {item.type === 'switch' && (
              <Switch
                className="switch"
                checked={item.value}
                onChange={checked => {
                  saveAccountField(item.name, checked)
                }}
              />
            )}
          </Col>
        </Row>
      )
    })
  }

  const saveAccountField = async (name, value) => {
    if (name === undefined || value === undefined) return

    const doctorEntities = {
      is_available: true,
    }

    setLoading(true)
    if (doctorEntities[name]) {
      // doctor update
      updateDoctor({
        variables: {
          _set: {
            [name]: value,
            ['updated_at']: 'now()'
          },
          where: {
            user_id: {
              _eq: userId,
            },
          },
        },
      })
      .then(
        () => {
          refetch()
          message.success(t('account_settings.update_success'))
        },
        changeError => {
          message.error(changeError.message)
        },
      )
      .finally(() => {
        setLoading(false)
      })
    } else {
      // user update
      updateUser({
        variables: {
          _set: {
            [name]: value,
            ['updated_at']: 'now()'
          },
          where: {
            id: {
              _eq: userId,
            },
          },
        },
      })
      .then(
        () => {
          refetch()
          message.success(t('account_settings.update_success'))
        },
        changeError => {
          message.error(changeError.message)
        },
      )
      .finally(() => {
        setLoading(false)
      })
    }
  }

  const removeUndefinedValuesAndEmptyStringToNull = values => {
    const newValues = {}

    for (const key in values) {
      if (values[key] !== undefined) {
        if (values[key] === '') newValues[key] = null
        else newValues[key] = values[key]
      }
    }

    return newValues
  }

  const handleUpdate = async rawValues => {
    const values = { ...rawValues }
    let isMutationSuccess = false
    const specialties = values.specialties

    delete values.specialties
    setLoading(true)

    if (values.password)
      await bcrypt
      .hash(values.password, 10)
      .then(result => (values.password = result))

    await updateUser({
      variables: {
        _set: {
          ...removeUndefinedValuesAndEmptyStringToNull(values),
        },
        where: {
          id: {
            _eq: userId,
          },
        },
      },
    }).then(
      () => {
        isMutationSuccess = true
      },
      changeError => {
        message.error(changeError.message)
      },
    )

    await updateDoctor({
      variables: {
        _set: {
          specialties: JSON.stringify(specialties),
        },
        where: {
          user_id: {
            _eq: userId,
          },
        },
      },
    }).then(
      () => {
      },
      changeError => {
        isMutationSuccess = false
        message.error(changeError.message)
      },
    )

    if (isMutationSuccess) {
      refetch()
      message.success(t('account_settings.update_success'))
    }

    setIsEdit(false)
    setLoading(false)
    setIsValuesChanged(false)
  }

  const getValue = item => {
    if (item.name === 'password') return '***********'
    if (!item.value) return t('crud.n_a')

    if (item.name === 'specialties') {
      if (!item.value.length) return t('crud.n_a')

      const specialties = item.value
      let specialtiesText = ''

      specialties.forEach((specialty, index) => {
        if (index === 0) specialtiesText += startCase(specialty)
        else specialtiesText += `, ${startCase(specialty)}`
      })

      return specialtiesText
    } else return item.value
  }

  const getFormItem = item => {
    return (
      <Form.Item
        name={item.name}
        className="field-input mb-0"
        style={{ marginTop: -8 }}
        initialValue={item.value}
        rules={item.rules ? item.rules : []}
      >
        {getFormField(item)}
      </Form.Item>
    )
  }

  const getFormField = item => {
    if (item.type === 'input') return <Input className="input-component"/>
    else if (item.type === 'password')
      return (
        <Input.Password
          className="input-component"
          iconRender={visible =>
            visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
          }
        />
      )
    else if (item.type === 'select')
      return (
        <Select
          tagRender={tagRender}
          mode="multiple"
          block
          suffixIcon={<DownOutlined/>}
          showArrow
          bordered={false}
          options={item.options}
        />
      )
  }

  const tagRender = subProps => {
    const { label, closable, onClose } = subProps

    return (
      <Tag className="specialty-tag" closable={closable} onClose={onClose}>
        {label}
      </Tag>
    )
  }

  return (
    <div className="bg-white h-full noto p-26 font-600">
      <div className="font-700 mb-28">{t('account_settings.account')}</div>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <div className="profile-photo-container">
            <div className="profile-title">
              {t('account_settings.profile_photo')}
            </div>
            <Avatar
              className="profile-photo"
              size={162}
              icon={<UserOutlined/>}
              shape="circle"
              src={account.avatar_link || null}
            />
            <Row className="profile-action-buttons">
              <Col span={4}/>
              <Col span={8}>
                <Upload {...uploadProps}>
                  <Button type="link" className="upload-photo font-700">
                    {t('account_settings.upload_photo')}
                  </Button>
                </Upload>
              </Col>
              <Col span={8}>
                <Button
                  type="link"
                  className="remove-photo font-700"
                  onClick={() => {
                    if (!account.avatar_link) return

                    ConfirmModal({
                      mtitle: t('account_settings.delete_profile'),
                      mcontent: t('confirm.cannot_undo'),
                      monOk: () => {
                        saveAccountField('avatar_link', null)
                      },
                      t,
                    })
                  }}
                >
                  {t('account_settings.remove_photo')}
                </Button>
              </Col>
              <Col span={4}/>
            </Row>
          </div>
        </Col>
        <Col span={14}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleUpdate}
            onValuesChange={() => setIsValuesChanged(true)}
          >
            <Spin spinning={loading} size="medium">
              {Settings()}
              <Row>
                <Col span={8}/>
                <Col span={14}>
                  <div className="form-action-buttons">
                    {!isEdit && (
                      <BlockButton
                        className="edit-button"
                        onClick={() => setIsEdit(true)}
                        title={t('crud.edit_details')}
                      />
                    )}

                    {isEdit && (
                      <>
                        <BlockButton
                          htmlType="submit"
                          disabled={!isValuesChanged}
                          className="save-button font-700"
                          title={t('crud.save_changes')}
                        />
                        <Button
                          type="link"
                          className="cancel-button font-700"
                          onClick={() => {
                            setIsEdit(false)
                            setIsValuesChanged(false)
                          }}
                        >
                          {t('common.cancel')}
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Spin>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
