import gql from 'graphql-tag'

export const UPDATE_APPOINTMENTS = gql`
	mutation UPDATE_APPOINTMENTS(
		$_set: appointments_set_input!
		$where: appointments_bool_exp!
	) {
		update_appointments(where: $where, _set: $_set) {
			affected_rows
		}
	}
`

export const UPDATE_DOCTOR = gql`
	mutation UPDATE_DOCTOR(
		$users_set: users_set_input!
		$users_where: users_bool_exp!
		$doctors_set: doctors_set_input!
		$doctors_where: doctors_bool_exp!
	) {
		update_doctors(where: $doctors_where, _set: $doctors_set) {
			returning {
				specialties
				id
			}
			affected_rows
		}
		update_users(where: $users_where, _set: $users_set) {
			returning {
				email
				first_name
				last_name
				password
				telephone
				address
			}
			affected_rows
		}
	}
`

export const DELETE_DOCTOR = gql`
	mutation DELETE_DOCTOR($id: uuid!) {
		update_users(
			where: { doctors: { id: { _eq: $id } } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_appointments(
			where: { doctor_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_baby_checkups(
			where: { doctor_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_baby_immunizations(
			where: { doctor_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_course_of_pregnancies(
			where: { doctor_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_prenatal_tests(
			where: { doctor_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_record_of_deliveries(
			where: { doctor_id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_doctors(where: { id: { _eq: $id } }, _set: { deleted_at: "now()" }) {
			affected_rows
		}
		update_messages(
			where: {
				_or: [
					{ userBySenderId: { doctors: { id: { _eq: $id } } } }
					{ user: { doctors: { id: { _eq: $id } } } }
				]
			}
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
	}
`

export const DELETE_PATIENT = gql`
	mutation DELETE_PATIENT(
		$users_set: users_set_input!
		$users_where: users_bool_exp!
		$patients_set: patients_set_input!
		$patients_where: patients_bool_exp!
	) {
		update_users(where: $users_where, _set: $users_set) {
			affected_rows
		}
		update_patients(where: $w, _set: $patients_set) {
			affected_rows
		}
	}
`
