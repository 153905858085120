import React from 'react'
import { Row, Col } from 'antd'
import Filter from './filter'
import UserConversations from './user-conversations'
import '../../assets/css/messages/chat.css'
import '../../assets/css/dashboard/tablelist.css'
import { useTranslation } from 'react-i18next'
import CommonSearch from '../../components/common/search'

const List = props => {
  const { t } = useTranslation()

  const {
    currentUser,
    setCurrentUser,
    users,
    setSearchFilter,
    messageFilter,
    setMessageFilter,
    onScroll,
    loading
  } = props

  return (
    <>
      <Row style={{ marginTop: 23 }}>
        <Col className="chat-list-status">
          {messageFilter ? t(`messages.${messageFilter}`) : t('messages.index')}
        </Col>
        <Col style={{ marginRight: 28, marginTop: -3 }}>
          <Filter setFilter={setMessageFilter}/>
        </Col>
      </Row>
      <CommonSearch
        isTransparent={true}
        onSearch={setSearchFilter}
        style={{ width: '88%', margin: 28 }}
        placeholder={`${t('messages.search')}...`}
      />
      <UserConversations
        users={users}
        loading={loading}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        onScroll={onScroll}
      />
    </>
  )
}

export default List
