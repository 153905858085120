import moment from 'moment'

import { uniqBy } from 'lodash'

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformAppointmentClients = (data, t, getName) => {
	let upcoming = []

	if (data) {
		upcoming = data
			.filter(record => {
				return moment().diff(moment(record.datetime), 'minutes') < 30
			})
			.map(record => {
				const week = record.patient.pregnancies[0]
					? moment().diff(record.patient.pregnancies[0].start_date, 'weeks') + 1
					: null

				return {
					key: record.patient.id,
					user_id: record.patient.user.id,
					item1: getName(
						record.patient.user.first_name,
						record.patient.user.last_name,
					),
					item2: `${
						week
							? `${week} ${week > 1 ? t('common.weeks') : t('common.week')}`
							: t('crud.n_a')
					}`,
				}
			})
	}

	upcoming = uniqBy(upcoming, 'key')

	return upcoming
}

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformAllPatients = (data, t, getName) => {
	let patients = []

	if (data) {
		patients = data.map(record => {
			const week = record.pregnancies.start_date
				? moment().diff(record.pregnancies.start_date, 'weeks') + 1
				: null

			return {
				key: record.id,
				user_id: record.user.id,
				item1: getName(record.user.first_name, record.user.last_name),
				item2: `${
					week
						? `${week} ${week > 1 ? t('common.weeks') : t('common.week')}`
						: t('crud.n_a')
				}`,
			}
		})
	}

	return patients
}

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformCancelledAppointments = (data, t, getName) => {
	let previous = []

	if (data) {
		previous = data.map(record => {
			return {
				key: record.id,
				item1: t(`appointments.${record.name}`),
				item2: getName(
					record.patient.user.first_name,
					record.patient.user.last_name,
				),
			}
		})
	}

	return previous
}

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformAppointments = (data, getName) => {
	let appointments = []

	if (data) {
		appointments = data.map(record => {
			const user_id = ((record.patient || {}).user || {}).id || ''
			const first = ((record.patient || {}).user || {}).first_name || ''
			const last = ((record.patient || {}).user || {}).last_name || ''

			return {
				key: record.id,
				name: getName(first, last),
				desc: record.name,
				datetime: record.datetime,
				doctor_id: ((record.doctor || {}).user || {}).id || null,
				user_id: user_id || null,
			}
		})
	}

	return appointments
}

//from raw data, we will format it to a dataset that can be displayed in a table
export const transformDoctors = (data, getName) => {
	let doctors = []

	if (data) {
		doctors = [...data].map(record => ({
			key: record.id,
			user_id: record.user.id,
			item1: getName(record.user.first_name, record.user.last_name),
			item2: JSON.parse(record.specialties).join(', '),
		}))
	}

	return doctors
}
