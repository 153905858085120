import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Form, message, DatePicker, Spin, Divider, Select, Input } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useMutation } from '@apollo/react-hooks'
import {
	ADD_PRENATAL_TESTS,
	UPDATE_PRENATAL_TESTS,
} from '../../../../graphql/prenatal-tests/mutations'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'

const { Option } = Select
const { BlockButton } = commonComponents

const AddEditPrenatalTestRecord = props => {
	const { t, i18n } = useTranslation()

	const {
		form,
		record,
		doctor,
		refetch,
		visible,
		pregnancy,
		hidePanel,
		latestRecord = {},
	} = props

	const { pregnancy_id } = useParams()
	const [isLoading, setLoading] = useState(false)
	const [addPrenatalTest] = useMutation(ADD_PRENATAL_TESTS)
	const [updatePrenatalTest] = useMutation(UPDATE_PRENATAL_TESTS)

	useEffect(() => {
		form.resetFields()
	}, [visible])

	// removes undefined, updates '' to null, formats datetime
	const transformValues = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else {
					if (key === 'date')
						newValues[key] = moment(values[key]).format('YYYY-MM-DD')
					else newValues[key] = values[key]
				}
			}
		}

		return newValues
	}

	const handleUpdate = values => {
		setLoading(true)

		if (record)
			updatePrenatalTest({
				variables: {
					_set: {
						...transformValues(values),
					},
					where: {
						id: {
							_eq: record.id,
						},
					},
				},
			}).then(
				() => {
					refetch()
					hidePanel()
					setLoading(false)
					form.resetFields()
					message.success(t('prenatal_tests.success_update'))
				},
				changeError => {
					setLoading(false)
					message.error(changeError.message)
				},
			)
		else
			addPrenatalTest({
				variables: {
					objects: {
						doctor_id: doctor.id || null,
						pregnancy_id,
						...transformValues(values),
					},
				},
			}).then(
				() => {
					refetch()
					hidePanel()
					setLoading(false)
					form.resetFields()
					message.success(t('prenatal_tests.success_add'))
				},
				changeError => {
					setLoading(false)
					message.error(changeError.message)
				},
			)
	}

	const getTestFormItems = () => {
		const tests = [
			{
				name: 'irregular_antibody',
				label: t('prenatal_tests.irregular_antibody'),
			},
			{
				name: 'syphilis',
				label: t('prenatal_tests.syphilis'),
			},
			{
				name: 'hcv',
				label: t('prenatal_tests.hcv'),
			},
			{
				name: 'htlv_1',
				label: t('prenatal_tests.htlv_1'),
			},
			{
				name: 'group_b_strep',
				label: t('prenatal_tests.group_b_strep'),
			},
			{
				name: 'cervical_cancer',
				label: t('prenatal_tests.cervical_cancer'),
			},
			{
				name: 'hbv',
				label: t('prenatal_tests.hbv'),
			},
			{
				name: 'rubella_virus',
				label: t('prenatal_tests.rubella_virus'),
			},
			{
				name: 'chlamydia',
				label: t('prenatal_tests.chlamydia'),
			},
		]

		return tests.map(test => (
			<Form.Item
				name={test.name}
				label={test.label}
				className='field-input'
				initialValue={
					record && record[test.name] !== null ? record[test.name] : undefined
				}
			>
				<Select
					block
					showArrow
					bordered={false}
					autoComplete='off'
					style={{ width: '100%' }}
					suffixIcon={<DownOutlined />}
					placeholder={t('prenatal_tests.eg_test')}
					defaultValue={
						!record && latestRecord[test.name] !== null
							? latestRecord[test.name]
							: undefined
					}
				>
					<Option value={true}>{t('prenatal_tests.tested')}</Option>
					<Option value={false}>{t('prenatal_tests.not_tested')}</Option>
				</Select>
			</Form.Item>
		))
	}

	return (
		<Form form={form} layout='vertical' onFinish={handleUpdate}>
			<div className='drawer-title'>
				{!record && t('prenatal_tests.add_prenatal_test')}
				{record && t('prenatal_tests.edit_prenatal_test')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				<Form.Item
					name='date'
					label={t('prenatal_tests.checkup_date')}
					className='field-input'
					initialValue={record ? moment(record.date) : undefined}
					rules={[
						{
							required: true,
							message: t('dialog.required_field'),
						},
					]}
				>
					<DatePicker
						allowClear={false}
						format={getDateFormat(i18n)}
						style={{ width: '100%' }}
						className='input-component'
						placeholder={t('crud.select_date')}
						autoComplete='off'
						disabledDate={current => {
							if (current.diff(moment(pregnancy.start_date)) > 0) return false

							return true
						}}
					/>
				</Form.Item>
				{getTestFormItems()}
				<Form.Item
					name='notes'
					label={t('prenatal_tests.notes')}
					className='field-input'
					initialValue={record ? record.notes : undefined}
				>
					<Input
						autoComplete='off'
						placeholder={t('prenatal_tests.notes')}
						style={{ width: '100%' }}
						className='input-component'
					/>
				</Form.Item>
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditPrenatalTestRecord
