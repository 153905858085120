export const getName = (first_name, last_name, lang) => {
  let name = ''

  switch (lang) {
    case 'en':
      name = `${first_name} ${last_name}`
      break
    case 'ja':
      name = `${last_name} ${first_name}`
      break
    case 'fr':
      name =  `${first_name} ${last_name}`
    default:
      break
  }
  return name
}