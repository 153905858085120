import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../../layouts'
import { default as DashPage } from './dashboard'
import { default as DashDoctorPage } from './doctor-dashboard'
import { useTranslation } from 'react-i18next'

export const DashboardPage = props => {
	const { t } = useTranslation()
	const {
		stateSetters: { setNavCont },
	} = useContext(HeaderContext)

	useEffect(() => {
		setNavCont({
			defaultKey: ['1'],
			hideSideBar: false,
			headerTitle: t('common.dashboard'),
			hideHeader: false,
		})
	}, [])

	return <DashPage />
}

export const DashboardDoctorPage = props => {
	const { t } = useTranslation()
	const {
		stateSetters: { setNavCont },
		states,
	} = useContext(HeaderContext)
	const { userId } = states.headerNavCont

	useEffect(() => {
		setNavCont({
			defaultKey: ['1'],
			hideSideBar: false,
			headerTitle: t('common.dashboard'),
			hideHeader: false,
		})
	}, [])

	return <DashDoctorPage />
}
