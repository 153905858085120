import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { Row, Col, Divider, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import '../../assets/css/patients/patients.css'
import { pregnancyComponents } from '../../components'
import { GET_BABIES } from '../../graphql/babies/queries'
import { GET_PREGNANCIES } from '../../graphql/pregnancies/queries'
import { useTranslation } from 'react-i18next'
import { GET_DOCTOR_BY_USER_ID } from '../../graphql/doctors/queries'
import { HeaderContext } from '../../layouts'
import moment from 'moment'
import { getName } from '../../components/common/get-name'

const { PregnancyDetails, PregnancyRecords, BabyRecords } = pregnancyComponents

const PatientPregnancyPage = () => {
	const { t, i18n } = useTranslation()
	const { pregnancy_id } = useParams()

	const { states } = useContext(HeaderContext)
	const { userId } = states.headerNavCont

	const [babies, setBabies] = useState({})
	const [doctor, setDoctor] = useState({})
	const [pregnancy, setPregnancy] = useState({
		patient: {
			id: '',
			user: {
				first_name: '',
				last_name: '',
			},
		},
	})

	const { data: babyData, refetch: babyRefetch } = useQuery(GET_BABIES, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				pregnancy_id: {
					_eq: pregnancy_id,
				},
			},
		},
	})

	const { data: pregnancyData, refetch: pregnancyRefetch } = useQuery(
		GET_PREGNANCIES,
		{
			fetchPolicy: 'network-only',
			variables: {
				where: {
					deleted_at: {
						_is_null: true,
					},
					id: {
						_eq: pregnancy_id,
					},
				},
			},
		},
	)

	const { data: doctorData } = useQuery(GET_DOCTOR_BY_USER_ID, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: { _is_null: true },
				user_id: {
					_eq: userId,
				},
			},
		},
	})

	useEffect(() => {
		if (!babyData) return

		setBabies(babyData.babies || [])
	}, [babyData])

	useEffect(() => {
		if (!pregnancyData) return

		setPregnancy((pregnancyData.pregnancies || [{}])[0] || {})
	}, [pregnancyData])

	useEffect(() => {
		if (!doctorData) return

		setDoctor((doctorData.doctors || [{}])[0] || {})
	}, [doctorData])

	const getPregnancyBreadcrumb = () => {
		if (!pregnancy.id) return ''

		const pregnancies = pregnancy.patient.pregnancies
		const orderReference = {
			1: 'common.first',
			2: 'common.second',
			3: 'common.third',
		}

		for (let i = 0; i < pregnancies.length; i++) {
			const pregnancy = pregnancies[i]

			if (
				i === pregnancies.length - 1 &&
				pregnancy.id === pregnancy_id &&
				moment().isAfter(moment(pregnancy.start_date)) &&
				moment().isBefore(moment(pregnancy.due_date))
			)
				return t('pregnancies.current_pregnancy')
			else if (pregnancy.id === pregnancy_id) {
				if (i + 1 > 3)
					return `${i + 1}${t('common.th')} ${t('pregnancies.pregnancy')}`
				else return `${t(orderReference[i + 1])} ${t('pregnancies.pregnancy')}`
			}
		}
	}

	return (
		<>
			<Breadcrumb separator='>'>
				<Breadcrumb.Item>
					<Link to='/patients' className='patient-header-hightlight'>
						{t('patients.index')}
					</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					<Link
						to={`/patients/profile/${pregnancy.patient.id}`}
						className='patient-header-hightlight'
					>
						{getName(
							pregnancy.patient.user.first_name,
							pregnancy.patient.user.last_name,
							i18n.language,
						)}
					</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					<span className='patient-header-title'>
						{getPregnancyBreadcrumb()}
					</span>
				</Breadcrumb.Item>
			</Breadcrumb>
			<Divider
				style={{
					backgroundColor: 'rgba(151, 151, 151, 0.5)',
					marginTop: '14px',
					marginBottom: '22px',
				}}
			/>
			<Row gutter={[16, 16]}>
				<Col 
					 xs={24}
					 sm={24}
					 md={babies.length ? 14 : 24}
				>
					<div
						className='prenancy-record-container'
						style={{
							background: '#ffffff',
							minHeight: '100vh',
							height: '100%',
							padding: 20,
						}}
					>
						<PregnancyDetails
							baby={{}}
							pregnancy={pregnancy}
							pregnancyRefetch={pregnancyRefetch}
						/>
						<Divider dashed />
						<PregnancyRecords
							doctor={doctor}
							babies={babies}
							babyRefetch={babyRefetch}
							pregnancy={pregnancy}
						/>
					</div>
				</Col>
				{babies.length !== 0 && (
					<Col 
						 xs={24}
						 sm={24}
						 md={10}
					>
						<div
							style={{
								background: '#ffffff',
								minHeight: '100vh',
								height: '100%',
								padding: 20,
							}}
						>
							<BabyRecords doctor={doctor} babies={babies} />
						</div>
					</Col>
				)}
			</Row>
		</>
	)
}
export default PatientPregnancyPage
