import React, { useContext } from 'react'
import { List, Avatar, Typography, Row, Col, Badge } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import { HeaderContext } from '../../layouts'
import { getName } from '../common/get-name'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'

const { Text } = Typography

const UserConversations = props => {
  const { i18n, t } = useTranslation()
  const { users, currentUser, setCurrentUser, onScroll, loading } = props
  const {
    states: {
      headerNavCont: { userId },
    },
  } = useContext(HeaderContext)

  const getHumanizeTime = rawTime => {
    if (!rawTime) return ''

    const time = moment(rawTime)
    const days = moment().diff(time, 'days')

    if (time.isAfter(moment().startOf('day'))) return time.format('HH:mm')
    if (days < 7) return time.format('ddd')

    return getTimeFormat(time)
  }

  const getTimeFormat = time => {
    let format = ''

    if (i18n.language === 'en') format = 'MM/DD/YY'
    else format = 'YYYY/MM/DD'

    return time.format(format)
  }

  const ConversationAvatar = props => (
    <Avatar
      size={50}
      style={{ resizeMode: 'stretch' }}
      icon={<UserOutlined/>}
      src={props.src}
    />
  )

  return (
    <div onScroll={onScroll} className="absolute w-full"
         style={{ top: '136px', bottom: 0, overflow: 'auto', minHeight: '500px'}}>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={users}
        style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%'}}
        locale={{ emptyText: t('messages.err_search_not_found') }}
        renderItem={user => {
          const isCurrent = currentUser.id && user.id === currentUser.id

          return (
            <List.Item
              key={user.id}
              className={`pointer ${isCurrent ? 'bg-gray' : 'bg-transparent'}`}
              onClick={() => setCurrentUser(user)}
            >
              <List.Item.Meta
                style={{ marginLeft: 28, marginRight: 10 }}
                avatar={
                  <>
                    {user.role === 'doctor' && (
                      <Badge
                        dot
                        offset={[-6, 43]}
                        className="active-badge-10"
                        color={user.is_available ? '#07B2BD' : '#858585'}
                      >
                        <ConversationAvatar src={user.avatar_link}/>
                      </Badge>
                    )}
                    {user.role !== 'doctor' && (
                      <ConversationAvatar src={user.avatar_link}/>
                    )}
                  </>
                }
                title={
                  <Text
                    ellipsis
                    strong={!user.is_seen}
                    className="whitespace-no-wrap w-full text-black"
                    style={{
                      marginBottom: !user.id ? '-14px' : '0',
                    }}
                  >
                    {getName(user.first_name, user.last_name, i18n.language)}
                  </Text>
                }
                description={
                  <Row>
                    <Col span={14}>
                      <Text
                        ellipsis
                        strong={!user.is_seen}
                        className={`noto text-12 ${
                          user.is_seen ? 'text-gray' : 'text-black'
                        } w-full`}
                      >
                        {user.text || ''}
                      </Text>
                    </Col>
                    <Col span={10}>
                      <Text
                        ellipsis
                        strong={!user.is_seen}
                        className={`noto text-12 ${
                          user.is_seen ? 'text-gray' : 'text-black'
                        } w-full text-right`}
                      >
                        {getHumanizeTime(user.created_at)}
                      </Text>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )
        }}
      />
    </div>
  )
}

export default UserConversations
