import React, { useState, useContext, useEffect } from 'react'
import HeaderContext from './main-layout-context'
import { Layout, Dropdown, Avatar, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { GET_USER_HEADER } from '../../graphql/users/queries'
import { useSubscription } from '@apollo/react-hooks'

const { Header } = Layout

const MainHeader = props => {
	const [user, setUser] = useState({})

	const {
		states: {
			headerNavCont: { userId, userType },
		},
	} = useContext(HeaderContext)

	const { data } = useSubscription(GET_USER_HEADER, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				id: {
					_eq: userId,
				},
			},
		},
	})

	useEffect(() => {
		if (data) setUser(data.users[0] || {})
	}, [data])

	return (
		<Header
			style={{
				marginLeft: '4px',
				background: '#fff',
				padding: 0,
				display: props.headerStyle,
			}}
		>
			<Button
				icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
				className={'menuButton'}
				onClick={props.onToggle}
			/>
			<Dropdown overlay={props.menuAsset} className='ant-dropdown-link'>
				<a href='#'>
					<Avatar
						src={user.avatar_link}
						icon={<UserOutlined />}
					/>
					{userType === 'admin' && (
						<span className='text-black' style={{ marginLeft: '8px' }}>
							E-MCH
						</span>
					)}
					{userType === 'doctor' && (
						<span className='text-black' style={{ marginLeft: '8px' }}>{`Dr. ${
							user.first_name || ''
						}`}</span>
					)}
				</a>
			</Dropdown>
		</Header>
	)
}

export default MainHeader
