import React, {
	forwardRef,
	useImperativeHandle,
	useState,
	useEffect,
} from 'react'
import '../../../assets/css/pregnancy/add-pregnancy.css'
import { ADD_PREGNANCY } from '../../../graphql/pregnancies/mutation'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { Modal, Button, DatePicker, message, Form, Row, Col, Spin } from 'antd'
import { UPSERT_COURSE_OF_PREGNANCIES } from '../../../graphql/course-of-pregnancies/mutations'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../utils'

const AddPregnancy = forwardRef((props, ref) => {
	const { t, i18n } = useTranslation()

	const [form] = Form.useForm()
	const [visible, setvisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [startDate, setStartDate] = useState(moment())
	const [endDate, setEndDate] = useState(moment())
	const [upsertCourses] = useMutation(UPSERT_COURSE_OF_PREGNANCIES)

	const [addPregnancy] = useMutation(ADD_PREGNANCY)

	useImperativeHandle(ref, () => {
		return {
			showModal: () => show(),
		}
	})

	const show = () => {
		setvisible(true)
	}

	const hasUndeliveredPregnancy = pregnancies => {
		return (
			pregnancies.length > 0 && pregnancies[0].record_of_deliveries.length === 0
		)
	}

	const handleAdd = () => {
		const pregnancies = props.pregnancies || {}

		if (hasUndeliveredPregnancy(pregnancies)) {
			message.error(t('pregnancies.err_pregnancy_add'))
			return
		}

		setLoading(true)

		addPregnancy({
			variables: {
				objects: [
					{
						patient_id: props.patient,
						start_date: startDate.format('YYYY-MM-DD'),
						due_date: endDate.format('YYYY-MM-DD'),
					},
				],
			},
		}).then(
			addResult => {
				const weeks =
					moment().diff(moment(startDate.format('YYYY-MM-DD')), 'weeks') + 1

				if (endDate.diff(moment(), 'days') >= 0 && weeks <= 45) {
					upsertCourses({
						variables: {
							objects: {
								pregnancy_id: addResult.data.insert_pregnancies.returning[0].id,
								doctor_id: props.doctor,
								week_of_pregnancy: weeks,
							},
						},
					}).then(
						() => {
							setLoading(false)
							message.success(t('pregnancies.success_add'))

							setStartDate(moment())
							setEndDate(moment().add(40, 'weeks'))

							setvisible(false)
							props.refetch()
						},
						changeError => {
							setLoading(false)
							message.error(changeError.message)
						},
					)
				} else {
					setLoading(false)
					message.success(t('pregnancies.success_add'))

					setStartDate(moment())
					setEndDate(moment().add(40, 'weeks'))

					setvisible(false)
					props.refetch()
				}
			},
			addError => {
				setLoading(false)
				message.error(addError.message)
			},
		)
	}

	const handleCancel = () => {
		setvisible(false)
	}

	useEffect(() => {
		setEndDate(moment(startDate).add(40, 'weeks'))
	}, [startDate])

	const disabledDatesForStartDate = current => {
		return current > moment().endOf('day')
	}

	const disabledDatesForEndDate = current => {
		return current <= startDate
	}

	return (
		<Modal
			visible={visible}
			onCancel={handleCancel}
			footer={[
				<Button
					className='add-pregnancy-button-cancel'
					key='back'
					onClick={handleCancel}
				>
					{t('common.cancel')}
				</Button>,
				<Button
					className='add-pregnancy-button-add'
					form='add_pregnancy_form'
					key='submit'
					htmlType='submit'
				>
					{t('crud.add')}
				</Button>,
			]}
		>
			<Row>
				<Col>
					<div className='add-pregnancy-title'>
						{t('pregnancies.add_pregnancy')}
					</div>
					<br />
				</Col>
			</Row>
			<div style={{ marginTop: 40 }}> </div>
			<Spin spinning={loading}>
				<Form
					form={form}
					id='add_pregnancy_form'
					layout='vertical'
					hideRequiredMark
					onFinish={handleAdd}
				>
					<Form.Item
						label={t('pregnancies.pregnancy_start_date')}
						rules={[
							{
								required: true,
								message: t('dialog.required_field'),
							},
						]}
						className='doctor-field-input'
					>
						<DatePicker
							className='input-component'
							style={{ width: '100%' }}
							format={getDateFormat(i18n)}
							allowClear={false}
							value={startDate}
							disabledDate={disabledDatesForStartDate}
							onChange={date => setStartDate(date)}
						/>
					</Form.Item>

					<Form.Item
						label={t('pregnancies.pregnancy_expected_due_date')}
						rules={[
							{
								required: true,
								message: t('dialog.required_field'),
							},
						]}
						className='doctor-field-input'
					>
						<DatePicker
							disabled = {true}
							className='input-component'
							style={{ width: '100%' }}
							format={getDateFormat(i18n)}
							allowClear={false}
							value={endDate}
							disabledDate={disabledDatesForEndDate}
							onChange={date => setEndDate(date)}
						/>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	)
})

export default AddPregnancy
