import gql from 'graphql-tag'

const appointmentEntities = `
	id
	patient {
		id
		user {
			id
			first_name
			last_name
			telephone
			email
			avatar_link
		}
	}
	name
	datetime
`

const appointmentAggregateEntities = `
	aggregate {
		count
	}
`

export const GET_APPOINTMENTS = gql`
	query GET_APPOINTMENTS(
		$active_where: appointments_bool_exp!
		$pending_where: appointments_bool_exp!
		$cancelled_where: appointments_bool_exp!
		$offset1: Int
		$offset2: Int
		$offset3: Int
	) {
		approved: appointments(
			where: $active_where
			order_by: { datetime: asc }
			limit: 10
			offset: $offset1
		) {
			${appointmentEntities}
		}
		approved_count: appointments_aggregate(
			where: $active_where
		) {
			${appointmentAggregateEntities}
		}
		pending: appointments(
			where: $pending_where
			order_by: { datetime: asc }
			limit: 10
			offset: $offset2
		) {
			${appointmentEntities}
		}
		pending_count: appointments_aggregate(
			where: $pending_where
		) {
			${appointmentAggregateEntities}
		}
		cancelled: appointments(
			where: $cancelled_where
			order_by: { datetime: asc }
			limit: 10
			offset: $offset3
		) {
			${appointmentEntities}
		}
		cancelled_count: appointments_aggregate(
			where: $cancelled_where
		) {
			${appointmentAggregateEntities}
		}
	}
`

export const GET_APPOINTMENTS_TODAY_AND_DUPLICATES = gql`
	query GET_APPOINTMENTS_TODAY($where: appointments_bool_exp!) {
		appointments(where: $where) {
			id
			name
			datetime
			patient_id
			is_approved
		}
	}
`

export const GET_APPOINTMENT_DETAILS = gql`
	query GET_APPOINTMENTS($where: appointments_bool_exp!) {
		appointments(where: $where) {
			id
			patient {
				id
				user {
					id
					first_name
					last_name
					avatar_link
				}
			}
			doctor {
				id
				user {
					id
					first_name
					last_name
					avatar_link
				}
			}
			name
			datetime
			location
			notes
			notification
			status
		}
	}
`
