import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Input,
	Row,
	Col,
	Form,
	Select,
	Button,
	Upload,
	Modal,
} from 'antd'
import { capitalize } from 'lodash'
import RichTextEditor from '../../common/rich-text-editor/index'

const EditPost = props => {
	const { t, i18n } = useTranslation()
	const {
		newsfeedFields,
		uploadprops,
		onFinish,
		isEdit,
		form,
		isModalViewVisible,
		handleCloseModal,
		actionSuccess,
		uploadState,
		setUploading,
		closeViewModal,
		setContent,
	} = props
	const { Option } = Select

	const closeModal = () => {
		closeViewModal()
		handleCloseModal()
	}

	const getWeeksOfPregnancy = () => {
		const weeks = []

		for (let i = 1; i <= 40; i++) {
			weeks.push(<Option value={i}>{getWeek(i)}</Option>)
		}
		return weeks
	}

	const getWeek = i => {
		if (i18n.language === 'en') return `${capitalize(t('common.week'))} ${i}`
		else return `${i} ${capitalize(t('common.week'))}`
	}

	const resetUploadState = () => {
		setUploading({
			uploadSrc: null,
			isUploading: false,
		})
	}

	useEffect(() => {
		form.resetFields()
	}, [])

	return (
		<>
			<Modal
				centered
				visible={isModalViewVisible}
				onCancel={closeModal}
				footer={null}
				width={900}
				keyboard={true}
			>
				<Row>
					<div className='post-title'>{t('newsfeeds.update_post')}</div>
				</Row>
				<Form
					form={form}
					layout='vertical'
					hideRequiredMark
					onFinish={onFinish}
				>
					<Form.Item
						name='title'
						label={t('newsfeeds.title')}
						rules={[
							{
								required: true,
								message: t('dialog.required_field'),
							},
						]}
						initialValue={newsfeedFields.title}
						className='doctor-field-input'
					>
						<Input
							className='input-component'
							placeholder={t('newsfeeds.title')}
							defaultValue={newsfeedFields.title}
							disabled={!isEdit}
						/>
					</Form.Item>
					<Form.Item
						name='subtitle'
						label={t('newsfeeds.subtitle')}
						rules={[
							{
								required: true,
								message: t('dialog.required_field'),
							},
						]}
						initialValue={newsfeedFields.subtitle}
						className='doctor-field-input'
					>
						<Input
							className='input-component'
							placeholder={t('newsfeeds.subtitle')}
							defaultValue={newsfeedFields.subtitle}
							disabled={!isEdit}
						/>
					</Form.Item>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item
								name='category'
								label={t('newsfeeds.category')}
								rules={[
									{
										required: true,
										message: t('dialog.required_field'),
									},
								]}
								initialValue={newsfeedFields.category}
								className='doctor-field-input'
							>
								<Select
									placeholder={t('newsfeeds.select_category')}
									block
									showArrow
									defaultValue={newsfeedFields.category}
									bordered={false}
									disabled={!isEdit}
								>
									<option value={'physical_changes'}>
										{t('newsfeeds.physical_changes')}
									</option>
									<option value={'things_to_expect'}>
										{t('newsfeeds.things_to_expect')}
									</option>
									<option value={'medical_tips'}>
										{t('newsfeeds.medical_tips')}
									</option>
									<option value={'baby_development'}>
										{t('newsfeeds.baby_development')}
									</option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name='week_of_pregnancy'
								label={t('newsfeeds.week_pregnancy')}
								rules={[
									{
										required: true,
										message: t('dialog.required_field'),
									},
								]}
								initialValue={newsfeedFields.week_of_pregnancy}
								className='doctor-field-input'
							>
								<Select
									placeholder={t('crud.select_a_week')}
									block
									showArrow
									bordered={false}
									defaultValue={newsfeedFields.week_of_pregnancy}
								>
									{getWeeksOfPregnancy()}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						className='btn-container doctor-field-input-label'
						label={t('account_settings.upload_photo')}
					>
						<Upload
							{...uploadprops}
							customRequest={actionSuccess}
							onPreview={file => {
								var image = new Image()
								image.src = props.image
								var w = window.open('')
								w.document.write(image.outerHTML)
							}}
							showUploadList={false}
						>
							<Button className='block-button' disabled={!isEdit}>
								{t('crud.upload')}
							</Button>
						</Upload>
						{uploadState.uploadSrc !== null && (
							<Button
								type='link'
								style={{ color: '#e5526f' }}
								onClick={() => {
									if (uploadState.uploadSrc === null) return
									resetUploadState()
								}}
							>
								{t('account_settings.remove_photo')}
							</Button>
						)}
					</Form.Item>
					<Form.Item
						name='content'
						label={t('newsfeeds.content')}
						rules={[
							{
								required: true,
								message: t('dialog.required_field'),
							},
						]}
						initialValue={newsfeedFields.content}
					>
						<RichTextEditor
							setContent={setContent}
							newsfeedContent={newsfeedFields.content}
						/>
					</Form.Item>
					<div style={{ marginTop: 70 }}> </div>
					<Form.Item className='btn-container'>
						<Button
							className='block-button view-post-button'
							style={{ width: '20%'}}
							hidden={!isEdit}
							htmlType='submit'
						>
							{t('newsfeeds.update_post')}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default EditPost
