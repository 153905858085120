import React, { useContext, useState, useEffect } from 'react'
import {
	Row,
	Col,
	Button,
	Input,
	Form,
	message,
	DatePicker,
	TimePicker,
	Spin,
	Avatar
} from 'antd'
import {
	ClockCircleOutlined,
	FormOutlined,
	BellOutlined,
	EnvironmentOutlined,
} from '@ant-design/icons'
import '../../assets/css/dashboard/appointment.css'
import { commonComponents } from '../common'
import { HeaderContext } from '../../layouts'
import { GET_APPOINTMENT_DETAILS } from '../../graphql/appointments/queries'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import { UPDATE_APPOINTMENTS } from '../../graphql/appointments/mutation'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../utils'
import { getName } from '../common/get-name'
import { UserOutlined } from '@ant-design/icons'

const { BlockButton } = commonComponents

const ViewEditAppointment = props => {
	const { t, i18n } = useTranslation()

	const {
		form = null,
		itemId = '',
		refetch = null,
		hidePanel = () => {},
		onCancelAppointment = null,
		showStatus = false,
		mode = ''
	} = props
	const { states } = useContext(HeaderContext)
	const { userType } = states.headerNavCont
	const [isEdit, setIsEdit] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [pageData, setPageData] = useState({
		schedule: null,
		location: null,
		notification: null,
		notes: null,
		appointment: null,
		doctor: {
			name: null,
			avatar: null,
		},
		patient: {
			name: null,
			avatar: null,
		},
		status: null,
	})

	const { data, refetch: refetchDetails } = useQuery(GET_APPOINTMENT_DETAILS, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				id: { _eq: itemId },
			},
		},
	})

	const [updateAppointment] = useMutation(UPDATE_APPOINTMENTS)

	const getSchedule = datetime => {
		let format = ''

		if (i18n.language === 'en') format = `${getDateFormat(i18n)} HH:mm`
		else format = `${getDateFormat(i18n)} dddd, HH:mm`

		return moment(datetime).format(format)
	}

	useEffect(() => {
		if (data) {
			setPageData({
				...pageData,
				schedule: getSchedule(data.appointments[0].datetime),
				datetime: moment(data.appointments[0].datetime),
				location: data.appointments[0].location,
				notification: data.appointments[0].notification,
				notes: data.appointments[0].notes,
				appointment: t(`appointments.${data.appointments[0].name}`),
				doctor: {
					name:
						`Dr. ` +
						getName(
							data.appointments[0].doctor.user.first_name,
							data.appointments[0].doctor.user.last_name,
							i18n.language,
						),
					avatar:
						data.appointments[0].doctor.user.avatar_link,
				},
				patient: {
					name: getName(
						data.appointments[0].patient.user.first_name,
						data.appointments[0].patient.user.last_name,
						i18n.language,
					),
					avatar:
						data.appointments[0].patient.user.avatar_link,
				},
				status: data.appointments[0].status,
			})

			if (form){
				console.log('date: ', data.appointments[0].datetime)
				console.log('time: ', data.appointments[0].datetime)
				form.setFieldsValue({
					date: moment(data.appointments[0].datetime),
					time: moment(data.appointments[0].datetime),
					location: data.appointments[0].location,
					notification: data.appointments[0].notification,
					notes: data.appointments[0].notes,
				})
			}
				
		}
	}, [data])

	const isCancelled = pageData.status === 'cancelled' || pageData.status === 'declined' ? true : false

	const details = [
		{
			icon: (
				<ClockCircleOutlined style={{ fontSize: '25px', color: '#07B2BD' }} />
			),
			name: 'datetime',
			title: t('appointments.schedule'),
			desc: pageData.schedule,
		},
		{
			icon: (
				<EnvironmentOutlined style={{ fontSize: '25px', color: '#07B2BD' }} />
			),
			name: 'location',
			title: t('appointments.location'),
			desc: pageData.location,
		},
		{
			icon: <BellOutlined style={{ fontSize: '25px', color: '#07B2BD' }} />,
			name: 'notification',
			title: t('appointments.notify_me'),
			desc: pageData.notification,
		},
		{
			icon: <FormOutlined style={{ fontSize: '25px', color: '#07B2BD' }} />,
			name: 'notes',
			title: t('crud.notes'),
			desc: pageData.notes,
		},
	]

	const attendees = [
		{
			name: pageData.doctor.name,
			image: pageData.doctor.avatar,
		},
		{
			name: pageData.patient.name,
			image: pageData.patient.avatar,
		},
	]

	const cancelAppointment = () => {
		refetch()
		hidePanel()
		onCancelAppointment()
	}

	const handleUpdate = values => {
		setLoading(true)
		const newValues = { ...values }

		newValues.datetime = moment(
			`${newValues.date.format('YYYY-MM-DD')} ${newValues.time.format(
				'HH:mm',
			)}`,
		).toISOString()

		delete newValues.date
		delete newValues.time

		updateAppointment({
			variables: {
				_set: {
					...newValues,
				},
				where: {
					id: {
						_eq: itemId,
					},
				},
			},
		}).then(
			() => {
				refetch()
				refetchDetails()
				setLoading(false)
				message.success(t('appointments.success_update'))
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const addEditRemoveButtons = () => {
		if (userType === 'doctor' && refetch && onCancelAppointment) {
			const isDisabled =
				pageData.datetime !== null &&
				pageData.datetime !== undefined &&
				!moment(pageData.datetime).isAfter(moment().add(10, 'minute'))

			return (
				<Row style={{ marginTop: 40 }}>
					<Col span={12}>
						<BlockButton
							className='edit-btn'
							title={`${isEdit ? t('crud.save') : t('crud.edit_details')}`}
							onClick={() => setIsEdit(!isEdit)}
							htmlType={isEdit ? 'button' : 'submit'}
							disabled={isDisabled}
						/>
					</Col>
					<Col span={12}>
						<Button
							block
							className='transparent-cancel-btn'
							onClick={cancelAppointment}
							disabled={isDisabled}
						>
							{t('appointments.cancel_appointment')}
						</Button>
					</Col>
				</Row>
			)
		}
	}

	const FormInput = name => {
		if (name === 'datetime')
			return (
				<>
					<Form.Item
						name='date'
						className='mb-0 inline-block doctor-field-input'
						style={{ width: '55%', paddingRight: '12px' }}
					>
						<DatePicker
							className='input-component w-full'
							format={getDateFormat(i18n)}
							allowClear={false}
							disabled
						/>
					</Form.Item>
					<Form.Item
						name='time'
						className='mb-0 inline-block doctor-field-input'
						style={{ width: '40%' }}
					>
						<TimePicker
							className='input-component w-full'
							format='HH:mm'
							allowClear={false}
							disabled
						/>
					</Form.Item>
				</>
			)
		else return <Input className='input-component' disabled = {name === 'notification'} />
	}

	const getStatus = ({ datetime, status }) => {
		if (!status) return ''

		if (status !== 'active' && status !== 'done')
			return t(`appointments.${status}`)

		if (moment(datetime).isAfter(moment())) return t('appointments.upcoming')
		else if (
			moment(datetime).isBefore(
				moment(moment().subtract(30, 'minutes').format()),
			)
		)
			return t('appointments.done')

		return t('appointments.ongoing')
	}

	return (
		<Form form={form} layout='vertical' onFinish={handleUpdate}>
			<Row>
				<Col>
					{showStatus && (
						<>
							<span style={isCancelled ? { color: 'red' } : {}}>
								{getStatus(pageData)}
							</span>
							<br />
						</>
					)}
					<div className='appointment-view-title'>
						{t('appointments.appointment')}
					</div>
					<br />
					<div className='appointment-view-desc'> {pageData.appointment} </div>
					<br />
					<br />
				</Col>
			</Row>
			<div style={{ marginTop: 40 }}> </div>
			<Spin spinning={isLoading} size='medium' tip={`${t('crud.updating')}...`}>
				{details.map(item => (
					<Row style={{ marginTop: 30 }}>
						<Col style={{ marginTop: 8 }}>{item.icon}</Col>
						<Col style={{ marginLeft: 25 }}>
							<div className='appointment-view-item-title'> {item.title}</div>{' '}
							<br />
							<div
								className={`${
									isEdit ? 'd-none' : ''
								} appointment-view-item-desc`}
							>
								{item.desc}
							</div>
							<div className={isEdit ? '' : 'd-none'}>
								<Form.Item
									name={item.name}
									className={`mb-0 ${
										item.name === 'datetime' ? '' : 'doctor-field-input'
									}`}
								>
									{FormInput(item.name)}
								</Form.Item>
							</div>
						</Col>
					</Row>
				))}
			</Spin>
			<div
				className='appointment-view-attendee-title'
				style={{ marginTop: 170 }}
			>
				{t('appointments.attendees')}
			</div>
			{attendees.map(item => (
				<Row style={{ marginTop: 30 }}>
					<Col>
						<Avatar
							size={55}
							style={{ resizeMode: 'stretch' }}
							icon={<UserOutlined />}
							src={item.image}
						/>
					</Col>
					<Col style={{ marginLeft: 15, marginTop: 7 }}>
						<div className='appointment-view-attendee-name'> {item.name}</div>
					</Col>
				</Row>
			))}
			
			{(mode !== 'cancelled') ? addEditRemoveButtons() : (() => {})()}
		</Form>
	)
}

export default ViewEditAppointment
