import React, {forwardRef, useState, useRef, useImperativeHandle} from "react"
import "../../assets/css/common/sidepanel.css"
import { Drawer } from "antd"

const Sidepanel = forwardRef((props, ref) => {

  const[state, setVisible] = useState({
    visible: false,
    content: (<></>)
  });

  useImperativeHandle(ref, () => ({
    showPanel(newContent){
      setVisible({
        visible: true,
        content: newContent
      })
    }
  }));

  const hidePanel = () => {
    setVisible(oldstate => ({visible: false}))
  }

  function addExtraProps(Component, extraProps) {
    return <Component.type {...Component.props} {...extraProps} />;
  }

  return(
    <Drawer
      className="side-panel"
      closable
      onClose={hidePanel}
      placement="right"
      visible={state.visible}
      width={props.width || 489}
    >
      <div className="content">
        {addExtraProps(state.content || (<></>), {hidePanel})}
      </div>
    </Drawer>
  )
});

export default Sidepanel;
