import React from 'react'
import { getClassName } from './getClassName'

const HeaderButton = props => {
	const onToggle = e => {
		e.preventDefault()
		props.onToggle(props.style)
	}

	let className = getClassName(props)

	switch (props.label) {
		case 'Paragraph':
			className += ' noto font-500 normal text-12'
			break
		case 'Header 1':
			className += ' noto font-700 normal text-18'
			break
		case 'Header 2':
			className += ' noto font-600 normal text-16'
			break
	}

	return (
		<span
			key={props.label}
			className={className}
			onClick={onToggle}
			style={{ marginTop: '5px' }}
		>
			{props.label}
		</span>
	)
}

export default HeaderButton
