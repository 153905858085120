import AquaIcon from './aqua.svg'
import GreyIcon from './grey.svg'
import PinkIcon from './pink.svg'
import BoldIcon from './bold.svg'
import BlackIcon from './black.svg'
import ItalicIcon from './italic.svg'
import UnderlineIcon from './underline.svg'
import StrikeIcon from './strikethrough.svg'
import OrderedListIcon from './ordered-list.svg'
import UnorderedListIcon from './unordered-list.svg'

export const editorIcons = {
	AquaIcon,
	GreyIcon,
	PinkIcon,
	BoldIcon,
	BlackIcon,
	ItalicIcon,
	StrikeIcon,
	UnderlineIcon,
	OrderedListIcon,
	UnorderedListIcon,
}
