import ReactDOM from 'react-dom';
import React, { useState, useContext, useEffect } from 'react'
import '../../assets/css/mainlayout/mainlayout.css'
import HeaderContext from './main-layout-context'
import Navigation from './navigation'
import MainHeader from './main-header'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { ReactComponent as Logo } from '../../emchLogo.svg'
import { ReactComponent as CollapsedLogo } from '../../collapsedMenuIcon.svg'
import AdminHeaderPage from './admin-header.js'
import DoctorHeaderPage from './doctor-header.js'
import '../../assets/css/common/utility.css'
import { useTranslation } from 'react-i18next'
import { setMessagingTokenToServer } from '../../firebase-messaging'

const { Content, Sider } = Layout
const { SubMenu } = Menu

const MainLayout = props => {
	const { t, i18n } = useTranslation()

	const [menuState, toggleCollapse] = useState({
		collapsed: localStorage.getItem('sideMenuToggle') ?? true,
	})
	const {
		states: {
			headerNavCont,
			headerNavCont: { userType, hideSideBar},
		},
		stateSetters: { setNavCont },
	} = useContext(HeaderContext)

	const toggle = () => {
		localStorage.setItem('sideMenuToggle', !menuState.collapsed)
		toggleCollapse({ collapsed: !menuState.collapsed })

		if (menuState.collapsed){
			on()
		} else {
			off()
		}
	}

	const on = () => {
		document.getElementById("overlay").style.display = "block";
	}
	  
	const off = () => {
		document.getElementById("overlay").style.display = "none";
	}

	const logout = () => {
		localStorage.removeItem('token')
		localStorage.removeItem('is_temp_pass')
		localStorage.removeItem('sideMenuToggle')

		setMessagingTokenToServer(null, i18n.language)

		setNavCont({
			employeeId: null,
			userId: null,
			userType: null,
		})
	}

	useEffect(() => {
		if (hideSideBar){
			off()
		} else {
			if (menuState.collapsed){
				off()
			} else {
				on()
			}
		}
	},[props.children])

	const hideElement = headerNavCont.hideSideBar ? 'none' : 'inline'

	const menu = (
		<Menu mode='vertical'>
			{userType === 'doctor' && (
				<Menu.Item key='1'>
					<Link to='/account'> {t('header.account_information')}</Link>
				</Menu.Item>
			)}
			<SubMenu
				key='2'
				title={t('header.languages')}
				className='hide-languages-arrow'
			>
				<Menu.Item
					key='3'
					onClick={() => {
						i18n.changeLanguage('ja')
						window.location.reload(false)
					}}
				>
					日本語
				</Menu.Item>
				<Menu.Item
					key='4'
					onClick={() => {
						i18n.changeLanguage('en')
						window.location.reload(false)
					}}
				>
					English
				</Menu.Item>
				<Menu.Item
					key='5'
					onClick={() => {
						i18n.changeLanguage('fr')
						window.location.reload(false)
					}}
				>
					Français 
				</Menu.Item>
			</SubMenu>
			<Menu.Item key='6'>
				<a target='_blank' onClick={logout}>
					{t('header.logout')}
				</a>
			</Menu.Item>
		</Menu>
	)

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider
				className={'mainSider'}
				trigger={null}
				collapsible
				collapsed={menuState.collapsed}
				theme={'light'}
				style={{ display: hideElement }}
			>
				<div className='logo'>
					{menuState.collapsed ? <CollapsedLogo /> : <Logo />}
				</div>
				<Navigation defaultKey={headerNavCont.defaultKey} />
			</Sider>
			<Layout>
				<div id="overlay" onClick={toggle}/>
				<MainHeader
					collapsed={menuState.collapsed}
					headerStyle={hideElement}
					onToggle={toggle}
					menuAsset={menu}
				/>
				{headerNavCont.hideHeader ? (
					<></>
				) : headerNavCont.userType == 'admin' ? (
					<AdminHeaderPage />
				) : (
					<DoctorHeaderPage />
				)}
				<Content className={'mainContent'}>{props.children}</Content>
			</Layout>
		</Layout>
	)
}
export default MainLayout
