import HealthRecord from './health-records'
import LivingStatusRecord from './living-status-records'
import CourseRecords from './course-records'
import PrenatalTestRecords from './prenatal-test-records'
import DeliveryRecord from './deliver-records'
import PostpartumRecord from './post-partum-records'
import BabyCheckup from './baby-checkup-records'
import Immunization from './baby-immunization-records'

const recordComponents = {
	HealthRecord,
	LivingStatusRecord,
	CourseRecords,
	PrenatalTestRecords,
	DeliveryRecord,
	PostpartumRecord,
	BabyCheckup,
	Immunization,
}

export { recordComponents }
