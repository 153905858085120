import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
	Form,
	message,
	DatePicker,
	Spin,
	Divider,
	Select,
	Input,
	TimePicker,
	InputNumber,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_BABIES } from '../../../../graphql/babies/mutations'
import { UPSERT_DELIVERIES } from '../../../../graphql/deliveries/mutations'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'

const { BlockButton } = commonComponents

const AddEditDeliveryRecord = props => {
	const { t, i18n } = useTranslation()

	const {
		hidePanel,
		visible,
		record,
		form,
		refetch,
		doctor,
		babies,
		babyRefetch,
		pregnancy,
		latestRecord = {},
	} = props

	const { pregnancy_id } = useParams()
	const [isLoading, setLoading] = useState(false)
	const [upsertBabies] = useMutation(UPSERT_BABIES)
	const [upsertDeliveries] = useMutation(UPSERT_DELIVERIES)

	useEffect(() => {
		form.resetFields()
	}, [visible])

	const getCurrentBaby = () => {
		if (!record) return {}

		for (let i = 0; i < babies.length; i++) {
			const baby = babies[i]

			if (
				!(
					record.baby_first_name && baby.first_name !== record.baby_first_name
				) &&
				!(record.baby_last_name && baby.last_name !== record.baby_last_name) &&
				!(record.baby_sex && baby.sex !== record.baby_sex)
			) {
				return baby
			}
		}

		return {}
	}

	const currentBaby = getCurrentBaby()

	const removeUndefinedValuesAndEmptyStringToNull = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else {
					if (key === 'length_of_pregnancy') newValues[key] = values[key] + ''
					else newValues[key] = values[key]
				}
			}
		}

		return newValues
	}

	const handleUpdate = values => {
		setLoading(true)

		const doctor_id = doctor && doctor.id ? { doctor_id: doctor.id } : {}

		upsertDeliveries({
			variables: {
				objects: {
					id: record && record.id ? record.id : undefined,
					...doctor_id,
					pregnancy_id,
					...removeUndefinedValuesAndEmptyStringToNull(values),
				},
			},
		}).then(
			() => {
				refetch()

				upsertBabies({
					variables: {
						objects: {
							id: currentBaby && currentBaby.id ? currentBaby.id : undefined,
							pregnancy_id,
							first_name: values.baby_first_name || '',
							last_name: values.baby_last_name || '',
							sex: values.baby_sex || '',
						},
					},
				}).then(
					() => {
						hidePanel()
						babyRefetch()
						setLoading(false)
						form.resetFields()
						if (record && record.id)
							message.success(t('record_of_deliveries.success_update'))
						else message.success(t('record_of_deliveries.success_add'))
					},
					changeError => {
						setLoading(false)
						message.error(changeError.message)
					},
				)
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const getRecordFormItems = () => {
		const formItems = [
			{
				name: 'length_of_pregnancy',
				label: `${t('record_of_deliveries.pregnancy_length')} (${t(
					'common.weeks',
				)})`,
				type: 'number',
				rules: [
					{
						required: true,
						message: t('dialog.required_field'),
					},
				],
				placeholder: t('record_of_deliveries.length_of_labor_placeholder'),
				maxLength: 5
			},
			{
				name: 'delivery_place',
				label: t('record_of_deliveries.delivery_place'),
				type: 'input',
				placeholder: t('crud.type_here'),
				maxLength: 20
			},
			{
				name: 'delivery_attendants',
				label: t('record_of_deliveries.delivery_attendants'),
				type: 'input',
				placeholder: t('crud.type_here'),
				maxLength: 20
			},
			{
				name: 'delivery_timestamp',
				label: t('record_of_deliveries.delivery_date_time'),
				type: 'datepicker',
				rules: [
					{
						required: true,
						message: t('dialog.required_field'),
					},
				],
				placeholder: t('crud.select_date_time'),
			},
			{
				name: 'delivery_type',
				label: t('record_of_deliveries.delivery_type'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{
						value: 'normal',
						label: t('record_of_deliveries.normal'),
					},
					{ value: 'breech', label: t('record_of_deliveries.breech') },
					{ value: 'other', label: t('record_of_deliveries.other') },
				],
				placeholder: t('record_of_deliveries.eg_delivery_type'),
			},
			{
				name: 'delivery_method',
				label: t('record_of_deliveries.delivery_method'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{
						value: 'natural',
						label: t('record_of_deliveries.natural'),
					},
					{ value: 'assisted', label: t('record_of_deliveries.assisted') },
					{ value: 'cesarian', label: t('record_of_deliveries.cesarian') },
				],
				placeholder: t('record_of_deliveries.eg_delivery_method'),
			},
			{
				name: 'length_of_labor',
				label: t('record_of_deliveries.length_of_labor'),
				type: 'timepicker',
				placeholder: t('record_of_deliveries.enter_length'),
			},
			{
				name: 'amount_of_bleeding',
				label: t('record_of_deliveries.amount_of_bleeding'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{
						value: 'light',
						label: t('record_of_deliveries.light'),
					},
					{ value: 'moderate', label: t('record_of_deliveries.moderate') },
					{ value: 'heavy', label: t('record_of_deliveries.heavy') },
				],
				placeholder: t('record_of_deliveries.eg_amount_of_bleeding'),
			},
			{
				name: 'blood_transfusion',
				label: t('record_of_deliveries.blood_transfusion'),
				type: 'input',
				placeholder: t('crud.type_here'),
				maxLength: 15
			},
			{
				name: 'baby_first_name',
				label: t('record_of_deliveries.baby_first_name'),
				type: 'input',
				rules: [
					{
						required: true,
						message: t('dialog.required_field'),
					},
				],
				placeholder: t('record_of_deliveries.baby_first_name'),
				maxLength: 15
			},
			{
				name: 'baby_last_name',
				label: t('record_of_deliveries.baby_last_name'),
				type: 'input',
				rules: [
					{
						required: true,
						message: t('dialog.required_field'),
					},
				],
				placeholder: t('record_of_deliveries.baby_last_name'),
				maxLength: 15
			},
			{
				name: 'baby_order',
				label: t('record_of_deliveries.baby_order'),
				type: 'select',
				options: [
					{ value: null, label: t('crud.n_a') },
					{ value: 1, label: t('common.first') },
					{ value: 2, label: t('common.second') },
					{ value: 3, label: t('common.third') },
					{ value: 4, label: t('common.fourth') },
					{ value: 5, label: t('common.fifth') },
				],
				placeholder: t('common.eg_order'),
			},
			{
				name: 'baby_sex',
				label: t('record_of_deliveries.baby_sex'),
				type: 'select',
				rules: [
					{
						required: true,
						message: t('dialog.required_field'),
					},
				],
				options: [
					{ value: 'male', label: t('common.male') },
					{ value: 'female', label: t('common.female') },
				],
				placeholder: t('common.eg_sex'),
			},
			{
				name: 'baby_height',
				label: `${t('health.height')} (cm)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'baby_weight',
				label: `${t('health.weight')} (kg)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'baby_head_circumference',
				label: `${t('baby_checkups.head_circumference')} (cm)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'baby_chest_circumference',
				label: `${t('baby_checkups.chest_circumference')} (cm)`,
				type: 'number',
				placeholder: t('crud.type_here'),
			},
			{
				name: 'baby_special_condition',
				label: t('record_of_deliveries.special_conditions'),
				type: 'input',
				placeholder: t('crud.type_here'),
			},
		]

		return formItems.map(formItem => (
			<Form.Item
				name={formItem.name}
				label={formItem.label}
				className='field-input'
				rules={formItem.rules || []}
				initialValue={getInitialValue(formItem)}
			>
				{getFormField(formItem)}
			</Form.Item>
		))
	}

	const getInitialValue = formItem => {
		if (record && record[formItem.name] !== null) {
			if (formItem.type === 'datepicker' || formItem.type === 'timepicker')
				return moment(record[formItem.name])

			return record[formItem.name]
		}

		if (
			isInheritableFormItem(formItem.name) &&
			latestRecord &&
			latestRecord[formItem.name] !== null
		)
			return latestRecord[formItem.name]

		return undefined
	}

	const isInheritableFormItem = name => {
		const inheritableForms = {
			length_of_pregnancy: true,
			delivery_place: true,
			delivery_attendants: true,
		}

		return inheritableForms[name] || false
	}

	const getFormField = formItem => {
		if (formItem.type === 'input')
			return (
				<Input
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
					maxLength={formItem.maxLength}
				/>
			)
		else if (formItem.type === 'number')
			return (
				<InputNumber
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
					min={1}
					max={99999}
				/>
			)
		else if (formItem.type === 'select')
			return (
				<Select
					showArrow
					bordered={false}
					autoComplete='off'
					options={formItem.options}
					style={{ width: '100%' }}
					suffixIcon={<DownOutlined />}
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'timepicker')
			return (
				<TimePicker
					format='HH:mm'
					showNow={false}
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={formItem.placeholder}
				/>
			)
		else if (formItem.type === 'datepicker')
			return (
				<DatePicker
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					format={`${getDateFormat(i18n)} HH:mm`}
					showTime={{ format: 'HH:mm' }}
					placeholder={formItem.placeholder}
					showToday={false}
					showNow={false}
					disabledDate={current => {
						if (current.diff(moment(), 'days') <= 0) return false
						return true
					}}
				/>
			)
	}

	return (
		<Form
			form={form}
			layout='vertical'
			onFinish={handleUpdate}
		>
			<div className='drawer-title'>
				{!record && t('record_of_deliveries.add_delivery')}
				{record && t('record_of_deliveries.update_delivery')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				{getRecordFormItems()}
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditDeliveryRecord
