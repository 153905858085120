import React from 'react'
import StyleButton from './StyleButton'

const ColorStyleControls = props => {
	const { editorState, onToggle, onClickColor, COLOR_STYLES } = props
	const currentStyle = editorState.getCurrentInlineStyle()

	return (
		<div className='RichEditor-controls'>
			{COLOR_STYLES.map(type => (
				<StyleButton
					key={type.label}
					icon={type.icon}
					label={type.label}
					style={type.style}
					onToggle={onToggle}
					onClickColor={onClickColor}
					active={currentStyle.has(type.style)}
				/>
			))}
		</div>
	)
}

export default ColorStyleControls
