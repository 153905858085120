import React, { useContext, useEffect, useState } from 'react'
import '../../assets/css/dashboard/dashboard.css'
import { Row, Col, Statistic, PageHeader, Avatar, Badge } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import HeaderContext from './main-layout-context'
import moment from 'moment'
import { useSubscription } from '@apollo/react-hooks'
import { GET_DOCTOR_INFO } from '../../graphql/layouts/queries'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../utils'
import { getName } from '../../components/common/get-name'

const DoctorHeaderPage = () => {
	const { t, i18n } = useTranslation()

	const [info, setInfo] = useState({
		name: '',
		description: '',
		avatar_link: null,
		is_available: false,
		week_total: 0,
		pending: 0,
		active: 0,
	})

	const {
		states: {
			headerNavCont: { headerTitle, userId },
		},
	} = useContext(HeaderContext)

	const { data } = useSubscription(GET_DOCTOR_INFO, {
		fetchPolicy: 'no-cache',
		shouldResubscribe: true,
		variables: {
			userId: userId,
			currentDate: moment().startOf('day').toISOString(),
			weekStart: moment().startOf('week').toISOString(),
			weekEnd: moment().endOf('week').toISOString(),
		},
	})

	useEffect(() => {
		if (data) {
			setInfo({
				name:
					`Dr. ` +
					getName(
						data.doctors[0].user.first_name,
						data.doctors[0].user.last_name,
						i18n.language,
					),
				description: getDate(),
				avatar_link: data.doctors[0].user.avatar_link || null,
				is_available: data.doctors[0].is_available || false,
				week_total: data.doctors[0].week_total.aggregate.count,
				pending: data.doctors[0].pending.aggregate.count,
				active: data.doctors[0].active.aggregate.count
			})
		}
	}, [data])

	const getDate = () => {
		let format = ''

		if (i18n.language === 'en') format = `ddd ${getDateFormat(i18n)}`
		else if (i18n.language === 'fr') format = `ddd ${getDateFormat(i18n)}`
		else format = `${getDateFormat(i18n)} dddd`

		return moment().format(format)
	}

	return (
		<PageHeader
			className='site-page-header-responsive'
			style={{
				marginLeft: '4px',
				background: '#fff',
			}}
			title={<b className='dash-title'>{headerTitle}</b>}
		>
			<Row>
				<Col>
					<Badge
						dot
						offset={[-6, 55]}
						className='active-badge-12'
						color={info.is_available ? '#07B2BD' : '#858585'}
					>
						<Avatar
							style={{ resizeMode: 'stretch' }}
							size={70}
							src={info.avatar_link}
							icon={<UserOutlined />}
						/>
					</Badge>
				</Col>
				<Col style={{ marginLeft: '10px', flexGrow: 100 }}>
					<div className='dash-header-title'>{info.name}</div>
					<div className='dash-header-desc' style={{ marginTop: '10px' }}>
						{info.description}
					</div>
				</Col>
				<Col style={{ marginLeft: '10px' }}>
					<Statistic
						style={{ textAlign: 'right' }}
						title={
							<div>
								{t('appointments.index')}
								<br />
								{t('appointments.this_week')}
							</div>
						}
						value={info.week_total}
					/>
				</Col>
				<Col
					style={{
						marginRight: '20px',
						marginLeft: '20px',
						background: '#E9E9E9',
						width: '1px',
					}}
				></Col>
				<Col>
					<Statistic
						valueStyle={{ color: '#E5526F' }}
						style={{ textAlign: 'right' }}
						title={
							<div>
								{t('appointments.index')}
								<br />
								{t('appointments.pending_approval')}
							</div>
						}
						value={info.pending}
					/>
				</Col>
				<Col
					style={{
						marginRight: '20px',
						marginLeft: '20px',
						background: '#E9E9E9',
						width: '1px',
					}}
				></Col>
				<Col>
					<Statistic
						style={{ textAlign: 'right' }}
						title={
							<div>
								{t('appointments.index')}
								<br />
								{t('appointments.total_active')}
							</div>
						}
						value={info.active}
					/>
				</Col>
			</Row>
		</PageHeader>
	)
}

export default DoctorHeaderPage
