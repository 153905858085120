import React, { useEffect, useState } from 'react'
import { Form, Spin, message } from 'antd'
import '../../../assets/css/dashboard/view-post.css'
import '../../../assets/css/common/utility.css'
import { useMutation } from '@apollo/react-hooks'
import {
  UPSERT_NEWSFEEDS,
  UPDATE_NEWSFEEDS,
} from '../../../graphql/newsfeeds/mutations'
import logo from '../../../assets/img/default_post.jpg'

import moment from 'moment'
import { getPregIds } from '../../../utils/get-preg-ids-by-week';
import s3Uploader from '../../../utils/s3-uploader';
import { commonComponents } from '../../common'
import { useTranslation } from 'react-i18next'
import ViewPost from './view-post'
import EditPost from './edit-post'

const { ConfirmModal } = commonComponents

const ViewEditPost = props => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState(false)
  const [upsertNewsfeeds] = useMutation(UPSERT_NEWSFEEDS)
  const [updateNewsfeeds] = useMutation(UPDATE_NEWSFEEDS)
  const [isEdit, setIsEdit] = useState(false)
  const [uploadState, setUploading] = useState({
    isUploading: false,
    uploadSrc: props.newsfeedFields.image,
  })
  const [tempImage, setTempImage] = useState(null)
  const [image, setImage] = useState(null)

  const [fileList, setFileList] = useState([])
  const onFinish = inputs => {
    props.handleCloseModal()
    setIsEdit(false)
    setLoading(true)
    upsertNewsfeeds({
      variables: {
        objects: [
          {
            id: props.newsfeedFields.id,
            author_id: props.newsfeedFields.name.id,
            title: inputs.title,
            category: inputs.category,
            content: inputs.content,
            image: uploadState.uploadSrc || logo,
            week_of_pregnancy: inputs.week_of_pregnancy,
            subtitle: inputs.subtitle,
          },
        ],
      },
    }).then(
      result => {
        if (image) {
          s3Uploader(image,
            `newsfeeds/${result.data.insert_newsfeeds.returning[0].id}`,
            (imageUrl) => {
              updateNewsfeeds({
                variables: {
                  where: { id: { _eq: result.data.insert_newsfeeds.returning[0].id } },
                  _set: {
                    image: imageUrl
                  }
                }
              }).then(() => {
                resetForms(result)
              })
            })
        } else {
          resetForms(result)
        }
      },
      addError => {
        setLoading(false)
        message.error(addError.message)
      },
    )
  }

  const resetForms = (result) => {
    setLoading(false)
    message.success(t('newsfeeds.success_update'))
    let id = result.data.insert_newsfeeds.returning[0].id
    props.refetchUpdated({
      where: {
        id: { _eq: id }
      }
    })
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const uploadprops = {
    name: 'image',
    accept: '.png,.jpg,.jpeg',
    fileList,
    onChange: async info => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map(file => {
        if (file.response) file.url = file.response.url
        return file
      })
      setFileList(fileList)

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
        getBase64(info.file.originFileObj, imageUrl => {
          setImage(info.file)
          setTempImage(imageUrl)
          setUploading({
            uploadSrc: imageUrl,
            isUploading: false,
            filename: info.file.name,
          })
        })
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const actionSuccess = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }
  const handleEdit = (showEdit = true) => {
    setIsEdit(showEdit)
    props.refetch()
  }
  const closeViewModal = () => {
    setIsEdit(false)
  }

  const handleDelete = () => {
    ConfirmModal({
      mtitle: t('newsfeeds.delete_newsfeed'),
      mcontent: t('confirm.cannot_undo'),
      monOk: () => {
        updateNewsfeeds({
          variables: {
            _set: {
              deleted_at: moment(),
            },
            where: {
              id: { _eq: props.newsfeedFields.id },
            },
          },
        }).then(
          result => {
            setLoading(false)
            message.success(t('newsfeeds.success_delete'))
            props.handleCloseModal(false)
            let id = result.data.update_newsfeeds.returning[0].id
            props.refetchUpdated({
              where: {
                id: { _eq: id }
              }
            })
          },
          addError => {
            setLoading(false)
            message.error(addError.message)
          },
        )
      },
      t,
    })
  }

  useEffect(() => {
    setUploading({
      isUploading: false,
      uploadSrc: props.newsfeedFields.image,
      filename: 'newsfeed_post.jpg'
    })
  }, [props.newsfeedFields.image])

  return (
    <Spin spinning={isLoading}>
      {!isEdit && (
        <ViewPost
          newsfeedFields={props.newsfeedFields}
          handleDelete={handleDelete}
          handleEdit={() => {
            handleEdit(true)
          }}
          isEdit={isEdit}
          isModalViewVisible={props.isModalViewVisible}
          handleCloseModal={props.handleCloseModal}
          closeViewModal={closeViewModal}
        />
      )}
      {isEdit && (
        <EditPost
          newsfeedFields={props.newsfeedFields}
          uploadprops={uploadprops}
          image={tempImage}
          onFinish={onFinish}
          isEdit={isEdit}
          form={form}
          handleEdit={() => {
            handleEdit(false)
          }}
          isModalViewVisible={props.isModalViewVisible}
          handleCloseModal={props.handleCloseModal}
          actionSuccess={actionSuccess}
          uploadState={uploadState}
          setUploading={setUploading}
          closeViewModal={closeViewModal}
          setContent={(newContent) => {
            form.setFieldsValue({ content: newContent })
          }}
        />
      )}
    </Spin>
  )
}

export default ViewEditPost
