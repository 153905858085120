import React, { useEffect, useState, useRef } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import {
	Typography,
	Col,
	Row,
	Button,
	Form,
	Collapse,
	Dropdown,
	Menu,
	message,
} from 'antd'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { GET_BABY_CHECKUPS } from '../../../../graphql/baby-checkups/queries'
import { UPDATE_BABY_CHECKUPS } from '../../../../graphql/baby-checkups/mutations'
import { commonComponents } from '../../../common'
import AddEditBabyCheckupRecord from './add-edit-drawer'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'
import { getName } from '../../../common/get-name'

const { Panel } = Collapse
const { Sidepanel, ConfirmModal } = commonComponents

const BabyCheckup = props => {
	const { t, i18n } = useTranslation()

	const { doctor, baby } = props

	const panel = useRef()
	const [form] = Form.useForm()
	const [checkups, setCheckups] = useState([])
	const [updateBabyCheckup] = useMutation(UPDATE_BABY_CHECKUPS)
	const [activePanel, setActivePanel] = useState(undefined)

	const { data, refetch } = useQuery(GET_BABY_CHECKUPS, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				baby_id: {
					_eq: baby.id,
				},
			},
		},
	})

	useEffect(() => {
		if (!data) return

		setActivePanel(undefined)
		setCheckups(data.baby_checkups)
	}, [data])

	const getOptionReference = key => {
		if (!key) return t('crud.n_a')

		const reference = {
			yes: t('common.yes'),
			no: t('common.no'),
			good: t('baby_checkups.good'),
			need_advice: t('baby_checkups.need_advice'),
			breastfeeding: t('baby_checkups.breastfeeding'),
			mixed: t('baby_checkups.mixed'),
			formula: t('baby_checkups.formula'),
			healthy: t('baby_checkups.healthy'),
			requires_monitoring: t('baby_checkups.requires_monitoring'),
			normal: t('baby_checkups.normal'),
			abnormal: t('baby_checkups.abnormal'),
			present: t('baby_checkups.present'),
			absent: t('baby_checkups.absent'),
			started: t('baby_checkups.started'),
			not_started: t('baby_checkups.not_started'),
			still_breastfeeding: t('baby_checkups.still_breastfeeding'),
			stopped_breastfeeding: t('baby_checkups.stopped_breastfeeding'),
			1: `1`,
			2: `2`,
			3: `3`,
			4: `4`,
			5: `5`,
			other: t('post_partum.other'),
		}

		return reference[key]
	}


	const menu = record => (
		<Menu>
			<Menu.Item
				onClick={() => {
					panel.current.showPanel(
						<AddEditBabyCheckupRecord
							form={form}
							baby={baby}
							record={record}
							refetch={refetch}
							latestRecord={checkups[0]}
						/>,
					)
				}}
			>
				{t('crud.edit')}
			</Menu.Item>
			<Menu.Item
				onClick={() =>
					ConfirmModal({
						mtitle: t('confirm.are_you_sure'),
						mcontent: t('confirm.cannot_undo'),
						monOk: () => {
							updateBabyCheckup({
								variables: {
									_set: {
										deleted_at: moment(),
									},
									where: {
										id: {
											_eq: record.id,
										},
									},
								},
							}).then(
								() => {
									refetch()
									message.success(t('baby_checkups.success_delete'))
								},
								changeError => {
									message.error(changeError.message)
								},
							)
						},
						t,
					})
				}
			>
				{t('crud.remove')}
			</Menu.Item>
		</Menu>
	)

	const getItemContentBasedFromBirth = content => {
		const numOfMonthsSinceBirth = moment(content.date).diff(
			moment(baby.pregnancy.record_of_deliveries[0].delivery_timestamp),
			'months',
		)

		const lessThanOneMonth = [
			{
				label: t('baby_checkups.nursing_ability'),
				data: getOptionReference(content.nursing_ability),
			},
			{
				label: t('baby_checkups.jaundice'),
				data: getOptionReference(content.jaundice),
			},
			{
				label: t('baby_checkups.irregularities_at_birth'),
				data: getOptionReference(content.irregularities_at_birth),
			},
			{
				label: t('baby_checkups.irregularities_after_birth'),
				data: getOptionReference(content.irregularities_after_birth),
			},
		]

		const fiveMonths = [
			{
				label: t('baby_checkups.feeding_method'),
				data: getOptionReference(content.feeding_method),
			},
		]
		const eightMonths = [
			{
				label: t('baby_checkups.feeding_method'),
				data: getOptionReference(content.feeding_method),
			},
			{
				label: t('baby_checkups.preparation_for_weaning'),
				data: getOptionReference(content.preparation_for_weaning),
			},
			{
				label: t('baby_checkups.condition_of_mouth'),
				data: getOptionReference(content.condition_of_mouth),
			},
		]
		const elevenMonths = [
			{
				label: t('baby_checkups.meals'),
				data: content.meals
					? `${getOptionReference(content.meals)} ${
							content.meals === '1' ? t('common.time_per_day')
							: content.meals === 'other' ? " "
							: t('common.times_per_day')
					  }` 
					: t('crud.n_a'),
			},
			{
				label: t('baby_checkups.number_of_teeth'),
				data: content.number_of_teeth ? content.number_of_teeth : t('crud.n_a'),
			},
			{
				label: t('baby_checkups.condition_of_mouth'),
				data: getOptionReference(content.condition_of_mouth),
			},
		]
		const oneYear = [
			{
				label: t('baby_checkups.meals'),
				data: content.meals
					? `${getOptionReference(content.meals)} ${
						content.meals === '1' ? t('common.time_per_day')
						: content.meals === 'other' ? " "
						: t('common.times_per_day')
				  }` 
					: t('crud.n_a'),
			},
			{
				label: t('baby_checkups.breastfeeding'),
				data: getOptionReference(content.breastfeeding),
			},
			{
				label: t('baby_checkups.condition_of_eyes'),
				data: getOptionReference(content.condition_of_eyes),
			},
		]

		if (numOfMonthsSinceBirth === 0) return lessThanOneMonth
		if (numOfMonthsSinceBirth <= 5) return fiveMonths
		if (numOfMonthsSinceBirth <= 8) return eightMonths
		if (numOfMonthsSinceBirth <= 11) return elevenMonths

		return oneYear
	}



	const checkupItemContent = content => {
		let doctorName = t('crud.n_a')

		if (content.doctor && content.doctor.id && content.doctor.user.id)
			doctorName = getName(
				content.doctor.user.first_name,
				content.doctor.user.last_name,
				i18n.language,
			)

		let checkupData = [
			{
				label: t('health.weight'),
				data: content.weight ? `${content.weight} kg` : t('crud.n_a'),
			},
			{
				label: t('health.height'),
				data: content.height ? `${content.height} cm` : t('crud.n_a'),
			},
			{
				label: t('baby_checkups.chest_circumference'),
				data: content.chest_circumference
					? `${content.chest_circumference} cm`
					: t('crud.n_a'),
			},
			{
				label: t('baby_checkups.head_circumference'),
				data: content.head_circumference
					? `${content.head_circumference} cm`
					: t('crud.n_a'),
			},
			{
				label: t('baby_checkups.diet'),
				data: getOptionReference(content.diet),
			},
			...getItemContentBasedFromBirth(content),
			{
				label: t('baby_checkups.general_health'),
				data: getOptionReference(content.general_health),
			},
			{
				label: t('doctors.notes_from_doctor'),
				data: content.notes || t('crud.n_a'),
			},
			{
				label: t('doctors.doctor'),
				data: doctorName,
			},
			{
				label: t('crud.medical_institution'),
				data: `${content.medical_institution || t('crud.n_a')}`,
			},
		]

		let contentData = checkupData.map((data, index) => {
			return (
				<Col span={data.label === 'Notes' ? 24 : 12} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		return contentData
	}
	const checkupItems = checkups.map((checkup, index) => {
		const months = baby.pregnancy.record_of_deliveries[0]
			? moment(checkup.date).diff(
					moment(baby.pregnancy.record_of_deliveries[0].delivery_timestamp),
					'months',
			  )
			: 0

		const weeks = baby.pregnancy.record_of_deliveries[0]
			? moment(checkup.date).diff(
					moment(baby.pregnancy.record_of_deliveries[0].delivery_timestamp),
					'weeks',
			  ) || 1
			: 1

		const weekReference = {
			1: t('common.first'),
			2: t('common.second'),
			3: t('common.third'),
			4: t('common.fourth'),
			5: t('common.fifth'),
		}

		return (
			<Panel
				header={
					<>
						<div style={{ color: '#969999' }}>
							{moment(checkup.date).format(getDateFormat(i18n))}
						</div>
						<div>{  (i18n.language === 'fr') ? `${capitalize(t('common.condition'))}${t('common.s')}${
							capitalize(
								baby.first_name.replace(/\s/g, '') || t('babies.baby'),
							) || t('babies.baby')
						}${
							baby.pregnancy
								? ` ${t('common.at')} ${months || weekReference[weeks || 1]} ${
										months !== 0
											? capitalize(
													months > 1 ? t('common.months') : t('common.month'),
											  )
											: capitalize(t('common.week'))
								  }`
								: ''
						}` : `${
							capitalize(
								baby.first_name.replace(/\s/g, '') || t('babies.baby'),
							) || t('babies.baby')
						}${t('common.s')}${capitalize(t('common.condition'))}${
							baby.pregnancy
								? ` ${t('common.at')} ${months || weekReference[weeks || 1]} ${
										months !== 0
											? capitalize(
													months > 1 ? t('common.months') : t('common.month'),
											  )
											: capitalize(t('common.week'))
								  }`
								: ''
						}`
					}
						</div>
					</>
				}
				key={index}
				className='site-collapse-custom-panel'
				style={{ padding: '10px 0' }}
			>
				<Dropdown overlay={menu(checkup)} placement='bottomRight'>
					<EllipsisOutlined className='ellipsis' />
				</Dropdown>
				<Row gutter={[16, 16]}>{checkupItemContent(checkup)}</Row>
			</Panel>
		)
	})

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>
						{getName(baby.first_name, baby.last_name, i18n.language)}
						{`${t('common.s')}${t('baby_checkups.index')}`}
					</h1>
				</Col>

				<Col span={12}>
					<Button
						icon={<PlusOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditBabyCheckupRecord
									form={form}
									doctor={doctor}
									baby={baby}
									refetch={refetch}
									latestRecord={checkups[0]}
								/>,
							)
						}
					>
						<span className='title'>{t('baby_checkups.add_checkup')}</span>
					</Button>
				</Col>
			</Row>
			<Sidepanel ref={panel} />
			<Collapse
				accordion
				expandIconPosition='right'
				bordered={false}
				style={{ padding: 20 }}
				className='site-collapse-custom-collapse'
				activeKey={activePanel}
				onChange={key => setActivePanel(key)}
			>
				{checkupItems}
			</Collapse>
		</>
	)
}
export default BabyCheckup
