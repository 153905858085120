import React from 'react'
import '../../assets/css/dashboard/tablelist.css'
import { Card, Table, Button, Row, Col, Dropdown, Menu, Typography } from 'antd'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { commonComponents } from '.'
import { useTranslation } from 'react-i18next'

const { BlockButton } = commonComponents

const TableList = props => {
	const { t } = useTranslation()

	const menu = (key, record) => {
		return (
			<Menu className='tablelist-menu'>
				{(props.actions || []).map(option => (
					<Menu.Item key={key} className='item'>
						<a
							target='_blank'
							onClick={() =>
								option.function(
									option.name === t('crud.contact')
										? record.user_id || null
										: key,
								)
							}
						>
							{option.name}
						</a>
					</Menu.Item>
				))}
			</Menu>
		)
	}

	const items = (props.items || []).map((item, index) => ({
		key: item.key,
		item1: item.item1,
		item2: item.item2 || '',
		item3: item.item3 || '',
		status: item.status,
		user_id: item.user_id,
	}))

	let columns = (props.columns || []).map((column, index) => ({
		title: column,
		className: 'dash-tablelist-header',
		dataIndex: 'item' + (index + 1),
		align: (props.columnProps || {}).align || 'center',
		render: (text, record) => (
			<Typography.Text className='dash-tablelist-item'>{text}</Typography.Text>
		),
	}))

	columns.push({
		title: '',
		className: 'dash-TableList-header',
		key: 'action',
		render: (text, record) => {
			if (record.key)
				return (
					<Dropdown
						className='dash-tablelist-dropdown'
						overlay={menu(record.key, record)}
						placement='bottomRight'
					>
						<a className='dropdown-link' target='_blank'>
							<EllipsisOutlined className='dash-tablelist-ellipsis' />
						</a>
					</Dropdown>
				)
			else return <p style={{ height: '20px' }}> </p>
		},
	})

	const getTableMode = () => {
		let mode = {}

		if (props.tableMode === 'doctor_list')
			mode = { emptyText: t('empty_messages.doctors_list') }
		else if (props.tableMode === 'today_appointments')
			mode = { emptyText: t('empty_messages.today_appointments_list') }
		else if (props.tableMode === 'active_appointments')
			mode = { emptyText: t('empty_messages.active_appointments_list') }
		else if (props.tableMode === 'pending_appointments')
			mode = { emptyText: t('empty_messages.pending_appointments_list') }
		else if (props.tableMode === 'cancelled_appointments')
			mode = { emptyText: t('empty_messages.cancelled_appointments_list') }
		else mode = { emptyText: t('empty_messages.appointment_list') }

		return mode
	}

	const DisplayTable = () => {
		return (
			<Table
				style={{ height: '245px' }}
				dataSource={items || []}
				columns={columns || []}
				pagination={false}
				locale={getTableMode()}
				scroll={{ y: items.length > 4 ? props.scrollHeight || 190 : null }}
			/>
		)
	}

	return (
		<Card>
			<span>
				<Row gutter={[16, 16]}>
					<Col span={ props.isAddBtnHidden ? 24 : 14}>
						<h1 className='dash-title'>{props.title || ''}</h1>
					</Col>
					<Col span={props.isAddBtnHidden ? 0 : 10}>
						<Button
							icon={<PlusOutlined />}
							onClick={props.addBtnOnClick}
							className={
								props.isAddBtnHidden
									? 'dash-add-button-hidden'
									: 'dash-add-button'
							}
						>
							<span className='title'>&nbsp;{props.addtitle}</span>
						</Button>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<DisplayTable />
					</Col>
				</Row>
				<Row>
					<Col className='view-btn-container' span={24}>
						<BlockButton
							title={(props.viewbtnprops || {}).title}
							onClick={(props.viewbtnprops || {}).onClick}
						/>
					</Col>
				</Row>
			</span>
		</Card>
	)
}

export default TableList
