import React, { Children } from 'react'
import { useHistory } from 'react-router-dom'
import { initApp, newFirebase } from './firebase'
import { decodeToken } from './utils'
import 'firebase/messaging'
import { useTranslation } from 'react-i18next'

const VAPID_ID = 'BJxtXvY8fD7HIx2IiC5w2290LNDI_Fh19e-9-T-BGRrh1vpW7RnkrHcVJ63HZBu6sbwsADRoHAFVHhjrbs0w8EQ'

console.log('initApp content: ', initApp)

let messaging = newFirebase.messaging.isSupported() ? initApp.messaging() : null;

console.log(`is Messaging supported? ${messaging != null}`)

export const tokenGenerate = (language) => {
    if (messaging == null){
      return
    }

    messaging.getToken({ vapidKey: VAPID_ID }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log('token', currentToken)
          localStorage.setItem('fcmtoken', currentToken);

          let token = localStorage.getItem('token')

          let userid
          if (token != null){
            userid = decodeToken(token).user_id
          } 

          setMessagingTokenToServer(userid, language)
          // ...
        } else {
          // Show permission request UI
          console.error('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
        // ...
      });
}

export const sendMessage = (userid = null, data) => {
    let fcm_xhr = new XMLHttpRequest()

    fcm_xhr.addEventListener('load', () => {
      if (fcm_xhr.status == 200){
        const response = JSON.parse(fcm_xhr.response)

        if (response.code != null){
          if (response.code == 200){
            console.log("Message Sending Success! ", response.message)
          } else{
            console.error("Message Sending Failed! ", response.error)
          }
        } else {
          console.error("Message Sending Error")
        }
      }
    })

    fcm_xhr.addEventListener('error', () => {
      console.error('Message Sending Error! Server is not responding!. Need to retry request')
    })
    
    let urlString = `${process.env.REACT_APP_API_AUTH}/send-message`

    if (userid != null){
      urlString = urlString + `/${userid}`
    }

    const token = localStorage.getItem('token')
    const tokenUserId = decodeToken(token).user_id
    data['data']['initiator'] = tokenUserId

    console.log('data body: ', data)

    fcm_xhr.open('POST', urlString)
    fcm_xhr.setRequestHeader('Content-Type','application/json;charset=UTF-8')

    fcm_xhr.send(JSON.stringify(data))
}

export const setMessagingTokenToServer = (userid = null, language) => {
    if (messaging == null){
      return
    }

    let fcm_xhr = new XMLHttpRequest()

    fcm_xhr.addEventListener('load', () => {
      if (fcm_xhr.status == 200){ 
        const response = JSON.parse(fcm_xhr.response)

        if (response.code != null){
          if (response.code == 200){
            console.log("FCM Token Reg Success! ", response.message)
          } else{
            console.log("FCM Token Reg Fail! ", response.error)
          }
        } else {
          console.log("FCM TOKEN REGISTER ERROR")
        }
      }
    })

    fcm_xhr.addEventListener('error', () => {
      console.log('FCM TOKEN REGISTER ERROR! Firebase Server is not working. Need to retry request')
    })

    const fcmtoken = getToken()
    const body = {
      token: fcmtoken,
      userid: userid,
      platform: 'web',
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    if (language != null) body['locale'] = language

    fcm_xhr.open('POST', `${process.env.REACT_APP_API_AUTH}/set-device-token`)
    fcm_xhr.setRequestHeader('Content-Type','application/json;charset=UTF-8')

    fcm_xhr.send(JSON.stringify(body))
}

export const getToken = () => {
    return localStorage.getItem('fcmtoken');
}


export const NotificationComp = props => {

  const history = useHistory()

  const handleNotification = (data) => {
    const {ext} = data

    history.push(ext)
  } 

  if (messaging != null){
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);

      const notificationTitle = payload.data.title;
      const notificationOptions = {
          body: payload.data.body,
          icon: `${process.env.REACT_APP_API_CLIENT}/favicon.ico`,        
      };

      console.log(`Payload content: ${payload}`)

      if (!("Notification" in window)) {
          console.log("This browser does not support system notifications.");
      } else if (Notification.permission === "granted" || Notification.permission == "default") {
          // If it's okay let's create a notification
          var notification = new Notification(notificationTitle,notificationOptions);
          notification.onclick = function(event) {
              event.preventDefault();
              handleNotification(payload.data)
              notification.close();
          }
      }
    });
  }

  return <>{props.children}</>
  
}

export const checkBrowserSupport = () => {
    return messaging != null
}

export const deleteToken = () => {
    localStorage.removeItem('fcmtoken')
}