import gql from 'graphql-tag'

export const GET_USER_INFO = gql`
	query GET_USER_INFO($where: users_bool_exp!) {
		users(where: $where) {
			id
			password
			first_name
			last_name
		}
	}
`
