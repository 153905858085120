import React, { useContext, useState, useEffect } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { HeaderContext } from '../index'
import Icon from '@ant-design/icons'
import { useSubscription } from '@apollo/react-hooks'
import { GET_MESSAGES_COUNT } from '../../graphql/messages/queries'
import { ReactComponent as PatientSVG } from '../../assets/svg/patient.svg'
import { ReactComponent as MessageSVG } from '../../assets/svg/messages.svg'
import { ReactComponent as DashboardSVG } from '../../assets/svg/dashboard.svg'
import { ReactComponent as AppointmentsSVG } from '../../assets/svg/appointments.svg'
import { useTranslation } from 'react-i18next'

const Navigation = props => {
  const dKey = props.defaultKey ? props.defaultKey : ['1']
  const { t } = useTranslation()
  const {
    states: {
      headerNavCont: { userId, userType },
    },
  } = useContext(HeaderContext)
  const [messageCount, setMessageCount] = useState(0)

  const iconStyle = { fontSize: 20 }

  function getSearchRoleFilter() {
    let roles = ['admin', 'doctor']

    if (userType !== 'admin') {
      roles.push('patient')
    }

    return { _in: roles }
  }

  const { data } = useSubscription(GET_MESSAGES_COUNT, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        receiver_id: { _eq: userId },
        is_seen: { _eq: false },
        deleted_at: { _is_null: true },
        user: { role: { description: getSearchRoleFilter() } }
      },
    },
  })

  useEffect(() => {
    if (!data) return
    setMessageCount(data.messages_aggregate.aggregate.count)
  }, [data])

  return (
    <Menu
      className={'menuNavigation'}
      theme="light"
      mode="inline"
      defaultSelectedKeys={dKey}
      selectedKeys={dKey}
    >
      <Menu.Item key="1">
        <Icon
          className="menu-icon"
          component={DashboardSVG}
          style={iconStyle}
        />
        <span>{t('common.dashboard')}</span>
        <Link to="/dashboard"/>
      </Menu.Item>
      <Menu.Item key="2">
        <Icon
          className="menu-icon"
          component={AppointmentsSVG}
          style={iconStyle}
        />
        <span>{t('appointments.index')}</span>
        <Link to="/appointments"/>
      </Menu.Item>
      <Menu.Item key="3">
        <Icon className="menu-icon" component={MessageSVG} style={iconStyle}/>
        <span>
					<span>{t('messages.index')}</span>{' '}
          {messageCount !== 0 && (
            <>
              (
              <span className="text-orange">
								{messageCount > 99 ? '99+' : messageCount}
							</span>
              )
            </>
          )}
				</span>
        <Link to="/messages"/>
      </Menu.Item>
      {userType == 'admin' ? (
        <Menu.Item key="4">
          <Icon
            className="menu-icon"
            component={PatientSVG}
            style={iconStyle}
          />
          <span>{t('doctors.index')}</span>
          <Link to="/doctors"/>
        </Menu.Item>
      ) : (
        <></>
      )}
      <Menu.Item key="5">
        <Icon className="menu-icon" component={PatientSVG} style={iconStyle}/>
        <span>{t('patients.index')}</span>
        <Link to="/patients"/>
      </Menu.Item>
    </Menu>
  )
}

export default Navigation
