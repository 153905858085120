import gql from 'graphql-tag'

export const GET_PATIENTS = gql`
	query GET_PATIENTS {
		patients(
			where: { deleted_at: { _is_null: true } }
			order_by: { user: { last_name: asc, first_name: asc } }
		) {
			id
			user {
				id
				first_name
				last_name
				telephone
				email
				avatar_link
			}
			date_of_birth
		}
	}
`

//we will only limit to patients that has appointment to the doctor
export const GET_DOCTOR_PATIENTS = gql`
	query GET_DOCTOR_PATIENTS($where: appointments_bool_exp) {
		appointments(where: $where, order_by: { datetime: asc }) {
			id
			patient {
				id
				account_code
				user {
					id
					last_name
					first_name
					telephone
					email
					avatar_link
				}
				pregnancies(limit: 1, order_by: { start_date: desc }) {
					id
					start_date
					due_date
					record_of_deliveries {
						id
						delivery_timestamp
					}
				}
			}
		}
	}
`

export const GET_ALL_PATIENTS = gql`
	query GET_ALL_PATIENTS($where: patients_bool_exp, $offset: Int) {
		patients(where: $where, limit: 10, offset: $offset) {
			id
			account_code
			user {
				id
				last_name
				first_name
				telephone
				address
				email
				avatar_link
			}
			date_of_birth
			pregnancies(limit: 1, order_by: { start_date: desc }) {
				id
				start_date
				due_date
				record_of_deliveries {
					id
					delivery_timestamp
				}
			}
		}
		patients_count: patients_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`

export const GET_PATIENT_INFO = gql`
	query GET_PATIENT_INFO(
		$where: patients_bool_exp
		$upcoming_appointments_where: appointments_bool_exp
	) {
		patients(where: $where) {
			id
			date_of_birth
			account_code
			user {
				id
				last_name
				first_name
				telephone
				address
				email
				avatar_link
				password
			}
			upcoming_appointments: appointments(
				where: $upcoming_appointments_where
				order_by: { datetime: asc }
			) {
				datetime
				doctor_id
				patient_id
				name
				location
			}
		}
	}
`

export const GET_PATIENT_DETAILS = gql`
	query GET_PATIENT_DETAILS(
		$patients_where: patients_bool_exp!
		$current_pregnancy_where: pregnancies_bool_exp
		$old_pregnancies_where: pregnancies_bool_exp
		$upcoming_appointments_where: appointments_bool_exp
		$previous_appointments_where: appointments_bool_exp
	) {
		patients(where: $patients_where) {
			id
			account_code
			user {
				id
				first_name
				last_name
				email
				telephone
				address
				avatar_link
			}
			date_of_birth
			current_pregnancy: pregnancies(
				where: $current_pregnancy_where
				order_by: { start_date: desc }
				limit: 1
			) {
				id
				due_date
				start_date
				record_of_deliveries {
					id
				}
			}
			pregnancies(
				where: $old_pregnancies_where
				order_by: { start_date: desc }
			) {
				pregId: id
				start_date
				due_date
				record_of_deliveries {
					id
					delivery_timestamp
					baby_first_name
					baby_last_name
				}
			}
			upcoming_appointments: appointments(
				where: $upcoming_appointments_where
				order_by: { datetime: asc }
			) {
				datetime
				doctor_id
				patient_id
				name
				location
			}
			previous_appointments: appointments(
				where: $previous_appointments_where
				order_by: { datetime: desc }
			) {
				prevId: id
				datetime
				name
				is_approved
			}
		}
	}
`
