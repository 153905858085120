import {
	Editor,
	RichUtils,
	EditorState,
	ContentState,
	getDefaultKeyBinding,
} from 'draft-js'
import { Row, Col } from 'antd'
import htmlToDraft from 'html-to-draftjs'
import '../../../assets/css/common/utility.css'
import { useTranslation } from 'react-i18next'
import React, { useState, useRef } from 'react'
import { getBlockStyle } from './getBlockStyle'
import createStyles from 'draft-js-custom-styles'
import { stateToHTML } from 'draft-js-export-html'
import '../../../assets/css/common/rich-text-editor.css'
import BlockStyleControls from './BlockStyleControls'
import ColorStyleControls from './ColorStyleControls'
import HeaderStyleControls from './HeaderStyleControls'
import InlineStyleControls from './InlineStyleControls'
import { editorIcons } from '../../../assets/svg/rich-text-editor-svgs/index'

const {
	AquaIcon,
	GreyIcon,
	PinkIcon,
	BoldIcon,
	BlackIcon,
	ItalicIcon,
	StrikeIcon,
	UnderlineIcon,
	OrderedListIcon,
	UnorderedListIcon,
} = editorIcons

const styleMap = {
	CODE: {
		padding: 2,
		fontSize: 16,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
	},
	STRIKETHROUGH: {
		textDecoration: 'line-through',
	},
	BLACKTEXT: {
		color: '#3B3F3F',
	},
	GREYTEXT: {
		color: '#969999',
	},
	PINKTEXT: {
		color: '#F8B0A5',
	},
	AQUATEXT: {
		color: '#07B2BD',
	},
}

const HEADER_STYLES = [
	{ label: 'Header 1', style: 'header-one' },
	{ label: 'Header 2', style: 'header-two' },
	{ label: 'Paragraph', style: 'header-four' },
]

const INLINE_STYLES = [
	{
		label: 'Bold',
		style: 'BOLD',
		icon: BoldIcon,
	},
	{
		label: 'Italic',
		style: 'ITALIC',
		icon: ItalicIcon,
	},
	{
		label: 'Underline',
		style: 'UNDERLINE',
		icon: UnderlineIcon,
	},
	{
		label: 'Strikethrough',
		style: 'STRIKETHROUGH',
		icon: StrikeIcon,
	},
]

const BLOCK_TYPES = [
	{
		label: 'UL',
		style: 'unordered-list-item',
		icon: UnorderedListIcon,
	},
	{
		label: 'OL',
		style: 'ordered-list-item',
		icon: OrderedListIcon,
	},
]

const COLOR_STYLES = [
	{
		label: 'black',
		style: 'BLACKTEXT',
		icon: BlackIcon,
	},
	{
		label: 'grey',
		style: 'GREYTEXT',
		icon: GreyIcon,
	},
	{
		label: 'pink',
		style: 'PINKTEXT',
		icon: PinkIcon,
	},
	{
		label: 'aqua',
		style: 'AQUATEXT',
		icon: AquaIcon,
	},
]

const RichTextEditor = props => {
	const { t, i18n } = useTranslation()
	const { setContent, newsfeedContent } = props
	const editor = useRef()

	const [editorState, setEditorState] = useState(
		newsfeedContent
			? EditorState.createWithContent(
					ContentState.createFromBlockArray(
						htmlToDraft(newsfeedContent).contentBlocks,
						htmlToDraft(newsfeedContent).entityMap,
					),
			  )
			: EditorState.createEmpty(),
	)

	let className = 'RichEditor-editor'
	var contentState = editorState.getCurrentContent()

	const { styles, customStyleFn, exporter } = createStyles(
		['color'],
		'CUSTOM_',
		styleMap,
	)

	const onEditorChange = editorState => {
		const inlineStyles = exporter(editorState)
		const contentState = editorState.getCurrentContent()
		setEditorState(editorState)
		if (contentState.hasText()){
			setContent(stateToHTML(contentState, { inlineStyles }))
		} else{
			setContent(null)
		}
	}

	const handleKeyCommand = (command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command)
		if (newState) {
			onEditorChange(newState)
			return true
		}
		return false
	}

	const mapKeyToEditorCommand = e => {
		if (e.keyCode === 9) {
			const newEditorState = RichUtils.onTab(e, editorState, 4)
			if (newEditorState !== editorState) {
				onEditorChange(newEditorState)
			}
			return
		}
		return getDefaultKeyBinding(e)
	}

	const toggleBlockType = blockType => {
		onEditorChange(RichUtils.toggleBlockType(editorState, blockType))
	}

	const toggleInlineStyle = inlineStyle => {
		onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
	}

	const toggleHeaderStyle = headerType => {
		onEditorChange(RichUtils.toggleBlockType(editorState, headerType))
	}

	const toggleColorStyle = colorType => {
		onEditorChange(RichUtils.toggleInlineStyle(editorState, colorType))
	}

	const addTextColor = val => {
		const newEditorState = styles.fontSize.add(editorState, val)
		onEditorChange(newEditorState)
	}

	const getPlaceholder = () => {
		if (i18n.language !== 'en') return t('newsfeeds.content')
		else
			return (
				<div className='placeholder-content'>
					<div className='h1'>Header 1</div>
					<div className='h2'>Header 2</div>
					<div className='p'>Paragraph</div>
					<div className='format'>
						Check out what this looks like in bold, italics, underline, and
						strikethrough.
					</div>
					<div className='list'>
						A list makes more sense when giving details or steps. We provided
						two kinds- numbered and in plain bullets.
					</div>
					<ol>
						<li>First</li>
						<li>Second</li>
						<li>Third</li>
					</ol>
					<ul className='ul'>
						<li>First</li>
						<li>Second</li>
						<li>Third</li>
					</ul>
				</div>
			)
	}

	if (!contentState.hasText()) {
		if (contentState.getBlockMap().first().getType() !== 'unstyled') {
			className += ' RichEditor-hidePlaceholder'
		}
	}

	return (
		<div className='RichEditor-root'>
			<Row gutter={16}>
				<Col>
					<HeaderStyleControls
						editorState={editorState}
						onToggle={toggleHeaderStyle}
						HEADER_STYLES={HEADER_STYLES}
					/>
				</Col>
				<Col>
					<InlineStyleControls
						editorState={editorState}
						onToggle={toggleInlineStyle}
						INLINE_STYLES={INLINE_STYLES}
					/>
				</Col>
				<Col>
					<BlockStyleControls
						editorState={editorState}
						onToggle={toggleBlockType}
						BLOCK_TYPES={BLOCK_TYPES}
					/>
				</Col>
				<Col>
					<Row gutter={16} style={{ marginTop: '5px' }}>
						<ColorStyleControls
							editorState={editorState}
							onToggle={toggleColorStyle}
							onClickColor={addTextColor}
							COLOR_STYLES={COLOR_STYLES}
						/>
					</Row>
				</Col>
			</Row>

			<div
				className={className}
				onClick={() => {
					editor.current.focus()
				}}
			>
				<Editor
					ref={editor}
					spellCheck={true}
					onChange={onEditorChange}
					editorState={editorState}
					customStyleMap={styleMap}
					blockStyleFn={getBlockStyle}
					customStyleFn={customStyleFn}
					handleKeyCommand={handleKeyCommand}
					keyBindingFn={mapKeyToEditorCommand}
					placeholder={getPlaceholder()}
				/>
			</div>
		</div>
	)
}

export default RichTextEditor
