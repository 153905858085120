import React, { useRef } from 'react'
import { commonComponents } from '../common'
import TableList from '../common/table-list'
import { transformPreviousAppointmentList } from '../../utils/patient-data'
import ViewEditAppointment from '../appointments/view-edit-appointment'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../utils'

const { Sidepanel } = commonComponents

const PatientPreviousAppointmentList = props => {
	const { t, i18n } = useTranslation()

	const data = props.data
	const panel = useRef()

	const actionList = [
		{
			name: t('crud.view'),
			function: key => {
				panel.current.showPanel(
					<ViewEditAppointment itemId={key} showStatus={true} />,
				)
			},
		},
	]

	return (
		<>
			<TableList
				title={t('appointments.previous_appointments')}
				isAddBtnHidden={true}
				columns={[t('appointments.appointment_for'), t('crud.date')]}
				items={transformPreviousAppointmentList(data, t, getDateFormat(i18n))}
				actions={actionList}
				scrollHeight={'270px'}
			/>
			<Sidepanel ref={panel} />
		</>
	)
}

export default PatientPreviousAppointmentList
