import React, { useContext, useState } from 'react'
import { decodeToken } from '../utils'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'

import {
	AccountPage,
	AppointmentsPage,
	ChatMessagePage,
	DoctorsPage,
	DashboardPage,
	DashboardDoctorPage,
	DoctorChangePassword,
	LoginPage,
	ForgotPassword,
	PatientPage,
	PatientPregnancyPage,
	PatientProfilePage,
} from '../pages'
import { MainLayout, HeaderContext } from '../layouts'
import { NotificationComp} from '../firebase-messaging'
import moment from 'moment'

const Routes = () => {
	const { states } = useContext(HeaderContext)
	const { userId, userType, is_temp_pass } = states.headerNavCont

	return (
		<MainLayout>
			<Switch>
				<Route exact strict path='/'>
					{userId ? <Redirect to='/dashboard' /> : <Redirect to='/login' />}
				</Route>
				<Route exact strict path='/dashboard'>
					{userId ? (
						is_temp_pass ? (
							<Redirect to='/doctor-password' userId={userId} />
						) : userType == 'admin' ? (
							<DashboardPage userId={userId} />
						) : (
							<DashboardDoctorPage userId={userId} />
						)
					) : (
						<Redirect to='/' />
					)}
				</Route>
				<Route exact strict path='/account'>
					{userId && userType == 'doctor' ? (
						<AccountPage />
					) : (
						<Redirect to='/' />
					)}
				</Route>
				<Route
					exact
					strict
					path={[
						'/appointments',
						'/appointments/:date',
						'/appointments/:date/:view_panel',
					]}
				>
					{userId ? <AppointmentsPage /> : <Redirect to='/' />}
				</Route>
				<Route exact strict path='/doctors'>
					{userId ? <DoctorsPage /> : <Redirect to='/' />}
				</Route>
				<Route exact strict path='/patients'>
					{userId ? <PatientPage /> : <Redirect to='/' />}
				</Route>
				<Route exact strict path='/patients/profile/:patient_id'>
					{userId ? <PatientProfilePage /> : <Redirect to='/' />}
				</Route>
				<Route exact strict path='/patients/profile/pregnancy/:pregnancy_id'>
					{userId ? <PatientPregnancyPage /> : <Redirect to='/' />}
				</Route>
				<Route exact strict path={['/messages', '/messages/:user_id']}>
					{userId ? <ChatMessagePage /> : <Redirect to='/' />}
				</Route>
				<Route exact path='/login'>
					{userId ? <Redirect to='/' /> : <LoginPage />}
				</Route>
				<Route exact path='/forgot-password'>
					<ForgotPassword />
				</Route>
				<Route exact path='/reset-password/:token'>
					<ForgotPassword />
				</Route>
				<Route exact path='/doctor-password'>
					{userId ? (
						<DoctorChangePassword userId={userId} />
					) : (
						<Redirect to='/' />
					)}
				</Route>
				<Route>
					<Redirect to='/' />
				</Route>
			</Switch>
		</MainLayout>
	)
}

const MainRouter = () => {
	const [headerNavCont, setNav] = useState({
		defaultKey: ['1'],
		headerTitle: 'Title',
		hideHeader: false,
		hideSideBar: false,
		collapseBar: false,
		userId: (decodeToken(localStorage.getItem('token')) || {}).user_id,
		userExp: (decodeToken(localStorage.getItem('token')) || {}).exp,
		userType: (decodeToken(localStorage.getItem('token')) || {}).type,
		is_temp_pass:
			localStorage.getItem('is_temp_pass') === 'true' ? true : false,
	})

	const setNavCont = value => {
		if (
			decodeToken(localStorage.getItem('token')) &&
			(decodeToken(localStorage.getItem('token')) || {}).exp >= moment().unix()
		) {
			setNav(oldState => ({ ...oldState, ...value }))
		} else {
			setNav(oldState => ({
				...oldState,
				defaultKey: ['1'],
				headerTitle: 'Title',
				hideHeader: true,
				hideSideBar: true,
				collapseBar: false,
				employeeId: null,
				userId: null,
				userType: null,
				userExp: null,
			}))
			localStorage.removeItem('token')
			localStorage.removeItem('is_temp_pass')
		}
	}
	const contentstate = {
		states: { headerNavCont },
		stateSetters: { setNavCont },
	}

	return (
		<Router>
			<NotificationComp>
				<HeaderContext.Provider value={contentstate}>
					<Routes />
				</HeaderContext.Provider>
			</NotificationComp>
		</Router>
	)
}

export default MainRouter
