import React from 'react'
import { Card, Row, Col, Typography, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import '../../assets/css/patients/patientprofile.css'
import { useTranslation } from 'react-i18next'
import { getName } from '../common/get-name'

const PatientDetails = props => {
	const { t, i18n } = useTranslation()

	let dataSource = props.data

	return (
		<>
			<Card className='patient-profile-card'>
				<Row gutter={[16, 16]}>
					<Col span={3} style={{ textAlign: 'center' }}>
						<Avatar
							className = "patient-details-image"
							style={{ resizeMode: 'stretch' }}
							icon={<UserOutlined />}
							src={dataSource.imageURL}
						/>
					</Col>
					<Col span={21}>
						<Typography
							className='color-black'
							style={{
								marginLeft: '20px',
								fontWeight: 500,
								fontSize: 18,
								lineHeight: '36px',
							}}
						>
							{getName(
								dataSource.name.first,
								dataSource.name.last,
								i18n.language,
							)}
						</Typography>
						<div className='patient-details-row'>
							<div className='flex-1'>
								<Typography className='fs-12 color-gray'>
									{t('crud.date_of_birth')}
								</Typography>
								<Typography className='fs-14'>{dataSource.dob}</Typography>
							</div>
							<div className='flex-1'>
								<Typography className='fs-12 color-gray'>
									{t('crud.email')}
								</Typography>
								<Typography className='fs-14'>{dataSource.email}</Typography>
							</div>
						</div>
						<div className='patient-details-row' style={{ marginTop: 14 }}>
							<div className='flex-1'>
								<Typography className='fs-12 color-gray'>
									{t('crud.contact_num')}
								</Typography >
								<Typography className='fs-14'>{dataSource.contact}</Typography>
							</div>
							<div className='flex-1' style={{ height: 42 }}>
								<Typography className='fs-12 color-gray'>
									{t('crud.address')}
								</Typography>
								<Typography className='fs-14'>{dataSource.address}</Typography> 
								
							</div>
						</div>
						<div className='patient-details-row' style={{ marginTop: 14 }}>
							<div className='flex-1' style={{ height: 42 }}>
								<Typography className='fs-12 color-gray'>
									{t('patients.account_code')}
								</Typography>
								<Typography className='fs-14'>{dataSource.account_code}</Typography>
							</div>
						</div>
					</Col>
				</Row>
			</Card>
		</>
	)
}
export default PatientDetails
