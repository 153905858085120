import React, { useRef, useEffect, useState, useContext } from 'react'
import TableList from '../common/table-list'
import ViewEditPatient from '../patients/view-edit-patient'
import AddPatient from '../patients/add-patient'
import AddAppointment from '../appointments/add-appointment'
import { commonComponents } from '../common'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { GET_DOCTOR_BY_USER_ID } from '../../graphql/doctors/queries'
import { useTranslation } from 'react-i18next'

import { HeaderContext } from '../../layouts'

const { Sidepanel } = commonComponents

const PatientList = props => {
	const { t } = useTranslation()

	const panel = useRef()
	const history = useHistory()
	const data = props.data

	const { states } = useContext(HeaderContext)
	const { userId, userType } = states.headerNavCont

	const refetch = props.refetch

	const { data: doctorData } = useQuery(GET_DOCTOR_BY_USER_ID, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: { _is_null: true },
				user: {
					id: {
						_eq: userId,
					},
				},
			},
		},
	})

	let doctorDefaultValue = ''
	if (userType === 'doctor') {
		if (doctorData !== undefined) {
			doctorDefaultValue = doctorData.doctors[0].id
		}
	}

	const actionList = [
		{
			name: t('crud.view'),
			function: patientId => {
				userType === 'doctor'
					? history.push(`patients/profile/${patientId}`)
					: panel.current.showPanel(
							<ViewEditPatient
								id={patientId}
								isEditMode={false}
								refetch={refetch}
								panel={panel}
								patientOptions={userType === 'admin' ? null : data}
								doctorLogged={userType === 'doctor' ? doctorDefaultValue : null}
							/>,
					  )
			},
		},
		{
			name: `${t('appointments.add_appointment')}`,
			function: patientId => {
				panel.current.showPanel(
					<AddAppointment
						mode='patient'
						actionFrom='dashboard_list'
						selectedId={patientId}
						refetch={refetch}
						panel={panel}
						patientOptions={userType === 'admin' ? null : data}
						doctorLogged={userType === 'doctor' ? doctorDefaultValue : null}
					/>,
				)
			},
		},
	]

 useEffect(()=>{
   if(['doctor'].includes(userType)){
     actionList.splice(1, 0, {
       name: t('crud.contact'),
       function: (id) => {
         history.push(id ? `messages/${id}` : 'messages')
       },
     })
   }
 }, [userType, actionList, data])

	return (
		<>
			<TableList
				title={
					userType === 'doctor'
						? t('patients.todays_patients')
						: t('patients.list_patients')
				}
				isAddBtnHidden={userType === 'doctor' ? true : false}
				addtitle={t('patients.add_patient')}
				addBtnOnClick={() => {
					panel.current.showPanel(
						<AddPatient mode='patient' refetch={() => props.refetch()} />,
					)
				}}
				tableMode={'today_appointments'}
				columns={[t('common.name'), t('pregnancies.pregnancy_cycle')]}
				items={data}
				actions={actionList}
				viewbtnprops={{
					title: t('patients.view_patients'),
					onClick: () => {
						history.push(`patients`)
					},
				}}
			/>
			<Sidepanel ref={panel} />
		</>
	)
}

export default PatientList
