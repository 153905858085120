import React, { useState } from 'react'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'

const { Search } = Input

const CommonSearch = props => {
	const { onSearch, placeholder, style = {}, isTransparent = false } = props

	const { t } = useTranslation()
	const [input, setInput] = useState(null)
	const [onChangeValue, setOnChangeValue] = useState(null)

	const isClear = input !== null && input !== null && input === onChangeValue

	const getEnterButton = () => {
		if (isClear) return <CloseOutlined />

		return <SearchOutlined />
	}

	const getClassName = () => {
		let clear = ''
		let transparent = ''

		if (isClear) clear = ' search-clear'
		if (isTransparent) transparent = ' search-transparent'

		return `search border-transparent${transparent}${clear}`
	}

	const clearSearch = () => {
		onSearch('')
		setInput(null)
		setOnChangeValue(null)
	}

	const search = value => {
		setInput(value)
		onSearch(value)
	}

	const extendOnSearch = value => {
		if (isClear) {
			clearSearch()
		} else {
			search(value)
		}
	}

	const extendOnPressEnter = e => {
		const value = e.currentTarget.value

		extendOnSearch(value)
	}

	return (
		<Search
			style={style}
			placeholder={placeholder || `${t('common.search')}...`}
			onSearch={extendOnSearch}
			onPressEnter={extendOnPressEnter}
			className={getClassName()}
			enterButton={getEnterButton()}
			value={onChangeValue}
			onChange={e => {
				const value = e.currentTarget.value
				setOnChangeValue(value)
			}}
		/>
	)
}

export default CommonSearch
