import React, { useEffect, useState, useRef } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
	Typography,
	Col,
	Row,
	Collapse,
	Button,
	Divider,
	Dropdown,
	Menu,
	Form,
	message,
} from 'antd'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { GET_DELIVERIES } from '../../../../graphql/deliveries/queries'
import { DELETE_DELIVERIES } from '../../../../graphql/deliveries/mutations'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { commonComponents } from '../../../common'
import AddEditDeliveryRecord from './add-edit-drawer'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../../../utils'
import { getName } from '../../../common/get-name'
import { capitalize } from 'lodash'

const { Panel } = Collapse
const { Sidepanel, ConfirmModal } = commonComponents

const CourseRecords = props => {
	const { t, i18n } = useTranslation()

	const { doctor, babies, babyRefetch, pregnancy } = props
	const { pregnancy_id } = useParams()

	const panel = useRef()
	const [form] = Form.useForm()
	const [deliveries, setDeliveries] = useState([])
	const [activePanel, setActivePanel] = useState(undefined)

	const [deleteDeliveries] = useMutation(DELETE_DELIVERIES)
	const { data, refetch } = useQuery(GET_DELIVERIES, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				pregnancy_id: {
					_eq: pregnancy_id,
				},
			},
		},
	})

	useEffect(() => {
		if (!data) return

		setActivePanel(undefined)
		setDeliveries(data.record_of_deliveries)
	}, [data])

	const header = record => {
		return (
			<>
				<Typography
					style={{
						color: '#969999',
						fontWeight: 500,
						fontSize: 12,
						lineHeight: '20px',
					}}
				>
					{record.delivery_timestamp
						? moment(record.delivery_timestamp).format(getDateFormat(i18n))
						: t('crud.n_a')}
				</Typography>
				<Typography
					style={{ color: '#3B3F3F' }}
					className='pregnancy-record-collapse-header'
				>
					{!record.baby_first_name &&
						!record.baby_last_name &&
						t('babies.baby')}
					{(record.baby_first_name || record.baby_last_name) &&
						getName(
							record.baby_first_name,
							record.baby_last_name,
							i18n.language,
						)}
				</Typography>
			</>
		)
	}

	const getLengthOfLabor = time => {
		const start = moment(time).startOf('day')
		const hours = moment(time).diff(start, 'hours')
		const minutes = moment(time).diff(start, 'minutes') - hours * 60

		const hoursText = `${hours} ${
			hours > 1 ? t('common.hours') : t('common.hour')
		}`
		const minutesText = `${minutes} ${
			minutes > 1 ? t('common.minutes') : t('common.minute')
		}`

		if (minutes > 0 && hours > 0)
			return `${hoursText} ${t('common.and')} ${minutesText}`
		else if (hours > 0) return hoursText
		else if (minutes > 0) return minutesText

		return t('crud.n_a')
	}

	const getBabyOrder = order => {
		const babyOrders = [
			t('common.first'),
			t('common.second'),
			t('common.third'),
			t('common.fourth'),
			t('common.fifth'),
		]
		return babyOrders[order - 1]
	}

	const getOptionReference = key => {
		if (!key) return t('crud.n_a')

		const reference = {
			normal: t('record_of_deliveries.normal'),
			breech: t('record_of_deliveries.breech'),
			other: t('record_of_deliveries.other'),
			natural: t('record_of_deliveries.natural'),
			assisted: t('record_of_deliveries.assisted'),
			cesarian: t('record_of_deliveries.cesarian'),
			light: t('record_of_deliveries.light'),
			moderate: t('record_of_deliveries.moderate'),
			heavy: t('record_of_deliveries.heavy'),
			male: t('common.male'),
			female: t('common.female'),
		}

		return reference[key]
	}

	const content = record => {
		const patientDeliveryData = [
			{
				label: t('record_of_deliveries.pregnancy_length'),
				data: record.length_of_pregnancy
					? `${record.length_of_pregnancy} ${
							record.length_of_pregnancy > 1
								? t('common.weeks')
								: t('common.week')
					  }`
					: t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.delivery_date'),
				data: record.delivery_timestamp
					? `${moment(record.delivery_timestamp).format(getDateFormat(i18n))}`
					: t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.delivery_type'),
				data: getOptionReference(record.delivery_type),
			},
			{
				label: t('record_of_deliveries.delivery_method'),
				data: getOptionReference(record.delivery_method),
			},
			{
				label: t('record_of_deliveries.length_of_labor'),
				data: record.length_of_labor
					? `${getLengthOfLabor(record.length_of_labor)}`
					: t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.amount_of_bleeding'),
				data: getOptionReference(record.amount_of_bleeding),
			},
			{
				label: t('record_of_deliveries.blood_transfusion'),
				data: record.blood_transfusion
					? `${record.blood_transfusion}`
					: t('crud.n_a'),
			},
		]

		const babyDeliveryData = [
			{
				label: t('common.name'),
				data:
					record.baby_first_name || record.baby_last_name
						? `${record.baby_first_name || ''} ${record.baby_last_name || ''}`
						: t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.time_of_birth'),
				data: record.delivery_timestamp
					? `${moment(record.delivery_timestamp).format('HH:mm')}`
					: t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.baby_sex'),
				data: getOptionReference(record.baby_sex),
			},
			{
				label: t('record_of_deliveries.baby_order'),
				data: record.baby_order
					? `${getBabyOrder(record.baby_order)}`
					: t('crud.n_a'),
			},
			{
				label: t('health.height'),
				data: record.baby_height ? `${record.baby_height} cm` : t('crud.n_a'),
			},
			{
				label: t('health.weight'),
				data: record.baby_weight ? `${record.baby_weight} kg` : t('crud.n_a'),
			},
			{
				label: t('baby_checkups.head_circumference'),
				data: record.baby_head_circumference
					? `${record.baby_head_circumference} cm`
					: t('crud.n_a'),
			},
			{
				label: t('baby_checkups.chest_circumference'),
				data: record.baby_chest_circumference
					? `${record.baby_chest_circumference} cm`
					: t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.special_conditions'),
				data: record.baby_special_condition || t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.delivery_place'),
				data: record.delivery_place
					? `${record.delivery_place}`
					: t('crud.n_a'),
			},
			{
				label: t('record_of_deliveries.delivery_attendants'),
				data: record.delivery_attendants
					? `${record.delivery_attendants}`
					: t('crud.n_a'),
			},
		]

		let patientDeliveryRecord = patientDeliveryData.map((data, index) => {
			return (
				<Col span={12} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		let babyConditionRecord = babyDeliveryData.map((data, index) => {
			return (
				<Col span={12} key={index}>
					<div>
						<Typography className='pregnancy-record-header'>
							{data.label}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{data.data}
						</Typography>
					</div>
				</Col>
			)
		})

		let doctorName = t('crud.n_a')

		if (record.doctor && record.doctor.id && record.doctor.user.id)
			doctorName = getName(
				record.doctor.user.first_name,
				record.doctor.user.last_name,
				i18n.language,
			)

		return (
			<>
				<Row gutter={[16, 16]}>{patientDeliveryRecord}</Row>
				<Divider dashed />
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<h1 className='dash-title'>
							{t('record_of_deliveries.babys_condition_at_birth')}
						</h1>
					</Col>
					{babyConditionRecord}
				</Row>
				<Divider dashed />
				<div>
					<Typography className='pregnancy-record-header'>
						{t('doctors.doctor')}
					</Typography>
					<Typography className='pregnancy-record-data'>
						{capitalize(doctorName)}
					</Typography>
				</div>
			</>
		)
	}

	const getCurrentBaby = record => {
		if (!record) return {}

		for (let i = 0; i < babies.length; i++) {
			const baby = babies[i]

			if (
				!(
					record.baby_first_name && baby.first_name !== record.baby_first_name
				) &&
				!(record.baby_last_name && baby.last_name !== record.baby_last_name) &&
				!(record.baby_sex && baby.sex !== record.baby_sex)
			) {
				return baby
			}
		}

		return {}
	}

	const menu = record => (
		<Menu>
			<Menu.Item
				onClick={() => {
					panel.current.showPanel(
						<AddEditDeliveryRecord
							form={form}
							record={record}
							refetch={refetch}
							babies={babies}
							babyRefetch={babyRefetch}
							pregnancy={pregnancy}
						/>,
					)
				}}
			>
				{t('crud.edit')}
			</Menu.Item>
			<Menu.Item
				onClick={() =>
					ConfirmModal({
						mtitle: t('confirm.are_you_sure'),
						mcontent: t('confirm.cannot_undo'),
						monOk: () => {
							deleteDeliveries({
								variables: {
									id: record.id,
									baby_id: getCurrentBaby(record).id || null,
								},
							}).then(
								() => {
									refetch()
									babyRefetch()
									message.success(t('record_of_deliveries.success_delete'))
								},
								changeError => {
									message.error(changeError.message)
								},
							)
						},
						t,
					})
				}
			>
				{t('crud.remove')}
			</Menu.Item>
		</Menu>
	)

	const items = deliveries.map((record, index) => {
		return (
			<Panel
				header={header(record)}
				key={index}
				className='site-collapse-custom-panel'
				style={{ padding: '10px 0' }}
			>
				<Dropdown overlay={menu(record)} placement='bottomRight'>
					<EllipsisOutlined className='ellipsis' />
				</Dropdown>
				{content(record)}
			</Panel>
		)
	})

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>
						{t('record_of_deliveries.patients_delivery_record')}
					</h1>
				</Col>
				<Col span={12}>
					<Button
						icon={<PlusOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditDeliveryRecord
									form={form}
									refetch={refetch}
									latestRecord={deliveries[0]}
									doctor={doctor}
									babyRefetch={babyRefetch}
									pregnancy={pregnancy}
								/>,
							)
						}
					>
						<span className='title'>
							{t('record_of_deliveries.add_record')}
						</span>
					</Button>
				</Col>
			</Row>
			<Sidepanel ref={panel} />
			<Collapse
				accordion
				expandIconPosition='right'
				bordered={false}
				style={{ padding: 20 }}
				activeKey={activePanel}
				onChange={key => setActivePanel(key)}
				className='site-collapse-custom-collapse'
			>
				{items}
			</Collapse>
		</>
	)
}
export default CourseRecords
