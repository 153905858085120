import React, { useEffect, useState } from 'react'
import {
	Button,
	DatePicker,
	Input,
	message,
	Form,
	Tag,
	Select,
	TimePicker,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import '../../assets/css/dashboard/addpatientdoctor.css'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// TODO:
const FieldGenerator = props => {
	const { t } = useTranslation()

	const { fields, form, onFinish, mode } = props
	const [date, setDate] = useState(null)

	const items = (fields || []).map(item => {
		const tagRender = subProps => {
			const { label, closable, onClose } = subProps

			return (
				<Tag className='specialty-tag' closable={closable} onClose={onClose}>
					{label}
				</Tag>
			)
		}

		const getCorrectInput = () => {
			if (item.isPassword) {
				return (
					<Input.Password
						style={{ boxShadow: 'none' }}
						className='input-component'
						defaultValue={item.value}
					/>
				)
			} else if (item.type === 'datepicker') {
				const disablePastDt = current => {
					if (item.disableDateDirection === 'before') {
						return current.isBefore(item.disableDateFrom)
					}

					return current.isAfter(item.disableDateFrom)
				}

				return (
					<DatePicker
						className='input-component'
						style={{ width: '100%' }}
						format={item.format}
						defaultValue={item.value}
						allowClear={item.allowClear || false}
						showToday={false}
						onChange={date => {
							setDate(date)
							form.setFieldsValue({ time: null })
						}}
						disabledDate={
							item.disableDateFrom !== undefined ? disablePastDt : false
						}
					/>
				)
			} else if (item.timeFormat) {
				const range = (start, end) => {
					const result = []
					for (let i = start; i < end; i++) {
						result.push(i)
					}
					return result
				}

				const disableHours = () => {
					if (!date) return range(0, 24)

					if (item.disablePast && date && date.isSame(moment(), 'day')) {
						let from = 0

						if (+date.format('HH') > item.enableHrFrom) {
							from = +date.format('HH')
							if (from === item.enableHrTo && +date.format('mm') > 0) from += 1
						} else from = item.enableHrFrom

						return range(0, from).concat(range(item.enableHrTo + 1, 24))
					} else
						return range(0, item.enableHrFrom).concat(
							range(item.enableHrTo + 1, 24),
						)
				}

				const disableMinutes = selectedHour => {
					if (selectedHour === -1 || !date) return range(0, 60)

					if (
						item.disablePast &&
						date &&
						date.isSame(moment(), 'day') &&
						+date.format('HH') === selectedHour
					) {
						if (selectedHour === item.enableHrTo) return range(1, 60)
						else return range(0, +date.format('mm'))
					} else {
						if (selectedHour === item.enableHrTo) return range(1, 60)
						else return []
					}
				}

				const getDefaultTime = () => {
					let defaultTime = moment(item.enableHrFrom, 'HH:mm')
					let dateVal = moment(date).format('YYYY-MM-DD').toString()
					let currDate = moment().format('YYYY-MM-DD').toString()

					if (moment(currDate).isSame(moment(dateVal))) {
						defaultTime = moment(date, 'HH:mm')
					}

					return defaultTime
				}

				return (
					<TimePicker
						className='input-component'
						style={{ width: '100%' }}
						format={item.timeFormat}
						defaultValue={item.value}
						defaultOpenValue={getDefaultTime()}
						showNow={false}
						allowClear={item.allowClear || false}
						disabledHours={disableHours}
						disabledMinutes={selectedHour => disableMinutes(selectedHour)}
					/>
				)
			} else if (item.options) {
				return (
					<Select
						tagRender={tagRender}
						mode={item.mode || 'multiple'}
						placeholder={item.placeholder}
						block
						suffixIcon={<DownOutlined />}
						showArrow
						showSearch
						optionFilterProp='label'
						bordered={false}
						options={item.options}
						defaultValue={item.value || undefined}
						disabled={item.value ? true : false}
					/>
				)
			} else {
				return <Input disabled={item.disabled} className='input-component' />
			}
		}
		return (
			<Form.Item
				name={item.fieldName}
				label={item.label.toUpperCase()}
				rules={
					item.rules
						? item.rules
						: [
								{
									required: true,
									message: t('dialog.required_field'),
								},
						  ]
				}
				initialValue={item.value}
				className='doctor-field-input'
			>
				{getCorrectInput()}
			</Form.Item>
		)
	})

	return (
		<>
			<Form form={form} layout='vertical' hideRequiredMark onFinish={onFinish}>
				{items}
				<Form.Item className='btn-container'>
					<Button block className='block-button' htmlType='submit'>
						{t('crud.add')} {mode}
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}

export default FieldGenerator
