import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import '../../assets/css/dashboard/dashboard.css'
import { Row, Col } from 'antd'
import { dashboardComponents } from '../../components'
import { GET_ADMIN_DASHBOARD_DATA } from '../../graphql/dashboard/queries'
import moment from 'moment'
import {
	transformDoctors,
	transformAllPatients,
	transformAppointments,
} from '../../utils/dashboard-data'
import { useTranslation } from 'react-i18next'
import { getName } from '../../components/common/get-name'

/* Sample import of component */
const {
	DoctorList,
	PatientList,
	ActiveAppointments,
	Newsfeed,
} = dashboardComponents

const DashboardPage = () => {
	const { t, i18n } = useTranslation()

	const [dashboardData, setDashboardData] = useState({
		active: null,
		doctors: null,
		patients: null,
	})

	const [activeDate, setActiveDate] = useState(moment())

	const { data, refetch } = useQuery(GET_ADMIN_DASHBOARD_DATA, {
		fetchPolicy: 'network-only',
		variables: {
			active_where: {
				datetime: {
					_gte: moment(activeDate).startOf('day'),
					_lte: moment(activeDate).endOf('day'),
				},
				status: {
					_eq: 'active',
				},
			},
			patients_where: {
				deleted_at: {
					_is_null: true,
				},
			},
			doctors_where: {
				deleted_at: {
					_is_null: true,
				},
			},
		},
	})

	useEffect(() => {
		if (data) {
			setDashboardData({
				...dashboardData,
				active: data.active,
				doctors: data.doctors,
				patients: data.patients,
			})
		}
	}, [data])

	const extendGetName = (first_name, last_name) => {
		return getName(first_name, last_name, i18n.language)
	}

	return (
		<span className="admin-dash-page">
			<Row  gutter={[16, 16]}>
				<Col span={12} xs={24} sm={24} md={12}>
					<ActiveAppointments
						data={
							transformAppointments(dashboardData.active, extendGetName) || []
						}
						refetch={refetch}
						date={activeDate}
						setDate={setActiveDate}
					/>
				</Col>
				<Col span={12} xs={24} sm={24} md={12}>
					<Newsfeed />
				</Col>
			</Row>
			<Row style={{height: '16px'}}></Row>
			<Row gutter={[16, 16]}>
				<Col span={12} xs={24} sm={24} md={12}>
					<DoctorList
						data={transformDoctors(dashboardData.doctors, extendGetName) || []}
						refetch={refetch}
					/>
				</Col>
				<Col span={12} xs={24} sm={24} md={12}>
					<PatientList
						data={
							transformAllPatients(dashboardData.patients, t, extendGetName) ||
							[]
						}
						refetch={refetch}
					/>
				</Col>
			</Row>
		</span>
	)
}

export default DashboardPage
