import React from 'react'
import './App.css'
import { ApolloClient } from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { MainRouter } from './routes'
import { ConfigProvider } from 'antd'
import jpLocale from 'antd/es/locale/ja_JP'
import enLocale from 'antd/es/locale/en_US'
import frLocale from 'antd/es/locale/fr_FR'
import { useTranslation } from 'react-i18next'
import { tokenGenerate, checkBrowserSupport } from './firebase-messaging'

const HASURA_API_WS_URL =
	'localhost' === process.env.REACT_APP_API_HOST.substring(0, 9)
		? `ws://${process.env.REACT_APP_API_HOST}/v1/graphql`
		: `wss://${process.env.REACT_APP_API_HOST}/v1/graphql`
const HASURA_API_HTTP_URL =
	'localhost' === process.env.REACT_APP_API_HOST.substring(0, 9)
		? `http://${process.env.REACT_APP_API_HOST}/v1/graphql`
		: `https://${process.env.REACT_APP_API_HOST}/v1/graphql`

function App() {
	// Language initialization
	const { i18n } = useTranslation()
	let locale = null

	if (i18n.language === 'en') locale = enLocale
	else if (i18n.language === 'fr') locale = frLocale
	else locale = jpLocale

	// Notification setup
	if (checkBrowserSupport()){
		if (Notification.permission == 'granted'){
			tokenGenerate(i18n.language)
		} else {
			Notification.requestPermission((status) => {
				console.log('Notification permission status:', status);
				if (Notification.permission == 'granted'){
					tokenGenerate(i18n.language)
				}
			})
		}
	}
	
	const getHeaders = () => {
		const token = localStorage.getItem('token')
		let headers = {}

		if (token) {
			headers.authorization = `Bearer ${token}`
		} else {
			headers['x-hasura-admin-secret'] = process.env.REACT_APP_API_SECRET
		}

		return headers
	}

	const httpLink = new HttpLink({
		uri: HASURA_API_HTTP_URL,
		headers: getHeaders(),
	})

	const subscriptionClient = new SubscriptionClient(HASURA_API_WS_URL, {
		reconnect: true,
		timeout: 60000,
		lazy: true,
		connectionParams: () => {
			return { headers: getHeaders() }
		},
		connectionCallback: err => {
			if (err) {
				wsLink.subscriptionClient.close(false, false)
			}
		},
	})

	subscriptionClient.maxConnectTimeGenerator.duration = () => {
    return subscriptionClient.maxConnectTimeGenerator.max
	}
	
	const wsLink = new WebSocketLink(subscriptionClient)

	const link = split(
		// split based on operation type
		({ query }) => {
			const { kind, operation } = getMainDefinition(query)
			return kind === 'OperationDefinition' && operation === 'subscription'
		},
		wsLink,
		httpLink,
	)

	const client = new ApolloClient({
		link,
		cache: new InMemoryCache({
			addTypename: true,
		}),
	})

	return (
		<ApolloProvider client={client}>
			<ConfigProvider locale={locale}>
				<MainRouter />
			</ConfigProvider>
		</ApolloProvider>
	)
}

export default App
