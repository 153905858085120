import gql from 'graphql-tag'

export const UPSERT_DELIVERIES = gql`
	mutation UPSERT_DELIVERIES($objects: [record_of_deliveries_insert_input!]!) {
		insert_record_of_deliveries(
			objects: $objects
			on_conflict: {
				constraint: record_of_deliveries_pkey
				update_columns: [
					length_of_pregnancy
					delivery_place
					delivery_attendants
					delivery_timestamp
					delivery_type
					delivery_method
					length_of_labor
					amount_of_bleeding
					blood_transfusion
					baby_first_name
					baby_last_name
					baby_sex
					baby_order
					baby_height
					baby_weight
					baby_head_circumference
					baby_chest_circumference
					baby_special_condition
				]
			}
		) {
			affected_rows
		}
	}
`

export const UPDATE_DELIVERIES = gql`
	mutation UPDATE_DELIVERIES(
		$_set: record_of_deliveries_set_input!
		$where: record_of_deliveries_bool_exp!
	) {
		update_record_of_deliveries(where: $where, _set: $_set) {
			affected_rows
		}
	}
`

export const DELETE_DELIVERIES = gql`
	mutation UPDATE_DELIVERIES($id: uuid!, $baby_id: uuid!) {
		update_record_of_deliveries(
			where: { id: { _eq: $id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_babies(
			where: { id: { _eq: $baby_id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_baby_checkups(
			where: { baby_id: { _eq: $baby_id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
		update_baby_immunizations(
			where: { baby_id: { _eq: $baby_id } }
			_set: { deleted_at: "now()" }
		) {
			affected_rows
		}
	}
`
