import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
	Form,
	message,
	Spin,
	Divider,
	Radio,
	Row,
	Typography,
	Col,
	Select,
	InputNumber,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_HEALTH_REFERENCES } from '../../../../graphql/health-references/mutations'
import { useTranslation } from 'react-i18next'

const { BlockButton } = commonComponents

const AddEditHealthRecord = props => {
	const { t } = useTranslation()

	const { hidePanel, visible, record, form, refetch, illnesses } = props
	const { pregnancy_id } = useParams()
	const [isLoading, setLoading] = useState(false)
	const [upsertHealthReferences] = useMutation(UPSERT_HEALTH_REFERENCES)

	useEffect(() => {
		form.resetFields()
	}, [visible])

	const removeUndefinedValuesAndEmptyStringToNull = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else newValues[key] = values[key]
			}
		}

		return newValues
	}

	const handleUpdate = values => {
		setLoading(true)

		upsertHealthReferences({
			variables: {
				objects: {
					id: record && record.id ? record.id : undefined,
					pregnancy_id,
					...removeUndefinedValuesAndEmptyStringToNull(values),
				},
			},
		}).then(
			() => {
				refetch()
				hidePanel()
				setLoading(false)
				form.resetFields()
				if (record && record.id) message.success(t('health.success_update'))
				else message.success(t('health.success_add'))
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const IllnessesFormItems = () => {
		return illnesses.map(formItem => {
			return (
				<Row>
					<Col span={2}></Col>
					<Col span={22}>
						<Form.Item
							name={formItem.name}
							label={formItem.label}
							initialValue={
								record && record[formItem.name] !== null
									? record[formItem.name]
									: undefined
							}
						>
							<Radio.Group className='w-full'>
								<Row>
									<Col span={6}>
										<Radio value={true}>{t('common.yes')}</Radio>
									</Col>
									<Col span={18}>
										<Radio value={false}>{t('common.no')}</Radio>
									</Col>
								</Row>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			)
		})
	}

	return (
		<Form form={form} layout='vertical' onFinish={handleUpdate}>
			<div className='drawer-title'>
				{!record && t('health.add_health_record')}
				{record && t('health.update_health_record')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				<Form.Item
					name='height'
					label={`${t('health.height')} (cm)`}
					className='field-input'
					initialValue={record && record.height !== null ? record.height : ''}
				>
					<InputNumber
						autoComplete='off'
						style={{ width: '100%' }}
						className='input-component'
						placeholder='e.g. 165'
						min={0}
						max={99999}
					/>
				</Form.Item>
				<Form.Item
					name='weight'
					label={`${t('health.weight')} (kg)`}
					className='field-input'
					initialValue={record && record.weight !== null ? record.weight : ''}
				>
					<InputNumber
						autoComplete='off'
						style={{ width: '100%' }}
						className='input-component'
						placeholder='e.g. 58'
						min={0}
						max={99999}
					/>
				</Form.Item>
				<Form.Item
					name='blood_type'
					label={t('health.blood_type')}
					className='field-input'
					initialValue={
						record && record.blood_type !== null ? record.blood_type : ''
					}
				>
					<Select
						showArrow
						bordered={false}
						autoComplete='off'
						options={[
							{ value: 'A+', label: 'A+' },
							{ value: 'A-', label: 'A-' },
							{ value: 'AB+', label: 'AB+' },
							{ value: 'AB-', label: 'AB-' },
							{ value: 'B+', label: 'B+' },
							{ value: 'B-', label: 'B-' },
							{ value: 'O+', label: 'O+' },
							{ value: 'O-', label: 'O-' },
						]}
						style={{ width: '100%' }}
						suffixIcon={<DownOutlined />}
						placeholder={t('health.select_blood_type')}
					/>
				</Form.Item>
				<Typography
					className='dash-title'
					style={{ float: 'unset', marginBottom: 20 }}
				>
					{t('health.has_patient_acquired_illness')}
				</Typography>
				{IllnessesFormItems()}
				<Form.Item
					name='smoker'
					label={t('health.smokes_tabacco')}
					initialValue={record && record.smoker !== null ? record.smoker : ''}
				>
					<Radio.Group className='w-full'>
						<Row>
							<Col span={6}>
								<Radio value={true}>{t('common.yes')}</Radio>
							</Col>
							<Col span={18}>
								<Radio value={false}>{t('common.no')}</Radio>
							</Col>
						</Row>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					name='drinker'
					label={t('health.drink')}
					initialValue={record && record.drinker !== null ? record.drinker : ''}
				>
					<Radio.Group className='w-full'>
						<Row>
							<Col span={6}>
								<Radio value={true}>{t('common.yes')}</Radio>
							</Col>
							<Col span={18}>
								<Radio value={false}>{t('common.no')}</Radio>
							</Col>
						</Row>
					</Radio.Group>
				</Form.Item>
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditHealthRecord
