import gql from 'graphql-tag'

export const UPSERT_HEALTH_REFERENCES = gql`
	mutation UPSERT_HEALTH_REFERENCES(
		$objects: [health_references_insert_input!]!
	) {
		insert_health_references(
			objects: $objects
			on_conflict: {
				constraint: health_references_pkey
				update_columns: [
					height
					weight
					high_blood
					chronic_neprhitis
					diabetis_mellitis
					hepatitis
					heart_desease
					tyroid_desease
					drinker
					smoker
					blood_type
				]
			}
		) {
			affected_rows
		}
	}
`
