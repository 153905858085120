import gql from 'graphql-tag'

export const UPSERT_LIVING_STATUSES = gql`
	mutation UPSERT_LIVING_STATUSES($objects: [living_statuses_insert_input!]!) {
		insert_living_statuses(
			objects: $objects
			on_conflict: {
				constraint: living_statuses_pkey
				update_columns: [
					job
					work_schedule
					transportation
					commute
					time_off
					changed_jobs
					permanently_left
					maternity_leave_before_birth
					maternity_leave_after_birth
					paternity_leave_before_birth
					paternity_leave_after_birth
					living_situation
					living_environment
					sunshine_exposure
					housemates
				]
			}
		) {
			affected_rows
		}
	}
`
