import React, { useState, useEffect } from 'react'
import { Typography, Progress, Card, Col, Row } from 'antd'
import moment from 'moment'
import '../../assets/css/patients/patientprofile.css'
import { useTranslation } from 'react-i18next'
import { getDateFormat } from '../../utils'

const PatientPregnancy = props => {
	const { t, i18n } = useTranslation()

	const [hasCurrentPregnancy, setHasCurrentPregnancy] = useState(false)
	const [counter, setCounter] = useState({
		progress: 0,
		elapsed_weeks: 0,
		remaining_weeks: 0,
	})

	const data = props.data

	useEffect(() => {
		if (data && data.start_date && data.record_of_deliveries === 0) {
			setHasCurrentPregnancy(true)

			const start_date = moment(data.start_date).format('YYYY-MM-DD')
			const due_date = moment(data.due_date).format('YYYY-MM-DD')

			const elapsed_weeks = moment().diff(moment(start_date), 'weeks') + 1
			const remaining_weeks = moment(due_date).diff(moment(), 'weeks') + 1

			const total_days = moment(due_date).diff(moment(start_date), 'days')
			const days_from_start_date = moment().diff(moment(start_date), 'days')

			setCounter({
				progress: Math.round((days_from_start_date / total_days) * 100),
				elapsed_weeks: elapsed_weeks,
				remaining_weeks: remaining_weeks,
			})
		}
	}, [data])

	const getCurrentPregnancyDetails = () => {
		if (!data) return t('pregnancies.no_pregnancy_record')

		const weeks = `${counter.elapsed_weeks} ${
			counter.elapsed_weeks > 1 ? t('common.weeks') : t('common.week')
		}`

		if (i18n.language === 'en' || i18n.language === 'fr')
			return `${data.name.first} ${data.name.last} ${t(
				'pregnancies.is_pregnant_for',
			)} ${weeks}`
		else return `${data.name.first} ${data.name.last} ${t('pregnancies.san')} ${t('pregnancies.is_pregnant_for')} ${weeks}${t('pregnancies.desu')}`
	}

	const getRemainingWeeks = () => {
		const weeks =
			counter.remaining_weeks > 1 ? t('common.weeks') : t('common.week')

		if (i18n.language === 'en')
			return `${counter.remaining_weeks} ${weeks} ${t('common.left')}`
		else
			return `${t('common.left')} ${counter.remaining_weeks} ${weeks}`
	}

	return (
		<Card className='patient-profile-card' style={{ height: 210 }}>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<h1 className='dash-title'>{t('pregnancies.current_pregnancy')}</h1>
				</Col>
			</Row>
			{hasCurrentPregnancy && (
				<>
					<Typography className='fs-12 color-gray'>
						{getCurrentPregnancyDetails()}
					</Typography>
					<Progress
						showInfo={false}
						percent={counter.progress}
						strokeColor='#FB5456'
						style={{ margin: '5px 0' }}
					/>
					<Typography className='color-black fs-14'>
						{data
							? `${t('pregnancies.pregnancy_expected_due_date')}: ${moment(
									data.due_date,
							  ).format(getDateFormat(i18n))}`
							: ''}
					</Typography>
					<Typography className='fs-12 color-gray'>
						{getRemainingWeeks()}
					</Typography>
				</>
			)}
			{!hasCurrentPregnancy && (
				<Typography className='color-black fs-14'>
					{t('pregnancies.no_pregnancy')}
				</Typography>
			)}
		</Card>
	)
}
export default PatientPregnancy
