import React from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'

const Filter = props => {
	const { t } = useTranslation()

	const actionList = [
		{
			name: 'all',
			function: () => {
				props.setFilter(null)
			},
		},
		{
			name: 'active',
			function: () => {
				props.setFilter('active')
			},
		},
		{
			name: 'unread',
			function: () => {
				props.setFilter('unread')
			},
		},
	]

	const menu = (
		<Menu className='tablelist-menu'>
			{actionList.map(option => (
				<Menu.Item key={option.name} className='item'>
					<a target='_blank' onClick={option.function}>
						{t(`messages.${option.name}`)}
					</a>
				</Menu.Item>
			))}
		</Menu>
	)
	return (
		<Dropdown
			className='dash-tablelist-dropdown'
			overlay={menu}
			placement='bottomRight'
		>
			<a className='dropdown-link' target='_blank'>
				<EllipsisOutlined className='dash-tablelist-ellipsis' />
			</a>
		</Dropdown>
	)
}

export default Filter
