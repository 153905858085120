import React from 'react'
import StyleButton from './StyleButton'

const InlineStyleControls = props => {
	const { editorState, onToggle, INLINE_STYLES } = props

	const currentStyle = editorState.getCurrentInlineStyle()

	return (
		<div className='RichEditor-controls'>
			{INLINE_STYLES.map(type => (
				<StyleButton
					key={type.label}
					icon={type.icon}
					style={type.style}
					label={type.label}
					onToggle={onToggle}
					active={currentStyle.has(type.style)}
				/>
			))}
		</div>
	)
}

export default InlineStyleControls
