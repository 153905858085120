import PatientList from './patient-list.js'
import PatientDetails from './patient-details.js'
import PatientPreviousAppointmentList from './patient-previous-appointment.js'
import PatientPregnancyList from './patient-pregnancy.js'
import PatientPregnancy from './patient-current-pregnancy.js'
import PatientUpcomingAppointment from './patient-upcoming-appointment.js'

import ViewPatientPanel from './view-edit-patient'

const patientsComponents = {
	PatientList,
	PatientPreviousAppointmentList,
	PatientPregnancyList,
	PatientDetails,
	PatientPregnancy,
	PatientUpcomingAppointment,
}

export { patientsComponents }

export { ViewPatientPanel }
