import React, { useRef, useEffect, useState, useContext } from 'react'
import '../../assets/css/patients/patientslist.css'
import {
	Card,
	List,
	Space,
	Table,
	Button,
	Row,
	Col,
	Divider,
	Pagination,
	Avatar,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { ViewPatientPanel } from '.'
import { commonComponents } from '../common'
import { HeaderContext } from '../../layouts'
import moment from 'moment'

import {
	GET_DOCTOR_PATIENTS,
	GET_ALL_PATIENTS,
} from '../../graphql/patients/queries'
import { useQuery } from '@apollo/react-hooks'
import {
	transformDoctorPatientList,
	transformAdminPatientList,
} from '../../utils/patient-data'

import { GET_DOCTOR_BY_USER_ID } from '../../graphql/doctors/queries'
import AddPatient from './add-patient'
import { useTranslation } from 'react-i18next'
import { getName } from '../common/get-name'
import CommonSearch from '../../components/common/search'

const { Sidepanel } = commonComponents

const PatientsList = () => {
	const { states } = useContext(HeaderContext)
	const { userId, userType } = states.headerNavCont
	const { t, i18n } = useTranslation()

	const panel = useRef()
	const history = useHistory()

	const [dataSourceDoctorPatients, setDataSourceDoctorPatients] = useState([])
	const [dataSourceAllPatients, setDataSourceAllPatients] = useState([])
	const [page, setPage] = useState(1)
	const [count, setCount] = useState(0)
	const [searchKey, setSearchKey] = useState('')
	const [doctorId, setDoctorId] = useState(null)

	const { data: dataDoctorPatients, loading: loadingData, refetch: refetchDoctorPatient } = useQuery(
		GET_DOCTOR_PATIENTS,
		{
			fetchPolicy: 'network-only',
			variables: {
				where: {
					doctor: {
						user_id: {
							_eq: userId,
						},
					},
					patient: {
						user: {
							_or: [
								{
									first_name: {
										_ilike: `%${searchKey}%`,
									},
								},
								{
									last_name: {
										_ilike: `%${searchKey}%`,
									},
								},
							],
						},
						deleted_at: { _is_null: true },
					},
					datetime: {
						_gte: moment().startOf('day').toISOString(),
						_lte: moment().endOf('day').toISOString(),
					},
					status: {
						_eq: 'active',
					},
				},
			},
		},
	)

	const { data: dataAllPatients, loading: loadingAdminPatientData, refetch: refetchAllPatient } = useQuery(
		GET_ALL_PATIENTS,
		{
			fetchPolicy: 'network-only',
			variables: {
				where: {
					user: {
						_or: [
							{
								first_name: {
									_ilike: `%${searchKey}%`,
								},
							},
							{
								last_name: {
									_ilike: `%${searchKey}%`,
								},
							},
						],
					},
					deleted_at: { _is_null: true },
				},
				offset: (page - 1) * 10,
			},
		},
	)

	const { data: doctorData } = useQuery(GET_DOCTOR_BY_USER_ID, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: { _is_null: true },
				user_id: {
					_eq: userId,
				},
			},
		},
	})

	useEffect(() => {
		if (userType === 'doctor')
			setDataSourceDoctorPatients(
				transformDoctorPatientList(dataDoctorPatients, t),
			)
		setDataSourceAllPatients(transformAdminPatientList(dataAllPatients, t))

		if (dataAllPatients) {
			if (dataAllPatients.patients_count) {
				setCount(dataAllPatients.patients_count.aggregate.count)
			}
		}
	}, [dataDoctorPatients, dataAllPatients])

	useEffect(() => {
		if (
			doctorData &&
			userType === 'doctor' &&
			doctorData &&
			doctorData.doctors instanceof Array &&
			doctorData.doctors[0] &&
			doctorData.doctors[0].id
		)
			setDoctorId(doctorData.doctors[0].id)
	}, [doctorData])

	/* Table Columns */
	const tablecolumns = [
		{
			title: t('patients.patient'),
			className: 'patient-table-header patient ',
			dataIndex: 'patient',
			align: 'left',
		},
		{
			title: t('patients.account_code'),
			className: 'patient-table-header code',
			dataIndex: 'code',
			align: 'left',
		},
		{
			title: t('pregnancies.pregnancy_cycle'),
			className: 'patient-table-header cycle',
			dataIndex: 'cycle',
			align: 'left',
		},
		{
			title: t('crud.contact_num'),
			className: 'patient-table-header contact',
			dataIndex: 'contact',
			align: 'left',
		},
		{
			title: t('crud.email'),
			className: 'patient-table-header email',
			dataIndex: 'email',
			align: 'left',
		},
	]

	const dataInfo = item => {
		const newdata = tableDataSource(item)[0]

		const span = 4
		const xsSpan = 12
		
		return (
			<Row gutter = {[16,16]}
				className = "patient-list-data" >
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = { span +  1 }>
					<h2>{t('patients.patient')}</h2>
					<h1>{newdata.patient}</h1>
				</Col>
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('patients.account_code')}</h2>
					<h1>{newdata.code}</h1>
				</Col>
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('pregnancies.pregnancy_cycle')}</h2>
					<h1>{newdata.cycle}</h1>
				</Col>
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('crud.contact_num')}</h2>
					<h1>{newdata.contact}</h1>
				</Col>
				<Col xs = {xsSpan} sm = {xsSpan} md={8} lg = {span}>
					<h2>{t('crud.email')}</h2>
					<h1>{newdata.email}</h1>
				</Col>
			</Row>
		)
	}

	const tableDataSource = item => {
		return [
			{
				patient: extendGetName(item.name.first, item.name.last),
				code: item.code || t('crud.n_a'),
				cycle: item.cycle,
				contact: item.contact || t('crud.n_a'),
				email: item.email,
			},
		]
	}

	const extendGetName = (first_name, last_name) => {
		return getName(first_name, last_name, i18n.language)
	}

	const todaysPatients =
		userType !== 'doctor' ? null : (
			<>
				<div className='patient-header-title'>
					{t('patients.todays_patients')}
				</div>
				<hr />
				<List
					loading={loadingData || loadingAdminPatientData}
					dataSource={dataSourceDoctorPatients}
					itemLayout='vertical'
					size='large'
					locale={{ emptyText: t('empty_messages.patients_list') }}
					renderItem={item => (
						<div>
							<List.Item className='patient-list-item' key={item.key}>
								<Card className='patient-card'>
									<Row gutter={[0,16]}>
										<Col span={1}
											 style= {{minWidth: '70px'}}
										>
											<Avatar
												size={55}
												style={{ resizeMode: 'stretch' }}
												icon={<UserOutlined />}
												src={item.imageURL}
											/>
										</Col>
										<Col 
											lg = {22}
											md = {17}
											sm = {20}
											flex = "auto"
										>
											{dataInfo(item)}
										</Col>
										<Col lg = {2}
											 md = {4}
											 flex = "auto"
										>
											<Button
												className='horizontal-resizable-btn patient-list-button-view'
												onClick={() => {
													if (userType === 'admin')
														panel.current.showPanel(
															<ViewPatientPanel
																isEditMode={false}
																data={item}
																panel={panel}
																patientOptions={
																	userType === 'admin'
																		? null
																		: dataDoctorPatients
																}
																doctorLogged={
																	userType === 'doctor' ? doctorId : null
																}
															/>,
														)
													else {
														history.push(`patients/profile/${item.key}`)
													}
												}}
											>
												{t('crud.view')}
											</Button>
										</Col>
									</Row>
								</Card>
							</List.Item>
						</div>
					)}
				/>
			</>
		)

	const handleSearch = async value => {
		await setPage(1)

		setSearchKey(value)
	}

	const refetchData = () => {
		refetchDoctorPatient()
		refetchAllPatient()
	}

	const handleAddPatient = () => {
		panel.current.showPanel(<AddPatient mode='patient' refetch={refetchData} />)
	}

	return (
		<>
			<div>
				<div className='text-right'>
					<Space>
						<Button
							className='dash-add-button'
							type='link'
							onClick={handleAddPatient}
						>
							+ {t('patients.add_patient')}
						</Button>
						<CommonSearch onSearch={value => handleSearch(value)} />
					</Space>
				</div>
				{todaysPatients}
				<div>
					<Divider className='divider' />
					<div
						className='noto text-14 text-black'
						style={{ marginTop: '-18px' }}
					>
						{t('patients.index')}
					</div>
					<Divider className='divider' />
					<List
						loading={loadingAdminPatientData || loadingData}
						dataSource={dataSourceAllPatients}
						itemLayout='vertical'
						size='large'
						locale={{ emptyText: t('empty_messages.patients_list') }}
						renderItem={item => (
							<div>
								<List.Item className='patient-list-item' key={item.key}>
									<Card className='patient-card'>
										<Row gutter = {[0,16]}>
											<Col span={1}
												 style= {{minWidth: '70px'}}
											>
												<Avatar
													size={55}
													style={{ resizeMode: 'stretch' }}
													icon={<UserOutlined />}
													src={item.imageURL}
												/>
											</Col>
											<Col 
												lg = {22}
												md = {17}
												sm = {20}
												flex = "auto"
											>
												{dataInfo(item)}
											</Col>
											<Col lg = {2}
												 md = {4}
												 flex = "auto">
												<Button
													className='horizontal-resizable-btn  patient-list-button-view'
													onClick={() => {
														if (userType === 'admin')
															panel.current.showPanel(
																<ViewPatientPanel
																	isEditMode={false}
																	id={item.key}
																	panel={panel}
																	refetch={
																		userType === 'admin'
																			? refetchAllPatient
																			: userType === 'doctor'
																			? refetchDoctorPatient
																			: () => {}
																	}
																	patientOptions={
																		userType === 'admin'
																			? null
																			: dataSourceAllPatients
																	}
																	doctorLogged={
																		userType === 'doctor' ? doctorId : null
																	}
																/>,
															)
														else {
															history.push(`patients/profile/${item.key}`)
														}
													}}
												>
													{t('crud.view')}
												</Button>
											</Col>
										</Row>
									</Card>
								</List.Item>
							</div>
						)}
					/>
				</div>
			</div>
			<Pagination
				className='float-right'
				current={page}
				total={count}
				hideOnSinglePage={true}
				showSizeChanger={false}
				onChange={page => {
					setPage(page)
				}}
			/>

			<Sidepanel ref={panel} />
		</>
	)
}

export default PatientsList
