import PregnancyDetails from './pregnancy-details.js'
import PregnancyRecords from './pregnancy-records.js'
import BabyRecords from './baby-records.js'

const pregnancyComponents = {
  PregnancyDetails,
  PregnancyRecords,
  BabyRecords
}

export { pregnancyComponents }