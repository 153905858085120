import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Divider, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { patientsComponents } from '../../components'
import '../../assets/css/patients/patients.css'
import { GET_DOCTOR_BY_USER_ID } from '../../graphql/doctors/queries'
import { GET_PATIENT_DETAILS } from '../../graphql/patients/queries'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { HeaderContext } from '../../layouts'
import { getDateFormat } from '../../utils'
import { getName } from '../../components/common/get-name'

const {
	PatientPreviousAppointmentList,
	PatientPregnancyList,
	PatientDetails,
	PatientPregnancy,
	PatientUpcomingAppointment,
} = patientsComponents

const PatientProfilePage = () => {
	const { t, i18n } = useTranslation()

	let { patient_id } = useParams()
	const { states } = useContext(HeaderContext)
	const { userId, userType } = states.headerNavCont
	const [doctorInfo, setDoctorInfo] = useState({})
	const [patientInfo, setPatientInfo] = useState({
		patient_details: {
			key: null,
			imageURL: null,
			name: {
				first: null,
				last: null,
			},
			date: null,
			dob: null,
			email: null,
			contact: null,
			address: null,
			account_code: null,
		},
		current_pregnancy: null,
		patient_pregnancies: [],
		upcoming_appointments: [],
		previous_appointments: [],
	})

	const { data, refetch } = useQuery(GET_PATIENT_DETAILS, {
		fetchPolicy: 'network-only',
		variables: {
			patients_where: {
				id: {
					_eq: patient_id,
				},
				deleted_at: { _is_null: true },
			},
			current_pregnancy_where: {
				start_date: {
					_lte: moment().format('YYYY-MM-DD'),
				},
				due_date: {
					_gte: moment().format('YYYY-MM-DD'),
				},
			},
			old_pregnancies_where: null,
			upcoming_appointments_where: {
				datetime: {
					_gte: moment().subtract(30, 'minutes').format(),
				},
				status: {
					_neq: 'cancelled',
				},
			},
			previous_appointments_where: {
				datetime: {
					_lt: moment().subtract(30, 'minutes').format(),
				},
				status: {
					_eq: 'active',
				},
			},
		},
	})

	const { data: doctorData } = useQuery(GET_DOCTOR_BY_USER_ID, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: { _is_null: true },
				user_id: {
					_eq: userId,
				},
			},
		},
	})

	let doctorDefaultValue = ''
	if (userType === 'doctor') {
		if (doctorData) {
			doctorDefaultValue = doctorData.doctors[0].id
		}
	}

	useEffect(() => {
		if (doctorData) {
			setDoctorInfo(doctorData.doctors[0] || {})
		}
	}, [doctorData])

	useEffect(() => {
		if (data) {
			setPatientInfo({
				...patientInfo,
				patient_details: {
					key: data.patients[0].id,
					imageURL: data.patients[0].user.avatar_link || null,
					name: {
						first: data.patients[0].user.first_name,
						last: data.patients[0].user.last_name,
					},
					dob:
						(data.patients[0].date_of_birth
							? moment(data.patients[0].date_of_birth).format(
									getDateFormat(i18n),
							  )
							: null) || t('crud.n_a'),
					email: data.patients[0].user.email,
					contact: data.patients[0].user.telephone || t('crud.n_a'),
					address: data.patients[0].user.address || t('crud.n_a'),
					account_code: data.patients[0].account_code || t('crud.n_a'),
				},
				current_pregnancy: data.patients[0].current_pregnancy[0]
					? {
							name: {
								first: data.patients[0].user.first_name,
								last: data.patients[0].user.last_name,
							},
							start_date: data.patients[0].current_pregnancy[0].start_date
								? moment(
										data.patients[0].current_pregnancy[0].start_date,
								  ).format('YYYY-MM-DD')
								: null,
							due_date: moment(
								data.patients[0].current_pregnancy[0].due_date,
							).format('YYYY-MM-DD'),
							record_of_deliveries:
								data.patients[0].current_pregnancy[0].record_of_deliveries
									.length,
					  }
					: null,
				patient_pregnancies: data.patients[0].pregnancies || [],
				current_appointments: data.patients[0].current_appointments,
				upcoming_appointments: data.patients[0].upcoming_appointments,
				previous_appointments: data.patients[0].previous_appointments,
			})
		}
	}, [data])

	return (
		<>
			<Breadcrumb separator='>'>
				<Breadcrumb.Item>
					<Link to='/patients' className='patient-header-hightlight'>
						{t('patients.index')}
					</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					<span className='patient-header-title'>
						{getName(
							patientInfo.patient_details.name.first,
							patientInfo.patient_details.name.last,
							i18n.language,
						)}
					</span>
				</Breadcrumb.Item>
			</Breadcrumb>
			<Divider
				style={{
					backgroundColor: 'rgba(151, 151, 151, 0.5)',
					marginTop: '14px',
					marginBottom: '22px',
				}}
			/>
			<Row gutter={[16, 16]}>
				<Col xs = {24} sm ={24} md = {12}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<PatientDetails data={patientInfo.patient_details} />
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<PatientPregnancy data={patientInfo.current_pregnancy} />
						</Col>
					</Row>
				</Col>
				<Col xs = {24} sm ={24} md = {12} >
					<PatientPregnancyList
						data={patientInfo.patient_pregnancies}
						patient={patientInfo.patient_details.key}
						doctor={doctorInfo.id}
						refetch={refetch}
					/>
				</Col>
			</Row>
			<Row style={{height:'16px'}}></Row>
			<Row gutter={[16, 16]}>
				<Col xs = {24} sm ={24} md = {12}>
					<PatientUpcomingAppointment
						data={patientInfo.upcoming_appointments}
						patient={patientInfo.patient_details.key}
						current_apointments
						refetch={refetch}
						doctor={doctorDefaultValue}
					/>
				</Col>
				<Col xs = {24} sm ={24} md = {12} className='patient-previous-appointments'>
					<PatientPreviousAppointmentList
						data={patientInfo.previous_appointments}
						refetch={refetch}
					/>
				</Col>
			</Row>
		</>
	)
}

export default PatientProfilePage
