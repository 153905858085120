import React, { useEffect, useState, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { Typography, Col, Row, Button, Divider, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import '../../../../assets/css/pregnancy/pregnancy-records.css'
import { GET_HEALTH_REFERENCES } from '../../../../graphql/health-references/queries'
import { commonComponents } from '../../../common'
import moment from 'moment'
import AddEditHealthRecord from './add-edit-drawer'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

const { Sidepanel } = commonComponents

const HealthRecords = () => {
	const { t, i18n } = useTranslation()

	const { pregnancy_id } = useParams()

	const panel = useRef()
	const [form] = Form.useForm()
	const [healthRecord, setHealthRecord] = useState({})
	const illnesses = [
		{ name: 'high_blood', label: t('health.high_blood') },
		{ name: 'chronic_neprhitis', label: t('health.chronic_nephritis') },
		{ name: 'diabetis_mellitis', label: t('health.diabetes_mellitis') },
		{ name: 'hepatitis', label: t('health.hepatitis') },
		{ name: 'heart_desease', label: t('health.heart_disease') },
		{ name: 'tyroid_desease', label: t('health.thyroid_disease') },
	]

	const { data, refetch } = useQuery(GET_HEALTH_REFERENCES, {
		fetchPolicy: 'network-only',
		variables: {
			where: {
				deleted_at: {
					_is_null: true,
				},
				pregnancy_id: {
					_eq: pregnancy_id,
				},
			},
		},
	})

	useEffect(() => {
		if (!data) return

		setHealthRecord(data.health_references[0] || {})
	}, [data])

	const getBMI = () => {
		const height = healthRecord.height
		const weight = healthRecord.weight

		if (!height || !weight) return t('crud.n_a')

		const heightHalf = height / 100

		return (weight / (heightHalf * heightHalf)).toFixed(1)
	}

	const getAge = date => {
		const years = moment().diff(moment(date), 'years')
		if (i18n.language === 'fr')
		return years
			? `${years} ${years > 1 ? t('common.years') : t('common.year')}`
			: t('crud.n_a')
		else
		return years
		? `${years} ${years > 1 ? t('common.years') : t('common.year')} ${t('common.old')}`
		: t('crud.n_a')
	}

	const PatientIllness = () => {
		return illnesses.map(illness => {
			const hasIllness = healthRecord[illness.name]
			return (
				<>
					<Col span={12}>
						<Typography className='pregnancy-record-data'>
							{capitalize(illness.label)}
						</Typography>
					</Col>
					<Col span={12}>
						<Typography
							className={`${
								hasIllness ? 'has-illness' : 'no-illness'
							} pregnancy-record-data`}
						>
							{hasIllness ? t('common.yes') : t('common.no')}
						</Typography>
					</Col>
				</>
			)
		})
	}

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<h1 className='dash-title'>{t('health.patients_health_record')}</h1>
				</Col>
				<Col span={12}>
					<Button
						icon={<EditOutlined />}
						className={'dash-add-button'}
						onClick={() =>
							panel.current.showPanel(
								<AddEditHealthRecord
									form={form}
									refetch={refetch}
									record={healthRecord}
									illnesses={illnesses}
								/>,
							)
						}
					>
						<span className='title'>{t('health.update_health')}</span>
					</Button>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<div style={{ marginBottom: 20 }}>
						<Typography className='pregnancy-record-header'>
							{t('health.height')}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{healthRecord.height
								? `${healthRecord.height} cm`
								: t('crud.n_a')}
						</Typography>
					</div>
					<div style={{ marginBottom: 20 }}>
						<Typography className='pregnancy-record-header'>
							{t('health.weight')}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{healthRecord.weight
								? `${healthRecord.weight} kg`
								: t('crud.n_a')}
						</Typography>
					</div>
					<div>
						<Typography className='pregnancy-record-header'>
							{t('health.blood_type')}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{healthRecord.blood_type || t('crud.n_a')}
						</Typography>
					</div>
				</Col>
				<Col span={12}>
					<div style={{ marginBottom: 20 }}>
						<Typography className='pregnancy-record-header'>
							{t('health.age')}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{healthRecord.pregnancy
								? getAge(healthRecord.pregnancy.patient.date_of_birth)
								: t('crud.n_a')}
						</Typography>
					</div>
					<div>
						<Typography className='pregnancy-record-header'>
							{t('health.bmi')}
						</Typography>
						<Typography className='pregnancy-record-data'>
							{getBMI()}
						</Typography>
					</div>
				</Col>
			</Row>
			<Divider dashed />
			<Typography
				className='dash-title'
				style={{ float: 'unset', marginBottom: 20 }}
			>
				{t('health.has_patient_acquired_illness')}
			</Typography>
			<Row gutter={[16, 16]}>{PatientIllness()}</Row>
			<Divider dashed />
			<Typography
				className='dash-title'
				style={{ float: 'unset', marginBottom: 20 }}
			>
				{t('health.others')}
			</Typography>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Typography className='pregnancy-record-data'>
						{t('health.smokes_tabacco')}
					</Typography>
				</Col>
				<Col span={12}>
					<Typography
						className={`${
							healthRecord.smoker ? 'has-illness' : 'no-illness'
						} pregnancy-record-data`}
					>
						{healthRecord.smoker ? t('common.yes') : t('common.no')}
					</Typography>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Typography className='pregnancy-record-data'>
						{t('health.drinks_alcohol')}
					</Typography>
				</Col>
				<Col span={12}>
					<Typography
						className={`${
							healthRecord.drinker ? 'has-illness' : 'no-illness'
						} pregnancy-record-data`}
					>
						{healthRecord.drinker ? t('common.yes') : t('common.no')}
					</Typography>
				</Col>
			</Row>

			<Sidepanel ref={panel} />
		</>
	)
}
export default HealthRecords
