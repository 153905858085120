import gql from 'graphql-tag'

export const AUTH_LOGIN = gql`
	query LoginUser($obj: Credentials) {
		auth_login(object: $obj) {
			token
		}
	}
`

export const ROLES = gql`
  query Roles {
    roles {
      description
      id
    }
  }
`
