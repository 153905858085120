import React from 'react'
import {
	Button,
	DatePicker,
	Input,
	message,
	Form,
	Tag,
	Select,
	TimePicker,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import '../../../assets/css/dashboard/addpatientdoctor.css'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const FieldGenerator = props => {
	const { t } = useTranslation()

	const { fields, form, onFinish } = props

	const items = (fields || []).map(item => {
		const tagRender = subProps => {
			const { label, closable, onClose } = subProps

			return (
				<Tag className='specialty-tag' closable={closable} onClose={onClose}>
					{label}
				</Tag>
			)
		}

		const getCorrectInput = () => {
			if (item.isPassword) {
				return (
					<Input.Password
						style={{ boxShadow: 'none' }}
						className='input-component'
						defaultValue={item.value}
					/>
				)
			} else if (item.dateFormat) {
				const disablePastDt = current => {
					if (item.disableDateDirection === 'before') {
						return current.isBefore(item.disableDateFrom)
					}

					return current.isAfter(item.disableDateFrom)
				}

				return (
					<DatePicker
						className='input-component'
						style={{ width: '100%' }}
						format={item.dateFormat}
						defaultValue={item.value}
						disabledDate={
							item.disableDateFrom !== undefined ? disablePastDt : false
						}
					/>
				)
			} else if (item.timeFormat) {
				const range = (start, end) => {
					const result = []
					for (let i = start; i < end; i++) {
						result.push(i)
					}
					return result
				}

				const disableHours = () => {
					return range(0, item.enableHrFrom).concat(range(item.enableHrTo, 24))
				}

				const disableMinutes = selectedHour => {
					if (selectedHour == '18') {
						return range(1, 60)
					}
					return []
				}

				return (
					<TimePicker
						className='input-component'
						style={{ width: '100%' }}
						format={item.timeFormat}
						defaultValue={item.value}
						disabledHours={disableHours}
						disabledMinutes={selectedHour => disableMinutes(selectedHour)}
					/>
				)
			} else if (item.options) {
				return (
					<Select
						tagRender={tagRender}
						mode={item.mode || 'multiple'}
						placeholder={t('doctors.select_specialties')}
						block
						suffixIcon={<DownOutlined />}
						showArrow
						bordered={false}
						options={item.options}
						defaultValue={item.value || undefined}
						disabled={item.value ? true : false}
					/>
				)
			} else {
				return <Input disabled={item.disabled} className='input-component' />
			}
		}
		return (
			<Form.Item
				name={item.fieldName}
				label={item.label.toUpperCase()}
				rules={item.rules ? item.rules : []}
				initialValue={item.value}
				className='doctor-field-input'
			>
				{getCorrectInput()}
			</Form.Item>
		)
	})

	return (
		<>
			<Form
				form={form}
				layout='vertical'
				hideRequiredMark
				onFinish={onFinish}
			>
				{items}
				<Form.Item className='btn-container'>
					<Button block className='block-button' htmlType='submit'>
						{t('crud.add')} 
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}

export default FieldGenerator
