import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
	Form,
	message,
	Spin,
	Divider,
	Input,
	Row,
	Typography,
	Col,
	Select,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { commonComponents } from '../../../common'
import '../../../../assets/css/dashboard/appointment.css'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_LIVING_STATUSES } from '../../../../graphql/living-statuses/mutations'
import { useTranslation } from 'react-i18next'

const { BlockButton } = commonComponents

const AddEditLivingStatusRecord = props => {
	const { t } = useTranslation()

	const { hidePanel, visible, record, form, refetch, recordCategories } = props
	const { pregnancy_id } = useParams()
	const [isLoading, setLoading] = useState(false)
	const [upsertLivingStatuses] = useMutation(UPSERT_LIVING_STATUSES)

	useEffect(() => {
		form.resetFields()
	}, [visible])

	const removeUndefinedValuesAndEmptyStringToNull = values => {
		const newValues = {}

		for (const key in values) {
			if (values[key] !== undefined) {
				if (values[key] === '') newValues[key] = null
				else newValues[key] = values[key]
			}
		}

		return newValues
	}

	const handleUpdate = values => {
		setLoading(true)

		upsertLivingStatuses({
			variables: {
				objects: {
					id: record && record.id ? record.id : undefined,
					pregnancy_id,
					...removeUndefinedValuesAndEmptyStringToNull(values),
				},
			},
		}).then(
			() => {
				refetch()
				hidePanel()
				setLoading(false)
				form.resetFields()
				if (record && record.id)
					message.success(t('living_status.success_update'))
				else message.success(t('living_status.success_add'))
			},
			changeError => {
				setLoading(false)
				message.error(changeError.message)
			},
		)
	}

	const RecordCategoryFormItems = () => {
		return recordCategories.map(recordCategory => (
			<>
				<Typography
					className='dash-title'
					style={{ float: 'unset', marginBottom: 20 }}
				>
					{recordCategory.label}
				</Typography>
				{RecordFormItems(recordCategory.records)}
			</>
		))
	}

	const RecordFormItems = records => {
		return records.map(formItem => {
			return (
				<Row>
					<Col span={2}></Col>
					<Col span={22}>
						<Form.Item
							name={formItem.name}
							label={formItem.label}
							className='field-input'
							initialValue={
								record && record[formItem.name] !== null
									? record[formItem.name]
									: undefined
							}
						>
							{getFormField(formItem)}
						</Form.Item>
					</Col>
				</Row>
			)
		})
	}

	const getFormField = formItem => {
		if (formItem.type !== 'select')
			return (
				<Input
					maxLength={15}
					autoComplete='off'
					style={{ width: '100%' }}
					className='input-component'
					placeholder={t('crud.type_here')}
				/>
			)
		else if (formItem.type === 'select')
			return (
				<Select
					showArrow
					bordered={false}
					autoComplete='off'
					options={formItem.options}
					style={{ width: '100%' }}
					suffixIcon={<DownOutlined />}
					placeholder={formItem.placeholder}
				/>
			)
	}

	return (
		<Form
			form={form}
			layout='vertical'
			onFinish={handleUpdate}
		>
			<div className='drawer-title'>
				{!record && t('living_status.add_living_status')}
				{record && t('living_status.update_living_status')}
			</div>
			<Divider dashed />
			<Spin spinning={isLoading} size='medium'>
				{RecordCategoryFormItems()}
				<BlockButton
					style={{ marginTop: '40px' }}
					htmlType='submit'
					title={record ? t('crud.save') : t('crud.add')}
				/>
			</Spin>
		</Form>
	)
}

export default AddEditLivingStatusRecord
